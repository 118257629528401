import { createContext, useContext, useState } from 'react';

export const NavbarContext = createContext();
export const NavbarUpdateContext = createContext();

export const useNavbarState = () => {
  return {
    navbarState: useContext(NavbarContext),
    setNavbarState: useContext(NavbarUpdateContext),
  };
};

export const NavbarProvider = ({ children }) => {
  const [navbarState, setNavbarState] = useState({
    menu: {
      isOpen: false,
    },
    size: 0,
  });

  return (
    <NavbarContext.Provider value={navbarState}>
      <NavbarUpdateContext.Provider value={setNavbarState}>{children}</NavbarUpdateContext.Provider>
    </NavbarContext.Provider>
  );
};
