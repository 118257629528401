export const PANTONE_COLORS = [
  {
    pantoneId: 1,
    name: '11-0103 TCX',
    majorColorName: 'White',
    colorName: 'Egret',
    r: 243,
    g: 236,
    b: 224,
  },
  {
    pantoneId: 2,
    name: '11-0104 TCX',
    majorColorName: 'White',
    colorName: 'Vanilla Ice',
    r: 240,
    g: 234,
    b: 218,
  },
  {
    pantoneId: 3,
    name: '11-0105 TCX',
    majorColorName: 'White',
    colorName: 'Antique White',
    r: 237,
    g: 227,
    b: 210,
  },
  {
    pantoneId: 4,
    name: '11-0107 TCX',
    majorColorName: 'White',
    colorName: 'Papyrus',
    r: 245,
    g: 237,
    b: 214,
  },
  {
    pantoneId: 5,
    name: '11-0205 TCX',
    majorColorName: 'White',
    colorName: 'Glass Green',
    r: 236,
    g: 234,
    b: 208,
  },
  {
    pantoneId: 6,
    name: '11-0304 TCX',
    majorColorName: 'White',
    colorName: 'Water Lily',
    r: 221,
    g: 227,
    b: 213,
  },
  {
    pantoneId: 7,
    name: '11-0410 TCX',
    majorColorName: 'White',
    colorName: 'Sylvan Green',
    r: 231,
    g: 234,
    b: 203,
  },
  {
    pantoneId: 8,
    name: '11-0507 TCX',
    majorColorName: 'White',
    colorName: 'Winter White',
    r: 245,
    g: 236,
    b: 210,
  },
  {
    pantoneId: 9,
    name: '11-0510 TCX',
    majorColorName: 'Orange',
    colorName: 'Afterglow',
    r: 243,
    g: 230,
    b: 201,
  },
  {
    pantoneId: 10,
    name: '11-0601 TCX',
    majorColorName: 'White',
    colorName: 'Bright White',
    r: 244,
    g: 245,
    b: 240,
  },
  {
    pantoneId: 11,
    name: '11-0602 TCX',
    majorColorName: 'White',
    colorName: 'Snow White',
    r: 242,
    g: 240,
    b: 235,
  },
  {
    pantoneId: 12,
    name: '11-0603 TCX',
    majorColorName: 'White',
    colorName: 'Pastel Parchment',
    r: 229,
    g: 217,
    b: 211,
  },
  {
    pantoneId: 13,
    name: '11-0604 TCX',
    majorColorName: 'White',
    colorName: 'Gardenia',
    r: 241,
    g: 232,
    b: 223,
  },
  {
    pantoneId: 14,
    name: '11-0606 TCX',
    majorColorName: 'White',
    colorName: 'Pristine',
    r: 242,
    g: 232,
    b: 218,
  },
  {
    pantoneId: 15,
    name: '11-0609 TCX',
    majorColorName: 'Yellow',
    colorName: 'Ethereal Green',
    r: 241,
    g: 236,
    b: 202,
  },
  {
    pantoneId: 16,
    name: '11-0616 TCX',
    majorColorName: 'Yellow',
    colorName: 'Pastel Yellow',
    r: 242,
    g: 230,
    b: 177,
  },
  {
    pantoneId: 17,
    name: '11-0617 TCX',
    majorColorName: 'Yellow',
    colorName: 'Transparent Yellow',
    r: 244,
    g: 236,
    b: 194,
  },
  {
    pantoneId: 18,
    name: '11-0618 TCX',
    majorColorName: 'Yellow',
    colorName: 'Wax Yellow',
    r: 237,
    g: 233,
    b: 173,
  },
  {
    pantoneId: 19,
    name: '11-0620 TCX',
    majorColorName: 'Yellow',
    colorName: 'Elfin Yellow',
    r: 238,
    g: 234,
    b: 151,
  },
  {
    pantoneId: 20,
    name: '11-0701 TCX',
    majorColorName: 'White',
    colorName: 'Whisper White',
    r: 237,
    g: 230,
    b: 219,
  },
  {
    pantoneId: 21,
    name: '11-0710 TCX',
    majorColorName: 'Yellow',
    colorName: 'Tender Yellow',
    r: 237,
    g: 237,
    b: 183,
  },
  {
    pantoneId: 22,
    name: '11-0809 TCX',
    majorColorName: 'Orange',
    colorName: 'Ecru',
    r: 243,
    g: 223,
    b: 202,
  },
  {
    pantoneId: 23,
    name: '11-0907 TCX',
    majorColorName: 'Orange',
    colorName: 'Pearled Ivory',
    r: 240,
    g: 223,
    b: 204,
  },
  {
    pantoneId: 24,
    name: '11-1005 TCX',
    majorColorName: 'White',
    colorName: 'Bridal Blush',
    r: 238,
    g: 226,
    b: 221,
  },
  {
    pantoneId: 25,
    name: '11-1305 TCX',
    majorColorName: 'White',
    colorName: 'Angel Wing',
    r: 243,
    g: 223,
    b: 215,
  },
  {
    pantoneId: 26,
    name: '11-1306 TCX',
    majorColorName: 'White',
    colorName: 'Cream Pink',
    r: 246,
    g: 228,
    b: 217,
  },
  {
    pantoneId: 27,
    name: '11-1404 TCX',
    majorColorName: 'White',
    colorName: 'Powder Puff',
    r: 243,
    g: 224,
    b: 214,
  },
  {
    pantoneId: 28,
    name: '11-1408 TCX',
    majorColorName: 'White',
    colorName: 'Rosewater',
    r: 246,
    g: 219,
    b: 216,
  },
  {
    pantoneId: 29,
    name: '11-2309 TCX',
    majorColorName: 'White',
    colorName: 'Petal Pink',
    r: 242,
    g: 226,
    b: 224,
  },
  {
    pantoneId: 30,
    name: '11-2409 TCX',
    majorColorName: 'White',
    colorName: 'Delicacy',
    r: 245,
    g: 227,
    b: 226,
  },
  {
    pantoneId: 31,
    name: '11-2511 TCX',
    majorColorName: 'White',
    colorName: 'Shrinking Violet',
    r: 244,
    g: 225,
    b: 230,
  },
  {
    pantoneId: 32,
    name: '11-4201 TCX',
    majorColorName: 'White',
    colorName: 'Cloud Dancer',
    r: 240,
    g: 238,
    b: 233,
  },
  {
    pantoneId: 33,
    name: '11-4202 TCX',
    majorColorName: 'White',
    colorName: 'Star White',
    r: 239,
    g: 239,
    b: 232,
  },
  {
    pantoneId: 34,
    name: '11-4300 TCX',
    majorColorName: 'White',
    colorName: 'Marshmallow',
    r: 240,
    g: 238,
    b: 228,
  },
  {
    pantoneId: 35,
    name: '11-4301 TCX',
    majorColorName: 'White',
    colorName: 'Lily White',
    r: 226,
    g: 226,
    b: 218,
  },
  {
    pantoneId: 36,
    name: '11-4303 TCX',
    majorColorName: 'White',
    colorName: 'Mystic Blue',
    r: 225,
    g: 227,
    b: 222,
  },
  {
    pantoneId: 37,
    name: '11-4601 TCX',
    majorColorName: 'White',
    colorName: 'Bit of Blue',
    r: 226,
    g: 234,
    b: 235,
  },
  {
    pantoneId: 38,
    name: '11-4604 TCX',
    majorColorName: 'White',
    colorName: 'Billowing Sail',
    r: 216,
    g: 231,
    b: 231,
  },
  {
    pantoneId: 39,
    name: '11-4800 TCX',
    majorColorName: 'White',
    colorName: 'Blanc de Blanc',
    r: 231,
    g: 233,
    b: 231,
  },
  {
    pantoneId: 40,
    name: '11-4802 TCX',
    majorColorName: 'White',
    colorName: 'Summer Shower',
    r: 229,
    g: 235,
    b: 227,
  },
  {
    pantoneId: 41,
    name: '11-4803 TCX',
    majorColorName: 'White',
    colorName: 'Ice',
    r: 224,
    g: 228,
    b: 217,
  },
  {
    pantoneId: 42,
    name: '11-4804 TCX',
    majorColorName: 'White',
    colorName: 'Lightest Sky',
    r: 228,
    g: 234,
    b: 223,
  },
  {
    pantoneId: 43,
    name: '11-4805 TCX',
    majorColorName: 'White',
    colorName: 'Hint of Mint',
    r: 216,
    g: 235,
    b: 230,
  },
  {
    pantoneId: 44,
    name: '12-0000 TCX',
    majorColorName: 'White',
    colorName: 'White Swan',
    r: 228,
    g: 215,
    b: 197,
  },
  {
    pantoneId: 45,
    name: '12-0104 TCX',
    majorColorName: 'White',
    colorName: 'White Asparagus',
    r: 225,
    g: 219,
    b: 200,
  },
  {
    pantoneId: 46,
    name: '12-0105 TCX',
    majorColorName: 'White',
    colorName: 'Bone White',
    r: 215,
    g: 208,
    b: 192,
  },
  {
    pantoneId: 47,
    name: '12-0106 TCX',
    majorColorName: 'White',
    colorName: 'Meadow Mist',
    r: 211,
    g: 222,
    b: 196,
  },
  {
    pantoneId: 48,
    name: '12-0108 TCX',
    majorColorName: 'White',
    colorName: 'Canary Green',
    r: 214,
    g: 222,
    b: 201,
  },
  {
    pantoneId: 49,
    name: '12-0109 TCX',
    majorColorName: 'White',
    colorName: 'Ambrosia',
    r: 210,
    g: 231,
    b: 202,
  },
  {
    pantoneId: 50,
    name: '12-0225 TCX',
    majorColorName: 'Green',
    colorName: 'Patina Green',
    r: 185,
    g: 234,
    b: 179,
  },
  {
    pantoneId: 51,
    name: '12-0304 TCX',
    majorColorName: 'White',
    colorName: 'Whitecap Gray',
    r: 224,
    g: 213,
    b: 198,
  },
  {
    pantoneId: 52,
    name: '12-0311 TCX',
    majorColorName: 'White',
    colorName: 'Asparagus Green',
    r: 210,
    g: 205,
    b: 180,
  },
  {
    pantoneId: 53,
    name: '12-0312 TCX',
    majorColorName: 'Green',
    colorName: 'Lime Cream',
    r: 215,
    g: 232,
    b: 188,
  },
  {
    pantoneId: 54,
    name: '12-0313 TCX',
    majorColorName: 'Green',
    colorName: 'Seafoam Green',
    r: 203,
    g: 213,
    b: 177,
  },
  {
    pantoneId: 55,
    name: '12-0315 TCX',
    majorColorName: 'Green',
    colorName: 'White Jade',
    r: 212,
    g: 219,
    b: 178,
  },
  {
    pantoneId: 56,
    name: '12-0317 TCX',
    majorColorName: 'Green',
    colorName: 'Gleam',
    r: 191,
    g: 209,
    b: 173,
  },
  {
    pantoneId: 57,
    name: '12-0322 TCX',
    majorColorName: 'Green',
    colorName: 'Butterfly',
    r: 202,
    g: 222,
    b: 165,
  },
  {
    pantoneId: 58,
    name: '12-0404 TCX',
    majorColorName: 'White',
    colorName: 'Light Gray',
    r: 218,
    g: 216,
    b: 201,
  },
  {
    pantoneId: 59,
    name: '12-0418 TCX',
    majorColorName: 'Yellow',
    colorName: 'Hay',
    r: 211,
    g: 204,
    b: 163,
  },
  {
    pantoneId: 60,
    name: '12-0426 TCX',
    majorColorName: 'Yellow',
    colorName: 'Mellow Green',
    r: 213,
    g: 213,
    b: 147,
  },
  {
    pantoneId: 61,
    name: '12-0435 TCX',
    majorColorName: 'Green',
    colorName: 'Daiquiri Green',
    r: 201,
    g: 215,
    b: 126,
  },
  {
    pantoneId: 62,
    name: '12-0520 TCX',
    majorColorName: 'Green',
    colorName: 'Pale Lime Yellow',
    r: 223,
    g: 230,
    b: 159,
  },
  {
    pantoneId: 63,
    name: '12-0521 TCX',
    majorColorName: 'Yellow',
    colorName: 'Young Wheat',
    r: 225,
    g: 227,
    b: 169,
  },
  {
    pantoneId: 64,
    name: '12-0524 TCX',
    majorColorName: 'Yellow',
    colorName: 'Citron',
    r: 223,
    g: 222,
    b: 155,
  },
  {
    pantoneId: 65,
    name: '12-0525 TCX',
    majorColorName: 'Green',
    colorName: 'Luminary Green',
    r: 227,
    g: 234,
    b: 165,
  },
  {
    pantoneId: 66,
    name: '12-0530 TCX',
    majorColorName: 'Yellow',
    colorName: 'Charlock',
    r: 229,
    g: 231,
    b: 144,
  },
  {
    pantoneId: 67,
    name: '12-0601 TCX',
    majorColorName: 'White',
    colorName: 'Eggnog',
    r: 236,
    g: 225,
    b: 211,
  },
  {
    pantoneId: 68,
    name: '12-0605 TCX',
    majorColorName: 'Orange',
    colorName: 'Angora',
    r: 223,
    g: 209,
    b: 187,
  },
  {
    pantoneId: 69,
    name: '12-0607 TCX',
    majorColorName: 'White',
    colorName: 'Green Essence',
    r: 233,
    g: 234,
    b: 200,
  },
  {
    pantoneId: 70,
    name: '12-0619 TCX',
    majorColorName: 'Yellow',
    colorName: 'Dusty Yellow',
    r: 212,
    g: 204,
    b: 154,
  },
  {
    pantoneId: 71,
    name: '12-0626 TCX',
    majorColorName: 'Yellow',
    colorName: 'Lemon Grass',
    r: 220,
    g: 212,
    b: 148,
  },
  {
    pantoneId: 72,
    name: '12-0633 TCX',
    majorColorName: 'Yellow',
    colorName: 'Canary Yellow',
    r: 223,
    g: 216,
    b: 126,
  },
  {
    pantoneId: 73,
    name: '12-0642 TCX',
    majorColorName: 'Yellow',
    colorName: 'Aurora',
    r: 237,
    g: 221,
    b: 89,
  },
  {
    pantoneId: 74,
    name: '12-0643 TCX',
    majorColorName: 'Yellow',
    colorName: 'Blazing Yellow',
    r: 254,
    g: 231,
    b: 21,
  },
  {
    pantoneId: 75,
    name: '12-0703 TCX',
    majorColorName: 'White',
    colorName: 'Seedpearl',
    r: 230,
    g: 218,
    b: 196,
  },
  {
    pantoneId: 76,
    name: '12-0704 TCX',
    majorColorName: 'Orange',
    colorName: 'White Smoke',
    r: 237,
    g: 220,
    b: 201,
  },
  {
    pantoneId: 77,
    name: '12-0710 TCX',
    majorColorName: 'Orange',
    colorName: 'Shortbread',
    r: 239,
    g: 220,
    b: 195,
  },
  {
    pantoneId: 78,
    name: '12-0712 TCX',
    majorColorName: 'Orange',
    colorName: 'Vanilla',
    r: 244,
    g: 225,
    b: 193,
  },
  {
    pantoneId: 79,
    name: '12-0713 TCX',
    majorColorName: 'Orange',
    colorName: 'Almond Oil',
    r: 244,
    g: 228,
    b: 193,
  },
  {
    pantoneId: 80,
    name: '12-0714 TCX',
    majorColorName: 'Orange',
    colorName: 'Cornhusk',
    r: 242,
    g: 214,
    b: 174,
  },
  {
    pantoneId: 81,
    name: '12-0715 TCX',
    majorColorName: 'Orange',
    colorName: 'Double Cream',
    r: 243,
    g: 224,
    b: 172,
  },
  {
    pantoneId: 82,
    name: '12-0720 TCX',
    majorColorName: 'Yellow',
    colorName: 'Mellow Yellow',
    r: 240,
    g: 221,
    b: 157,
  },
  {
    pantoneId: 83,
    name: '12-0721 TCX',
    majorColorName: 'Yellow',
    colorName: 'Lemonade',
    r: 240,
    g: 231,
    b: 157,
  },
  {
    pantoneId: 84,
    name: '12-0722 TCX',
    majorColorName: 'Yellow',
    colorName: 'French Vanilla',
    r: 239,
    g: 225,
    b: 167,
  },
  {
    pantoneId: 85,
    name: '12-0727 TCX',
    majorColorName: 'Yellow',
    colorName: 'Sunshine',
    r: 250,
    g: 222,
    b: 133,
  },
  {
    pantoneId: 86,
    name: '12-0736 TCX',
    majorColorName: 'Orange',
    colorName: 'Lemon Drop',
    r: 253,
    g: 216,
    b: 120,
  },
  {
    pantoneId: 87,
    name: '12-0738 TCX',
    majorColorName: 'Yellow',
    colorName: 'Yellow Cream',
    r: 239,
    g: 220,
    b: 117,
  },
  {
    pantoneId: 88,
    name: '12-0740 TCX',
    majorColorName: 'Yellow',
    colorName: 'Limelight',
    r: 240,
    g: 232,
    b: 125,
  },
  {
    pantoneId: 89,
    name: '12-0741 TCX',
    majorColorName: 'Green',
    colorName: 'Sunny Lime',
    r: 223,
    g: 239,
    b: 135,
  },
  {
    pantoneId: 90,
    name: '12-0752 TCX',
    majorColorName: 'Yellow',
    colorName: 'Buttercup',
    r: 250,
    g: 224,
    b: 60,
  },
  {
    pantoneId: 91,
    name: '12-0804 TCX',
    majorColorName: 'White',
    colorName: 'Cloud Cream',
    r: 230,
    g: 221,
    b: 197,
  },
  {
    pantoneId: 92,
    name: '12-0806 TCX',
    majorColorName: 'Orange',
    colorName: 'Rutabaga',
    r: 236,
    g: 221,
    b: 190,
  },
  {
    pantoneId: 93,
    name: '12-0807 TCX',
    majorColorName: 'Orange',
    colorName: 'Sun Kiss',
    r: 235,
    g: 209,
    b: 187,
  },
  {
    pantoneId: 94,
    name: '12-0811 TCX',
    majorColorName: 'Orange',
    colorName: 'Dawn',
    r: 235,
    g: 210,
    b: 183,
  },
  {
    pantoneId: 95,
    name: '12-0812 TCX',
    majorColorName: 'Orange',
    colorName: 'Alabaster Gleam',
    r: 240,
    g: 222,
    b: 189,
  },
  {
    pantoneId: 96,
    name: '12-0813 TCX',
    majorColorName: 'Orange',
    colorName: 'Autumn Blonde',
    r: 238,
    g: 208,
    b: 174,
  },
  {
    pantoneId: 97,
    name: '12-0815 TCX',
    majorColorName: 'Orange',
    colorName: 'Vanilla Custard',
    r: 243,
    g: 224,
    b: 190,
  },
  {
    pantoneId: 98,
    name: '12-0817 TCX',
    majorColorName: 'Orange',
    colorName: 'Apricot Gelato',
    r: 245,
    g: 215,
    b: 175,
  },
  {
    pantoneId: 99,
    name: '12-0822 TCX',
    majorColorName: 'Orange',
    colorName: 'Golden Fleece',
    r: 242,
    g: 209,
    b: 160,
  },
  {
    pantoneId: 100,
    name: '12-0824 TCX',
    majorColorName: 'Orange',
    colorName: 'Pale Banana',
    r: 250,
    g: 225,
    b: 153,
  },
  {
    pantoneId: 101,
    name: '12-0825 TCX',
    majorColorName: 'Yellow',
    colorName: 'Popcorn',
    r: 248,
    g: 222,
    b: 141,
  },
  {
    pantoneId: 102,
    name: '12-0826 TCX',
    majorColorName: 'Orange',
    colorName: 'Golden Haze',
    r: 251,
    g: 216,
    b: 151,
  },
  {
    pantoneId: 103,
    name: '12-0910 TCX',
    majorColorName: 'Orange',
    colorName: "Lamb's Wool",
    r: 229,
    g: 208,
    b: 177,
  },
  {
    pantoneId: 104,
    name: '12-0911 TCX',
    majorColorName: 'Orange',
    colorName: 'Nude',
    r: 242,
    g: 211,
    b: 188,
  },
  {
    pantoneId: 105,
    name: '12-0912 TCX',
    majorColorName: 'Orange',
    colorName: 'Tender Peach',
    r: 248,
    g: 213,
    b: 184,
  },
  {
    pantoneId: 106,
    name: '12-0913 TCX',
    majorColorName: 'Orange',
    colorName: 'Alesan',
    r: 241,
    g: 206,
    b: 179,
  },
  {
    pantoneId: 107,
    name: '12-0915 TCX',
    majorColorName: 'Orange',
    colorName: 'Pale Peach',
    r: 254,
    g: 209,
    b: 189,
  },
  {
    pantoneId: 108,
    name: '12-0917 TCX',
    majorColorName: 'Orange',
    colorName: 'Bleached Apricot',
    r: 252,
    g: 202,
    b: 172,
  },
  {
    pantoneId: 109,
    name: '12-0921 TCX',
    majorColorName: 'Orange',
    colorName: 'Golden Straw',
    r: 230,
    g: 189,
    b: 143,
  },
  {
    pantoneId: 110,
    name: '12-1005 TCX',
    majorColorName: 'Orange',
    colorName: 'Novelle Peach',
    r: 231,
    g: 207,
    b: 189,
  },
  {
    pantoneId: 111,
    name: '12-1006 TCX',
    majorColorName: 'Orange',
    colorName: 'Mother of Pearl',
    r: 233,
    g: 212,
    b: 195,
  },
  {
    pantoneId: 112,
    name: '12-1007 TCX',
    majorColorName: 'Orange',
    colorName: 'Pastel Rose Tan',
    r: 233,
    g: 209,
    b: 191,
  },
  {
    pantoneId: 113,
    name: '12-1008 TCX',
    majorColorName: 'Orange',
    colorName: 'Linen',
    r: 237,
    g: 210,
    b: 192,
  },
  {
    pantoneId: 114,
    name: '12-1009 TCX',
    majorColorName: 'Orange',
    colorName: 'Vanilla Cream',
    r: 244,
    g: 216,
    b: 198,
  },
  {
    pantoneId: 115,
    name: '12-1010 TCX',
    majorColorName: 'Orange',
    colorName: 'Scallop Shell',
    r: 251,
    g: 216,
    b: 201,
  },
  {
    pantoneId: 116,
    name: '12-1011 TCX',
    majorColorName: 'Orange',
    colorName: 'Peach Puree',
    r: 239,
    g: 207,
    b: 186,
  },
  {
    pantoneId: 117,
    name: '12-1106 TCX',
    majorColorName: 'White',
    colorName: 'Sheer Pink',
    r: 246,
    g: 229,
    b: 219,
  },
  {
    pantoneId: 118,
    name: '12-1107 TCX',
    majorColorName: 'Orange',
    colorName: 'Pink Champagne',
    r: 240,
    g: 216,
    b: 204,
  },
  {
    pantoneId: 119,
    name: '12-1108 TCX',
    majorColorName: 'White',
    colorName: 'Dew',
    r: 238,
    g: 222,
    b: 209,
  },
  {
    pantoneId: 120,
    name: '12-1206 TCX',
    majorColorName: 'White',
    colorName: 'Silver Peony',
    r: 231,
    g: 207,
    b: 199,
  },
  {
    pantoneId: 121,
    name: '12-1207 TCX',
    majorColorName: 'Orange',
    colorName: 'Pearl Blush',
    r: 244,
    g: 206,
    b: 197,
  },
  {
    pantoneId: 122,
    name: '12-1209 TCX',
    majorColorName: 'Orange',
    colorName: 'Soft Pink',
    r: 242,
    g: 216,
    b: 205,
  },
  {
    pantoneId: 123,
    name: '12-1212 TCX',
    majorColorName: 'Pink',
    colorName: 'Veiled Rose',
    r: 248,
    g: 205,
    b: 201,
  },
  {
    pantoneId: 124,
    name: '12-1304 TCX',
    majorColorName: 'White',
    colorName: 'Pearl',
    r: 249,
    g: 219,
    b: 216,
  },
  {
    pantoneId: 125,
    name: '12-1305 TCX',
    majorColorName: 'White',
    colorName: 'Heavenly Pink',
    r: 244,
    g: 222,
    b: 222,
  },
  {
    pantoneId: 126,
    name: '12-1310 TCX',
    majorColorName: 'Pink',
    colorName: 'Blushing Bride',
    r: 251,
    g: 211,
    b: 217,
  },
  {
    pantoneId: 127,
    name: '12-1403 TCX',
    majorColorName: 'White',
    colorName: 'Tapioca',
    r: 220,
    g: 205,
    b: 188,
  },
  {
    pantoneId: 128,
    name: '12-1404 TCX',
    majorColorName: 'White',
    colorName: 'Pink Tint',
    r: 219,
    g: 203,
    b: 189,
  },
  {
    pantoneId: 129,
    name: '12-1605 TCX',
    majorColorName: 'White',
    colorName: 'Crystal Pink',
    r: 237,
    g: 208,
    b: 206,
  },
  {
    pantoneId: 130,
    name: '12-1706 TCX',
    majorColorName: 'Pink',
    colorName: 'Pink Dogwood',
    r: 247,
    g: 209,
    b: 209,
  },
  {
    pantoneId: 131,
    name: '12-1708 TCX',
    majorColorName: 'Pink',
    colorName: 'Crystal Rose',
    r: 253,
    g: 195,
    b: 198,
  },
  {
    pantoneId: 132,
    name: '12-2102 TCX',
    majorColorName: 'White',
    colorName: 'Mauve Morn',
    r: 236,
    g: 214,
    b: 214,
  },
  {
    pantoneId: 133,
    name: '12-2103 TCX',
    majorColorName: 'White',
    colorName: 'Almost Mauve',
    r: 231,
    g: 220,
    b: 217,
  },
  {
    pantoneId: 134,
    name: '12-2902 TCX',
    majorColorName: 'White',
    colorName: 'Mauve Chalk',
    r: 229,
    g: 208,
    b: 207,
  },
  {
    pantoneId: 135,
    name: '12-2903 TCX',
    majorColorName: 'White',
    colorName: 'Light Lilac',
    r: 222,
    g: 198,
    b: 211,
  },
  {
    pantoneId: 136,
    name: '12-2904 TCX',
    majorColorName: 'White',
    colorName: 'Primrose Pink',
    r: 238,
    g: 212,
    b: 217,
  },
  {
    pantoneId: 137,
    name: '12-2905 TCX',
    majorColorName: 'White',
    colorName: 'Cradle Pink',
    r: 237,
    g: 208,
    b: 221,
  },
  {
    pantoneId: 138,
    name: '12-2906 TCX',
    majorColorName: 'White',
    colorName: 'Barely Pink',
    r: 248,
    g: 215,
    b: 221,
  },
  {
    pantoneId: 139,
    name: '12-4302 TCX',
    majorColorName: 'White',
    colorName: 'Vaporous Gray',
    r: 223,
    g: 221,
    b: 215,
  },
  {
    pantoneId: 140,
    name: '12-4304 TCX',
    majorColorName: 'White',
    colorName: 'Bluewash',
    r: 226,
    g: 230,
    b: 224,
  },
  {
    pantoneId: 141,
    name: '12-4305 TCX',
    majorColorName: 'White',
    colorName: 'Spa Blue',
    r: 211,
    g: 222,
    b: 223,
  },
  {
    pantoneId: 142,
    name: '12-4306 TCX',
    majorColorName: 'White',
    colorName: 'Barely Blue',
    r: 221,
    g: 224,
    b: 223,
  },
  {
    pantoneId: 143,
    name: '12-4604 TCX',
    majorColorName: 'White',
    colorName: 'Skylight',
    r: 200,
    g: 224,
    b: 224,
  },
  {
    pantoneId: 144,
    name: '12-4607 TCX',
    majorColorName: 'White',
    colorName: 'Pastel Blue',
    r: 188,
    g: 211,
    b: 213,
  },
  {
    pantoneId: 145,
    name: '12-4608 TCX',
    majorColorName: 'Blue',
    colorName: 'Clearwater',
    r: 170,
    g: 213,
    b: 219,
  },
  {
    pantoneId: 146,
    name: '12-4609 TCX',
    majorColorName: 'White',
    colorName: 'Starlight Blue',
    r: 181,
    g: 206,
    b: 212,
  },
  {
    pantoneId: 147,
    name: '12-4610 TCX',
    majorColorName: 'White',
    colorName: 'Whispering Blue',
    r: 201,
    g: 220,
    b: 220,
  },
  {
    pantoneId: 148,
    name: '12-4705 TCX',
    majorColorName: 'White',
    colorName: 'Blue Blush',
    r: 214,
    g: 219,
    b: 217,
  },
  {
    pantoneId: 149,
    name: '12-4805 TCX',
    majorColorName: 'White',
    colorName: 'Wan Blue',
    r: 203,
    g: 220,
    b: 223,
  },
  {
    pantoneId: 150,
    name: '12-4806 TCX',
    majorColorName: 'White',
    colorName: 'Chalk Blue',
    r: 204,
    g: 218,
    b: 215,
  },
  {
    pantoneId: 151,
    name: '12-5201 TCX',
    majorColorName: 'White',
    colorName: 'Icicle',
    r: 218,
    g: 220,
    b: 208,
  },
  {
    pantoneId: 152,
    name: '12-5202 TCX',
    majorColorName: 'White',
    colorName: 'Turtledove',
    r: 222,
    g: 215,
    b: 200,
  },
  {
    pantoneId: 153,
    name: '12-5203 TCX',
    majorColorName: 'White',
    colorName: 'Murmur',
    r: 210,
    g: 216,
    b: 210,
  },
  {
    pantoneId: 154,
    name: '12-5204 TCX',
    majorColorName: 'White',
    colorName: 'Morning Mist',
    r: 207,
    g: 223,
    b: 219,
  },
  {
    pantoneId: 155,
    name: '12-5206 TCX',
    majorColorName: 'White',
    colorName: 'Blue Glass',
    r: 198,
    g: 227,
    b: 225,
  },
  {
    pantoneId: 156,
    name: '12-5209 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Soothing Sea',
    r: 195,
    g: 233,
    b: 228,
  },
  {
    pantoneId: 157,
    name: '12-5303 TCX',
    majorColorName: 'White',
    colorName: 'Sprout Green',
    r: 203,
    g: 215,
    b: 210,
  },
  {
    pantoneId: 158,
    name: '12-5403 TCX',
    majorColorName: 'White',
    colorName: 'Blue Flower',
    r: 208,
    g: 217,
    b: 212,
  },
  {
    pantoneId: 159,
    name: '12-5404 TCX',
    majorColorName: 'White',
    colorName: 'Whisper Green',
    r: 224,
    g: 230,
    b: 215,
  },
  {
    pantoneId: 160,
    name: '12-5406 TCX',
    majorColorName: 'White',
    colorName: 'Opal Blue',
    r: 195,
    g: 221,
    b: 214,
  },
  {
    pantoneId: 161,
    name: '12-5407 TCX',
    majorColorName: 'White',
    colorName: 'Aqua Glass',
    r: 210,
    g: 232,
    b: 224,
  },
  {
    pantoneId: 162,
    name: '12-5408 TCX',
    majorColorName: 'White',
    colorName: 'Moonlight Jade',
    r: 199,
    g: 229,
    b: 223,
  },
  {
    pantoneId: 163,
    name: '12-5409 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Fair Aqua',
    r: 184,
    g: 226,
    b: 220,
  },
  {
    pantoneId: 164,
    name: '12-5410 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Bleached Aqua',
    r: 188,
    g: 227,
    b: 223,
  },
  {
    pantoneId: 165,
    name: '12-5504 TCX',
    majorColorName: 'White',
    colorName: 'Clearly Aqua',
    r: 206,
    g: 225,
    b: 212,
  },
  {
    pantoneId: 166,
    name: '12-5505 TCX',
    majorColorName: 'White',
    colorName: 'Glacier',
    r: 195,
    g: 219,
    b: 212,
  },
  {
    pantoneId: 167,
    name: '12-5506 TCX',
    majorColorName: 'White',
    colorName: 'Dusty Aqua',
    r: 192,
    g: 220,
    b: 205,
  },
  {
    pantoneId: 168,
    name: '12-5507 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Bay',
    r: 186,
    g: 229,
    b: 214,
  },
  {
    pantoneId: 169,
    name: '12-5508 TCX',
    majorColorName: 'White',
    colorName: 'Hushed Green',
    r: 216,
    g: 233,
    b: 229,
  },
  {
    pantoneId: 170,
    name: '12-5603 TCX',
    majorColorName: 'White',
    colorName: 'Zephyr Blue',
    r: 211,
    g: 217,
    b: 209,
  },
  {
    pantoneId: 171,
    name: '12-5808 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Honeydew',
    r: 186,
    g: 225,
    b: 211,
  },
  {
    pantoneId: 172,
    name: '12-6204 TCX',
    majorColorName: 'White',
    colorName: 'Silver Green',
    r: 215,
    g: 215,
    b: 199,
  },
  {
    pantoneId: 173,
    name: '12-6205 TCX',
    majorColorName: 'White',
    colorName: 'Milky Green',
    r: 207,
    g: 219,
    b: 209,
  },
  {
    pantoneId: 174,
    name: '12-6206 TCX',
    majorColorName: 'White',
    colorName: 'Fairest Jade',
    r: 216,
    g: 227,
    b: 215,
  },
  {
    pantoneId: 175,
    name: '12-6207 TCX',
    majorColorName: 'White',
    colorName: 'Frost',
    r: 221,
    g: 226,
    b: 214,
  },
  {
    pantoneId: 176,
    name: '12-6208 TCX',
    majorColorName: 'White',
    colorName: 'Phantom Green',
    r: 220,
    g: 228,
    b: 215,
  },
  {
    pantoneId: 177,
    name: '13-0000 TCX',
    majorColorName: 'White',
    colorName: 'Moonbeam',
    r: 205,
    g: 198,
    b: 189,
  },
  {
    pantoneId: 178,
    name: '13-0002 TCX',
    majorColorName: 'White',
    colorName: 'White Sand',
    r: 219,
    g: 213,
    b: 209,
  },
  {
    pantoneId: 179,
    name: '13-0107 TCX',
    majorColorName: 'White',
    colorName: 'Dewkist',
    r: 196,
    g: 209,
    b: 194,
  },
  {
    pantoneId: 180,
    name: '13-0111 TCX',
    majorColorName: 'White',
    colorName: 'Seacrest',
    r: 191,
    g: 209,
    b: 179,
  },
  {
    pantoneId: 181,
    name: '13-0116 TCX',
    majorColorName: 'Green',
    colorName: 'Pastel Green',
    r: 180,
    g: 211,
    b: 178,
  },
  {
    pantoneId: 182,
    name: '13-0117 TCX',
    majorColorName: 'Green',
    colorName: 'Green Ash',
    r: 160,
    g: 218,
    b: 169,
  },
  {
    pantoneId: 183,
    name: '13-0210 TCX',
    majorColorName: 'White',
    colorName: 'Fog Green',
    r: 194,
    g: 203,
    b: 180,
  },
  {
    pantoneId: 184,
    name: '13-0212 TCX',
    majorColorName: 'White',
    colorName: 'Tender Greens',
    r: 197,
    g: 207,
    b: 182,
  },
  {
    pantoneId: 185,
    name: '13-0215 TCX',
    majorColorName: 'Green',
    colorName: 'Reed',
    r: 195,
    g: 211,
    b: 168,
  },
  {
    pantoneId: 186,
    name: '13-0220 TCX',
    majorColorName: 'Green',
    colorName: 'Paradise Green',
    r: 178,
    g: 231,
    b: 159,
  },
  {
    pantoneId: 187,
    name: '13-0221 TCX',
    majorColorName: 'Green',
    colorName: 'Pistachio Green',
    r: 169,
    g: 211,
    b: 158,
  },
  {
    pantoneId: 188,
    name: '13-0317 TCX',
    majorColorName: 'Green',
    colorName: 'Lily Green',
    r: 197,
    g: 207,
    b: 152,
  },
  {
    pantoneId: 189,
    name: '13-0319 TCX',
    majorColorName: 'Green',
    colorName: 'Shadow Lime',
    r: 207,
    g: 224,
    b: 157,
  },
  {
    pantoneId: 190,
    name: '13-0324 TCX',
    majorColorName: 'Green',
    colorName: 'Lettuce Green',
    r: 190,
    g: 211,
    b: 142,
  },
  {
    pantoneId: 191,
    name: '13-0331 TCX',
    majorColorName: 'Green',
    colorName: 'Sap Green',
    r: 175,
    g: 203,
    b: 128,
  },
  {
    pantoneId: 192,
    name: '13-0333 TCX',
    majorColorName: 'Yellow',
    colorName: 'Lima Bean',
    r: 225,
    g: 213,
    b: 144,
  },
  {
    pantoneId: 193,
    name: '13-0401 TCX',
    majorColorName: 'White',
    colorName: 'Oatmeal',
    r: 203,
    g: 195,
    b: 180,
  },
  {
    pantoneId: 194,
    name: '13-0403 TCX',
    majorColorName: 'White',
    colorName: 'Gray Morn',
    r: 202,
    g: 190,
    b: 181,
  },
  {
    pantoneId: 195,
    name: '13-0442 TCX',
    majorColorName: 'Green',
    colorName: 'Green Glow',
    r: 176,
    g: 201,
    b: 101,
  },
  {
    pantoneId: 196,
    name: '13-0513 TCX',
    majorColorName: 'Yellow',
    colorName: 'Frozen Dew',
    r: 216,
    g: 207,
    b: 178,
  },
  {
    pantoneId: 197,
    name: '13-0522 TCX',
    majorColorName: 'Yellow',
    colorName: 'Pale Green',
    r: 203,
    g: 206,
    b: 145,
  },
  {
    pantoneId: 198,
    name: '13-0530 TCX',
    majorColorName: 'Green',
    colorName: 'Lime Sherbet',
    r: 205,
    g: 215,
    b: 138,
  },
  {
    pantoneId: 199,
    name: '13-0532 TCX',
    majorColorName: 'Green',
    colorName: 'Celery Green',
    r: 197,
    g: 204,
    b: 123,
  },
  {
    pantoneId: 200,
    name: '13-0535 TCX',
    majorColorName: 'Green',
    colorName: 'Sharp Green',
    r: 198,
    g: 230,
    b: 122,
  },
  {
    pantoneId: 201,
    name: '13-0540 TCX',
    majorColorName: 'Green',
    colorName: 'Wild Lime',
    r: 195,
    g: 211,
    b: 99,
  },
  {
    pantoneId: 202,
    name: '13-0550 TCX',
    majorColorName: 'Green',
    colorName: 'Lime Punch',
    r: 192,
    g: 215,
    b: 37,
  },
  {
    pantoneId: 203,
    name: '13-0607 TCX',
    majorColorName: 'White',
    colorName: 'Fog',
    r: 208,
    g: 197,
    b: 177,
  },
  {
    pantoneId: 204,
    name: '13-0608 TCX',
    majorColorName: 'White',
    colorName: 'Aloe Wash',
    r: 208,
    g: 211,
    b: 183,
  },
  {
    pantoneId: 205,
    name: '13-0611 TCX',
    majorColorName: 'Yellow',
    colorName: 'Moth',
    r: 210,
    g: 203,
    b: 175,
  },
  {
    pantoneId: 206,
    name: '13-0613 TCX',
    majorColorName: 'Orange',
    colorName: 'Chino Green',
    r: 217,
    g: 202,
    b: 165,
  },
  {
    pantoneId: 207,
    name: '13-0614 TCX',
    majorColorName: 'Yellow',
    colorName: 'Garden Glade',
    r: 220,
    g: 216,
    b: 168,
  },
  {
    pantoneId: 208,
    name: '13-0624 TCX',
    majorColorName: 'Yellow',
    colorName: 'Golden Mist',
    r: 213,
    g: 205,
    b: 148,
  },
  {
    pantoneId: 209,
    name: '13-0632 TCX',
    majorColorName: 'Yellow',
    colorName: 'Endive',
    r: 210,
    g: 204,
    b: 129,
  },
  {
    pantoneId: 210,
    name: '13-0633 TCX',
    majorColorName: 'Yellow',
    colorName: 'Chardonnay',
    r: 231,
    g: 223,
    b: 153,
  },
  {
    pantoneId: 211,
    name: '13-0640 TCX',
    majorColorName: 'Yellow',
    colorName: 'Acacia',
    r: 218,
    g: 205,
    b: 101,
  },
  {
    pantoneId: 212,
    name: '13-0645 TCX',
    majorColorName: 'Yellow',
    colorName: 'Limeade',
    r: 211,
    g: 217,
    b: 95,
  },
  {
    pantoneId: 213,
    name: '13-0648 TCX',
    majorColorName: 'Yellow',
    colorName: 'Green Sheen',
    r: 217,
    g: 206,
    b: 82,
  },
  {
    pantoneId: 214,
    name: '13-0650 TCX',
    majorColorName: 'Yellow',
    colorName: 'Sulphur Spring',
    r: 213,
    g: 215,
    b: 23,
  },
  {
    pantoneId: 215,
    name: '13-0711 TCX',
    majorColorName: 'Orange',
    colorName: 'Putty',
    r: 212,
    g: 202,
    b: 176,
  },
  {
    pantoneId: 216,
    name: '13-0715 TCX',
    majorColorName: 'Orange',
    colorName: 'Sea Mist',
    r: 216,
    g: 201,
    b: 163,
  },
  {
    pantoneId: 217,
    name: '13-0720 TCX',
    majorColorName: 'Yellow',
    colorName: 'Custard',
    r: 229,
    g: 214,
    b: 142,
  },
  {
    pantoneId: 218,
    name: '13-0725 TCX',
    majorColorName: 'Orange',
    colorName: 'Raffia',
    r: 218,
    g: 196,
    b: 131,
  },
  {
    pantoneId: 219,
    name: '13-0739 TCX',
    majorColorName: 'Yellow',
    colorName: 'Cream Gold',
    r: 222,
    g: 192,
    b: 95,
  },
  {
    pantoneId: 220,
    name: '13-0746 TCX',
    majorColorName: 'Yellow',
    colorName: 'Maize',
    r: 238,
    g: 200,
    b: 67,
  },
  {
    pantoneId: 221,
    name: '13-0752 TCX',
    majorColorName: 'Yellow',
    colorName: 'Lemon',
    r: 243,
    g: 191,
    b: 8,
  },
  {
    pantoneId: 222,
    name: '13-0755 TCX',
    majorColorName: 'Yellow',
    colorName: 'Primrose Yellow',
    r: 246,
    g: 209,
    b: 85,
  },
  {
    pantoneId: 223,
    name: '13-0758 TCX',
    majorColorName: 'Yellow',
    colorName: 'Dandelion',
    r: 255,
    g: 208,
    b: 46,
  },
  {
    pantoneId: 224,
    name: '13-0814 TCX',
    majorColorName: 'Orange',
    colorName: 'Summer Melon',
    r: 234,
    g: 211,
    b: 174,
  },
  {
    pantoneId: 225,
    name: '13-0815 TCX',
    majorColorName: 'Orange',
    colorName: 'Banana Crepe',
    r: 231,
    g: 211,
    b: 173,
  },
  {
    pantoneId: 226,
    name: '13-0822 TCX',
    majorColorName: 'Orange',
    colorName: 'Sunlight',
    r: 237,
    g: 213,
    b: 158,
  },
  {
    pantoneId: 227,
    name: '13-0840 TCX',
    majorColorName: 'Orange',
    colorName: 'Snapdragon',
    r: 254,
    g: 215,
    b: 119,
  },
  {
    pantoneId: 228,
    name: '13-0850 TCX',
    majorColorName: 'Orange',
    colorName: 'Aspen Gold',
    r: 255,
    g: 214,
    b: 98,
  },
  {
    pantoneId: 229,
    name: '13-0858 TCX',
    majorColorName: 'Yellow',
    colorName: 'Vibrant Yellow',
    r: 255,
    g: 218,
    b: 41,
  },
  {
    pantoneId: 230,
    name: '13-0859 TCX',
    majorColorName: 'Yellow',
    colorName: 'Lemon Chrome',
    r: 255,
    g: 195,
    b: 0,
  },
  {
    pantoneId: 231,
    name: '13-0905 TCX',
    majorColorName: 'White',
    colorName: 'Birch',
    r: 221,
    g: 213,
    b: 199,
  },
  {
    pantoneId: 232,
    name: '13-0907 TCX',
    majorColorName: 'White',
    colorName: 'Sandshell',
    r: 216,
    g: 204,
    b: 187,
  },
  {
    pantoneId: 233,
    name: '13-0908 TCX',
    majorColorName: 'White',
    colorName: 'Parchment',
    r: 223,
    g: 209,
    b: 190,
  },
  {
    pantoneId: 234,
    name: '13-0915 TCX',
    majorColorName: 'Orange',
    colorName: 'Reed Yellow',
    r: 220,
    g: 201,
    b: 158,
  },
  {
    pantoneId: 235,
    name: '13-0916 TCX',
    majorColorName: 'Orange',
    colorName: 'Chamomile',
    r: 232,
    g: 208,
    b: 167,
  },
  {
    pantoneId: 236,
    name: '13-0917 TCX',
    majorColorName: 'Orange',
    colorName: 'Italian Straw',
    r: 231,
    g: 209,
    b: 161,
  },
  {
    pantoneId: 237,
    name: '13-0922 TCX',
    majorColorName: 'Orange',
    colorName: 'Straw',
    r: 224,
    g: 201,
    b: 146,
  },
  {
    pantoneId: 238,
    name: '13-0932 TCX',
    majorColorName: 'Orange',
    colorName: 'Cornsilk',
    r: 237,
    g: 195,
    b: 115,
  },
  {
    pantoneId: 239,
    name: '13-0935 TCX',
    majorColorName: 'Orange',
    colorName: 'Flax',
    r: 255,
    g: 200,
    b: 125,
  },
  {
    pantoneId: 240,
    name: '13-0939 TCX',
    majorColorName: 'Orange',
    colorName: 'Golden Cream',
    r: 247,
    g: 191,
    b: 104,
  },
  {
    pantoneId: 241,
    name: '13-0940 TCX',
    majorColorName: 'Orange',
    colorName: 'Sunset Gold',
    r: 247,
    g: 196,
    b: 108,
  },
  {
    pantoneId: 242,
    name: '13-0941 TCX',
    majorColorName: 'Orange',
    colorName: 'Banana Cream',
    r: 255,
    g: 207,
    b: 115,
  },
  {
    pantoneId: 243,
    name: '13-0942 TCX',
    majorColorName: 'Orange',
    colorName: 'Amber Yellow',
    r: 250,
    g: 183,
    b: 90,
  },
  {
    pantoneId: 244,
    name: '13-0945 TCX',
    majorColorName: 'Orange',
    colorName: 'Pale Marigold',
    r: 255,
    g: 198,
    b: 110,
  },
  {
    pantoneId: 245,
    name: '13-0947 TCX',
    majorColorName: 'Orange',
    colorName: 'Banana',
    r: 252,
    g: 185,
    b: 83,
  },
  {
    pantoneId: 246,
    name: '13-1006 TCX',
    majorColorName: 'Orange',
    colorName: 'Creme Brulee',
    r: 219,
    g: 204,
    b: 181,
  },
  {
    pantoneId: 247,
    name: '13-1007 TCX',
    majorColorName: 'Yellow',
    colorName: 'Oyster White',
    r: 210,
    g: 202,
    b: 175,
  },
  {
    pantoneId: 248,
    name: '13-1008 TCX',
    majorColorName: 'Orange',
    colorName: 'Bleached Sand',
    r: 218,
    g: 204,
    b: 180,
  },
  {
    pantoneId: 249,
    name: '13-1009 TCX',
    majorColorName: 'Orange',
    colorName: 'Biscotti',
    r: 218,
    g: 199,
    b: 171,
  },
  {
    pantoneId: 250,
    name: '13-1010 TCX',
    majorColorName: 'Orange',
    colorName: 'Gray Sand',
    r: 229,
    g: 204,
    b: 175,
  },
  {
    pantoneId: 251,
    name: '13-1011 TCX',
    majorColorName: 'Orange',
    colorName: 'Ivory Cream',
    r: 218,
    g: 192,
    b: 167,
  },
  {
    pantoneId: 252,
    name: '13-1012 TCX',
    majorColorName: 'Orange',
    colorName: 'Frosted Almond',
    r: 210,
    g: 194,
    b: 172,
  },
  {
    pantoneId: 253,
    name: '13-1013 TCX',
    majorColorName: 'Orange',
    colorName: 'Appleblossom',
    r: 221,
    g: 188,
    b: 160,
  },
  {
    pantoneId: 254,
    name: '13-1014 TCX',
    majorColorName: 'Orange',
    colorName: 'Mellow Buff',
    r: 216,
    g: 185,
    b: 152,
  },
  {
    pantoneId: 255,
    name: '13-1015 TCX',
    majorColorName: 'Orange',
    colorName: 'Honey Peach',
    r: 220,
    g: 189,
    b: 158,
  },
  {
    pantoneId: 256,
    name: '13-1016 TCX',
    majorColorName: 'Orange',
    colorName: 'Wheat',
    r: 222,
    g: 197,
    b: 165,
  },
  {
    pantoneId: 257,
    name: '13-1017 TCX',
    majorColorName: 'Orange',
    colorName: 'Almond Cream',
    r: 244,
    g: 194,
    b: 159,
  },
  {
    pantoneId: 258,
    name: '13-1018 TCX',
    majorColorName: 'Orange',
    colorName: 'Desert Dust',
    r: 227,
    g: 188,
    b: 142,
  },
  {
    pantoneId: 259,
    name: '13-1019 TCX',
    majorColorName: 'Orange',
    colorName: 'Cream Blush',
    r: 248,
    g: 193,
    b: 154,
  },
  {
    pantoneId: 260,
    name: '13-1020 TCX',
    majorColorName: 'Orange',
    colorName: 'Apricot Ice',
    r: 251,
    g: 190,
    b: 153,
  },
  {
    pantoneId: 261,
    name: '13-1021 TCX',
    majorColorName: 'Orange',
    colorName: 'Prairie Sunset',
    r: 255,
    g: 187,
    b: 158,
  },
  {
    pantoneId: 262,
    name: '13-1022 TCX',
    majorColorName: 'Orange',
    colorName: 'Caramel Cream',
    r: 244,
    g: 186,
    b: 148,
  },
  {
    pantoneId: 263,
    name: '13-1023 TCX',
    majorColorName: 'Orange',
    colorName: 'Peach Fuzz',
    r: 255,
    g: 190,
    b: 152,
  },
  {
    pantoneId: 264,
    name: '13-1024 TCX',
    majorColorName: 'Orange',
    colorName: 'Buff',
    r: 235,
    g: 195,
    b: 150,
  },
  {
    pantoneId: 265,
    name: '13-1025 TCX',
    majorColorName: 'Orange',
    colorName: 'Impala',
    r: 248,
    g: 206,
    b: 151,
  },
  {
    pantoneId: 266,
    name: '13-1026 TCX',
    majorColorName: 'Orange',
    colorName: 'Creampuff',
    r: 255,
    g: 205,
    b: 168,
  },
  {
    pantoneId: 267,
    name: '13-1027 TCX',
    majorColorName: 'Orange',
    colorName: 'Apricot Cream',
    r: 241,
    g: 189,
    b: 137,
  },
  {
    pantoneId: 268,
    name: '13-1030 TCX',
    majorColorName: 'Orange',
    colorName: 'Sunburst',
    r: 246,
    g: 194,
    b: 137,
  },
  {
    pantoneId: 269,
    name: '13-1031 TCX',
    majorColorName: 'Orange',
    colorName: 'Apricot Sherbet',
    r: 250,
    g: 205,
    b: 158,
  },
  {
    pantoneId: 270,
    name: '13-1106 TCX',
    majorColorName: 'White',
    colorName: 'Sand Dollar',
    r: 222,
    g: 205,
    b: 190,
  },
  {
    pantoneId: 271,
    name: '13-1107 TCX',
    majorColorName: 'White',
    colorName: 'Whisper Pink',
    r: 218,
    g: 203,
    b: 190,
  },
  {
    pantoneId: 272,
    name: '13-1108 TCX',
    majorColorName: 'Orange',
    colorName: 'Cream Tan',
    r: 228,
    g: 199,
    b: 184,
  },
  {
    pantoneId: 273,
    name: '13-1109 TCX',
    majorColorName: 'Orange',
    colorName: 'Bisque',
    r: 237,
    g: 202,
    b: 181,
  },
  {
    pantoneId: 274,
    name: '13-1114 TCX',
    majorColorName: 'Orange',
    colorName: 'Bellini',
    r: 244,
    g: 201,
    b: 177,
  },
  {
    pantoneId: 275,
    name: '13-1310 TCX',
    majorColorName: 'Pink',
    colorName: 'English Rose',
    r: 244,
    g: 198,
    b: 195,
  },
  {
    pantoneId: 276,
    name: '13-1318 TCX',
    majorColorName: 'Orange',
    colorName: 'Tropical Peach',
    r: 255,
    g: 196,
    b: 178,
  },
  {
    pantoneId: 277,
    name: '13-1404 TCX',
    majorColorName: 'Orange',
    colorName: 'Pale Dogwood',
    r: 237,
    g: 205,
    b: 194,
  },
  {
    pantoneId: 278,
    name: '13-1405 TCX',
    majorColorName: 'White',
    colorName: 'Shell',
    r: 225,
    g: 207,
    b: 198,
  },
  {
    pantoneId: 279,
    name: '13-1406 TCX',
    majorColorName: 'Orange',
    colorName: 'Cloud Pink',
    r: 245,
    g: 209,
    b: 200,
  },
  {
    pantoneId: 280,
    name: '13-1407 TCX',
    majorColorName: 'Orange',
    colorName: 'Creole Pink',
    r: 247,
    g: 213,
    b: 204,
  },
  {
    pantoneId: 281,
    name: '13-1408 TCX',
    majorColorName: 'Pink',
    colorName: 'Chintz Rose',
    r: 238,
    g: 196,
    b: 190,
  },
  {
    pantoneId: 282,
    name: '13-1409 TCX',
    majorColorName: 'Pink',
    colorName: 'Seashell Pink',
    r: 247,
    g: 200,
    b: 194,
  },
  {
    pantoneId: 283,
    name: '13-1504 TCX',
    majorColorName: 'White',
    colorName: 'Peach Blush',
    r: 228,
    g: 204,
    b: 198,
  },
  {
    pantoneId: 284,
    name: '13-1510 TCX',
    majorColorName: 'Pink',
    colorName: 'Impatiens Pink',
    r: 255,
    g: 196,
    b: 188,
  },
  {
    pantoneId: 285,
    name: '13-1513 TCX',
    majorColorName: 'Pink',
    colorName: 'Gossamer Pink',
    r: 250,
    g: 200,
    b: 195,
  },
  {
    pantoneId: 286,
    name: '13-1904 TCX',
    majorColorName: 'White',
    colorName: 'Chalk Pink',
    r: 230,
    g: 197,
    b: 202,
  },
  {
    pantoneId: 287,
    name: '13-1906 TCX',
    majorColorName: 'Pink',
    colorName: 'Rose Shadow',
    r: 249,
    g: 194,
    b: 205,
  },
  {
    pantoneId: 288,
    name: '13-2004 TCX',
    majorColorName: 'White',
    colorName: 'Potpourri',
    r: 231,
    g: 201,
    b: 202,
  },
  {
    pantoneId: 289,
    name: '13-2005 TCX',
    majorColorName: 'Pink',
    colorName: 'Strawberry Cream',
    r: 244,
    g: 195,
    b: 196,
  },
  {
    pantoneId: 290,
    name: '13-2006 TCX',
    majorColorName: 'Pink',
    colorName: 'Almond Blossom',
    r: 245,
    g: 190,
    b: 199,
  },
  {
    pantoneId: 291,
    name: '13-2010 TCX',
    majorColorName: 'Pink',
    colorName: 'Orchid Pink',
    r: 243,
    g: 187,
    b: 202,
  },
  {
    pantoneId: 292,
    name: '13-2803 TCX',
    majorColorName: 'White',
    colorName: 'Pale Lilac',
    r: 225,
    g: 198,
    b: 204,
  },
  {
    pantoneId: 293,
    name: '13-2804 TCX',
    majorColorName: 'Pink',
    colorName: 'Parfait Pink',
    r: 233,
    g: 195,
    b: 207,
  },
  {
    pantoneId: 294,
    name: '13-2805 TCX',
    majorColorName: 'Pink',
    colorName: 'Roseate Spoonbill',
    r: 230,
    g: 188,
    b: 205,
  },
  {
    pantoneId: 295,
    name: '13-2806 TCX',
    majorColorName: 'Pink',
    colorName: 'Pink Lady',
    r: 239,
    g: 193,
    b: 214,
  },
  {
    pantoneId: 296,
    name: '13-3405 TCX',
    majorColorName: 'White',
    colorName: 'Lilac Snow',
    r: 224,
    g: 199,
    b: 215,
  },
  {
    pantoneId: 297,
    name: '13-3406 TCX',
    majorColorName: 'White',
    colorName: 'Orchid Ice',
    r: 224,
    g: 208,
    b: 219,
  },
  {
    pantoneId: 298,
    name: '13-3801 TCX',
    majorColorName: 'White',
    colorName: 'Crystal Gray',
    r: 215,
    g: 203,
    b: 196,
  },
  {
    pantoneId: 299,
    name: '13-3802 TCX',
    majorColorName: 'White',
    colorName: 'Orchid Tint',
    r: 219,
    g: 210,
    b: 219,
  },
  {
    pantoneId: 300,
    name: '13-3803 TCX',
    majorColorName: 'White',
    colorName: 'Lilac Ash',
    r: 215,
    g: 205,
    b: 205,
  },
  {
    pantoneId: 301,
    name: '13-3804 TCX',
    majorColorName: 'White',
    colorName: 'Gray Lilac',
    r: 212,
    g: 202,
    b: 205,
  },
  {
    pantoneId: 302,
    name: '13-3805 TCX',
    majorColorName: 'White',
    colorName: 'Orchid Hush',
    r: 206,
    g: 195,
    b: 210,
  },
  {
    pantoneId: 303,
    name: '13-3820 TCX',
    majorColorName: 'White',
    colorName: 'Lavender Fog',
    r: 210,
    g: 196,
    b: 214,
  },
  {
    pantoneId: 304,
    name: '13-4103 TCX',
    majorColorName: 'White',
    colorName: 'Illusion Blue',
    r: 201,
    g: 211,
    b: 220,
  },
  {
    pantoneId: 305,
    name: '13-4105 TCX',
    majorColorName: 'White',
    colorName: 'Lilac Hint',
    r: 208,
    g: 208,
    b: 218,
  },
  {
    pantoneId: 306,
    name: '13-4110 TCX',
    majorColorName: 'White',
    colorName: 'Arctic Ice',
    r: 191,
    g: 199,
    b: 214,
  },
  {
    pantoneId: 307,
    name: '13-4303 TCX',
    majorColorName: 'White',
    colorName: 'Dawn Blue',
    r: 202,
    g: 204,
    b: 203,
  },
  {
    pantoneId: 308,
    name: '13-4304 TCX',
    majorColorName: 'White',
    colorName: 'Ballad Blue',
    r: 192,
    g: 206,
    b: 218,
  },
  {
    pantoneId: 309,
    name: '13-4305 TCX',
    majorColorName: 'White',
    colorName: 'Foggy Dew',
    r: 209,
    g: 213,
    b: 208,
  },
  {
    pantoneId: 310,
    name: '13-4308 TCX',
    majorColorName: 'White',
    colorName: 'Baby Blue',
    r: 181,
    g: 199,
    b: 211,
  },
  {
    pantoneId: 311,
    name: '13-4403 TCX',
    majorColorName: 'White',
    colorName: 'Silver Birch',
    r: 210,
    g: 207,
    b: 196,
  },
  {
    pantoneId: 312,
    name: '13-4404 TCX',
    majorColorName: 'White',
    colorName: 'Ice Flow',
    r: 198,
    g: 210,
    b: 210,
  },
  {
    pantoneId: 313,
    name: '13-4405 TCX',
    majorColorName: 'White',
    colorName: 'Misty Blue',
    r: 191,
    g: 205,
    b: 204,
  },
  {
    pantoneId: 314,
    name: '13-4409 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Glow',
    r: 178,
    g: 212,
    b: 221,
  },
  {
    pantoneId: 315,
    name: '13-4411 TCX',
    majorColorName: 'Blue',
    colorName: 'Crystal Blue',
    r: 161,
    g: 200,
    b: 219,
  },
  {
    pantoneId: 316,
    name: '13-4804 TCX',
    majorColorName: 'White',
    colorName: 'Pale Blue',
    r: 196,
    g: 214,
    b: 211,
  },
  {
    pantoneId: 317,
    name: '13-4809 TCX',
    majorColorName: 'Blue',
    colorName: 'Plume',
    r: 165,
    g: 207,
    b: 213,
  },
  {
    pantoneId: 318,
    name: '13-4909 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Blue Light',
    r: 172,
    g: 223,
    b: 221,
  },
  {
    pantoneId: 319,
    name: '13-4910 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Blue Tint',
    r: 159,
    g: 217,
    b: 215,
  },
  {
    pantoneId: 320,
    name: '13-5304 TCX',
    majorColorName: 'White',
    colorName: 'Rainy Day',
    r: 207,
    g: 200,
    b: 189,
  },
  {
    pantoneId: 321,
    name: '13-5305 TCX',
    majorColorName: 'White',
    colorName: 'Pale Aqua',
    r: 193,
    g: 204,
    b: 194,
  },
  {
    pantoneId: 322,
    name: '13-5306 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Icy Morn',
    r: 176,
    g: 211,
    b: 209,
  },
  {
    pantoneId: 323,
    name: '13-5309 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Pastel Turquoise',
    r: 153,
    g: 197,
    b: 196,
  },
  {
    pantoneId: 324,
    name: '13-5313 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Aruba Blue',
    r: 129,
    g: 215,
    b: 211,
  },
  {
    pantoneId: 325,
    name: '13-5409 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Yucca',
    r: 161,
    g: 215,
    b: 201,
  },
  {
    pantoneId: 326,
    name: '13-5412 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Beach Glass',
    r: 150,
    g: 223,
    b: 206,
  },
  {
    pantoneId: 327,
    name: '13-5414 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Ice Green',
    r: 135,
    g: 216,
    b: 195,
  },
  {
    pantoneId: 328,
    name: '13-5714 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Cabbage',
    r: 135,
    g: 215,
    b: 190,
  },
  {
    pantoneId: 329,
    name: '13-5907 TCX',
    majorColorName: 'White',
    colorName: 'Gossamer Green',
    r: 178,
    g: 207,
    b: 190,
  },
  {
    pantoneId: 330,
    name: '13-5911 TCX',
    majorColorName: 'Green',
    colorName: "Bird's Egg Green",
    r: 170,
    g: 204,
    b: 185,
  },
  {
    pantoneId: 331,
    name: '13-6006 TCX',
    majorColorName: 'White',
    colorName: 'Almost Aqua',
    r: 202,
    g: 211,
    b: 193,
  },
  {
    pantoneId: 332,
    name: '13-6007 TCX',
    majorColorName: 'White',
    colorName: 'Spray',
    r: 190,
    g: 211,
    b: 187,
  },
  {
    pantoneId: 333,
    name: '13-6008 TCX',
    majorColorName: 'White',
    colorName: 'Misty Jade',
    r: 188,
    g: 217,
    b: 200,
  },
  {
    pantoneId: 334,
    name: '13-6009 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Brook Green',
    r: 175,
    g: 221,
    b: 204,
  },
  {
    pantoneId: 335,
    name: '13-6105 TCX',
    majorColorName: 'White',
    colorName: 'Celadon Tint',
    r: 203,
    g: 206,
    b: 190,
  },
  {
    pantoneId: 336,
    name: '13-6106 TCX',
    majorColorName: 'White',
    colorName: 'Green Tint',
    r: 197,
    g: 204,
    b: 192,
  },
  {
    pantoneId: 337,
    name: '13-6107 TCX',
    majorColorName: 'White',
    colorName: 'Green Lily',
    r: 193,
    g: 206,
    b: 193,
  },
  {
    pantoneId: 338,
    name: '13-6108 TCX',
    majorColorName: 'White',
    colorName: 'Celadon',
    r: 184,
    g: 204,
    b: 186,
  },
  {
    pantoneId: 339,
    name: '13-6110 TCX',
    majorColorName: 'Green',
    colorName: 'Mist Green',
    r: 170,
    g: 206,
    b: 188,
  },
  {
    pantoneId: 340,
    name: '13-6208 TCX',
    majorColorName: 'White',
    colorName: 'Bok Choy',
    r: 188,
    g: 202,
    b: 179,
  },
  {
    pantoneId: 341,
    name: '14-0000 TCX',
    majorColorName: 'White',
    colorName: 'Silver Gray',
    r: 193,
    g: 183,
    b: 176,
  },
  {
    pantoneId: 342,
    name: '14-0002 TCX',
    majorColorName: 'White',
    colorName: 'Pumice Stone',
    r: 202,
    g: 194,
    b: 185,
  },
  {
    pantoneId: 343,
    name: '14-0105 TCX',
    majorColorName: 'White',
    colorName: 'Overcast',
    r: 195,
    g: 189,
    b: 171,
  },
  {
    pantoneId: 344,
    name: '14-0108 TCX',
    majorColorName: 'White',
    colorName: 'Castle Wall',
    r: 200,
    g: 193,
    b: 171,
  },
  {
    pantoneId: 345,
    name: '14-0114 TCX',
    majorColorName: 'White',
    colorName: 'Celadon Green',
    r: 181,
    g: 193,
    b: 165,
  },
  {
    pantoneId: 346,
    name: '14-0115 TCX',
    majorColorName: 'Green',
    colorName: 'Foam Green',
    r: 180,
    g: 199,
    b: 156,
  },
  {
    pantoneId: 347,
    name: '14-0121 TCX',
    majorColorName: 'Green',
    colorName: 'Nile Green',
    r: 167,
    g: 199,
    b: 150,
  },
  {
    pantoneId: 348,
    name: '14-0123 TCX',
    majorColorName: 'Green',
    colorName: 'Arcadian Green',
    r: 163,
    g: 200,
    b: 147,
  },
  {
    pantoneId: 349,
    name: '14-0127 TCX',
    majorColorName: 'Green',
    colorName: 'Greengage',
    r: 139,
    g: 194,
    b: 140,
  },
  {
    pantoneId: 350,
    name: '14-0156 TCX',
    majorColorName: 'Green',
    colorName: 'Summer Green',
    r: 126,
    g: 211,
    b: 127,
  },
  {
    pantoneId: 351,
    name: '14-0210 TCX',
    majorColorName: 'White',
    colorName: 'Tidal Foam',
    r: 191,
    g: 185,
    b: 163,
  },
  {
    pantoneId: 352,
    name: '14-0216 TCX',
    majorColorName: 'Green',
    colorName: 'Lint',
    r: 182,
    g: 186,
    b: 153,
  },
  {
    pantoneId: 353,
    name: '14-0217 TCX',
    majorColorName: 'Green',
    colorName: 'Seedling',
    r: 192,
    g: 203,
    b: 161,
  },
  {
    pantoneId: 354,
    name: '14-0223 TCX',
    majorColorName: 'Green',
    colorName: 'Nile',
    r: 180,
    g: 187,
    b: 133,
  },
  {
    pantoneId: 355,
    name: '14-0226 TCX',
    majorColorName: 'Green',
    colorName: 'Opaline Green',
    r: 163,
    g: 197,
    b: 125,
  },
  {
    pantoneId: 356,
    name: '14-0232 TCX',
    majorColorName: 'Green',
    colorName: 'Jade Lime',
    r: 161,
    g: 202,
    b: 123,
  },
  {
    pantoneId: 357,
    name: '14-0244 TCX',
    majorColorName: 'Green',
    colorName: 'Bright Lime Green',
    r: 151,
    g: 188,
    b: 98,
  },
  {
    pantoneId: 358,
    name: '14-0418 TCX',
    majorColorName: 'Yellow',
    colorName: 'Bog',
    r: 186,
    g: 182,
    b: 150,
  },
  {
    pantoneId: 359,
    name: '14-0425 TCX',
    majorColorName: 'Yellow',
    colorName: 'Beechnut',
    r: 194,
    g: 193,
    b: 141,
  },
  {
    pantoneId: 360,
    name: '14-0434 TCX',
    majorColorName: 'Yellow',
    colorName: 'Green Banana',
    r: 186,
    g: 188,
    b: 114,
  },
  {
    pantoneId: 361,
    name: '14-0445 TCX',
    majorColorName: 'Green',
    colorName: 'Bright Chartreuse',
    r: 181,
    g: 191,
    b: 80,
  },
  {
    pantoneId: 362,
    name: '14-0446 TCX',
    majorColorName: 'Green',
    colorName: 'Tender Shoots',
    r: 181,
    g: 204,
    b: 57,
  },
  {
    pantoneId: 363,
    name: '14-0452 TCX',
    majorColorName: 'Green',
    colorName: 'Lime Green',
    r: 159,
    g: 193,
    b: 49,
  },
  {
    pantoneId: 364,
    name: '14-0615 TCX',
    majorColorName: 'Yellow',
    colorName: 'Green Haze',
    r: 202,
    g: 196,
    b: 164,
  },
  {
    pantoneId: 365,
    name: '14-0626 TCX',
    majorColorName: 'Yellow',
    colorName: 'Dried Moss',
    r: 204,
    g: 185,
    b: 126,
  },
  {
    pantoneId: 366,
    name: '14-0627 TCX',
    majorColorName: 'Yellow',
    colorName: 'Shadow Green',
    r: 207,
    g: 196,
    b: 134,
  },
  {
    pantoneId: 367,
    name: '14-0636 TCX',
    majorColorName: 'Yellow',
    colorName: 'Muted Lime',
    r: 209,
    g: 200,
    b: 124,
  },
  {
    pantoneId: 368,
    name: '14-0647 TCX',
    majorColorName: 'Yellow',
    colorName: 'Celery',
    r: 206,
    g: 193,
    b: 83,
  },
  {
    pantoneId: 369,
    name: '14-0708 TCX',
    majorColorName: 'Orange',
    colorName: 'Cement',
    r: 196,
    g: 182,
    b: 166,
  },
  {
    pantoneId: 370,
    name: '14-0721 TCX',
    majorColorName: 'Orange',
    colorName: 'Hemp',
    r: 192,
    g: 173,
    b: 124,
  },
  {
    pantoneId: 371,
    name: '14-0740 TCX',
    majorColorName: 'Orange',
    colorName: 'Bamboo',
    r: 210,
    g: 176,
    b: 76,
  },
  {
    pantoneId: 372,
    name: '14-0754 TCX',
    majorColorName: 'Yellow',
    colorName: 'Super Lemon',
    r: 228,
    g: 191,
    b: 69,
  },
  {
    pantoneId: 373,
    name: '14-0755 TCX',
    majorColorName: 'Yellow',
    colorName: 'Sulphur',
    r: 221,
    g: 182,
    b: 20,
  },
  {
    pantoneId: 374,
    name: '14-0756 TCX',
    majorColorName: 'Yellow',
    colorName: 'Empire Yellow',
    r: 247,
    g: 208,
    b: 0,
  },
  {
    pantoneId: 375,
    name: '14-0760 TCX',
    majorColorName: 'Yellow',
    colorName: 'Cyber Yellow',
    r: 255,
    g: 212,
    b: 0,
  },
  {
    pantoneId: 376,
    name: '14-0826 TCX',
    majorColorName: 'Yellow',
    colorName: 'Pampas',
    r: 207,
    g: 187,
    b: 123,
  },
  {
    pantoneId: 377,
    name: '14-0827 TCX',
    majorColorName: 'Yellow',
    colorName: 'Dusky Citron',
    r: 227,
    g: 204,
    b: 129,
  },
  {
    pantoneId: 378,
    name: '14-0837 TCX',
    majorColorName: 'Orange',
    colorName: 'Misted Yellow',
    r: 218,
    g: 185,
    b: 101,
  },
  {
    pantoneId: 379,
    name: '14-0846 TCX',
    majorColorName: 'Orange',
    colorName: 'Yolk Yellow',
    r: 226,
    g: 176,
    b: 81,
  },
  {
    pantoneId: 380,
    name: '14-0847 TCX',
    majorColorName: 'Orange',
    colorName: 'Buff Yellow',
    r: 241,
    g: 191,
    b: 112,
  },
  {
    pantoneId: 381,
    name: '14-0848 TCX',
    majorColorName: 'Orange',
    colorName: 'Mimosa',
    r: 240,
    g: 192,
    b: 90,
  },
  {
    pantoneId: 382,
    name: '14-0850 TCX',
    majorColorName: 'Orange',
    colorName: 'Daffodil',
    r: 253,
    g: 192,
    b: 78,
  },
  {
    pantoneId: 383,
    name: '14-0852 TCX',
    majorColorName: 'Orange',
    colorName: 'Freesia',
    r: 243,
    g: 193,
    b: 44,
  },
  {
    pantoneId: 384,
    name: '14-0925 TCX',
    majorColorName: 'Orange',
    colorName: 'Parsnip',
    r: 214,
    g: 198,
    b: 154,
  },
  {
    pantoneId: 385,
    name: '14-0935 TCX',
    majorColorName: 'Orange',
    colorName: 'Jojoba',
    r: 218,
    g: 190,
    b: 129,
  },
  {
    pantoneId: 386,
    name: '14-0936 TCX',
    majorColorName: 'Orange',
    colorName: 'Sahara Sun',
    r: 223,
    g: 192,
    b: 138,
  },
  {
    pantoneId: 387,
    name: '14-0941 TCX',
    majorColorName: 'Orange',
    colorName: 'Beeswax',
    r: 235,
    g: 168,
    b: 81,
  },
  {
    pantoneId: 388,
    name: '14-0951 TCX',
    majorColorName: 'Orange',
    colorName: 'Golden Rod',
    r: 226,
    g: 168,
    b: 41,
  },
  {
    pantoneId: 389,
    name: '14-0955 TCX',
    majorColorName: 'Orange',
    colorName: 'Citrus',
    r: 249,
    g: 172,
    b: 47,
  },
  {
    pantoneId: 390,
    name: '14-0957 TCX',
    majorColorName: 'Orange',
    colorName: 'Spectra Yellow',
    r: 247,
    g: 183,
    b: 24,
  },
  {
    pantoneId: 391,
    name: '14-1012 TCX',
    majorColorName: 'Brown',
    colorName: 'Gilded Beige',
    r: 179,
    g: 159,
    b: 141,
  },
  {
    pantoneId: 392,
    name: '14-1014 TCX',
    majorColorName: 'Orange',
    colorName: 'Gravel',
    r: 203,
    g: 191,
    b: 162,
  },
  {
    pantoneId: 393,
    name: '14-1025 TCX',
    majorColorName: 'Orange',
    colorName: 'Cocoon',
    r: 201,
    g: 178,
    b: 124,
  },
  {
    pantoneId: 394,
    name: '14-1031 TCX',
    majorColorName: 'Orange',
    colorName: 'Rattan',
    r: 209,
    g: 178,
    b: 114,
  },
  {
    pantoneId: 395,
    name: '14-1036 TCX',
    majorColorName: 'Orange',
    colorName: 'Ochre',
    r: 214,
    g: 175,
    b: 102,
  },
  {
    pantoneId: 396,
    name: '14-1038 TCX',
    majorColorName: 'Orange',
    colorName: 'New Wheat',
    r: 215,
    g: 181,
    b: 127,
  },
  {
    pantoneId: 397,
    name: '14-1041 TCX',
    majorColorName: 'Orange',
    colorName: 'Golden Apricot',
    r: 221,
    g: 167,
    b: 88,
  },
  {
    pantoneId: 398,
    name: '14-1045 TCX',
    majorColorName: 'Orange',
    colorName: 'Amber',
    r: 239,
    g: 173,
    b: 85,
  },
  {
    pantoneId: 399,
    name: '14-1050 TCX',
    majorColorName: 'Orange',
    colorName: 'Marigold',
    r: 253,
    g: 172,
    b: 83,
  },
  {
    pantoneId: 400,
    name: '14-1051 TCX',
    majorColorName: 'Orange',
    colorName: 'Warm Apricot',
    r: 255,
    g: 184,
    b: 101,
  },
  {
    pantoneId: 401,
    name: '14-1064 TCX',
    majorColorName: 'Orange',
    colorName: 'Saffron',
    r: 255,
    g: 165,
    b: 0,
  },
  {
    pantoneId: 402,
    name: '14-1106 TCX',
    majorColorName: 'White',
    colorName: 'Peyote',
    r: 197,
    g: 187,
    b: 174,
  },
  {
    pantoneId: 403,
    name: '14-1107 TCX',
    majorColorName: 'White',
    colorName: 'Oyster Gray',
    r: 203,
    g: 193,
    b: 174,
  },
  {
    pantoneId: 404,
    name: '14-1108 TCX',
    majorColorName: 'Orange',
    colorName: 'Wood Ash',
    r: 215,
    g: 202,
    b: 176,
  },
  {
    pantoneId: 405,
    name: '14-1110 TCX',
    majorColorName: 'Orange',
    colorName: 'Boulder',
    r: 209,
    g: 190,
    b: 155,
  },
  {
    pantoneId: 406,
    name: '14-1112 TCX',
    majorColorName: 'Orange',
    colorName: 'Pebble',
    r: 202,
    g: 182,
    b: 152,
  },
  {
    pantoneId: 407,
    name: '14-1113 TCX',
    majorColorName: 'Orange',
    colorName: 'Marzipan',
    r: 216,
    g: 192,
    b: 157,
  },
  {
    pantoneId: 408,
    name: '14-1116 TCX',
    majorColorName: 'Orange',
    colorName: 'Almond Buff',
    r: 204,
    g: 179,
    b: 144,
  },
  {
    pantoneId: 409,
    name: '14-1118 TCX',
    majorColorName: 'Orange',
    colorName: 'Beige',
    r: 213,
    g: 186,
    b: 152,
  },
  {
    pantoneId: 410,
    name: '14-1119 TCX',
    majorColorName: 'Orange',
    colorName: 'Winter Wheat',
    r: 223,
    g: 192,
    b: 159,
  },
  {
    pantoneId: 411,
    name: '14-1120 TCX',
    majorColorName: 'Orange',
    colorName: 'Apricot Illusion',
    r: 226,
    g: 196,
    b: 166,
  },
  {
    pantoneId: 412,
    name: '14-1122 TCX',
    majorColorName: 'Orange',
    colorName: 'Sheepskin',
    r: 218,
    g: 181,
    b: 143,
  },
  {
    pantoneId: 413,
    name: '14-1127 TCX',
    majorColorName: 'Orange',
    colorName: 'Desert Mist',
    r: 224,
    g: 181,
    b: 137,
  },
  {
    pantoneId: 414,
    name: '14-1128 TCX',
    majorColorName: 'Orange',
    colorName: 'Buff Orange',
    r: 255,
    g: 187,
    b: 124,
  },
  {
    pantoneId: 415,
    name: '14-1133 TCX',
    majorColorName: 'Orange',
    colorName: 'Apricot Nectar',
    r: 236,
    g: 170,
    b: 121,
  },
  {
    pantoneId: 416,
    name: '14-1135 TCX',
    majorColorName: 'Orange',
    colorName: 'Salmon Buff',
    r: 254,
    g: 170,
    b: 123,
  },
  {
    pantoneId: 417,
    name: '14-1139 TCX',
    majorColorName: 'Orange',
    colorName: 'Pumpkin',
    r: 245,
    g: 162,
    b: 111,
  },
  {
    pantoneId: 418,
    name: '14-1159 TCX',
    majorColorName: 'Orange',
    colorName: 'Zinnia',
    r: 255,
    g: 160,
    b: 16,
  },
  {
    pantoneId: 419,
    name: '14-1209 TCX',
    majorColorName: 'Orange',
    colorName: 'Smoke Gray',
    r: 206,
    g: 186,
    b: 168,
  },
  {
    pantoneId: 420,
    name: '14-1210 TCX',
    majorColorName: 'Orange',
    colorName: 'Shifting Sand',
    r: 216,
    g: 192,
    b: 173,
  },
  {
    pantoneId: 421,
    name: '14-1212 TCX',
    majorColorName: 'Orange',
    colorName: 'Frappe',
    r: 209,
    g: 183,
    b: 160,
  },
  {
    pantoneId: 422,
    name: '14-1213 TCX',
    majorColorName: 'Orange',
    colorName: 'Toasted Almond',
    r: 210,
    g: 180,
    b: 156,
  },
  {
    pantoneId: 423,
    name: '14-1217 TCX',
    majorColorName: 'Orange',
    colorName: 'Amberlight',
    r: 226,
    g: 190,
    b: 162,
  },
  {
    pantoneId: 424,
    name: '14-1219 TCX',
    majorColorName: 'Orange',
    colorName: 'Peach Parfait',
    r: 248,
    g: 191,
    b: 168,
  },
  {
    pantoneId: 425,
    name: '14-1220 TCX',
    majorColorName: 'Orange',
    colorName: 'Peach Nougat',
    r: 230,
    g: 175,
    b: 145,
  },
  {
    pantoneId: 426,
    name: '14-1224 TCX',
    majorColorName: 'Orange',
    colorName: 'Coral Sands',
    r: 237,
    g: 170,
    b: 134,
  },
  {
    pantoneId: 427,
    name: '14-1225 TCX',
    majorColorName: 'Orange',
    colorName: 'Beach Sand',
    r: 251,
    g: 185,
    b: 149,
  },
  {
    pantoneId: 428,
    name: '14-1227 TCX',
    majorColorName: 'Orange',
    colorName: 'Peach',
    r: 242,
    g: 169,
    b: 135,
  },
  {
    pantoneId: 429,
    name: '14-1228 TCX',
    majorColorName: 'Orange',
    colorName: 'Peach Nectar',
    r: 255,
    g: 181,
    b: 155,
  },
  {
    pantoneId: 430,
    name: '14-1230 TCX',
    majorColorName: 'Orange',
    colorName: 'Apricot Wash',
    r: 251,
    g: 172,
    b: 130,
  },
  {
    pantoneId: 431,
    name: '14-1231 TCX',
    majorColorName: 'Orange',
    colorName: 'Peach Cobbler',
    r: 255,
    g: 177,
    b: 129,
  },
  {
    pantoneId: 432,
    name: '14-1305 TCX',
    majorColorName: 'White',
    colorName: 'Mushroom',
    r: 189,
    g: 172,
    b: 163,
  },
  {
    pantoneId: 433,
    name: '14-1307 TCX',
    majorColorName: 'Orange',
    colorName: 'Rose Dust',
    r: 205,
    g: 178,
    b: 165,
  },
  {
    pantoneId: 434,
    name: '14-1309 TCX',
    majorColorName: 'Orange',
    colorName: 'Peach Whip',
    r: 219,
    g: 190,
    b: 183,
  },
  {
    pantoneId: 435,
    name: '14-1310 TCX',
    majorColorName: 'Orange',
    colorName: 'Cameo Rose',
    r: 215,
    g: 184,
    b: 171,
  },
  {
    pantoneId: 436,
    name: '14-1311 TCX',
    majorColorName: 'Orange',
    colorName: 'Evening Sand',
    r: 221,
    g: 182,
    b: 171,
  },
  {
    pantoneId: 437,
    name: '14-1312 TCX',
    majorColorName: 'Orange',
    colorName: 'Pale Blush',
    r: 228,
    g: 191,
    b: 179,
  },
  {
    pantoneId: 438,
    name: '14-1313 TCX',
    majorColorName: 'Orange',
    colorName: 'Rose Cloud',
    r: 219,
    g: 176,
    b: 162,
  },
  {
    pantoneId: 439,
    name: '14-1314 TCX',
    majorColorName: 'Orange',
    colorName: 'Spanish Villa',
    r: 223,
    g: 186,
    b: 169,
  },
  {
    pantoneId: 440,
    name: '14-1316 TCX',
    majorColorName: 'Orange',
    colorName: 'Dusty Pink',
    r: 222,
    g: 170,
    b: 155,
  },
  {
    pantoneId: 441,
    name: '14-1318 TCX',
    majorColorName: 'Orange',
    colorName: 'Coral Pink',
    r: 232,
    g: 167,
    b: 152,
  },
  {
    pantoneId: 442,
    name: '14-1323 TCX',
    majorColorName: 'Orange',
    colorName: 'Salmon',
    r: 250,
    g: 170,
    b: 148,
  },
  {
    pantoneId: 443,
    name: '14-1324 TCX',
    majorColorName: 'Pink',
    colorName: 'Peach Bud',
    r: 253,
    g: 178,
    b: 168,
  },
  {
    pantoneId: 444,
    name: '14-1418 TCX',
    majorColorName: 'Orange',
    colorName: 'Peach Melba',
    r: 251,
    g: 189,
    b: 175,
  },
  {
    pantoneId: 445,
    name: '14-1419 TCX',
    majorColorName: 'Pink',
    colorName: 'Peach Pearl',
    r: 255,
    g: 178,
    b: 165,
  },
  {
    pantoneId: 446,
    name: '14-1420 TCX',
    majorColorName: 'Pink',
    colorName: 'Apricot Blush',
    r: 254,
    g: 174,
    b: 165,
  },
  {
    pantoneId: 447,
    name: '14-1506 TCX',
    majorColorName: 'Orange',
    colorName: 'Rose Smoke',
    r: 211,
    g: 180,
    b: 173,
  },
  {
    pantoneId: 448,
    name: '14-1508 TCX',
    majorColorName: 'Pink',
    colorName: 'Silver Pink',
    r: 220,
    g: 177,
    b: 175,
  },
  {
    pantoneId: 449,
    name: '14-1511 TCX',
    majorColorName: 'Pink',
    colorName: 'Powder Pink',
    r: 236,
    g: 178,
    b: 179,
  },
  {
    pantoneId: 450,
    name: '14-1513 TCX',
    majorColorName: 'Pink',
    colorName: 'Blossom',
    r: 242,
    g: 178,
    b: 174,
  },
  {
    pantoneId: 451,
    name: '14-1521 TCX',
    majorColorName: 'Pink',
    colorName: "Peaches N' Cream",
    r: 244,
    g: 166,
    b: 163,
  },
  {
    pantoneId: 452,
    name: '14-1714 TCX',
    majorColorName: 'Pink',
    colorName: 'Quartz Pink',
    r: 239,
    g: 166,
    b: 170,
  },
  {
    pantoneId: 453,
    name: '14-1905 TCX',
    majorColorName: 'Pink',
    colorName: 'Lotus',
    r: 226,
    g: 193,
    b: 192,
  },
  {
    pantoneId: 454,
    name: '14-1907 TCX',
    majorColorName: 'Pink',
    colorName: 'Peachskin',
    r: 223,
    g: 184,
    b: 182,
  },
  {
    pantoneId: 455,
    name: '14-1909 TCX',
    majorColorName: 'Pink',
    colorName: 'Coral Blush',
    r: 230,
    g: 178,
    b: 184,
  },
  {
    pantoneId: 456,
    name: '14-1911 TCX',
    majorColorName: 'Pink',
    colorName: 'Candy Pink',
    r: 245,
    g: 176,
    b: 189,
  },
  {
    pantoneId: 457,
    name: '14-2305 TCX',
    majorColorName: 'Pink',
    colorName: 'Pink Nectar',
    r: 216,
    g: 170,
    b: 183,
  },
  {
    pantoneId: 458,
    name: '14-2307 TCX',
    majorColorName: 'Pink',
    colorName: 'Cameo Pink',
    r: 219,
    g: 169,
    b: 184,
  },
  {
    pantoneId: 459,
    name: '14-2311 TCX',
    majorColorName: 'Pink',
    colorName: 'Prism Pink',
    r: 240,
    g: 161,
    b: 191,
  },
  {
    pantoneId: 460,
    name: '14-2710 TCX',
    majorColorName: 'Pink',
    colorName: 'Lilac Sachet',
    r: 233,
    g: 173,
    b: 202,
  },
  {
    pantoneId: 461,
    name: '14-2808 TCX',
    majorColorName: 'Pink',
    colorName: 'Sweet Lilac',
    r: 232,
    g: 181,
    b: 206,
  },
  {
    pantoneId: 462,
    name: '14-3204 TCX',
    majorColorName: 'Pink',
    colorName: 'Fragrant Lilac',
    r: 206,
    g: 173,
    b: 190,
  },
  {
    pantoneId: 463,
    name: '14-3206 TCX',
    majorColorName: 'White',
    colorName: 'Winsome Orchid',
    r: 212,
    g: 185,
    b: 203,
  },
  {
    pantoneId: 464,
    name: '14-3207 TCX',
    majorColorName: 'Pink',
    colorName: 'Pink Lavender',
    r: 217,
    g: 175,
    b: 202,
  },
  {
    pantoneId: 465,
    name: '14-3209 TCX',
    majorColorName: 'Pink',
    colorName: 'Pastel Lavender',
    r: 216,
    g: 161,
    b: 196,
  },
  {
    pantoneId: 466,
    name: '14-3612 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Orchid Bloom',
    r: 197,
    g: 174,
    b: 207,
  },
  {
    pantoneId: 467,
    name: '14-3710 TCX',
    majorColorName: 'White',
    colorName: 'Orchid Petal',
    r: 191,
    g: 180,
    b: 203,
  },
  {
    pantoneId: 468,
    name: '14-3803 TCX',
    majorColorName: 'White',
    colorName: 'Hushed Violet',
    r: 209,
    g: 192,
    b: 191,
  },
  {
    pantoneId: 469,
    name: '14-3805 TCX',
    majorColorName: 'White',
    colorName: 'Iris',
    r: 186,
    g: 175,
    b: 188,
  },
  {
    pantoneId: 470,
    name: '14-3812 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Pastel Lilac',
    r: 189,
    g: 176,
    b: 208,
  },
  {
    pantoneId: 471,
    name: '14-3903 TCX',
    majorColorName: 'White',
    colorName: 'Lilac Marble',
    r: 195,
    g: 186,
    b: 191,
  },
  {
    pantoneId: 472,
    name: '14-3904 TCX',
    majorColorName: 'White',
    colorName: 'Evening Haze',
    r: 189,
    g: 184,
    b: 199,
  },
  {
    pantoneId: 473,
    name: '14-3905 TCX',
    majorColorName: 'White',
    colorName: 'Lavender Blue',
    r: 197,
    g: 192,
    b: 208,
  },
  {
    pantoneId: 474,
    name: '14-3907 TCX',
    majorColorName: 'White',
    colorName: 'Thistle',
    r: 185,
    g: 179,
    b: 197,
  },
  {
    pantoneId: 475,
    name: '14-3911 TCX',
    majorColorName: 'White',
    colorName: 'Purple Heather',
    r: 186,
    g: 184,
    b: 211,
  },
  {
    pantoneId: 476,
    name: '14-4002 TCX',
    majorColorName: 'White',
    colorName: 'Wind Chime',
    r: 202,
    g: 197,
    b: 194,
  },
  {
    pantoneId: 477,
    name: '14-4102 TCX',
    majorColorName: 'White',
    colorName: 'Glacier Gray',
    r: 197,
    g: 198,
    b: 199,
  },
  {
    pantoneId: 478,
    name: '14-4103 TCX',
    majorColorName: 'White',
    colorName: 'Gray Violet',
    r: 187,
    g: 188,
    b: 188,
  },
  {
    pantoneId: 479,
    name: '14-4106 TCX',
    majorColorName: 'White',
    colorName: 'Gray Dawn',
    r: 187,
    g: 193,
    b: 204,
  },
  {
    pantoneId: 480,
    name: '14-4110 TCX',
    majorColorName: 'White',
    colorName: 'Heather',
    r: 183,
    g: 192,
    b: 214,
  },
  {
    pantoneId: 481,
    name: '14-4112 TCX',
    majorColorName: 'Blue',
    colorName: 'Skyway',
    r: 173,
    g: 190,
    b: 211,
  },
  {
    pantoneId: 482,
    name: '14-4115 TCX',
    majorColorName: 'Blue',
    colorName: 'Cashmere Blue',
    r: 165,
    g: 184,
    b: 208,
  },
  {
    pantoneId: 483,
    name: '14-4121 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Bell',
    r: 147,
    g: 180,
    b: 215,
  },
  {
    pantoneId: 484,
    name: '14-4201 TCX',
    majorColorName: 'White',
    colorName: 'Lunar Rock',
    r: 197,
    g: 197,
    b: 197,
  },
  {
    pantoneId: 485,
    name: '14-4203 TCX',
    majorColorName: 'White',
    colorName: 'Vapor Blue',
    r: 190,
    g: 189,
    b: 189,
  },
  {
    pantoneId: 486,
    name: '14-4206 TCX',
    majorColorName: 'White',
    colorName: 'Pearl Blue',
    r: 176,
    g: 183,
    b: 190,
  },
  {
    pantoneId: 487,
    name: '14-4210 TCX',
    majorColorName: 'Blue',
    colorName: 'Celestial Blue',
    r: 163,
    g: 180,
    b: 196,
  },
  {
    pantoneId: 488,
    name: '14-4214 TCX',
    majorColorName: 'Blue',
    colorName: 'Powder Blue',
    r: 150,
    g: 179,
    b: 210,
  },
  {
    pantoneId: 489,
    name: '14-4306 TCX',
    majorColorName: 'White',
    colorName: 'Cloud Blue',
    r: 162,
    g: 182,
    b: 185,
  },
  {
    pantoneId: 490,
    name: '14-4307 TCX',
    majorColorName: 'Blue',
    colorName: 'Winter Sky',
    r: 169,
    g: 192,
    b: 203,
  },
  {
    pantoneId: 491,
    name: '14-4313 TCX',
    majorColorName: 'Blue',
    colorName: 'Aquamarine',
    r: 157,
    g: 195,
    b: 212,
  },
  {
    pantoneId: 492,
    name: '14-4318 TCX',
    majorColorName: 'Blue',
    colorName: 'Sky Blue',
    r: 138,
    g: 186,
    b: 211,
  },
  {
    pantoneId: 493,
    name: '14-4500 TCX',
    majorColorName: 'White',
    colorName: 'Moonstruck',
    r: 194,
    g: 190,
    b: 182,
  },
  {
    pantoneId: 494,
    name: '14-4501 TCX',
    majorColorName: 'White',
    colorName: 'Silver Lining',
    r: 189,
    g: 182,
    b: 171,
  },
  {
    pantoneId: 495,
    name: '14-4502 TCX',
    majorColorName: 'White',
    colorName: 'Mercury',
    r: 186,
    g: 194,
    b: 186,
  },
  {
    pantoneId: 496,
    name: '14-4503 TCX',
    majorColorName: 'White',
    colorName: 'Metal',
    r: 186,
    g: 191,
    b: 188,
  },
  {
    pantoneId: 497,
    name: '14-4504 TCX',
    majorColorName: 'White',
    colorName: 'Sky Gray',
    r: 188,
    g: 200,
    b: 198,
  },
  {
    pantoneId: 498,
    name: '14-4505 TCX',
    majorColorName: 'White',
    colorName: 'Smoke',
    r: 191,
    g: 200,
    b: 195,
  },
  {
    pantoneId: 499,
    name: '14-4506 TCX',
    majorColorName: 'White',
    colorName: 'Ether',
    r: 158,
    g: 182,
    b: 184,
  },
  {
    pantoneId: 500,
    name: '14-4508 TCX',
    majorColorName: 'Blue',
    colorName: 'Stratosphere',
    r: 158,
    g: 193,
    b: 204,
  },
  {
    pantoneId: 501,
    name: '14-4510 TCX',
    majorColorName: 'Blue',
    colorName: 'Aquatic',
    r: 153,
    g: 193,
    b: 204,
  },
  {
    pantoneId: 502,
    name: '14-4512 TCX',
    majorColorName: 'Blue',
    colorName: 'Porcelain Blue',
    r: 149,
    g: 192,
    b: 203,
  },
  {
    pantoneId: 503,
    name: '14-4516 TCX',
    majorColorName: 'Blue',
    colorName: 'Petit four',
    r: 135,
    g: 194,
    b: 212,
  },
  {
    pantoneId: 504,
    name: '14-4522 TCX',
    majorColorName: 'Blue',
    colorName: 'Bachelor Button',
    r: 74,
    g: 187,
    b: 213,
  },
  {
    pantoneId: 505,
    name: '14-4804 TCX',
    majorColorName: 'White',
    colorName: 'Blue Fox',
    r: 185,
    g: 188,
    b: 182,
  },
  {
    pantoneId: 506,
    name: '14-4807 TCX',
    majorColorName: 'White',
    colorName: 'Surf Spray',
    r: 180,
    g: 200,
    b: 194,
  },
  {
    pantoneId: 507,
    name: '14-4809 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Eggshell Blue',
    r: 163,
    g: 204,
    b: 201,
  },
  {
    pantoneId: 508,
    name: '14-4810 TCX',
    majorColorName: 'Blue',
    colorName: 'Canal Blue',
    r: 156,
    g: 194,
    b: 197,
  },
  {
    pantoneId: 509,
    name: '14-4811 TCX',
    majorColorName: 'Blue-green',
    colorName: 'Aqua Sky',
    r: 123,
    g: 196,
    b: 196,
  },
  {
    pantoneId: 510,
    name: '14-4814 TCX',
    majorColorName: 'Blue',
    colorName: 'Angel Blue',
    r: 131,
    g: 197,
    b: 205,
  },
  {
    pantoneId: 511,
    name: '14-4816 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Radiance',
    r: 88,
    g: 201,
    b: 212,
  },
  {
    pantoneId: 512,
    name: '14-4908 TCX',
    majorColorName: 'White',
    colorName: 'Harbor Gray',
    r: 168,
    g: 192,
    b: 187,
  },
  {
    pantoneId: 513,
    name: '14-5002 TCX',
    majorColorName: 'Gray',
    colorName: 'Silver',
    r: 162,
    g: 162,
    b: 161,
  },
  {
    pantoneId: 514,
    name: '14-5413 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Holiday',
    r: 129,
    g: 195,
    b: 180,
  },
  {
    pantoneId: 515,
    name: '14-5416 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Bermuda',
    r: 96,
    g: 201,
    b: 179,
  },
  {
    pantoneId: 516,
    name: '14-5420 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Cockatoo',
    r: 88,
    g: 200,
    b: 182,
  },
  {
    pantoneId: 517,
    name: '14-5706 TCX',
    majorColorName: 'White',
    colorName: 'Silt Green',
    r: 169,
    g: 189,
    b: 177,
  },
  {
    pantoneId: 518,
    name: '14-5707 TCX',
    majorColorName: 'White',
    colorName: 'Aqua Foam',
    r: 173,
    g: 195,
    b: 180,
  },
  {
    pantoneId: 519,
    name: '14-5711 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Ocean Wave',
    r: 142,
    g: 197,
    b: 182,
  },
  {
    pantoneId: 520,
    name: '14-5713 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Cascade',
    r: 118,
    g: 193,
    b: 178,
  },
  {
    pantoneId: 521,
    name: '14-5714 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Beveled Glass',
    r: 122,
    g: 204,
    b: 184,
  },
  {
    pantoneId: 522,
    name: '14-5718 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Opal',
    r: 119,
    g: 207,
    b: 183,
  },
  {
    pantoneId: 523,
    name: '14-5721 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Electric Green',
    r: 75,
    g: 195,
    b: 168,
  },
  {
    pantoneId: 524,
    name: '14-6007 TCX',
    majorColorName: 'White',
    colorName: 'Sea Foam',
    r: 183,
    g: 194,
    b: 178,
  },
  {
    pantoneId: 525,
    name: '14-6008 TCX',
    majorColorName: 'White',
    colorName: 'Subtle Green',
    r: 181,
    g: 203,
    b: 187,
  },
  {
    pantoneId: 526,
    name: '14-6011 TCX',
    majorColorName: 'Green',
    colorName: 'Grayed Jade',
    r: 155,
    g: 190,
    b: 169,
  },
  {
    pantoneId: 527,
    name: '14-6017 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Neptune Green',
    r: 127,
    g: 187,
    b: 158,
  },
  {
    pantoneId: 528,
    name: '14-6305 TCX',
    majorColorName: 'White',
    colorName: 'Pelican',
    r: 193,
    g: 188,
    b: 172,
  },
  {
    pantoneId: 529,
    name: '14-6308 TCX',
    majorColorName: 'White',
    colorName: 'Alfalfa',
    r: 183,
    g: 181,
    b: 159,
  },
  {
    pantoneId: 530,
    name: '14-6312 TCX',
    majorColorName: 'White',
    colorName: 'Cameo Green',
    r: 170,
    g: 192,
    b: 173,
  },
  {
    pantoneId: 531,
    name: '14-6316 TCX',
    majorColorName: 'Green',
    colorName: 'Sprucestone',
    r: 159,
    g: 192,
    b: 156,
  },
  {
    pantoneId: 532,
    name: '14-6319 TCX',
    majorColorName: 'Green',
    colorName: 'Meadow',
    r: 139,
    g: 186,
    b: 148,
  },
  {
    pantoneId: 533,
    name: '14-6324 TCX',
    majorColorName: 'Green',
    colorName: 'Peapod',
    r: 130,
    g: 177,
    b: 133,
  },
  {
    pantoneId: 534,
    name: '14-6327 TCX',
    majorColorName: 'Green',
    colorName: 'Zephyr Green',
    r: 124,
    g: 176,
    b: 131,
  },
  {
    pantoneId: 535,
    name: '14-6329 TCX',
    majorColorName: 'Green',
    colorName: 'Absinthe Green',
    r: 118,
    g: 181,
    b: 131,
  },
  {
    pantoneId: 536,
    name: '14-6330 TCX',
    majorColorName: 'Green',
    colorName: 'Spring Bud',
    r: 107,
    g: 205,
    b: 156,
  },
  {
    pantoneId: 537,
    name: '14-6340 TCX',
    majorColorName: 'Green',
    colorName: 'Spring Bouquet',
    r: 109,
    g: 206,
    b: 135,
  },
  {
    pantoneId: 538,
    name: '14-6408 TCX',
    majorColorName: 'White',
    colorName: 'Abbey Stone',
    r: 171,
    g: 167,
    b: 152,
  },
  {
    pantoneId: 539,
    name: '15-0000 TCX',
    majorColorName: 'White',
    colorName: 'Dove',
    r: 179,
    g: 173,
    b: 167,
  },
  {
    pantoneId: 540,
    name: '15-0146 TCX',
    majorColorName: 'Green',
    colorName: 'Green Flash',
    r: 121,
    g: 199,
    b: 83,
  },
  {
    pantoneId: 541,
    name: '15-0309 TCX',
    majorColorName: 'Brown',
    colorName: 'Spray Green',
    r: 174,
    g: 166,
    b: 146,
  },
  {
    pantoneId: 542,
    name: '15-0318 TCX',
    majorColorName: 'Yellow',
    colorName: 'Sage Green',
    r: 178,
    g: 172,
    b: 136,
  },
  {
    pantoneId: 543,
    name: '15-0326 TCX',
    majorColorName: 'Green',
    colorName: 'Tarragon',
    r: 164,
    g: 174,
    b: 119,
  },
  {
    pantoneId: 544,
    name: '15-0332 TCX',
    majorColorName: 'Green',
    colorName: 'Leaf Green',
    r: 159,
    g: 175,
    b: 108,
  },
  {
    pantoneId: 545,
    name: '15-0336 TCX',
    majorColorName: 'Green',
    colorName: 'Herbal Garden',
    r: 156,
    g: 173,
    b: 96,
  },
  {
    pantoneId: 546,
    name: '15-0341 TCX',
    majorColorName: 'Green',
    colorName: 'Parrot Green',
    r: 141,
    g: 176,
    b: 81,
  },
  {
    pantoneId: 547,
    name: '15-0343 TCX',
    majorColorName: 'Green',
    colorName: 'Greenery',
    r: 136,
    g: 176,
    b: 75,
  },
  {
    pantoneId: 548,
    name: '15-0513 TCX',
    majorColorName: 'Brown',
    colorName: 'Eucalyptus',
    r: 177,
    g: 169,
    b: 146,
  },
  {
    pantoneId: 549,
    name: '15-0522 TCX',
    majorColorName: 'Yellow',
    colorName: 'Pale Olive Green',
    r: 181,
    g: 173,
    b: 136,
  },
  {
    pantoneId: 550,
    name: '15-0523 TCX',
    majorColorName: 'Green',
    colorName: 'Winter Pear',
    r: 176,
    g: 180,
    b: 135,
  },
  {
    pantoneId: 551,
    name: '15-0525 TCX',
    majorColorName: 'Yellow',
    colorName: 'Weeping Willow',
    r: 179,
    g: 177,
    b: 123,
  },
  {
    pantoneId: 552,
    name: '15-0531 TCX',
    majorColorName: 'Green',
    colorName: 'Sweet Pea',
    r: 163,
    g: 169,
    b: 105,
  },
  {
    pantoneId: 553,
    name: '15-0533 TCX',
    majorColorName: 'Yellow',
    colorName: 'Linden Green',
    r: 196,
    g: 191,
    b: 113,
  },
  {
    pantoneId: 554,
    name: '15-0535 TCX',
    majorColorName: 'Yellow',
    colorName: 'Palm',
    r: 175,
    g: 175,
    b: 94,
  },
  {
    pantoneId: 555,
    name: '15-0538 TCX',
    majorColorName: 'Yellow',
    colorName: 'Green Oasis',
    r: 176,
    g: 180,
    b: 84,
  },
  {
    pantoneId: 556,
    name: '15-0543 TCX',
    majorColorName: 'Yellow',
    colorName: 'Apple Green',
    r: 181,
    g: 182,
    b: 68,
  },
  {
    pantoneId: 557,
    name: '15-0545 TCX',
    majorColorName: 'Green',
    colorName: 'Jasmine Green',
    r: 126,
    g: 200,
    b: 69,
  },
  {
    pantoneId: 558,
    name: '15-0548 TCX',
    majorColorName: 'Yellow',
    colorName: 'Citronelle',
    r: 184,
    g: 175,
    b: 35,
  },
  {
    pantoneId: 559,
    name: '15-0628 TCX',
    majorColorName: 'Yellow',
    colorName: 'Leek Green',
    r: 183,
    g: 177,
    b: 122,
  },
  {
    pantoneId: 560,
    name: '15-0636 TCX',
    majorColorName: 'Yellow',
    colorName: 'Golden Green',
    r: 189,
    g: 179,
    b: 105,
  },
  {
    pantoneId: 561,
    name: '15-0643 TCX',
    majorColorName: 'Yellow',
    colorName: 'Cress Green',
    r: 188,
    g: 169,
    b: 73,
  },
  {
    pantoneId: 562,
    name: '15-0646 TCX',
    majorColorName: 'Yellow',
    colorName: 'Warm Olive',
    r: 199,
    g: 182,
    b: 60,
  },
  {
    pantoneId: 563,
    name: '15-0703 TCX',
    majorColorName: 'White',
    colorName: 'Ashes of Roses',
    r: 181,
    g: 172,
    b: 171,
  },
  {
    pantoneId: 564,
    name: '15-0719 TCX',
    majorColorName: 'Brown',
    colorName: 'Silver Fern',
    r: 187,
    g: 170,
    b: 126,
  },
  {
    pantoneId: 565,
    name: '15-0730 TCX',
    majorColorName: 'Brown',
    colorName: 'Southern Moss',
    r: 188,
    g: 166,
    b: 106,
  },
  {
    pantoneId: 566,
    name: '15-0732 TCX',
    majorColorName: 'Orange',
    colorName: 'Olivenite',
    r: 193,
    g: 166,
    b: 92,
  },
  {
    pantoneId: 567,
    name: '15-0743 TCX',
    majorColorName: 'Yellow',
    colorName: 'Oil Yellow',
    r: 196,
    g: 166,
    b: 71,
  },
  {
    pantoneId: 568,
    name: '15-0751 TCX',
    majorColorName: 'Yellow',
    colorName: 'Lemon Curry',
    r: 205,
    g: 163,
    b: 35,
  },
  {
    pantoneId: 569,
    name: '15-0850 TCX',
    majorColorName: 'Orange',
    colorName: 'Ceylon Yellow',
    r: 212,
    g: 174,
    b: 64,
  },
  {
    pantoneId: 570,
    name: '15-0927 TCX',
    majorColorName: 'Brown',
    colorName: 'Pale Gold',
    r: 189,
    g: 152,
    b: 101,
  },
  {
    pantoneId: 571,
    name: '15-0942 TCX',
    majorColorName: 'Orange',
    colorName: 'Sauterne',
    r: 197,
    g: 162,
    b: 83,
  },
  {
    pantoneId: 572,
    name: '15-0948 TCX',
    majorColorName: 'Orange',
    colorName: 'Golden Spice',
    r: 198,
    g: 151,
    b: 63,
  },
  {
    pantoneId: 573,
    name: '15-0953 TCX',
    majorColorName: 'Orange',
    colorName: 'Golden Yellow',
    r: 203,
    g: 142,
    b: 22,
  },
  {
    pantoneId: 574,
    name: '15-0955 TCX',
    majorColorName: 'Orange',
    colorName: 'Old Gold',
    r: 236,
    g: 168,
    b: 37,
  },
  {
    pantoneId: 575,
    name: '15-1046 TCX',
    majorColorName: 'Orange',
    colorName: 'Mineral Yellow',
    r: 211,
    g: 156,
    b: 67,
  },
  {
    pantoneId: 576,
    name: '15-1049 TCX',
    majorColorName: 'Orange',
    colorName: "Artisan's Gold",
    r: 242,
    g: 171,
    b: 70,
  },
  {
    pantoneId: 577,
    name: '15-1050 TCX',
    majorColorName: 'Orange',
    colorName: 'Golden Glow',
    r: 217,
    g: 153,
    b: 56,
  },
  {
    pantoneId: 578,
    name: '15-1054 TCX',
    majorColorName: 'Orange',
    colorName: 'Cadmium Yellow',
    r: 238,
    g: 150,
    b: 38,
  },
  {
    pantoneId: 579,
    name: '15-1058 TCX',
    majorColorName: 'Orange',
    colorName: 'Radiant Yellow',
    r: 252,
    g: 158,
    b: 33,
  },
  {
    pantoneId: 580,
    name: '15-1062 TCX',
    majorColorName: 'Orange',
    colorName: 'Gold Fusion',
    r: 255,
    g: 176,
    b: 0,
  },
  {
    pantoneId: 581,
    name: '15-1116 TCX',
    majorColorName: 'Brown',
    colorName: 'Safari',
    r: 186,
    g: 170,
    b: 145,
  },
  {
    pantoneId: 582,
    name: '15-1119 TCX',
    majorColorName: 'Brown',
    colorName: 'Taos Taupe',
    r: 191,
    g: 167,
    b: 127,
  },
  {
    pantoneId: 583,
    name: '15-1132 TCX',
    majorColorName: 'Orange',
    colorName: 'Fall Leaf',
    r: 201,
    g: 168,
    b: 106,
  },
  {
    pantoneId: 584,
    name: '15-1142 TCX',
    majorColorName: 'Orange',
    colorName: 'Honey Gold',
    r: 209,
    g: 160,
    b: 84,
  },
  {
    pantoneId: 585,
    name: '15-1145 TCX',
    majorColorName: 'Orange',
    colorName: 'Chamois',
    r: 247,
    g: 178,
    b: 106,
  },
  {
    pantoneId: 586,
    name: '15-1147 TCX',
    majorColorName: 'Orange',
    colorName: 'Butterscotch',
    r: 225,
    g: 150,
    b: 64,
  },
  {
    pantoneId: 587,
    name: '15-1150 TCX',
    majorColorName: 'Orange',
    colorName: 'Dark Cheddar',
    r: 224,
    g: 129,
    b: 25,
  },
  {
    pantoneId: 588,
    name: '15-1153 TCX',
    majorColorName: 'Orange',
    colorName: 'Apricot',
    r: 241,
    g: 144,
    b: 53,
  },
  {
    pantoneId: 589,
    name: '15-1157 TCX',
    majorColorName: 'Orange',
    colorName: 'Flame Orange',
    r: 251,
    g: 139,
    b: 35,
  },
  {
    pantoneId: 590,
    name: '15-1160 TCX',
    majorColorName: 'Orange',
    colorName: 'Blazing Orange',
    r: 255,
    g: 166,
    b: 79,
  },
  {
    pantoneId: 591,
    name: '15-1164 TCX',
    majorColorName: 'Orange',
    colorName: 'Bright Marigold',
    r: 255,
    g: 141,
    b: 0,
  },
  {
    pantoneId: 592,
    name: '15-1213 TCX',
    majorColorName: 'Brown',
    colorName: 'Candied Ginger',
    r: 191,
    g: 163,
    b: 135,
  },
  {
    pantoneId: 593,
    name: '15-1214 TCX',
    majorColorName: 'Orange',
    colorName: 'Warm Sand',
    r: 197,
    g: 174,
    b: 145,
  },
  {
    pantoneId: 594,
    name: '15-1215 TCX',
    majorColorName: 'Brown',
    colorName: 'Sesame',
    r: 186,
    g: 163,
    b: 139,
  },
  {
    pantoneId: 595,
    name: '15-1216 TCX',
    majorColorName: 'Brown',
    colorName: 'Pale Khaki',
    r: 191,
    g: 175,
    b: 146,
  },
  {
    pantoneId: 596,
    name: '15-1217 TCX',
    majorColorName: 'Orange',
    colorName: 'Mojave Desert',
    r: 199,
    g: 181,
    b: 149,
  },
  {
    pantoneId: 597,
    name: '15-1220 TCX',
    majorColorName: 'Orange',
    colorName: 'Latte',
    r: 197,
    g: 165,
    b: 130,
  },
  {
    pantoneId: 598,
    name: '15-1225 TCX',
    majorColorName: 'Orange',
    colorName: 'Sand',
    r: 204,
    g: 166,
    b: 127,
  },
  {
    pantoneId: 599,
    name: '15-1231 TCX',
    majorColorName: 'Orange',
    colorName: 'Clay',
    r: 210,
    g: 161,
    b: 114,
  },
  {
    pantoneId: 600,
    name: '15-1234 TCX',
    majorColorName: 'Orange',
    colorName: 'Gold Earth',
    r: 221,
    g: 156,
    b: 107,
  },
  {
    pantoneId: 601,
    name: '15-1237 TCX',
    majorColorName: 'Orange',
    colorName: 'Apricot Tan',
    r: 221,
    g: 151,
    b: 96,
  },
  {
    pantoneId: 602,
    name: '15-1239 TCX',
    majorColorName: 'Orange',
    colorName: 'Cantaloupe',
    r: 255,
    g: 161,
    b: 119,
  },
  {
    pantoneId: 603,
    name: '15-1242 TCX',
    majorColorName: 'Orange',
    colorName: 'Muskmelon',
    r: 236,
    g: 147,
    b: 94,
  },
  {
    pantoneId: 604,
    name: '15-1245 TCX',
    majorColorName: 'Orange',
    colorName: 'Mock Orange',
    r: 255,
    g: 163,
    b: 104,
  },
  {
    pantoneId: 605,
    name: '15-1247 TCX',
    majorColorName: 'Orange',
    colorName: 'Tangerine',
    r: 248,
    g: 143,
    b: 88,
  },
  {
    pantoneId: 606,
    name: '15-1263 TCX',
    majorColorName: 'Orange',
    colorName: 'Autumn Glory',
    r: 255,
    g: 136,
    b: 18,
  },
  {
    pantoneId: 607,
    name: '15-1305 TCX',
    majorColorName: 'White',
    colorName: 'Feather Gray',
    r: 184,
    g: 173,
    b: 158,
  },
  {
    pantoneId: 608,
    name: '15-1306 TCX',
    majorColorName: 'Brown',
    colorName: 'Oxford Tan',
    r: 184,
    g: 169,
    b: 154,
  },
  {
    pantoneId: 609,
    name: '15-1308 TCX',
    majorColorName: 'Brown',
    colorName: 'Doeskin',
    r: 189,
    g: 171,
    b: 155,
  },
  {
    pantoneId: 610,
    name: '15-1309 TCX',
    majorColorName: 'Orange',
    colorName: 'Moonlight',
    r: 197,
    g: 177,
    b: 160,
  },
  {
    pantoneId: 611,
    name: '15-1314 TCX',
    majorColorName: 'Orange',
    colorName: 'Cuban Sand',
    r: 193,
    g: 166,
    b: 141,
  },
  {
    pantoneId: 612,
    name: '15-1315 TCX',
    majorColorName: 'Orange',
    colorName: 'Rugby Tan',
    r: 194,
    g: 165,
    b: 148,
  },
  {
    pantoneId: 613,
    name: '15-1316 TCX',
    majorColorName: 'Orange',
    colorName: 'Maple Sugar',
    r: 201,
    g: 163,
    b: 141,
  },
  {
    pantoneId: 614,
    name: '15-1317 TCX',
    majorColorName: 'Orange',
    colorName: 'Sirocco',
    r: 195,
    g: 157,
    b: 136,
  },
  {
    pantoneId: 615,
    name: '15-1318 TCX',
    majorColorName: 'Orange',
    colorName: 'Pink Sand',
    r: 223,
    g: 177,
    b: 155,
  },
  {
    pantoneId: 616,
    name: '15-1319 TCX',
    majorColorName: 'Orange',
    colorName: 'Almost Apricot',
    r: 229,
    g: 179,
    b: 155,
  },
  {
    pantoneId: 617,
    name: '15-1322 TCX',
    majorColorName: 'Orange',
    colorName: 'Dusty Coral',
    r: 210,
    g: 155,
    b: 131,
  },
  {
    pantoneId: 618,
    name: '15-1327 TCX',
    majorColorName: 'Orange',
    colorName: 'Peach Bloom',
    r: 217,
    g: 155,
    b: 124,
  },
  {
    pantoneId: 619,
    name: '15-1331 TCX',
    majorColorName: 'Orange',
    colorName: 'Coral Reef',
    r: 250,
    g: 161,
    b: 129,
  },
  {
    pantoneId: 620,
    name: '15-1333 TCX',
    majorColorName: 'Orange',
    colorName: 'Canyon Sunset',
    r: 225,
    g: 146,
    b: 122,
  },
  {
    pantoneId: 621,
    name: '15-1334 TCX',
    majorColorName: 'Orange',
    colorName: 'Shell Coral',
    r: 234,
    g: 149,
    b: 117,
  },
  {
    pantoneId: 622,
    name: '15-1340 TCX',
    majorColorName: 'Orange',
    colorName: 'Cadmium Orange',
    r: 249,
    g: 148,
    b: 113,
  },
  {
    pantoneId: 623,
    name: '15-1415 TCX',
    majorColorName: 'Pink',
    colorName: 'Coral Cloud',
    r: 226,
    g: 169,
    b: 161,
  },
  {
    pantoneId: 624,
    name: '15-1423 TCX',
    majorColorName: 'Pink',
    colorName: 'Peach Amber',
    r: 251,
    g: 159,
    b: 147,
  },
  {
    pantoneId: 625,
    name: '15-1433 TCX',
    majorColorName: 'Orange',
    colorName: 'Papaya Punch',
    r: 252,
    g: 162,
    b: 137,
  },
  {
    pantoneId: 626,
    name: '15-1435 TCX',
    majorColorName: 'Pink',
    colorName: 'Desert Flower',
    r: 255,
    g: 150,
    b: 135,
  },
  {
    pantoneId: 627,
    name: '15-1511 TCX',
    majorColorName: 'Orange',
    colorName: 'Mahogany Rose',
    r: 197,
    g: 161,
    b: 147,
  },
  {
    pantoneId: 628,
    name: '15-1512 TCX',
    majorColorName: 'Orange',
    colorName: 'Misty Rose',
    r: 202,
    g: 163,
    b: 154,
  },
  {
    pantoneId: 629,
    name: '15-1515 TCX',
    majorColorName: 'Pink',
    colorName: 'Mellow Rose',
    r: 217,
    g: 166,
    b: 161,
  },
  {
    pantoneId: 630,
    name: '15-1516 TCX',
    majorColorName: 'Red',
    colorName: 'Peach Beige',
    r: 211,
    g: 162,
    b: 151,
  },
  {
    pantoneId: 631,
    name: '15-1523 TCX',
    majorColorName: 'Orange',
    colorName: 'Shrimp',
    r: 226,
    g: 154,
    b: 134,
  },
  {
    pantoneId: 632,
    name: '15-1530 TCX',
    majorColorName: 'Pink',
    colorName: 'Peach Pink',
    r: 250,
    g: 154,
    b: 133,
  },
  {
    pantoneId: 633,
    name: '15-1607 TCX',
    majorColorName: 'Pink',
    colorName: 'Pale Mauve',
    r: 198,
    g: 164,
    b: 164,
  },
  {
    pantoneId: 634,
    name: '15-1611 TCX',
    majorColorName: 'Pink',
    colorName: 'Bridal Rose',
    r: 214,
    g: 159,
    b: 162,
  },
  {
    pantoneId: 635,
    name: '15-1614 TCX',
    majorColorName: 'Pink',
    colorName: 'Blush',
    r: 209,
    g: 150,
    b: 154,
  },
  {
    pantoneId: 636,
    name: '15-1621 TCX',
    majorColorName: 'Pink',
    colorName: 'Candlelight Peach',
    r: 248,
    g: 163,
    b: 157,
  },
  {
    pantoneId: 637,
    name: '15-1624 TCX',
    majorColorName: 'Pink',
    colorName: 'Conch Shell',
    r: 252,
    g: 143,
    b: 155,
  },
  {
    pantoneId: 638,
    name: '15-1626 TCX',
    majorColorName: 'Pink',
    colorName: 'Salmon Rose',
    r: 255,
    g: 141,
    b: 148,
  },
  {
    pantoneId: 639,
    name: '15-1717 TCX',
    majorColorName: 'Pink',
    colorName: 'Pink Icing',
    r: 238,
    g: 160,
    b: 166,
  },
  {
    pantoneId: 640,
    name: '15-1816 TCX',
    majorColorName: 'Pink',
    colorName: 'Peony',
    r: 237,
    g: 156,
    b: 168,
  },
  {
    pantoneId: 641,
    name: '15-1821 TCX',
    majorColorName: 'Pink',
    colorName: 'Flamingo Pink',
    r: 247,
    g: 150,
    b: 158,
  },
  {
    pantoneId: 642,
    name: '15-1905 TCX',
    majorColorName: 'White',
    colorName: 'Burnished Lilac',
    r: 197,
    g: 174,
    b: 177,
  },
  {
    pantoneId: 643,
    name: '15-1906 TCX',
    majorColorName: 'Pink',
    colorName: 'Zephyr',
    r: 200,
    g: 159,
    b: 165,
  },
  {
    pantoneId: 644,
    name: '15-1912 TCX',
    majorColorName: 'Pink',
    colorName: 'Sea Pink',
    r: 222,
    g: 152,
    b: 171,
  },
  {
    pantoneId: 645,
    name: '15-1920 TCX',
    majorColorName: 'Pink',
    colorName: 'Morning Glory',
    r: 238,
    g: 129,
    b: 159,
  },
  {
    pantoneId: 646,
    name: '15-1922 TCX',
    majorColorName: 'Pink',
    colorName: 'Geranium Pink',
    r: 246,
    g: 144,
    b: 157,
  },
  {
    pantoneId: 647,
    name: '15-2205 TCX',
    majorColorName: 'White',
    colorName: 'Dawn Pink',
    r: 191,
    g: 163,
    b: 175,
  },
  {
    pantoneId: 648,
    name: '15-2210 TCX',
    majorColorName: 'Pink',
    colorName: 'Orchid Smoke',
    r: 210,
    g: 148,
    b: 170,
  },
  {
    pantoneId: 649,
    name: '15-2214 TCX',
    majorColorName: 'Pink',
    colorName: 'Rosebloom',
    r: 226,
    g: 144,
    b: 178,
  },
  {
    pantoneId: 650,
    name: '15-2215 TCX',
    majorColorName: 'Pink',
    colorName: 'Begonia Pink',
    r: 236,
    g: 154,
    b: 190,
  },
  {
    pantoneId: 651,
    name: '15-2216 TCX',
    majorColorName: 'Pink',
    colorName: 'Sachet Pink',
    r: 241,
    g: 138,
    b: 173,
  },
  {
    pantoneId: 652,
    name: '15-2217 TCX',
    majorColorName: 'Pink',
    colorName: 'Aurora Pink',
    r: 232,
    g: 129,
    b: 166,
  },
  {
    pantoneId: 653,
    name: '15-2705 TCX',
    majorColorName: 'White',
    colorName: 'Keepsake Lilac',
    r: 192,
    g: 165,
    b: 174,
  },
  {
    pantoneId: 654,
    name: '15-2706 TCX',
    majorColorName: 'White',
    colorName: 'Violet Ice',
    r: 194,
    g: 172,
    b: 177,
  },
  {
    pantoneId: 655,
    name: '15-2718 TCX',
    majorColorName: 'Pink',
    colorName: 'Fuchsia Pink',
    r: 223,
    g: 136,
    b: 183,
  },
  {
    pantoneId: 656,
    name: '15-2913 TCX',
    majorColorName: 'Pink',
    colorName: 'Lilac Chiffon',
    r: 222,
    g: 155,
    b: 196,
  },
  {
    pantoneId: 657,
    name: '15-3207 TCX',
    majorColorName: 'Pink',
    colorName: 'Mauve Mist',
    r: 196,
    g: 157,
    b: 180,
  },
  {
    pantoneId: 658,
    name: '15-3214 TCX',
    majorColorName: 'Pink',
    colorName: 'Orchid',
    r: 209,
    g: 152,
    b: 197,
  },
  {
    pantoneId: 659,
    name: '15-3412 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Orchid Bouquet',
    r: 209,
    g: 172,
    b: 206,
  },
  {
    pantoneId: 660,
    name: '15-3507 TCX',
    majorColorName: 'White',
    colorName: 'Lavender Frost',
    r: 189,
    g: 171,
    b: 190,
  },
  {
    pantoneId: 661,
    name: '15-3508 TCX',
    majorColorName: 'White',
    colorName: 'Fair Orchid',
    r: 192,
    g: 170,
    b: 192,
  },
  {
    pantoneId: 662,
    name: '15-3620 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Lavendula',
    r: 188,
    g: 164,
    b: 203,
  },
  {
    pantoneId: 663,
    name: '15-3802 TCX',
    majorColorName: 'White',
    colorName: 'Cloud Gray',
    r: 183,
    g: 169,
    b: 172,
  },
  {
    pantoneId: 664,
    name: '15-3807 TCX',
    majorColorName: 'White',
    colorName: 'Misty Lilac',
    r: 188,
    g: 180,
    b: 196,
  },
  {
    pantoneId: 665,
    name: '15-3817 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Lavender',
    r: 175,
    g: 164,
    b: 206,
  },
  {
    pantoneId: 666,
    name: '15-3909 TCX',
    majorColorName: 'White',
    colorName: 'Cosmic Sky',
    r: 170,
    g: 170,
    b: 196,
  },
  {
    pantoneId: 667,
    name: '15-3910 TCX',
    majorColorName: 'White',
    colorName: 'Languid Lavender',
    r: 162,
    g: 161,
    b: 186,
  },
  {
    pantoneId: 668,
    name: '15-3915 TCX',
    majorColorName: 'Blue',
    colorName: 'Kentucky Blue',
    r: 165,
    g: 179,
    b: 204,
  },
  {
    pantoneId: 669,
    name: '15-3920 TCX',
    majorColorName: 'Blue',
    colorName: 'Placid Blue',
    r: 140,
    g: 173,
    b: 211,
  },
  {
    pantoneId: 670,
    name: '15-3930 TCX',
    majorColorName: 'Blue',
    colorName: 'Vista Blue',
    r: 129,
    g: 160,
    b: 212,
  },
  {
    pantoneId: 671,
    name: '15-4003 TCX',
    majorColorName: 'White',
    colorName: 'Storm Gray',
    r: 181,
    g: 186,
    b: 182,
  },
  {
    pantoneId: 672,
    name: '15-4005 TCX',
    majorColorName: 'Blue',
    colorName: 'Dream Blue',
    r: 160,
    g: 188,
    b: 208,
  },
  {
    pantoneId: 673,
    name: '15-4008 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Fog',
    r: 155,
    g: 171,
    b: 187,
  },
  {
    pantoneId: 674,
    name: '15-4020 TCX',
    majorColorName: 'Blue',
    colorName: 'Cerulean',
    r: 155,
    g: 183,
    b: 212,
  },
  {
    pantoneId: 675,
    name: '15-4101 TCX',
    majorColorName: 'White',
    colorName: 'High-rise',
    r: 174,
    g: 178,
    b: 181,
  },
  {
    pantoneId: 676,
    name: '15-4105 TCX',
    majorColorName: 'Blue',
    colorName: 'Angel Falls',
    r: 163,
    g: 189,
    b: 211,
  },
  {
    pantoneId: 677,
    name: '15-4225 TCX',
    majorColorName: 'Blue',
    colorName: 'Alaskan Blue',
    r: 109,
    g: 169,
    b: 210,
  },
  {
    pantoneId: 678,
    name: '15-4305 TCX',
    majorColorName: 'Gray',
    colorName: 'Quarry',
    r: 152,
    g: 160,
    b: 165,
  },
  {
    pantoneId: 679,
    name: '15-4309 TCX',
    majorColorName: 'Blue',
    colorName: 'Sterling Blue',
    r: 162,
    g: 185,
    b: 194,
  },
  {
    pantoneId: 680,
    name: '15-4312 TCX',
    majorColorName: 'Blue',
    colorName: 'Forget-Me-Not',
    r: 143,
    g: 173,
    b: 189,
  },
  {
    pantoneId: 681,
    name: '15-4319 TCX',
    majorColorName: 'Blue',
    colorName: 'Air Blue',
    r: 119,
    g: 172,
    b: 199,
  },
  {
    pantoneId: 682,
    name: '15-4323 TCX',
    majorColorName: 'Blue',
    colorName: 'Ethereal Blue',
    r: 92,
    g: 166,
    b: 206,
  },
  {
    pantoneId: 683,
    name: '15-4415 TCX',
    majorColorName: 'Blue',
    colorName: 'Milky Blue',
    r: 114,
    g: 168,
    b: 186,
  },
  {
    pantoneId: 684,
    name: '15-4421 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Grotto',
    r: 92,
    g: 172,
    b: 206,
  },
  {
    pantoneId: 685,
    name: '15-4427 TCX',
    majorColorName: 'Blue',
    colorName: 'Norse Blue',
    r: 76,
    g: 165,
    b: 199,
  },
  {
    pantoneId: 686,
    name: '15-4502 TCX',
    majorColorName: 'White',
    colorName: 'Silver Cloud',
    r: 190,
    g: 183,
    b: 176,
  },
  {
    pantoneId: 687,
    name: '15-4503 TCX',
    majorColorName: 'White',
    colorName: 'Chateau Gray',
    r: 187,
    g: 177,
    b: 168,
  },
  {
    pantoneId: 688,
    name: '15-4702 TCX',
    majorColorName: 'White',
    colorName: 'Puritan Gray',
    r: 168,
    g: 176,
    b: 174,
  },
  {
    pantoneId: 689,
    name: '15-4703 TCX',
    majorColorName: 'White',
    colorName: 'Mirage Gray',
    r: 171,
    g: 175,
    b: 174,
  },
  {
    pantoneId: 690,
    name: '15-4704 TCX',
    majorColorName: 'White',
    colorName: 'Pigeon',
    r: 169,
    g: 175,
    b: 170,
  },
  {
    pantoneId: 691,
    name: '15-4706 TCX',
    majorColorName: 'White',
    colorName: 'Gray Mist',
    r: 153,
    g: 174,
    b: 174,
  },
  {
    pantoneId: 692,
    name: '15-4707 TCX',
    majorColorName: 'White',
    colorName: 'Blue Haze',
    r: 165,
    g: 188,
    b: 187,
  },
  {
    pantoneId: 693,
    name: '15-4712 TCX',
    majorColorName: 'Blue',
    colorName: 'Marine Blue',
    r: 118,
    g: 175,
    b: 182,
  },
  {
    pantoneId: 694,
    name: '15-4715 TCX',
    majorColorName: 'Blue',
    colorName: 'Aqua Sea',
    r: 107,
    g: 170,
    b: 174,
  },
  {
    pantoneId: 695,
    name: '15-4717 TCX',
    majorColorName: 'Blue',
    colorName: 'Aqua',
    r: 100,
    g: 161,
    b: 173,
  },
  {
    pantoneId: 696,
    name: '15-4720 TCX',
    majorColorName: 'Blue',
    colorName: 'River Blue',
    r: 56,
    g: 175,
    b: 205,
  },
  {
    pantoneId: 697,
    name: '15-4722 TCX',
    majorColorName: 'Blue',
    colorName: 'Capri',
    r: 68,
    g: 187,
    b: 202,
  },
  {
    pantoneId: 698,
    name: '15-4825 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Curacao',
    r: 50,
    g: 190,
    b: 204,
  },
  {
    pantoneId: 699,
    name: '15-5205 TCX',
    majorColorName: 'White',
    colorName: 'Aqua Gray',
    r: 165,
    g: 178,
    b: 170,
  },
  {
    pantoneId: 700,
    name: '15-5207 TCX',
    majorColorName: 'Blue-green',
    colorName: 'Aquifer',
    r: 137,
    g: 172,
    b: 172,
  },
  {
    pantoneId: 701,
    name: '15-5209 TCX',
    majorColorName: 'Blue',
    colorName: 'Aqua Haze',
    r: 135,
    g: 185,
    b: 188,
  },
  {
    pantoneId: 702,
    name: '15-5210 TCX',
    majorColorName: 'Blue',
    colorName: 'Nile Blue',
    r: 118,
    g: 167,
    b: 171,
  },
  {
    pantoneId: 703,
    name: '15-5217 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Blue Turquoise',
    r: 83,
    g: 176,
    b: 174,
  },
  {
    pantoneId: 704,
    name: '15-5218 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Pool Blue',
    r: 103,
    g: 188,
    b: 179,
  },
  {
    pantoneId: 705,
    name: '15-5416 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Florida Keys',
    r: 86,
    g: 190,
    b: 171,
  },
  {
    pantoneId: 706,
    name: '15-5421 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Aqua Green',
    r: 0,
    g: 184,
    b: 159,
  },
  {
    pantoneId: 707,
    name: '15-5425 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Atlantis',
    r: 0,
    g: 175,
    b: 159,
  },
  {
    pantoneId: 708,
    name: '15-5516 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Waterfall',
    r: 58,
    g: 176,
    b: 162,
  },
  {
    pantoneId: 709,
    name: '15-5519 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Turquoise',
    r: 69,
    g: 181,
    b: 170,
  },
  {
    pantoneId: 710,
    name: '15-5534 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Bright Green',
    r: 0,
    g: 155,
    b: 92,
  },
  {
    pantoneId: 711,
    name: '15-5704 TCX',
    majorColorName: 'White',
    colorName: 'Mineral Gray',
    r: 178,
    g: 182,
    b: 172,
  },
  {
    pantoneId: 712,
    name: '15-5706 TCX',
    majorColorName: 'White',
    colorName: 'Frosty Green',
    r: 163,
    g: 181,
    b: 166,
  },
  {
    pantoneId: 713,
    name: '15-5711 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Dusty Jade Green',
    r: 123,
    g: 181,
    b: 163,
  },
  {
    pantoneId: 714,
    name: '15-5718 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Biscay Green',
    r: 85,
    g: 198,
    b: 169,
  },
  {
    pantoneId: 715,
    name: '15-5728 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Mint Leaf',
    r: 0,
    g: 182,
    b: 148,
  },
  {
    pantoneId: 716,
    name: '15-5812 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Lichen',
    r: 155,
    g: 194,
    b: 177,
  },
  {
    pantoneId: 717,
    name: '15-5819 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Spearmint',
    r: 100,
    g: 191,
    b: 164,
  },
  {
    pantoneId: 718,
    name: '15-6114 TCX',
    majorColorName: 'Green',
    colorName: 'Hemlock',
    r: 151,
    g: 193,
    b: 161,
  },
  {
    pantoneId: 719,
    name: '15-6120 TCX',
    majorColorName: 'Green',
    colorName: 'Ming',
    r: 124,
    g: 176,
    b: 138,
  },
  {
    pantoneId: 720,
    name: '15-6123 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Jade Cream',
    r: 96,
    g: 184,
    b: 146,
  },
  {
    pantoneId: 721,
    name: '15-6304 TCX',
    majorColorName: 'White',
    colorName: 'Pussywillow Gray',
    r: 174,
    g: 172,
    b: 161,
  },
  {
    pantoneId: 722,
    name: '15-6307 TCX',
    majorColorName: 'White',
    colorName: 'Agate Gray',
    r: 177,
    g: 176,
    b: 159,
  },
  {
    pantoneId: 723,
    name: '15-6310 TCX',
    majorColorName: 'White',
    colorName: 'Swamp',
    r: 168,
    g: 177,
    b: 151,
  },
  {
    pantoneId: 724,
    name: '15-6313 TCX',
    majorColorName: 'White',
    colorName: 'Laurel Green',
    r: 173,
    g: 187,
    b: 161,
  },
  {
    pantoneId: 725,
    name: '15-6315 TCX',
    majorColorName: 'White',
    colorName: 'Smoke Green',
    r: 168,
    g: 187,
    b: 162,
  },
  {
    pantoneId: 726,
    name: '15-6316 TCX',
    majorColorName: 'Green',
    colorName: 'Fair Green',
    r: 146,
    g: 175,
    b: 136,
  },
  {
    pantoneId: 727,
    name: '15-6317 TCX',
    majorColorName: 'Green',
    colorName: 'Quiet Green',
    r: 158,
    g: 188,
    b: 151,
  },
  {
    pantoneId: 728,
    name: '15-6322 TCX',
    majorColorName: 'Green',
    colorName: 'Light Grass Green',
    r: 124,
    g: 182,
    b: 142,
  },
  {
    pantoneId: 729,
    name: '15-6340 TCX',
    majorColorName: 'Green',
    colorName: 'Irish Green',
    r: 69,
    g: 190,
    b: 118,
  },
  {
    pantoneId: 730,
    name: '15-6410 TCX',
    majorColorName: 'White',
    colorName: 'Moss Gray',
    r: 175,
    g: 171,
    b: 151,
  },
  {
    pantoneId: 731,
    name: '15-6414 TCX',
    majorColorName: 'Green',
    colorName: 'Reseda',
    r: 161,
    g: 173,
    b: 146,
  },
  {
    pantoneId: 732,
    name: '15-6423 TCX',
    majorColorName: 'Green',
    colorName: 'Forest Shade',
    r: 145,
    g: 172,
    b: 128,
  },
  {
    pantoneId: 733,
    name: '15-6428 TCX',
    majorColorName: 'Green',
    colorName: 'Green Tea',
    r: 134,
    g: 169,
    b: 111,
  },
  {
    pantoneId: 734,
    name: '15-6432 TCX',
    majorColorName: 'Green',
    colorName: 'Shamrock',
    r: 111,
    g: 162,
    b: 107,
  },
  {
    pantoneId: 735,
    name: '15-6437 TCX',
    majorColorName: 'Green',
    colorName: 'Grass Green',
    r: 123,
    g: 179,
    b: 105,
  },
  {
    pantoneId: 736,
    name: '15-6442 TCX',
    majorColorName: 'Green',
    colorName: 'Bud Green',
    r: 121,
    g: 180,
    b: 101,
  },
  {
    pantoneId: 737,
    name: '16-0000 TCX',
    majorColorName: 'Gray',
    colorName: 'Paloma',
    r: 159,
    g: 156,
    b: 153,
  },
  {
    pantoneId: 738,
    name: '16-0110 TCX',
    majorColorName: 'White',
    colorName: 'Desert Sage',
    r: 167,
    g: 174,
    b: 158,
  },
  {
    pantoneId: 739,
    name: '16-0123 TCX',
    majorColorName: 'Green',
    colorName: 'Tendril',
    r: 137,
    g: 160,
    b: 107,
  },
  {
    pantoneId: 740,
    name: '16-0207 TCX',
    majorColorName: 'Gray',
    colorName: 'London Fog',
    r: 162,
    g: 158,
    b: 146,
  },
  {
    pantoneId: 741,
    name: '16-0213 TCX',
    majorColorName: 'Gray',
    colorName: 'Tea',
    r: 153,
    g: 155,
    b: 133,
  },
  {
    pantoneId: 742,
    name: '16-0220 TCX',
    majorColorName: 'Green',
    colorName: 'Mistletoe',
    r: 138,
    g: 162,
    b: 130,
  },
  {
    pantoneId: 743,
    name: '16-0224 TCX',
    majorColorName: 'Green',
    colorName: 'Green Eyes',
    r: 125,
    g: 149,
    b: 109,
  },
  {
    pantoneId: 744,
    name: '16-0228 TCX',
    majorColorName: 'Green',
    colorName: 'Jade Green',
    r: 117,
    g: 148,
    b: 101,
  },
  {
    pantoneId: 745,
    name: '16-0230 TCX',
    majorColorName: 'Green',
    colorName: 'Macaw Green',
    r: 155,
    g: 181,
    b: 62,
  },
  {
    pantoneId: 746,
    name: '16-0233 TCX',
    majorColorName: 'Green',
    colorName: 'Meadow Green',
    r: 115,
    g: 153,
    b: 87,
  },
  {
    pantoneId: 747,
    name: '16-0235 TCX',
    majorColorName: 'Green',
    colorName: 'Kiwi',
    r: 122,
    g: 171,
    b: 85,
  },
  {
    pantoneId: 748,
    name: '16-0237 TCX',
    majorColorName: 'Green',
    colorName: 'Foliage',
    r: 117,
    g: 161,
    b: 79,
  },
  {
    pantoneId: 749,
    name: '16-0421 TCX',
    majorColorName: 'Green',
    colorName: 'Sage',
    r: 145,
    g: 148,
    b: 110,
  },
  {
    pantoneId: 750,
    name: '16-0430 TCX',
    majorColorName: 'Green',
    colorName: 'Fern',
    r: 154,
    g: 160,
    b: 103,
  },
  {
    pantoneId: 751,
    name: '16-0435 TCX',
    majorColorName: 'Green',
    colorName: 'Dark Citron',
    r: 160,
    g: 172,
    b: 79,
  },
  {
    pantoneId: 752,
    name: '16-0439 TCX',
    majorColorName: 'Green',
    colorName: 'Spinach Green',
    r: 144,
    g: 155,
    b: 76,
  },
  {
    pantoneId: 753,
    name: '16-0518 TCX',
    majorColorName: 'Yellow',
    colorName: 'Gray Green',
    r: 164,
    g: 154,
    b: 121,
  },
  {
    pantoneId: 754,
    name: '16-0526 TCX',
    majorColorName: 'Yellow',
    colorName: 'Cedar',
    r: 146,
    g: 142,
    b: 100,
  },
  {
    pantoneId: 755,
    name: '16-0532 TCX',
    majorColorName: 'Yellow',
    colorName: 'Moss',
    r: 160,
    g: 157,
    b: 89,
  },
  {
    pantoneId: 756,
    name: '16-0540 TCX',
    majorColorName: 'Yellow',
    colorName: 'Oasis',
    r: 163,
    g: 160,
    b: 78,
  },
  {
    pantoneId: 757,
    name: '16-0613 TCX',
    majorColorName: 'Yellow',
    colorName: 'Elm',
    r: 163,
    g: 159,
    b: 134,
  },
  {
    pantoneId: 758,
    name: '16-0632 TCX',
    majorColorName: 'Yellow',
    colorName: 'Willow',
    r: 154,
    g: 139,
    b: 79,
  },
  {
    pantoneId: 759,
    name: '16-0639 TCX',
    majorColorName: 'Yellow',
    colorName: 'Golden Olive',
    r: 175,
    g: 152,
    b: 65,
  },
  {
    pantoneId: 760,
    name: '16-0713 TCX',
    majorColorName: 'Yellow',
    colorName: 'Slate Green',
    r: 160,
    g: 152,
    b: 124,
  },
  {
    pantoneId: 761,
    name: '16-0726 TCX',
    majorColorName: 'Brown',
    colorName: 'Khaki',
    r: 163,
    g: 146,
    b: 100,
  },
  {
    pantoneId: 762,
    name: '16-0730 TCX',
    majorColorName: 'Brown',
    colorName: 'Antique Gold',
    r: 181,
    g: 158,
    b: 95,
  },
  {
    pantoneId: 763,
    name: '16-0737 TCX',
    majorColorName: 'Yellow',
    colorName: 'Burnished Gold',
    r: 170,
    g: 152,
    b: 85,
  },
  {
    pantoneId: 764,
    name: '16-0742 TCX',
    majorColorName: 'Yellow',
    colorName: 'Green Sulphur',
    r: 174,
    g: 142,
    b: 44,
  },
  {
    pantoneId: 765,
    name: '16-0806 TCX',
    majorColorName: 'White',
    colorName: 'Goat',
    r: 168,
    g: 154,
    b: 145,
  },
  {
    pantoneId: 766,
    name: '16-0836 TCX',
    majorColorName: 'Orange',
    colorName: 'Rich Gold',
    r: 200,
    g: 178,
    b: 115,
  },
  {
    pantoneId: 767,
    name: '16-0840 TCX',
    majorColorName: 'Yellow',
    colorName: 'Antique Moss',
    r: 185,
    g: 160,
    b: 35,
  },
  {
    pantoneId: 768,
    name: '16-0847 TCX',
    majorColorName: 'Yellow',
    colorName: 'Olive Oil',
    r: 169,
    g: 139,
    b: 45,
  },
  {
    pantoneId: 769,
    name: '16-0906 TCX',
    majorColorName: 'Brown',
    colorName: 'Simply Taupe',
    r: 173,
    g: 159,
    b: 147,
  },
  {
    pantoneId: 770,
    name: '16-0924 TCX',
    majorColorName: 'Orange',
    colorName: 'Croissant',
    r: 196,
    g: 171,
    b: 134,
  },
  {
    pantoneId: 771,
    name: '16-0928 TCX',
    majorColorName: 'Brown',
    colorName: 'Curry',
    r: 190,
    g: 158,
    b: 111,
  },
  {
    pantoneId: 772,
    name: '16-0940 TCX',
    majorColorName: 'Orange',
    colorName: 'Taffy',
    r: 195,
    g: 155,
    b: 106,
  },
  {
    pantoneId: 773,
    name: '16-0945 TCX',
    majorColorName: 'Orange',
    colorName: 'Tinsel',
    r: 195,
    g: 150,
    b: 77,
  },
  {
    pantoneId: 774,
    name: '16-0946 TCX',
    majorColorName: 'Brown',
    colorName: 'Honey',
    r: 186,
    g: 146,
    b: 56,
  },
  {
    pantoneId: 775,
    name: '16-0947 TCX',
    majorColorName: 'Orange',
    colorName: 'Bright Gold',
    r: 207,
    g: 159,
    b: 82,
  },
  {
    pantoneId: 776,
    name: '16-0948 TCX',
    majorColorName: 'Brown',
    colorName: 'Harvest Gold',
    r: 182,
    g: 138,
    b: 58,
  },
  {
    pantoneId: 777,
    name: '16-0950 TCX',
    majorColorName: 'Orange',
    colorName: 'Narcissus',
    r: 195,
    g: 148,
    b: 73,
  },
  {
    pantoneId: 778,
    name: '16-0952 TCX',
    majorColorName: 'Orange',
    colorName: 'Nugget Gold',
    r: 200,
    g: 151,
    b: 32,
  },
  {
    pantoneId: 779,
    name: '16-0953 TCX',
    majorColorName: 'Orange',
    colorName: 'Tawny Olive',
    r: 196,
    g: 150,
    b: 44,
  },
  {
    pantoneId: 780,
    name: '16-0954 TCX',
    majorColorName: 'Brown',
    colorName: 'Arrowwood',
    r: 188,
    g: 141,
    b: 31,
  },
  {
    pantoneId: 781,
    name: '16-1010 TCX',
    majorColorName: 'Brown',
    colorName: 'Incense',
    r: 175,
    g: 154,
    b: 126,
  },
  {
    pantoneId: 782,
    name: '16-1054 TCX',
    majorColorName: 'Orange',
    colorName: 'Sunflower',
    r: 211,
    g: 146,
    b: 55,
  },
  {
    pantoneId: 783,
    name: '16-1105 TCX',
    majorColorName: 'Brown',
    colorName: 'Plaza Taupe',
    r: 174,
    g: 163,
    b: 147,
  },
  {
    pantoneId: 784,
    name: '16-1106 TCX',
    majorColorName: 'Brown',
    colorName: 'Tuffet',
    r: 165,
    g: 151,
    b: 136,
  },
  {
    pantoneId: 785,
    name: '16-1107 TCX',
    majorColorName: 'Brown',
    colorName: 'Aluminum',
    r: 159,
    g: 149,
    b: 134,
  },
  {
    pantoneId: 786,
    name: '16-1108 TCX',
    majorColorName: 'Brown',
    colorName: 'Twill',
    r: 167,
    g: 155,
    b: 130,
  },
  {
    pantoneId: 787,
    name: '16-1110 TCX',
    majorColorName: 'Brown',
    colorName: 'Olive Gray',
    r: 166,
    g: 153,
    b: 122,
  },
  {
    pantoneId: 788,
    name: '16-1118 TCX',
    majorColorName: 'Brown',
    colorName: 'Sponge',
    r: 164,
    g: 151,
    b: 117,
  },
  {
    pantoneId: 789,
    name: '16-1126 TCX',
    majorColorName: 'Brown',
    colorName: 'Antelope',
    r: 177,
    g: 150,
    b: 100,
  },
  {
    pantoneId: 790,
    name: '16-1133 TCX',
    majorColorName: 'Brown',
    colorName: 'Mustard Gold',
    r: 176,
    g: 142,
    b: 81,
  },
  {
    pantoneId: 791,
    name: '16-1139 TCX',
    majorColorName: 'Orange',
    colorName: 'Amber Gold',
    r: 193,
    g: 149,
    b: 82,
  },
  {
    pantoneId: 792,
    name: '16-1140 TCX',
    majorColorName: 'Orange',
    colorName: 'Yam',
    r: 208,
    g: 137,
    b: 63,
  },
  {
    pantoneId: 793,
    name: '16-1142 TCX',
    majorColorName: 'Orange',
    colorName: 'Golden Nugget',
    r: 219,
    g: 155,
    b: 89,
  },
  {
    pantoneId: 794,
    name: '16-1143 TCX',
    majorColorName: 'Orange',
    colorName: 'Honey Yellow',
    r: 202,
    g: 148,
    b: 86,
  },
  {
    pantoneId: 795,
    name: '16-1144 TCX',
    majorColorName: 'Orange',
    colorName: 'Oak Buff',
    r: 207,
    g: 156,
    b: 99,
  },
  {
    pantoneId: 796,
    name: '16-1148 TCX',
    majorColorName: 'Orange',
    colorName: 'Nugget',
    r: 207,
    g: 136,
    b: 72,
  },
  {
    pantoneId: 797,
    name: '16-1150 TCX',
    majorColorName: 'Orange',
    colorName: 'Topaz',
    r: 208,
    g: 131,
    b: 68,
  },
  {
    pantoneId: 798,
    name: '16-1210 TCX',
    majorColorName: 'Brown',
    colorName: 'Light Taupe',
    r: 177,
    g: 157,
    b: 141,
  },
  {
    pantoneId: 799,
    name: '16-1212 TCX',
    majorColorName: 'Brown',
    colorName: 'Nomad',
    r: 180,
    g: 159,
    b: 137,
  },
  {
    pantoneId: 800,
    name: '16-1219 TCX',
    majorColorName: 'Brown',
    colorName: 'Tuscany',
    r: 190,
    g: 151,
    b: 133,
  },
  {
    pantoneId: 801,
    name: '16-1220 TCX',
    majorColorName: 'Orange',
    colorName: 'Cafe Creme',
    r: 199,
    g: 150,
    b: 133,
  },
  {
    pantoneId: 802,
    name: '16-1221 TCX',
    majorColorName: 'Brown',
    colorName: 'Roebuck',
    r: 176,
    g: 144,
    b: 128,
  },
  {
    pantoneId: 803,
    name: '16-1235 TCX',
    majorColorName: 'Brown',
    colorName: 'Sandstorm',
    r: 189,
    g: 139,
    b: 105,
  },
  {
    pantoneId: 804,
    name: '16-1253 TCX',
    majorColorName: 'Orange',
    colorName: 'Orange Ochre',
    r: 220,
    g: 121,
    b: 58,
  },
  {
    pantoneId: 805,
    name: '16-1255 TCX',
    majorColorName: 'Orange',
    colorName: 'Russet Orange',
    r: 228,
    g: 113,
    b: 39,
  },
  {
    pantoneId: 806,
    name: '16-1257 TCX',
    majorColorName: 'Orange',
    colorName: 'Sun Orange',
    r: 244,
    g: 128,
    b: 55,
  },
  {
    pantoneId: 807,
    name: '16-1260 TCX',
    majorColorName: 'Orange',
    colorName: 'Harvest Pumpkin',
    r: 213,
    g: 98,
    b: 49,
  },
  {
    pantoneId: 808,
    name: '16-1305 TCX',
    majorColorName: 'White',
    colorName: 'String',
    r: 170,
    g: 159,
    b: 150,
  },
  {
    pantoneId: 809,
    name: '16-1310 TCX',
    majorColorName: 'Brown',
    colorName: 'Natural',
    r: 170,
    g: 144,
    b: 125,
  },
  {
    pantoneId: 810,
    name: '16-1315 TCX',
    majorColorName: 'Brown',
    colorName: 'Cornstalk',
    r: 169,
    g: 148,
    b: 122,
  },
  {
    pantoneId: 811,
    name: '16-1317 TCX',
    majorColorName: 'Brown',
    colorName: 'Brush',
    r: 185,
    g: 153,
    b: 132,
  },
  {
    pantoneId: 812,
    name: '16-1318 TCX',
    majorColorName: 'Brown',
    colorName: 'Warm Taupe',
    r: 175,
    g: 148,
    b: 131,
  },
  {
    pantoneId: 813,
    name: '16-1320 TCX',
    majorColorName: 'Brown',
    colorName: 'Nougat',
    r: 182,
    g: 152,
    b: 133,
  },
  {
    pantoneId: 814,
    name: '16-1323 TCX',
    majorColorName: 'Brown',
    colorName: 'Macaroon',
    r: 179,
    g: 139,
    b: 113,
  },
  {
    pantoneId: 815,
    name: '16-1324 TCX',
    majorColorName: 'Brown',
    colorName: 'Lark',
    r: 184,
    g: 155,
    b: 114,
  },
  {
    pantoneId: 816,
    name: '16-1325 TCX',
    majorColorName: 'Orange',
    colorName: 'Copper',
    r: 196,
    g: 126,
    b: 90,
  },
  {
    pantoneId: 817,
    name: '16-1326 TCX',
    majorColorName: 'Brown',
    colorName: 'Prairie Sand',
    r: 181,
    g: 154,
    b: 106,
  },
  {
    pantoneId: 818,
    name: '16-1327 TCX',
    majorColorName: 'Orange',
    colorName: 'Toasted Nut',
    r: 192,
    g: 135,
    b: 104,
  },
  {
    pantoneId: 819,
    name: '16-1328 TCX',
    majorColorName: 'Orange',
    colorName: 'Sandstone',
    r: 196,
    g: 138,
    b: 105,
  },
  {
    pantoneId: 820,
    name: '16-1329 TCX',
    majorColorName: 'Pink',
    colorName: 'Coral Haze',
    r: 227,
    g: 142,
    b: 132,
  },
  {
    pantoneId: 821,
    name: '16-1330 TCX',
    majorColorName: 'Orange',
    colorName: 'Muted Clay',
    r: 210,
    g: 147,
    b: 128,
  },
  {
    pantoneId: 822,
    name: '16-1331 TCX',
    majorColorName: 'Orange',
    colorName: 'Toast',
    r: 202,
    g: 153,
    b: 120,
  },
  {
    pantoneId: 823,
    name: '16-1332 TCX',
    majorColorName: 'Orange',
    colorName: 'Pheasant',
    r: 198,
    g: 132,
    b: 99,
  },
  {
    pantoneId: 824,
    name: '16-1333 TCX',
    majorColorName: 'Brown',
    colorName: 'Doe',
    r: 185,
    g: 142,
    b: 104,
  },
  {
    pantoneId: 825,
    name: '16-1334 TCX',
    majorColorName: 'Brown',
    colorName: 'Tan',
    r: 182,
    g: 149,
    b: 116,
  },
  {
    pantoneId: 826,
    name: '16-1336 TCX',
    majorColorName: 'Brown',
    colorName: 'Biscuit',
    r: 180,
    g: 131,
    b: 91,
  },
  {
    pantoneId: 827,
    name: '16-1337 TCX',
    majorColorName: 'Orange',
    colorName: 'Coral Gold',
    r: 210,
    g: 125,
    b: 86,
  },
  {
    pantoneId: 828,
    name: '16-1338 TCX',
    majorColorName: 'Orange',
    colorName: 'Copper Tan',
    r: 222,
    g: 142,
    b: 101,
  },
  {
    pantoneId: 829,
    name: '16-1340 TCX',
    majorColorName: 'Orange',
    colorName: 'Brandied Melon',
    r: 206,
    g: 123,
    b: 91,
  },
  {
    pantoneId: 830,
    name: '16-1341 TCX',
    majorColorName: 'Orange',
    colorName: 'Butterum',
    r: 198,
    g: 143,
    b: 101,
  },
  {
    pantoneId: 831,
    name: '16-1342 TCX',
    majorColorName: 'Orange',
    colorName: 'Buckskin',
    r: 209,
    g: 142,
    b: 84,
  },
  {
    pantoneId: 832,
    name: '16-1343 TCX',
    majorColorName: 'Orange',
    colorName: 'Autumn Sunset',
    r: 243,
    g: 133,
    b: 84,
  },
  {
    pantoneId: 833,
    name: '16-1344 TCX',
    majorColorName: 'Orange',
    colorName: 'Dusty Orange',
    r: 226,
    g: 122,
    b: 83,
  },
  {
    pantoneId: 834,
    name: '16-1346 TCX',
    majorColorName: 'Orange',
    colorName: 'Golden Ochre',
    r: 199,
    g: 121,
    b: 67,
  },
  {
    pantoneId: 835,
    name: '16-1349 TCX',
    majorColorName: 'Orange',
    colorName: 'Coral Rose',
    r: 243,
    g: 119,
    b: 77,
  },
  {
    pantoneId: 836,
    name: '16-1350 TCX',
    majorColorName: 'Orange',
    colorName: 'Amberglow',
    r: 220,
    g: 121,
    b: 62,
  },
  {
    pantoneId: 837,
    name: '16-1356 TCX',
    majorColorName: 'Orange',
    colorName: 'Persimmon Orange',
    r: 244,
    g: 115,
    b: 39,
  },
  {
    pantoneId: 838,
    name: '16-1357 TCX',
    majorColorName: 'Orange',
    colorName: 'Bird of Paradise',
    r: 255,
    g: 140,
    b: 85,
  },
  {
    pantoneId: 839,
    name: '16-1359 TCX',
    majorColorName: 'Orange',
    colorName: 'Orange Peel',
    r: 250,
    g: 122,
    b: 53,
  },
  {
    pantoneId: 840,
    name: '16-1360 TCX',
    majorColorName: 'Orange',
    colorName: 'Nectarine',
    r: 255,
    g: 134,
    b: 86,
  },
  {
    pantoneId: 841,
    name: '16-1361 TCX',
    majorColorName: 'Orange',
    colorName: 'Carrot',
    r: 253,
    g: 111,
    b: 59,
  },
  {
    pantoneId: 842,
    name: '16-1362 TCX',
    majorColorName: 'Orange',
    colorName: 'Vermillion Orange',
    r: 249,
    g: 99,
    b: 59,
  },
  {
    pantoneId: 843,
    name: '16-1364 TCX',
    majorColorName: 'Orange',
    colorName: 'Vibrant Orange',
    r: 255,
    g: 116,
    b: 32,
  },
  {
    pantoneId: 844,
    name: '16-1406 TCX',
    majorColorName: 'White',
    colorName: 'Atmosphere',
    r: 168,
    g: 156,
    b: 148,
  },
  {
    pantoneId: 845,
    name: '16-1407 TCX',
    majorColorName: 'Brown',
    colorName: 'Cobblestone',
    r: 168,
    g: 154,
    b: 142,
  },
  {
    pantoneId: 846,
    name: '16-1412 TCX',
    majorColorName: 'Brown',
    colorName: 'Stucco',
    r: 165,
    g: 141,
    b: 127,
  },
  {
    pantoneId: 847,
    name: '16-1414 TCX',
    majorColorName: 'Brown',
    colorName: 'Chanterelle',
    r: 162,
    g: 135,
    b: 118,
  },
  {
    pantoneId: 848,
    name: '16-1415 TCX',
    majorColorName: 'Brown',
    colorName: 'Almondine',
    r: 167,
    g: 140,
    b: 123,
  },
  {
    pantoneId: 849,
    name: '16-1422 TCX',
    majorColorName: 'Brown',
    colorName: 'Cork',
    r: 186,
    g: 134,
    b: 113,
  },
  {
    pantoneId: 850,
    name: '16-1429 TCX',
    majorColorName: 'Brown',
    colorName: 'Sunburn',
    r: 179,
    g: 114,
    b: 86,
  },
  {
    pantoneId: 851,
    name: '16-1431 TCX',
    majorColorName: 'Pink',
    colorName: 'Canyon Clay',
    r: 206,
    g: 132,
    b: 119,
  },
  {
    pantoneId: 852,
    name: '16-1432 TCX',
    majorColorName: 'Brown',
    colorName: 'Almond',
    r: 167,
    g: 117,
    b: 77,
  },
  {
    pantoneId: 853,
    name: '16-1434 TCX',
    majorColorName: 'Pink',
    colorName: 'Coral Almond',
    r: 226,
    g: 157,
    b: 148,
  },
  {
    pantoneId: 854,
    name: '16-1435 TCX',
    majorColorName: 'Orange',
    colorName: 'Carnelian',
    r: 206,
    g: 120,
    b: 93,
  },
  {
    pantoneId: 855,
    name: '16-1439 TCX',
    majorColorName: 'Orange',
    colorName: 'Caramel',
    r: 195,
    g: 124,
    b: 84,
  },
  {
    pantoneId: 856,
    name: '16-1440 TCX',
    majorColorName: 'Orange',
    colorName: 'Langoustino',
    r: 202,
    g: 108,
    b: 86,
  },
  {
    pantoneId: 857,
    name: '16-1441 TCX',
    majorColorName: 'Orange',
    colorName: 'Arabesque',
    r: 209,
    g: 111,
    b: 82,
  },
  {
    pantoneId: 858,
    name: '16-1442 TCX',
    majorColorName: 'Orange',
    colorName: 'Melon',
    r: 254,
    g: 136,
    b: 99,
  },
  {
    pantoneId: 859,
    name: '16-1443 TCX',
    majorColorName: 'Orange',
    colorName: 'Apricot Buff',
    r: 205,
    g: 126,
    b: 77,
  },
  {
    pantoneId: 860,
    name: '16-1448 TCX',
    majorColorName: 'Orange',
    colorName: 'Burnt Orange',
    r: 200,
    g: 103,
    b: 51,
  },
  {
    pantoneId: 861,
    name: '16-1450 TCX',
    majorColorName: 'Orange',
    colorName: 'Flamingo',
    r: 223,
    g: 114,
    b: 83,
  },
  {
    pantoneId: 862,
    name: '16-1451 TCX',
    majorColorName: 'Red',
    colorName: 'Nasturtium',
    r: 254,
    g: 99,
    b: 71,
  },
  {
    pantoneId: 863,
    name: '16-1452 TCX',
    majorColorName: 'Orange',
    colorName: 'Firecracker',
    r: 243,
    g: 105,
    b: 68,
  },
  {
    pantoneId: 864,
    name: '16-1454 TCX',
    majorColorName: 'Orange',
    colorName: 'Jaffa Orange',
    r: 216,
    g: 109,
    b: 57,
  },
  {
    pantoneId: 865,
    name: '16-1459 TCX',
    majorColorName: 'Orange',
    colorName: 'Mandarin Orange',
    r: 236,
    g: 106,
    b: 55,
  },
  {
    pantoneId: 866,
    name: '16-1462 TCX',
    majorColorName: 'Orange',
    colorName: 'Golden Poppy',
    r: 245,
    g: 103,
    b: 51,
  },
  {
    pantoneId: 867,
    name: '16-1506 TCX',
    majorColorName: 'White',
    colorName: 'Bark',
    r: 169,
    g: 149,
    b: 146,
  },
  {
    pantoneId: 868,
    name: '16-1508 TCX',
    majorColorName: 'Pink',
    colorName: 'Adobe Rose',
    r: 186,
    g: 159,
    b: 153,
  },
  {
    pantoneId: 869,
    name: '16-1509 TCX',
    majorColorName: 'White',
    colorName: 'Shadow Gray',
    r: 187,
    g: 165,
    b: 160,
  },
  {
    pantoneId: 870,
    name: '16-1510 TCX',
    majorColorName: 'Pink',
    colorName: 'Fawn',
    r: 174,
    g: 148,
    b: 144,
  },
  {
    pantoneId: 871,
    name: '16-1511 TCX',
    majorColorName: 'Pink',
    colorName: 'Rose Tan',
    r: 209,
    g: 156,
    b: 151,
  },
  {
    pantoneId: 872,
    name: '16-1516 TCX',
    majorColorName: 'Pink',
    colorName: 'Cameo Brown',
    r: 192,
    g: 138,
    b: 128,
  },
  {
    pantoneId: 873,
    name: '16-1518 TCX',
    majorColorName: 'Pink',
    colorName: 'Rosette',
    r: 206,
    g: 142,
    b: 139,
  },
  {
    pantoneId: 874,
    name: '16-1520 TCX',
    majorColorName: 'Pink',
    colorName: 'Lobster Bisque',
    r: 221,
    g: 146,
    b: 137,
  },
  {
    pantoneId: 875,
    name: '16-1522 TCX',
    majorColorName: 'Pink',
    colorName: 'Rose Dawn',
    r: 194,
    g: 135,
    b: 123,
  },
  {
    pantoneId: 876,
    name: '16-1526 TCX',
    majorColorName: 'Pink',
    colorName: 'Terra Cotta',
    r: 211,
    g: 131,
    b: 119,
  },
  {
    pantoneId: 877,
    name: '16-1529 TCX',
    majorColorName: 'Pink',
    colorName: 'Burnt Coral',
    r: 233,
    g: 137,
    b: 126,
  },
  {
    pantoneId: 878,
    name: '16-1532 TCX',
    majorColorName: 'Pink',
    colorName: 'Crabapple',
    r: 215,
    g: 126,
    b: 112,
  },
  {
    pantoneId: 879,
    name: '16-1539 TCX',
    majorColorName: 'Pink',
    colorName: 'Coral',
    r: 237,
    g: 116,
    b: 100,
  },
  {
    pantoneId: 880,
    name: '16-1541 TCX',
    majorColorName: 'Pink',
    colorName: 'Camellia',
    r: 246,
    g: 116,
    b: 95,
  },
  {
    pantoneId: 881,
    name: '16-1542 TCX',
    majorColorName: 'Pink',
    colorName: 'Fresh Salmon',
    r: 255,
    g: 127,
    b: 106,
  },
  {
    pantoneId: 882,
    name: '16-1543 TCX',
    majorColorName: 'Pink',
    colorName: 'Fusion Coral',
    r: 255,
    g: 133,
    b: 118,
  },
  {
    pantoneId: 883,
    name: '16-1544 TCX',
    majorColorName: 'Pink',
    colorName: 'Persimmon',
    r: 246,
    g: 120,
    b: 102,
  },
  {
    pantoneId: 884,
    name: '16-1546 TCX',
    majorColorName: 'Pink',
    colorName: 'Living Coral',
    r: 255,
    g: 111,
    b: 97,
  },
  {
    pantoneId: 885,
    name: '16-1610 TCX',
    majorColorName: 'Pink',
    colorName: 'Brandied Apricot',
    r: 202,
    g: 132,
    b: 138,
  },
  {
    pantoneId: 886,
    name: '16-1617 TCX',
    majorColorName: 'Pink',
    colorName: 'Mauveglow',
    r: 209,
    g: 132,
    b: 137,
  },
  {
    pantoneId: 887,
    name: '16-1620 TCX',
    majorColorName: 'Pink',
    colorName: 'Tea Rose',
    r: 220,
    g: 113,
    b: 120,
  },
  {
    pantoneId: 888,
    name: '16-1624 TCX',
    majorColorName: 'Pink',
    colorName: 'Lantana',
    r: 218,
    g: 126,
    b: 122,
  },
  {
    pantoneId: 889,
    name: '16-1626 TCX',
    majorColorName: 'Pink',
    colorName: 'Peach Blossom',
    r: 222,
    g: 130,
    b: 134,
  },
  {
    pantoneId: 890,
    name: '16-1632 TCX',
    majorColorName: 'Pink',
    colorName: 'Shell Pink',
    r: 248,
    g: 129,
    b: 128,
  },
  {
    pantoneId: 891,
    name: '16-1640 TCX',
    majorColorName: 'Pink',
    colorName: 'Sugar Coral',
    r: 245,
    g: 108,
    b: 115,
  },
  {
    pantoneId: 892,
    name: '16-1641 TCX',
    majorColorName: 'Pink',
    colorName: 'Georgia Peach',
    r: 249,
    g: 114,
    b: 114,
  },
  {
    pantoneId: 893,
    name: '16-1703 TCX',
    majorColorName: 'White',
    colorName: 'Sphinx',
    r: 171,
    g: 152,
    b: 149,
  },
  {
    pantoneId: 894,
    name: '16-1707 TCX',
    majorColorName: 'Pink',
    colorName: 'Deauville Mauve',
    r: 175,
    g: 146,
    b: 148,
  },
  {
    pantoneId: 895,
    name: '16-1708 TCX',
    majorColorName: 'Pink',
    colorName: 'Lilas',
    r: 184,
    g: 137,
    b: 149,
  },
  {
    pantoneId: 896,
    name: '16-1712 TCX',
    majorColorName: 'Pink',
    colorName: 'Polignac',
    r: 194,
    g: 135,
    b: 153,
  },
  {
    pantoneId: 897,
    name: '16-1715 TCX',
    majorColorName: 'Pink',
    colorName: 'Wild Rose',
    r: 206,
    g: 132,
    b: 152,
  },
  {
    pantoneId: 898,
    name: '16-1720 TCX',
    majorColorName: 'Pink',
    colorName: 'Strawberry Ice',
    r: 231,
    g: 139,
    b: 144,
  },
  {
    pantoneId: 899,
    name: '16-1723 TCX',
    majorColorName: 'Pink',
    colorName: 'Confetti',
    r: 230,
    g: 121,
    b: 142,
  },
  {
    pantoneId: 900,
    name: '16-1731 TCX',
    majorColorName: 'Pink',
    colorName: 'Strawberry Pink',
    r: 245,
    g: 127,
    b: 142,
  },
  {
    pantoneId: 901,
    name: '16-1735 TCX',
    majorColorName: 'Pink',
    colorName: 'Pink Lemonade',
    r: 238,
    g: 109,
    b: 138,
  },
  {
    pantoneId: 902,
    name: '16-1806 TCX',
    majorColorName: 'Pink',
    colorName: 'Woodrose',
    r: 174,
    g: 140,
    b: 142,
  },
  {
    pantoneId: 903,
    name: '16-2107 TCX',
    majorColorName: 'Pink',
    colorName: 'Orchid Haze',
    r: 176,
    g: 135,
    b: 155,
  },
  {
    pantoneId: 904,
    name: '16-2111 TCX',
    majorColorName: 'Pink',
    colorName: 'Mauve Orchid',
    r: 181,
    g: 130,
    b: 153,
  },
  {
    pantoneId: 905,
    name: '16-2120 TCX',
    majorColorName: 'Pink',
    colorName: 'Wild Orchid',
    r: 217,
    g: 121,
    b: 162,
  },
  {
    pantoneId: 906,
    name: '16-2124 TCX',
    majorColorName: 'Pink',
    colorName: 'Pink Carnation',
    r: 237,
    g: 122,
    b: 158,
  },
  {
    pantoneId: 907,
    name: '16-2126 TCX',
    majorColorName: 'Pink',
    colorName: 'Azalea Pink',
    r: 233,
    g: 106,
    b: 151,
  },
  {
    pantoneId: 908,
    name: '16-2215 TCX',
    majorColorName: 'Pink',
    colorName: 'Cashmere Rose',
    r: 206,
    g: 135,
    b: 159,
  },
  {
    pantoneId: 909,
    name: '16-2614 TCX',
    majorColorName: 'Pink',
    colorName: 'Moonlite Mauve',
    r: 210,
    g: 143,
    b: 176,
  },
  {
    pantoneId: 910,
    name: '16-3110 TCX',
    majorColorName: 'Pink',
    colorName: 'Smoky Grape',
    r: 184,
    g: 138,
    b: 172,
  },
  {
    pantoneId: 911,
    name: '16-3115 TCX',
    majorColorName: 'Pink',
    colorName: 'Crocus',
    r: 198,
    g: 127,
    b: 174,
  },
  {
    pantoneId: 912,
    name: '16-3116 TCX',
    majorColorName: 'Pink',
    colorName: 'Opera Mauve',
    r: 202,
    g: 128,
    b: 177,
  },
  {
    pantoneId: 913,
    name: '16-3118 TCX',
    majorColorName: 'Pink',
    colorName: 'Cyclamen',
    r: 214,
    g: 135,
    b: 186,
  },
  {
    pantoneId: 914,
    name: '16-3205 TCX',
    majorColorName: 'Pink',
    colorName: 'Mauve Shadows',
    r: 181,
    g: 152,
    b: 163,
  },
  {
    pantoneId: 915,
    name: '16-3304 TCX',
    majorColorName: 'Gray',
    colorName: 'Sea Fog',
    r: 165,
    g: 146,
    b: 157,
  },
  {
    pantoneId: 916,
    name: '16-3307 TCX',
    majorColorName: 'Pink',
    colorName: 'Lavender Mist',
    r: 174,
    g: 144,
    b: 167,
  },
  {
    pantoneId: 917,
    name: '16-3310 TCX',
    majorColorName: 'Pink',
    colorName: 'Lavender Herb',
    r: 177,
    g: 142,
    b: 170,
  },
  {
    pantoneId: 918,
    name: '16-3320 TCX',
    majorColorName: 'Pink',
    colorName: 'Violet',
    r: 193,
    g: 127,
    b: 181,
  },
  {
    pantoneId: 919,
    name: '16-3416 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Violet Tulle',
    r: 193,
    g: 147,
    b: 192,
  },
  {
    pantoneId: 920,
    name: '16-3520 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'African Violet',
    r: 176,
    g: 133,
    b: 183,
  },
  {
    pantoneId: 921,
    name: '16-3521 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Lupine',
    r: 190,
    g: 156,
    b: 193,
  },
  {
    pantoneId: 922,
    name: '16-3525 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Regal Orchid',
    r: 169,
    g: 139,
    b: 175,
  },
  {
    pantoneId: 923,
    name: '16-3617 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Sheer Lilac',
    r: 183,
    g: 147,
    b: 192,
  },
  {
    pantoneId: 924,
    name: '16-3801 TCX',
    majorColorName: 'Gray',
    colorName: 'Opal Gray',
    r: 164,
    g: 158,
    b: 158,
  },
  {
    pantoneId: 925,
    name: '16-3802 TCX',
    majorColorName: 'Gray',
    colorName: 'Ash',
    r: 160,
    g: 153,
    b: 152,
  },
  {
    pantoneId: 926,
    name: '16-3803 TCX',
    majorColorName: 'Gray',
    colorName: 'Gull Gray',
    r: 164,
    g: 156,
    b: 160,
  },
  {
    pantoneId: 927,
    name: '16-3810 TCX',
    majorColorName: 'White',
    colorName: 'Wisteria',
    r: 161,
    g: 152,
    b: 175,
  },
  {
    pantoneId: 928,
    name: '16-3812 TCX',
    majorColorName: 'Blue',
    colorName: 'Heirloom Lilac',
    r: 157,
    g: 150,
    b: 178,
  },
  {
    pantoneId: 929,
    name: '16-3815 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Viola',
    r: 166,
    g: 146,
    b: 186,
  },
  {
    pantoneId: 930,
    name: '16-3823 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Violet Tulip',
    r: 158,
    g: 145,
    b: 195,
  },
  {
    pantoneId: 931,
    name: '16-3907 TCX',
    majorColorName: 'White',
    colorName: 'Dapple Gray',
    r: 156,
    g: 155,
    b: 167,
  },
  {
    pantoneId: 932,
    name: '16-3911 TCX',
    majorColorName: 'White',
    colorName: 'Lavender Aura',
    r: 159,
    g: 153,
    b: 170,
  },
  {
    pantoneId: 933,
    name: '16-3919 TCX',
    majorColorName: 'Blue',
    colorName: 'Eventide',
    r: 149,
    g: 158,
    b: 183,
  },
  {
    pantoneId: 934,
    name: '16-3920 TCX',
    majorColorName: 'Blue',
    colorName: 'Lavender Lustre',
    r: 140,
    g: 156,
    b: 193,
  },
  {
    pantoneId: 935,
    name: '16-3925 TCX',
    majorColorName: 'Blue',
    colorName: 'Easter Egg',
    r: 145,
    g: 155,
    b: 201,
  },
  {
    pantoneId: 936,
    name: '16-3929 TCX',
    majorColorName: 'Blue',
    colorName: 'Grapemist',
    r: 131,
    g: 152,
    b: 202,
  },
  {
    pantoneId: 937,
    name: '16-3930 TCX',
    majorColorName: 'Blue',
    colorName: 'Thistle Down',
    r: 148,
    g: 153,
    b: 187,
  },
  {
    pantoneId: 938,
    name: '16-3931 TCX',
    majorColorName: 'Blue',
    colorName: 'Sweet Lavender',
    r: 154,
    g: 155,
    b: 193,
  },
  {
    pantoneId: 939,
    name: '16-4010 TCX',
    majorColorName: 'Blue',
    colorName: 'Dusty Blue',
    r: 140,
    g: 157,
    b: 173,
  },
  {
    pantoneId: 940,
    name: '16-4013 TCX',
    majorColorName: 'Blue',
    colorName: 'Ashley Blue',
    r: 134,
    g: 153,
    b: 171,
  },
  {
    pantoneId: 941,
    name: '16-4019 TCX',
    majorColorName: 'Blue',
    colorName: 'Forever Blue',
    r: 137,
    g: 155,
    b: 184,
  },
  {
    pantoneId: 942,
    name: '16-4020 TCX',
    majorColorName: 'Blue',
    colorName: 'Della Robbia Blue',
    r: 122,
    g: 157,
    b: 203,
  },
  {
    pantoneId: 943,
    name: '16-4021 TCX',
    majorColorName: 'Blue',
    colorName: 'Allure',
    r: 114,
    g: 145,
    b: 180,
  },
  {
    pantoneId: 944,
    name: '16-4031 TCX',
    majorColorName: 'Blue',
    colorName: 'Cornflower Blue',
    r: 115,
    g: 145,
    b: 200,
  },
  {
    pantoneId: 945,
    name: '16-4032 TCX',
    majorColorName: 'Blue',
    colorName: 'Provence',
    r: 101,
    g: 141,
    b: 198,
  },
  {
    pantoneId: 946,
    name: '16-4109 TCX',
    majorColorName: 'Blue',
    colorName: 'Arona',
    r: 135,
    g: 155,
    b: 163,
  },
  {
    pantoneId: 947,
    name: '16-4114 TCX',
    majorColorName: 'Blue',
    colorName: 'Stone Blue',
    r: 130,
    g: 156,
    b: 165,
  },
  {
    pantoneId: 948,
    name: '16-4120 TCX',
    majorColorName: 'Blue',
    colorName: 'Dusk Blue',
    r: 123,
    g: 160,
    b: 192,
  },
  {
    pantoneId: 949,
    name: '16-4127 TCX',
    majorColorName: 'Blue',
    colorName: 'Heritage Blue',
    r: 93,
    g: 150,
    b: 188,
  },
  {
    pantoneId: 950,
    name: '16-4132 TCX',
    majorColorName: 'Blue',
    colorName: 'Little Boy Blue',
    r: 110,
    g: 162,
    b: 213,
  },
  {
    pantoneId: 951,
    name: '16-4134 TCX',
    majorColorName: 'Blue',
    colorName: 'Bonnie Blue',
    r: 83,
    g: 156,
    b: 204,
  },
  {
    pantoneId: 952,
    name: '16-4402 TCX',
    majorColorName: 'Gray',
    colorName: 'Drizzle',
    r: 160,
    g: 159,
    b: 156,
  },
  {
    pantoneId: 953,
    name: '16-4404 TCX',
    majorColorName: 'Gray',
    colorName: 'Abyss',
    r: 143,
    g: 158,
    b: 157,
  },
  {
    pantoneId: 954,
    name: '16-4408 TCX',
    majorColorName: 'Gray',
    colorName: 'Slate',
    r: 140,
    g: 159,
    b: 161,
  },
  {
    pantoneId: 955,
    name: '16-4411 TCX',
    majorColorName: 'Blue',
    colorName: 'Tourmaline',
    r: 134,
    g: 161,
    b: 169,
  },
  {
    pantoneId: 956,
    name: '16-4414 TCX',
    majorColorName: 'Blue',
    colorName: 'Cameo Blue',
    r: 118,
    g: 157,
    b: 166,
  },
  {
    pantoneId: 957,
    name: '16-4421 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Mist',
    r: 91,
    g: 172,
    b: 195,
  },
  {
    pantoneId: 958,
    name: '16-4427 TCX',
    majorColorName: 'Blue',
    colorName: 'Horizon Blue',
    r: 40,
    g: 157,
    b: 190,
  },
  {
    pantoneId: 959,
    name: '16-4519 TCX',
    majorColorName: 'Blue',
    colorName: 'Delphinium Blue',
    r: 97,
    g: 152,
    b: 174,
  },
  {
    pantoneId: 960,
    name: '16-4525 TCX',
    majorColorName: 'Blue',
    colorName: 'Maui Blue',
    r: 82,
    g: 162,
    b: 180,
  },
  {
    pantoneId: 961,
    name: '16-4529 TCX',
    majorColorName: 'Blue',
    colorName: 'Cyan Blue',
    r: 20,
    g: 163,
    b: 199,
  },
  {
    pantoneId: 962,
    name: '16-4530 TCX',
    majorColorName: 'Blue',
    colorName: 'Aquarius',
    r: 60,
    g: 173,
    b: 212,
  },
  {
    pantoneId: 963,
    name: '16-4535 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Atoll',
    r: 0,
    g: 177,
    b: 210,
  },
  {
    pantoneId: 964,
    name: '16-4610 TCX',
    majorColorName: 'Blue',
    colorName: 'Stillwater',
    r: 112,
    g: 164,
    b: 176,
  },
  {
    pantoneId: 965,
    name: '16-4612 TCX',
    majorColorName: 'Blue',
    colorName: 'Reef Waters',
    r: 111,
    g: 159,
    b: 169,
  },
  {
    pantoneId: 966,
    name: '16-4702 TCX',
    majorColorName: 'Gray',
    colorName: 'Limestone',
    r: 152,
    g: 154,
    b: 152,
  },
  {
    pantoneId: 967,
    name: '16-4706 TCX',
    majorColorName: 'Gray',
    colorName: 'Silver Blue',
    r: 138,
    g: 154,
    b: 154,
  },
  {
    pantoneId: 968,
    name: '16-4712 TCX',
    majorColorName: 'Blue',
    colorName: 'Mineral Blue',
    r: 109,
    g: 145,
    b: 146,
  },
  {
    pantoneId: 969,
    name: '16-4719 TCX',
    majorColorName: 'Blue',
    colorName: 'Porcelain',
    r: 93,
    g: 156,
    b: 164,
  },
  {
    pantoneId: 970,
    name: '16-4725 TCX',
    majorColorName: 'Blue',
    colorName: 'Scuba Blue',
    r: 0,
    g: 171,
    b: 192,
  },
  {
    pantoneId: 971,
    name: '16-4728 TCX',
    majorColorName: 'Blue',
    colorName: 'Peacock Blue',
    r: 0,
    g: 160,
    b: 176,
  },
  {
    pantoneId: 972,
    name: '16-4834 TCX',
    majorColorName: 'Blue',
    colorName: 'Bluebird',
    r: 0,
    g: 157,
    b: 174,
  },
  {
    pantoneId: 973,
    name: '16-5106 TCX',
    majorColorName: 'White',
    colorName: 'Blue Surf',
    r: 144,
    g: 168,
    b: 164,
  },
  {
    pantoneId: 974,
    name: '16-5109 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Wasabi',
    r: 115,
    g: 168,
    b: 158,
  },
  {
    pantoneId: 975,
    name: '16-5112 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Canton',
    r: 109,
    g: 162,
    b: 158,
  },
  {
    pantoneId: 976,
    name: '16-5114 TCX',
    majorColorName: 'Blue',
    colorName: 'Dusty Turquoise',
    r: 100,
    g: 155,
    b: 158,
  },
  {
    pantoneId: 977,
    name: '16-5119 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Sea Blue',
    r: 84,
    g: 159,
    b: 152,
  },
  {
    pantoneId: 978,
    name: '16-5121 TCX',
    majorColorName: 'Blue',
    colorName: 'Meadowbrook',
    r: 96,
    g: 160,
    b: 163,
  },
  {
    pantoneId: 979,
    name: '16-5123 TCX',
    majorColorName: 'Blue',
    colorName: 'Baltic',
    r: 39,
    g: 157,
    b: 159,
  },
  {
    pantoneId: 980,
    name: '16-5127 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Ceramic',
    r: 0,
    g: 170,
    b: 169,
  },
  {
    pantoneId: 981,
    name: '16-5304 TCX',
    majorColorName: 'White',
    colorName: 'Jadeite',
    r: 149,
    g: 166,
    b: 159,
  },
  {
    pantoneId: 982,
    name: '16-5412 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Agate Green',
    r: 89,
    g: 159,
    b: 153,
  },
  {
    pantoneId: 983,
    name: '16-5418 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Lagoon',
    r: 77,
    g: 158,
    b: 154,
  },
  {
    pantoneId: 984,
    name: '16-5421 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Sea Green',
    r: 20,
    g: 156,
    b: 136,
  },
  {
    pantoneId: 985,
    name: '16-5422 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Bright Aqua',
    r: 48,
    g: 162,
    b: 153,
  },
  {
    pantoneId: 986,
    name: '16-5425 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Pool Green',
    r: 0,
    g: 175,
    b: 157,
  },
  {
    pantoneId: 987,
    name: '16-5427 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Billiard',
    r: 0,
    g: 170,
    b: 146,
  },
  {
    pantoneId: 988,
    name: '16-5431 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Peacock Green',
    r: 0,
    g: 167,
    b: 139,
  },
  {
    pantoneId: 989,
    name: '16-5515 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Beryl Green',
    r: 97,
    g: 145,
    b: 135,
  },
  {
    pantoneId: 990,
    name: '16-5533 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Arcadia',
    r: 0,
    g: 162,
    b: 138,
  },
  {
    pantoneId: 991,
    name: '16-5721 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Marine Green',
    r: 64,
    g: 164,
    b: 142,
  },
  {
    pantoneId: 992,
    name: '16-5803 TCX',
    majorColorName: 'Gray',
    colorName: 'Flint Gray',
    r: 160,
    g: 156,
    b: 152,
  },
  {
    pantoneId: 993,
    name: '16-5804 TCX',
    majorColorName: 'Gray',
    colorName: 'Slate Gray',
    r: 138,
    g: 150,
    b: 145,
  },
  {
    pantoneId: 994,
    name: '16-5806 TCX',
    majorColorName: 'Gray',
    colorName: 'Green Milieu',
    r: 138,
    g: 153,
    b: 146,
  },
  {
    pantoneId: 995,
    name: '16-5807 TCX',
    majorColorName: 'Gray',
    colorName: 'Lily Pad',
    r: 129,
    g: 143,
    b: 132,
  },
  {
    pantoneId: 996,
    name: '16-5808 TCX',
    majorColorName: 'Gray',
    colorName: 'Iceberg Green',
    r: 140,
    g: 156,
    b: 146,
  },
  {
    pantoneId: 997,
    name: '16-5810 TCX',
    majorColorName: 'Gray',
    colorName: 'Green Bay',
    r: 126,
    g: 146,
    b: 133,
  },
  {
    pantoneId: 998,
    name: '16-5815 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Feldspar',
    r: 114,
    g: 155,
    b: 139,
  },
  {
    pantoneId: 999,
    name: '16-5820 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Green Spruce',
    r: 88,
    g: 159,
    b: 126,
  },
  {
    pantoneId: 1000,
    name: '16-5825 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Gumdrop Green',
    r: 46,
    g: 167,
    b: 133,
  },
  {
    pantoneId: 1001,
    name: '16-5904 TCX',
    majorColorName: 'Gray',
    colorName: 'Wrought Iron',
    r: 153,
    g: 158,
    b: 152,
  },
  {
    pantoneId: 1002,
    name: '16-5907 TCX',
    majorColorName: 'Green',
    colorName: 'Granite Green',
    r: 134,
    g: 162,
    b: 147,
  },
  {
    pantoneId: 1003,
    name: '16-5917 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Malachite Green',
    r: 112,
    g: 154,
    b: 137,
  },
  {
    pantoneId: 1004,
    name: '16-5919 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Creme de Menthe',
    r: 112,
    g: 163,
    b: 141,
  },
  {
    pantoneId: 1005,
    name: '16-5924 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Winter Green',
    r: 79,
    g: 158,
    b: 129,
  },
  {
    pantoneId: 1006,
    name: '16-5930 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Ming Green',
    r: 58,
    g: 162,
    b: 120,
  },
  {
    pantoneId: 1007,
    name: '16-5932 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Holly Green',
    r: 15,
    g: 157,
    b: 118,
  },
  {
    pantoneId: 1008,
    name: '16-5938 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Mint',
    r: 0,
    g: 161,
    b: 112,
  },
  {
    pantoneId: 1009,
    name: '16-5942 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Blarney',
    r: 0,
    g: 167,
    b: 118,
  },
  {
    pantoneId: 1010,
    name: '16-6008 TCX',
    majorColorName: 'Gray',
    colorName: 'Seagrass',
    r: 149,
    g: 152,
    b: 137,
  },
  {
    pantoneId: 1011,
    name: '16-6030 TCX',
    majorColorName: 'Green',
    colorName: 'Katydid',
    r: 102,
    g: 188,
    b: 145,
  },
  {
    pantoneId: 1012,
    name: '16-6116 TCX',
    majorColorName: 'Green',
    colorName: 'Shale Green',
    r: 115,
    g: 144,
    b: 114,
  },
  {
    pantoneId: 1013,
    name: '16-6127 TCX',
    majorColorName: 'Green',
    colorName: 'Greenbriar',
    r: 75,
    g: 155,
    b: 105,
  },
  {
    pantoneId: 1014,
    name: '16-6138 TCX',
    majorColorName: 'Green',
    colorName: 'Kelly Green',
    r: 51,
    g: 156,
    b: 94,
  },
  {
    pantoneId: 1015,
    name: '16-6216 TCX',
    majorColorName: 'Green',
    colorName: 'Basil',
    r: 135,
    g: 159,
    b: 132,
  },
  {
    pantoneId: 1016,
    name: '16-6240 TCX',
    majorColorName: 'Green',
    colorName: 'Island Green',
    r: 43,
    g: 174,
    b: 102,
  },
  {
    pantoneId: 1017,
    name: '16-6318 TCX',
    majorColorName: 'Green',
    colorName: 'Mineral Green',
    r: 122,
    g: 155,
    b: 120,
  },
  {
    pantoneId: 1018,
    name: '16-6324 TCX',
    majorColorName: 'Green',
    colorName: 'Jadesheen',
    r: 119,
    g: 162,
    b: 118,
  },
  {
    pantoneId: 1019,
    name: '16-6329 TCX',
    majorColorName: 'Green',
    colorName: 'Peppermint',
    r: 105,
    g: 158,
    b: 109,
  },
  {
    pantoneId: 1020,
    name: '16-6339 TCX',
    majorColorName: 'Green',
    colorName: 'Vibrant Green',
    r: 85,
    g: 168,
    b: 96,
  },
  {
    pantoneId: 1021,
    name: '16-6340 TCX',
    majorColorName: 'Green',
    colorName: 'Classic Green',
    r: 57,
    g: 168,
    b: 69,
  },
  {
    pantoneId: 1022,
    name: '16-6444 TCX',
    majorColorName: 'Green',
    colorName: 'Poison Green',
    r: 77,
    g: 181,
    b: 96,
  },
  {
    pantoneId: 1023,
    name: '17-0000 TCX',
    majorColorName: 'Gray',
    colorName: 'Frost Gray',
    r: 132,
    g: 130,
    b: 131,
  },
  {
    pantoneId: 1024,
    name: '17-0115 TCX',
    majorColorName: 'Green',
    colorName: 'Oil Green',
    r: 128,
    g: 133,
    b: 109,
  },
  {
    pantoneId: 1025,
    name: '17-0119 TCX',
    majorColorName: 'Green',
    colorName: 'Turf Green',
    r: 111,
    g: 140,
    b: 105,
  },
  {
    pantoneId: 1026,
    name: '17-0123 TCX',
    majorColorName: 'Green',
    colorName: 'Stone Green',
    r: 101,
    g: 142,
    b: 103,
  },
  {
    pantoneId: 1027,
    name: '17-0133 TCX',
    majorColorName: 'Green',
    colorName: 'Fluorite Green',
    r: 105,
    g: 145,
    b: 88,
  },
  {
    pantoneId: 1028,
    name: '17-0145 TCX',
    majorColorName: 'Green',
    colorName: 'Online Lime',
    r: 68,
    g: 136,
    b: 60,
  },
  {
    pantoneId: 1029,
    name: '17-0205 TCX',
    majorColorName: 'Gray',
    colorName: 'Elephant Skin',
    r: 143,
    g: 137,
    b: 130,
  },
  {
    pantoneId: 1030,
    name: '17-0207 TCX',
    majorColorName: 'Gray',
    colorName: 'Rock Ridge',
    r: 145,
    g: 140,
    b: 134,
  },
  {
    pantoneId: 1031,
    name: '17-0210 TCX',
    majorColorName: 'Green',
    colorName: 'Loden Frost',
    r: 120,
    g: 143,
    b: 116,
  },
  {
    pantoneId: 1032,
    name: '17-0215 TCX',
    majorColorName: 'Green',
    colorName: 'Aspen Green',
    r: 126,
    g: 155,
    b: 118,
  },
  {
    pantoneId: 1033,
    name: '17-0220 TCX',
    majorColorName: 'Green',
    colorName: 'Watercress',
    r: 116,
    g: 140,
    b: 105,
  },
  {
    pantoneId: 1034,
    name: '17-0230 TCX',
    majorColorName: 'Green',
    colorName: 'Forest Green',
    r: 107,
    g: 141,
    b: 83,
  },
  {
    pantoneId: 1035,
    name: '17-0235 TCX',
    majorColorName: 'Green',
    colorName: 'Piquant Green',
    r: 118,
    g: 147,
    b: 88,
  },
  {
    pantoneId: 1036,
    name: '17-0324 TCX',
    majorColorName: 'Green',
    colorName: 'Epsom',
    r: 132,
    g: 145,
    b: 97,
  },
  {
    pantoneId: 1037,
    name: '17-0330 TCX',
    majorColorName: 'Green',
    colorName: 'Turtle Green',
    r: 129,
    g: 137,
    b: 78,
  },
  {
    pantoneId: 1038,
    name: '17-0336 TCX',
    majorColorName: 'Green',
    colorName: 'Peridot',
    r: 129,
    g: 149,
    b: 72,
  },
  {
    pantoneId: 1039,
    name: '17-0510 TCX',
    majorColorName: 'Brown',
    colorName: 'Silver Sage',
    r: 147,
    g: 139,
    b: 120,
  },
  {
    pantoneId: 1040,
    name: '17-0517 TCX',
    majorColorName: 'Brown',
    colorName: 'Dusky Green',
    r: 116,
    g: 108,
    b: 87,
  },
  {
    pantoneId: 1041,
    name: '17-0525 TCX',
    majorColorName: 'Green',
    colorName: 'Mosstone',
    r: 133,
    g: 137,
    b: 97,
  },
  {
    pantoneId: 1042,
    name: '17-0535 TCX',
    majorColorName: 'Yellow',
    colorName: 'Green Olive',
    r: 141,
    g: 139,
    b: 85,
  },
  {
    pantoneId: 1043,
    name: '17-0610 TCX',
    majorColorName: 'Gray',
    colorName: 'Laurel Oak',
    r: 145,
    g: 140,
    b: 126,
  },
  {
    pantoneId: 1044,
    name: '17-0613 TCX',
    majorColorName: 'Gray',
    colorName: 'Vetiver',
    r: 128,
    g: 125,
    b: 111,
  },
  {
    pantoneId: 1045,
    name: '17-0618 TCX',
    majorColorName: 'Brown',
    colorName: 'Mermaid',
    r: 129,
    g: 122,
    b: 101,
  },
  {
    pantoneId: 1046,
    name: '17-0620 TCX',
    majorColorName: 'Yellow',
    colorName: 'Aloe',
    r: 129,
    g: 122,
    b: 96,
  },
  {
    pantoneId: 1047,
    name: '17-0625 TCX',
    majorColorName: 'Yellow',
    colorName: 'Boa',
    r: 142,
    g: 133,
    b: 95,
  },
  {
    pantoneId: 1048,
    name: '17-0627 TCX',
    majorColorName: 'Yellow',
    colorName: 'Dried Herb',
    r: 132,
    g: 122,
    b: 89,
  },
  {
    pantoneId: 1049,
    name: '17-0636 TCX',
    majorColorName: 'Yellow',
    colorName: 'Green Moss',
    r: 133,
    g: 121,
    b: 70,
  },
  {
    pantoneId: 1050,
    name: '17-0808 TCX',
    majorColorName: 'Brown',
    colorName: 'Taupe Gray',
    r: 142,
    g: 124,
    b: 113,
  },
  {
    pantoneId: 1051,
    name: '17-0836 TCX',
    majorColorName: 'Brown',
    colorName: 'Ecru Olive',
    r: 146,
    g: 123,
    b: 60,
  },
  {
    pantoneId: 1052,
    name: '17-0839 TCX',
    majorColorName: 'Yellow',
    colorName: 'Golden Palm',
    r: 170,
    g: 136,
    b: 5,
  },
  {
    pantoneId: 1053,
    name: '17-0840 TCX',
    majorColorName: 'Brown',
    colorName: 'Amber Green',
    r: 154,
    g: 128,
    b: 58,
  },
  {
    pantoneId: 1054,
    name: '17-0843 TCX',
    majorColorName: 'Brown',
    colorName: 'Bronze Mist',
    r: 156,
    g: 126,
    b: 65,
  },
  {
    pantoneId: 1055,
    name: '17-0909 TCX',
    majorColorName: 'Brown',
    colorName: 'Fossil',
    r: 128,
    g: 111,
    b: 99,
  },
  {
    pantoneId: 1056,
    name: '17-0929 TCX',
    majorColorName: 'Brown',
    colorName: 'Fennel Seed',
    r: 153,
    g: 132,
    b: 86,
  },
  {
    pantoneId: 1057,
    name: '17-0935 TCX',
    majorColorName: 'Brown',
    colorName: 'Dull Gold',
    r: 138,
    g: 111,
    b: 72,
  },
  {
    pantoneId: 1058,
    name: '17-0942 TCX',
    majorColorName: 'Brown',
    colorName: 'Medal Bronze',
    r: 151,
    g: 117,
    b: 71,
  },
  {
    pantoneId: 1059,
    name: '17-1009 TCX',
    majorColorName: 'Brown',
    colorName: 'Dune',
    r: 153,
    g: 137,
    b: 120,
  },
  {
    pantoneId: 1060,
    name: '17-1019 TCX',
    majorColorName: 'Brown',
    colorName: 'Elmwood',
    r: 140,
    g: 124,
    b: 97,
  },
  {
    pantoneId: 1061,
    name: '17-1022 TCX',
    majorColorName: 'Brown',
    colorName: 'Kelp',
    r: 152,
    g: 132,
    b: 103,
  },
  {
    pantoneId: 1062,
    name: '17-1028 TCX',
    majorColorName: 'Brown',
    colorName: 'Antique Bronze',
    r: 144,
    g: 121,
    b: 84,
  },
  {
    pantoneId: 1063,
    name: '17-1036 TCX',
    majorColorName: 'Brown',
    colorName: 'Bistre',
    r: 152,
    g: 117,
    b: 74,
  },
  {
    pantoneId: 1064,
    name: '17-1040 TCX',
    majorColorName: 'Brown',
    colorName: 'Spruce Yellow',
    r: 190,
    g: 138,
    b: 74,
  },
  {
    pantoneId: 1065,
    name: '17-1044 TCX',
    majorColorName: 'Brown',
    colorName: 'Chipmunk',
    r: 151,
    g: 111,
    b: 76,
  },
  {
    pantoneId: 1066,
    name: '17-1045 TCX',
    majorColorName: 'Brown',
    colorName: 'Apple Cinnamon',
    r: 176,
    g: 136,
    b: 90,
  },
  {
    pantoneId: 1067,
    name: '17-1046 TCX',
    majorColorName: 'Brown',
    colorName: 'Golden Oak',
    r: 190,
    g: 117,
    b: 45,
  },
  {
    pantoneId: 1068,
    name: '17-1047 TCX',
    majorColorName: 'Brown',
    colorName: 'Honey Mustard',
    r: 182,
    g: 143,
    b: 82,
  },
  {
    pantoneId: 1069,
    name: '17-1048 TCX',
    majorColorName: 'Brown',
    colorName: 'Inca Gold',
    r: 187,
    g: 122,
    b: 44,
  },
  {
    pantoneId: 1070,
    name: '17-1107 TCX',
    majorColorName: 'Brown',
    colorName: 'Seneca Rock',
    r: 154,
    g: 146,
    b: 127,
  },
  {
    pantoneId: 1071,
    name: '17-1109 TCX',
    majorColorName: 'Brown',
    colorName: 'Chinchilla',
    r: 156,
    g: 142,
    b: 123,
  },
  {
    pantoneId: 1072,
    name: '17-1113 TCX',
    majorColorName: 'Brown',
    colorName: 'Coriander',
    r: 147,
    g: 135,
    b: 114,
  },
  {
    pantoneId: 1073,
    name: '17-1118 TCX',
    majorColorName: 'Brown',
    colorName: 'Lead Gray',
    r: 138,
    g: 121,
    b: 99,
  },
  {
    pantoneId: 1074,
    name: '17-1125 TCX',
    majorColorName: 'Brown',
    colorName: 'Dijon',
    r: 151,
    g: 117,
    b: 76,
  },
  {
    pantoneId: 1075,
    name: '17-1128 TCX',
    majorColorName: 'Brown',
    colorName: 'Bone Brown',
    r: 157,
    g: 116,
    b: 70,
  },
  {
    pantoneId: 1076,
    name: '17-1129 TCX',
    majorColorName: 'Brown',
    colorName: 'Wood Thrush',
    r: 164,
    g: 125,
    b: 67,
  },
  {
    pantoneId: 1077,
    name: '17-1134 TCX',
    majorColorName: 'Brown',
    colorName: 'Brown Sugar',
    r: 161,
    g: 114,
    b: 73,
  },
  {
    pantoneId: 1078,
    name: '17-1137 TCX',
    majorColorName: 'Brown',
    colorName: 'Cashew',
    r: 164,
    g: 113,
    b: 73,
  },
  {
    pantoneId: 1079,
    name: '17-1143 TCX',
    majorColorName: 'Brown',
    colorName: 'Hazel',
    r: 174,
    g: 114,
    b: 80,
  },
  {
    pantoneId: 1080,
    name: '17-1147 TCX',
    majorColorName: 'Brown',
    colorName: 'Amber Brown',
    r: 166,
    g: 102,
    b: 70,
  },
  {
    pantoneId: 1081,
    name: '17-1210 TCX',
    majorColorName: 'Gray',
    colorName: 'Moon Rock',
    r: 149,
    g: 139,
    b: 132,
  },
  {
    pantoneId: 1082,
    name: '17-1212 TCX',
    majorColorName: 'Brown',
    colorName: 'Fungi',
    r: 143,
    g: 129,
    b: 119,
  },
  {
    pantoneId: 1083,
    name: '17-1223 TCX',
    majorColorName: 'Brown',
    colorName: 'Praline',
    r: 173,
    g: 139,
    b: 117,
  },
  {
    pantoneId: 1084,
    name: '17-1224 TCX',
    majorColorName: 'Brown',
    colorName: 'Camel',
    r: 176,
    g: 132,
    b: 106,
  },
  {
    pantoneId: 1085,
    name: '17-1225 TCX',
    majorColorName: 'Brown',
    colorName: 'Tawny Birch',
    r: 174,
    g: 133,
    b: 108,
  },
  {
    pantoneId: 1086,
    name: '17-1226 TCX',
    majorColorName: 'Brown',
    colorName: 'Tawny Brown',
    r: 171,
    g: 133,
    b: 111,
  },
  {
    pantoneId: 1087,
    name: '17-1227 TCX',
    majorColorName: 'Brown',
    colorName: 'Cafe au Lait',
    r: 174,
    g: 135,
    b: 116,
  },
  {
    pantoneId: 1088,
    name: '17-1230 TCX',
    majorColorName: 'Brown',
    colorName: 'Mocha Mousse',
    r: 164,
    g: 120,
    b: 100,
  },
  {
    pantoneId: 1089,
    name: '17-1310 TCX',
    majorColorName: 'Brown',
    colorName: 'Timber Wolf',
    r: 141,
    g: 128,
    b: 112,
  },
  {
    pantoneId: 1090,
    name: '17-1312 TCX',
    majorColorName: 'Brown',
    colorName: 'Silver Mink',
    r: 159,
    g: 141,
    b: 124,
  },
  {
    pantoneId: 1091,
    name: '17-1319 TCX',
    majorColorName: 'Brown',
    colorName: 'Amphora',
    r: 159,
    g: 134,
    b: 114,
  },
  {
    pantoneId: 1092,
    name: '17-1320 TCX',
    majorColorName: 'Brown',
    colorName: 'Tannin',
    r: 166,
    g: 138,
    b: 109,
  },
  {
    pantoneId: 1093,
    name: '17-1321 TCX',
    majorColorName: 'Brown',
    colorName: 'Woodsmoke',
    r: 148,
    g: 119,
    b: 100,
  },
  {
    pantoneId: 1094,
    name: '17-1322 TCX',
    majorColorName: 'Brown',
    colorName: 'Burro',
    r: 158,
    g: 126,
    b: 103,
  },
  {
    pantoneId: 1095,
    name: '17-1327 TCX',
    majorColorName: 'Brown',
    colorName: 'Tobacco Brown',
    r: 154,
    g: 115,
    b: 82,
  },
  {
    pantoneId: 1096,
    name: '17-1328 TCX',
    majorColorName: 'Brown',
    colorName: 'Indian Tan',
    r: 173,
    g: 133,
    b: 103,
  },
  {
    pantoneId: 1097,
    name: '17-1330 TCX',
    majorColorName: 'Brown',
    colorName: 'Lion',
    r: 160,
    g: 113,
    b: 79,
  },
  {
    pantoneId: 1098,
    name: '17-1336 TCX',
    majorColorName: 'Brown',
    colorName: 'Bran',
    r: 166,
    g: 110,
    b: 74,
  },
  {
    pantoneId: 1099,
    name: '17-1340 TCX',
    majorColorName: 'Brown',
    colorName: 'Adobe',
    r: 163,
    g: 98,
    b: 59,
  },
  {
    pantoneId: 1100,
    name: '17-1341 TCX',
    majorColorName: 'Pink',
    colorName: 'Tawny Orange',
    r: 211,
    g: 127,
    b: 111,
  },
  {
    pantoneId: 1101,
    name: '17-1347 TCX',
    majorColorName: 'Brown',
    colorName: 'Autumn Leaf',
    r: 181,
    g: 104,
    b: 76,
  },
  {
    pantoneId: 1102,
    name: '17-1350 TCX',
    majorColorName: 'Orange',
    colorName: 'Oriole',
    r: 255,
    g: 121,
    b: 19,
  },
  {
    pantoneId: 1103,
    name: '17-1353 TCX',
    majorColorName: 'Orange',
    colorName: 'Apricot Orange',
    r: 200,
    g: 107,
    b: 60,
  },
  {
    pantoneId: 1104,
    name: '17-1410 TCX',
    majorColorName: 'Brown',
    colorName: 'Pine Bark',
    r: 130,
    g: 112,
    b: 100,
  },
  {
    pantoneId: 1105,
    name: '17-1417 TCX',
    majorColorName: 'Brown',
    colorName: 'Beaver Fur',
    r: 153,
    g: 120,
    b: 103,
  },
  {
    pantoneId: 1106,
    name: '17-1418 TCX',
    majorColorName: 'Brown',
    colorName: 'Ginger Snap',
    r: 151,
    g: 125,
    b: 112,
  },
  {
    pantoneId: 1107,
    name: '17-1422 TCX',
    majorColorName: 'Brown',
    colorName: 'Raw Umber',
    r: 146,
    g: 112,
    b: 95,
  },
  {
    pantoneId: 1108,
    name: '17-1424 TCX',
    majorColorName: 'Pink',
    colorName: 'Brick Dust',
    r: 176,
    g: 112,
    b: 105,
  },
  {
    pantoneId: 1109,
    name: '17-1430 TCX',
    majorColorName: 'Brown',
    colorName: 'Pecan Brown',
    r: 163,
    g: 110,
    b: 81,
  },
  {
    pantoneId: 1110,
    name: '17-1436 TCX',
    majorColorName: 'Brown',
    colorName: 'Raw Sienna',
    r: 185,
    g: 113,
    b: 79,
  },
  {
    pantoneId: 1111,
    name: '17-1444 TCX',
    majorColorName: 'Pink',
    colorName: 'Ginger',
    r: 201,
    g: 101,
    b: 81,
  },
  {
    pantoneId: 1112,
    name: '17-1446 TCX',
    majorColorName: 'Brown',
    colorName: 'Mango',
    r: 183,
    g: 94,
    b: 65,
  },
  {
    pantoneId: 1113,
    name: '17-1456 TCX',
    majorColorName: 'Red',
    colorName: 'Tigerlily',
    r: 226,
    g: 88,
    b: 62,
  },
  {
    pantoneId: 1114,
    name: '17-1462 TCX',
    majorColorName: 'Orange',
    colorName: 'Flame',
    r: 242,
    g: 85,
    b: 44,
  },
  {
    pantoneId: 1115,
    name: '17-1464 TCX',
    majorColorName: 'Orange',
    colorName: 'Red Orange',
    r: 240,
    g: 86,
    b: 39,
  },
  {
    pantoneId: 1116,
    name: '17-1500 TCX',
    majorColorName: 'Gray',
    colorName: 'Steeple Gray',
    r: 130,
    g: 126,
    b: 124,
  },
  {
    pantoneId: 1117,
    name: '17-1501 TCX',
    majorColorName: 'Gray',
    colorName: 'Wild Dove',
    r: 139,
    g: 140,
    b: 137,
  },
  {
    pantoneId: 1118,
    name: '17-1502 TCX',
    majorColorName: 'Gray',
    colorName: 'Cloudburst',
    r: 131,
    g: 127,
    b: 127,
  },
  {
    pantoneId: 1119,
    name: '17-1505 TCX',
    majorColorName: 'Gray',
    colorName: 'Quail',
    r: 152,
    g: 134,
    b: 140,
  },
  {
    pantoneId: 1120,
    name: '17-1506 TCX',
    majorColorName: 'Gray',
    colorName: 'Cinder',
    r: 138,
    g: 126,
    b: 120,
  },
  {
    pantoneId: 1121,
    name: '17-1510 TCX',
    majorColorName: 'Pink',
    colorName: 'Antler',
    r: 149,
    g: 122,
    b: 118,
  },
  {
    pantoneId: 1122,
    name: '17-1511 TCX',
    majorColorName: 'Pink',
    colorName: 'Wistful Mauve',
    r: 148,
    g: 108,
    b: 116,
  },
  {
    pantoneId: 1123,
    name: '17-1512 TCX',
    majorColorName: 'Pink',
    colorName: 'Nostalgia Rose',
    r: 164,
    g: 119,
    b: 126,
  },
  {
    pantoneId: 1124,
    name: '17-1514 TCX',
    majorColorName: 'Pink',
    colorName: 'Ash Rose',
    r: 181,
    g: 129,
    b: 125,
  },
  {
    pantoneId: 1125,
    name: '17-1516 TCX',
    majorColorName: 'Pink',
    colorName: 'Burlwood',
    r: 155,
    g: 113,
    b: 107,
  },
  {
    pantoneId: 1126,
    name: '17-1518 TCX',
    majorColorName: 'Pink',
    colorName: 'Old Rose',
    r: 180,
    g: 123,
    b: 119,
  },
  {
    pantoneId: 1127,
    name: '17-1520 TCX',
    majorColorName: 'Pink',
    colorName: 'Canyon Rose',
    r: 175,
    g: 108,
    b: 103,
  },
  {
    pantoneId: 1128,
    name: '17-1522 TCX',
    majorColorName: 'Pink',
    colorName: 'Mauvewood',
    r: 167,
    g: 93,
    b: 103,
  },
  {
    pantoneId: 1129,
    name: '17-1524 TCX',
    majorColorName: 'Pink',
    colorName: 'Desert Sand',
    r: 189,
    g: 123,
    b: 116,
  },
  {
    pantoneId: 1130,
    name: '17-1525 TCX',
    majorColorName: 'Pink',
    colorName: 'Cedar Wood',
    r: 161,
    g: 101,
    b: 91,
  },
  {
    pantoneId: 1131,
    name: '17-1532 TCX',
    majorColorName: 'Pink',
    colorName: 'Aragon',
    r: 176,
    g: 100,
    b: 85,
  },
  {
    pantoneId: 1132,
    name: '17-1537 TCX',
    majorColorName: 'Pink',
    colorName: 'Mineral Red',
    r: 179,
    g: 84,
    b: 87,
  },
  {
    pantoneId: 1133,
    name: '17-1540 TCX',
    majorColorName: 'Pink',
    colorName: 'Apricot Brandy',
    r: 194,
    g: 106,
    b: 90,
  },
  {
    pantoneId: 1134,
    name: '17-1544 TCX',
    majorColorName: 'Pink',
    colorName: 'Burnt Sienna',
    r: 198,
    g: 93,
    b: 82,
  },
  {
    pantoneId: 1135,
    name: '17-1545 TCX',
    majorColorName: 'Pink',
    colorName: 'Cranberry',
    r: 187,
    g: 74,
    b: 77,
  },
  {
    pantoneId: 1136,
    name: '17-1547 TCX',
    majorColorName: 'Pink',
    colorName: 'Emberglow',
    r: 234,
    g: 103,
    b: 89,
  },
  {
    pantoneId: 1137,
    name: '17-1553 TCX',
    majorColorName: 'Pink',
    colorName: 'Paprika',
    r: 206,
    g: 77,
    b: 66,
  },
  {
    pantoneId: 1138,
    name: '17-1558 TCX',
    majorColorName: 'Red',
    colorName: 'Grenadine',
    r: 223,
    g: 63,
    b: 50,
  },
  {
    pantoneId: 1139,
    name: '17-1562 TCX',
    majorColorName: 'Red',
    colorName: 'Mandarin Red',
    r: 231,
    g: 74,
    b: 51,
  },
  {
    pantoneId: 1140,
    name: '17-1563 TCX',
    majorColorName: 'Red',
    colorName: 'Cherry Tomato',
    r: 235,
    g: 60,
    b: 39,
  },
  {
    pantoneId: 1141,
    name: '17-1564 TCX',
    majorColorName: 'Red',
    colorName: 'Fiesta',
    r: 221,
    g: 65,
    b: 50,
  },
  {
    pantoneId: 1142,
    name: '17-1605 TCX',
    majorColorName: 'Pink',
    colorName: 'Elderberry',
    r: 157,
    g: 132,
    b: 142,
  },
  {
    pantoneId: 1143,
    name: '17-1608 TCX',
    majorColorName: 'Pink',
    colorName: 'Heather Rose',
    r: 173,
    g: 109,
    b: 127,
  },
  {
    pantoneId: 1144,
    name: '17-1610 TCX',
    majorColorName: 'Pink',
    colorName: 'Dusky Orchid',
    r: 154,
    g: 113,
    b: 130,
  },
  {
    pantoneId: 1145,
    name: '17-1612 TCX',
    majorColorName: 'Pink',
    colorName: 'Mellow Mauve',
    r: 153,
    g: 99,
    b: 120,
  },
  {
    pantoneId: 1146,
    name: '17-1614 TCX',
    majorColorName: 'Pink',
    colorName: 'Deco Rose',
    r: 152,
    g: 95,
    b: 104,
  },
  {
    pantoneId: 1147,
    name: '17-1623 TCX',
    majorColorName: 'Pink',
    colorName: 'Rose Wine',
    r: 164,
    g: 89,
    b: 109,
  },
  {
    pantoneId: 1148,
    name: '17-1633 TCX',
    majorColorName: 'Pink',
    colorName: 'Holly Berry',
    r: 180,
    g: 78,
    b: 93,
  },
  {
    pantoneId: 1149,
    name: '17-1635 TCX',
    majorColorName: 'Pink',
    colorName: 'Rose of Sharon',
    r: 220,
    g: 91,
    b: 98,
  },
  {
    pantoneId: 1150,
    name: '17-1641 TCX',
    majorColorName: 'Pink',
    colorName: 'Chrysanthemum',
    r: 190,
    g: 69,
    b: 79,
  },
  {
    pantoneId: 1151,
    name: '17-1643 TCX',
    majorColorName: 'Pink',
    colorName: 'Porcelain Rose',
    r: 234,
    g: 107,
    b: 106,
  },
  {
    pantoneId: 1152,
    name: '17-1644 TCX',
    majorColorName: 'Pink',
    colorName: 'Spiced Coral',
    r: 215,
    g: 92,
    b: 93,
  },
  {
    pantoneId: 1153,
    name: '17-1647 TCX',
    majorColorName: 'Pink',
    colorName: 'Dubarry',
    r: 242,
    g: 95,
    b: 102,
  },
  {
    pantoneId: 1154,
    name: '17-1654 TCX',
    majorColorName: 'Red',
    colorName: 'Poinsettia',
    r: 203,
    g: 52,
    b: 65,
  },
  {
    pantoneId: 1155,
    name: '17-1656 TCX',
    majorColorName: 'Pink',
    colorName: 'Hot Coral',
    r: 243,
    g: 91,
    b: 83,
  },
  {
    pantoneId: 1156,
    name: '17-1663 TCX',
    majorColorName: 'Red',
    colorName: 'Bittersweet',
    r: 217,
    g: 55,
    b: 68,
  },
  {
    pantoneId: 1157,
    name: '17-1664 TCX',
    majorColorName: 'Red',
    colorName: 'Poppy Red',
    r: 220,
    g: 52,
    b: 59,
  },
  {
    pantoneId: 1158,
    name: '17-1710 TCX',
    majorColorName: 'Pink',
    colorName: 'Bordeaux',
    r: 150,
    g: 99,
    b: 123,
  },
  {
    pantoneId: 1159,
    name: '17-1718 TCX',
    majorColorName: 'Pink',
    colorName: 'Dusty Rose',
    r: 186,
    g: 121,
    b: 125,
  },
  {
    pantoneId: 1160,
    name: '17-1723 TCX',
    majorColorName: 'Pink',
    colorName: 'Malaga',
    r: 159,
    g: 80,
    b: 105,
  },
  {
    pantoneId: 1161,
    name: '17-1736 TCX',
    majorColorName: 'Pink',
    colorName: 'Sun Kissed Coral',
    r: 234,
    g: 102,
    b: 118,
  },
  {
    pantoneId: 1162,
    name: '17-1740 TCX',
    majorColorName: 'Pink',
    colorName: 'Claret Red',
    r: 200,
    g: 76,
    b: 97,
  },
  {
    pantoneId: 1163,
    name: '17-1744 TCX',
    majorColorName: 'Pink',
    colorName: 'Calypso Coral',
    r: 238,
    g: 92,
    b: 108,
  },
  {
    pantoneId: 1164,
    name: '17-1753 TCX',
    majorColorName: 'Pink',
    colorName: 'Geranium',
    r: 218,
    g: 61,
    b: 88,
  },
  {
    pantoneId: 1165,
    name: '17-1755 TCX',
    majorColorName: 'Pink',
    colorName: 'Paradise Pink',
    r: 228,
    g: 68,
    b: 94,
  },
  {
    pantoneId: 1166,
    name: '17-1818 TCX',
    majorColorName: 'Pink',
    colorName: 'Red Violet',
    r: 163,
    g: 87,
    b: 118,
  },
  {
    pantoneId: 1167,
    name: '17-1831 TCX',
    majorColorName: 'Pink',
    colorName: 'Carmine',
    r: 188,
    g: 72,
    b: 105,
  },
  {
    pantoneId: 1168,
    name: '17-1842 TCX',
    majorColorName: 'Pink',
    colorName: 'Azalea',
    r: 212,
    g: 46,
    b: 91,
  },
  {
    pantoneId: 1169,
    name: '17-1927 TCX',
    majorColorName: 'Pink',
    colorName: 'Desert Rose',
    r: 207,
    g: 105,
    b: 119,
  },
  {
    pantoneId: 1170,
    name: '17-1928 TCX',
    majorColorName: 'Pink',
    colorName: 'Bubblegum',
    r: 234,
    g: 115,
    b: 141,
  },
  {
    pantoneId: 1171,
    name: '17-1929 TCX',
    majorColorName: 'Pink',
    colorName: 'Rapture Rose',
    r: 209,
    g: 98,
    b: 119,
  },
  {
    pantoneId: 1172,
    name: '17-1930 TCX',
    majorColorName: 'Pink',
    colorName: 'Camellia Rose',
    r: 235,
    g: 96,
    b: 129,
  },
  {
    pantoneId: 1173,
    name: '17-1937 TCX',
    majorColorName: 'Pink',
    colorName: 'Hot Pink',
    r: 229,
    g: 89,
    b: 130,
  },
  {
    pantoneId: 1174,
    name: '17-2031 TCX',
    majorColorName: 'Pink',
    colorName: 'Fuchsia Rose',
    r: 199,
    g: 67,
    b: 117,
  },
  {
    pantoneId: 1175,
    name: '17-2033 TCX',
    majorColorName: 'Pink',
    colorName: 'Fandango Pink',
    r: 224,
    g: 79,
    b: 128,
  },
  {
    pantoneId: 1176,
    name: '17-2036 TCX',
    majorColorName: 'Pink',
    colorName: 'Magenta',
    r: 210,
    g: 60,
    b: 119,
  },
  {
    pantoneId: 1177,
    name: '17-2120 TCX',
    majorColorName: 'Pink',
    colorName: 'Chateau Rose',
    r: 210,
    g: 115,
    b: 143,
  },
  {
    pantoneId: 1178,
    name: '17-2127 TCX',
    majorColorName: 'Pink',
    colorName: 'Shocking Pink',
    r: 222,
    g: 91,
    b: 140,
  },
  {
    pantoneId: 1179,
    name: '17-2227 TCX',
    majorColorName: 'Pink',
    colorName: 'Lilac Rose',
    r: 189,
    g: 66,
    b: 117,
  },
  {
    pantoneId: 1180,
    name: '17-2230 TCX',
    majorColorName: 'Pink',
    colorName: 'Carmine Rose',
    r: 227,
    g: 91,
    b: 143,
  },
  {
    pantoneId: 1181,
    name: '17-2520 TCX',
    majorColorName: 'Pink',
    colorName: 'Ibis Rose',
    r: 202,
    g: 98,
    b: 143,
  },
  {
    pantoneId: 1182,
    name: '17-2601 TCX',
    majorColorName: 'Gray',
    colorName: 'Zinc',
    r: 146,
    g: 137,
    b: 138,
  },
  {
    pantoneId: 1183,
    name: '17-2617 TCX',
    majorColorName: 'Pink',
    colorName: 'Dahlia Mauve',
    r: 166,
    g: 79,
    b: 130,
  },
  {
    pantoneId: 1184,
    name: '17-2624 TCX',
    majorColorName: 'Pink',
    colorName: 'Rose Violet',
    r: 192,
    g: 66,
    b: 138,
  },
  {
    pantoneId: 1185,
    name: '17-2625 TCX',
    majorColorName: 'Pink',
    colorName: 'Super Pink',
    r: 206,
    g: 107,
    b: 164,
  },
  {
    pantoneId: 1186,
    name: '17-2627 TCX',
    majorColorName: 'Pink',
    colorName: 'Phlox Pink',
    r: 206,
    g: 94,
    b: 154,
  },
  {
    pantoneId: 1187,
    name: '17-3014 TCX',
    majorColorName: 'Pink',
    colorName: 'Mulberry',
    r: 167,
    g: 108,
    b: 151,
  },
  {
    pantoneId: 1188,
    name: '17-3020 TCX',
    majorColorName: 'Pink',
    colorName: 'Spring Crocus',
    r: 186,
    g: 105,
    b: 161,
  },
  {
    pantoneId: 1189,
    name: '17-3023 TCX',
    majorColorName: 'Pink',
    colorName: 'Rosebud',
    r: 182,
    g: 95,
    b: 154,
  },
  {
    pantoneId: 1190,
    name: '17-3313 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Dusty Lavender',
    r: 161,
    g: 117,
    b: 156,
  },
  {
    pantoneId: 1191,
    name: '17-3323 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Iris Orchid',
    r: 167,
    g: 103,
    b: 162,
  },
  {
    pantoneId: 1192,
    name: '17-3410 TCX',
    majorColorName: 'Pink',
    colorName: 'Valerian',
    r: 159,
    g: 122,
    b: 147,
  },
  {
    pantoneId: 1193,
    name: '17-3612 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Orchid Mist',
    r: 145,
    g: 119,
    b: 152,
  },
  {
    pantoneId: 1194,
    name: '17-3615 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Chalk Violet',
    r: 143,
    g: 125,
    b: 165,
  },
  {
    pantoneId: 1195,
    name: '17-3619 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Hyacinth',
    r: 147,
    g: 108,
    b: 167,
  },
  {
    pantoneId: 1196,
    name: '17-3628 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Amethyst Orchid',
    r: 146,
    g: 106,
    b: 166,
  },
  {
    pantoneId: 1197,
    name: '17-3725 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Bougainvillea',
    r: 152,
    g: 132,
    b: 185,
  },
  {
    pantoneId: 1198,
    name: '17-3730 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Paisley Purple',
    r: 139,
    g: 121,
    b: 177,
  },
  {
    pantoneId: 1199,
    name: '17-3802 TCX',
    majorColorName: 'Gray',
    colorName: 'Gull',
    r: 145,
    g: 140,
    b: 143,
  },
  {
    pantoneId: 1200,
    name: '17-3808 TCX',
    majorColorName: 'Gray',
    colorName: 'Nirvana',
    r: 162,
    g: 145,
    b: 155,
  },
  {
    pantoneId: 1201,
    name: '17-3810 TCX',
    majorColorName: 'Gray',
    colorName: 'Purple Ash',
    r: 143,
    g: 131,
    b: 149,
  },
  {
    pantoneId: 1202,
    name: '17-3812 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Dusk',
    r: 137,
    g: 127,
    b: 152,
  },
  {
    pantoneId: 1203,
    name: '17-3817 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Daybreak',
    r: 137,
    g: 129,
    b: 160,
  },
  {
    pantoneId: 1204,
    name: '17-3826 TCX',
    majorColorName: 'Blue',
    colorName: 'Aster Purple',
    r: 125,
    g: 116,
    b: 168,
  },
  {
    pantoneId: 1205,
    name: '17-3834 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Dahlia Purple',
    r: 126,
    g: 110,
    b: 172,
  },
  {
    pantoneId: 1206,
    name: '17-3906 TCX',
    majorColorName: 'Gray',
    colorName: 'Minimal Gray',
    r: 148,
    g: 141,
    b: 153,
  },
  {
    pantoneId: 1207,
    name: '17-3910 TCX',
    majorColorName: 'Gray',
    colorName: 'Lavender Gray',
    r: 152,
    g: 144,
    b: 162,
  },
  {
    pantoneId: 1208,
    name: '17-3915 TCX',
    majorColorName: 'Blue',
    colorName: 'Tempest',
    r: 121,
    g: 131,
    b: 155,
  },
  {
    pantoneId: 1209,
    name: '17-3917 TCX',
    majorColorName: 'Blue',
    colorName: 'Stonewash',
    r: 116,
    g: 128,
    b: 154,
  },
  {
    pantoneId: 1210,
    name: '17-3918 TCX',
    majorColorName: 'Blue',
    colorName: 'Country Blue',
    r: 113,
    g: 127,
    b: 155,
  },
  {
    pantoneId: 1211,
    name: '17-3919 TCX',
    majorColorName: 'Blue',
    colorName: 'Purple Impression',
    r: 133,
    g: 143,
    b: 177,
  },
  {
    pantoneId: 1212,
    name: '17-3920 TCX',
    majorColorName: 'Blue',
    colorName: 'English Manor',
    r: 113,
    g: 129,
    b: 164,
  },
  {
    pantoneId: 1213,
    name: '17-3922 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Ice',
    r: 112,
    g: 120,
    b: 155,
  },
  {
    pantoneId: 1214,
    name: '17-3923 TCX',
    majorColorName: 'Blue',
    colorName: 'Colony Blue',
    r: 101,
    g: 118,
    b: 154,
  },
  {
    pantoneId: 1215,
    name: '17-3924 TCX',
    majorColorName: 'Blue',
    colorName: 'Lavender Violet',
    r: 118,
    g: 123,
    b: 165,
  },
  {
    pantoneId: 1216,
    name: '17-3925 TCX',
    majorColorName: 'Blue',
    colorName: 'Persian Violet',
    r: 140,
    g: 142,
    b: 178,
  },
  {
    pantoneId: 1217,
    name: '17-3930 TCX',
    majorColorName: 'Blue',
    colorName: 'Jacaranda',
    r: 132,
    g: 141,
    b: 197,
  },
  {
    pantoneId: 1218,
    name: '17-3932 TCX',
    majorColorName: 'Blue',
    colorName: 'Deep Periwinkle',
    r: 124,
    g: 131,
    b: 188,
  },
  {
    pantoneId: 1219,
    name: '17-3934 TCX',
    majorColorName: 'Blue',
    colorName: 'Persian Jewel',
    r: 110,
    g: 129,
    b: 190,
  },
  {
    pantoneId: 1220,
    name: '17-3936 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Bonnet',
    r: 99,
    g: 132,
    b: 184,
  },
  {
    pantoneId: 1221,
    name: '17-4015 TCX',
    majorColorName: 'Blue',
    colorName: 'Infinity',
    r: 110,
    g: 126,
    b: 153,
  },
  {
    pantoneId: 1222,
    name: '17-4020 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Shadow',
    r: 102,
    g: 130,
    b: 154,
  },
  {
    pantoneId: 1223,
    name: '17-4021 TCX',
    majorColorName: 'Blue',
    colorName: 'Faded Denim',
    r: 121,
    g: 142,
    b: 164,
  },
  {
    pantoneId: 1224,
    name: '17-4023 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Heaven',
    r: 91,
    g: 126,
    b: 152,
  },
  {
    pantoneId: 1225,
    name: '17-4027 TCX',
    majorColorName: 'Blue',
    colorName: 'Riviera',
    r: 88,
    g: 121,
    b: 162,
  },
  {
    pantoneId: 1226,
    name: '17-4030 TCX',
    majorColorName: 'Blue',
    colorName: 'Silver Lake Blue',
    r: 97,
    g: 139,
    b: 185,
  },
  {
    pantoneId: 1227,
    name: '17-4037 TCX',
    majorColorName: 'Blue',
    colorName: 'Ultramarine',
    r: 91,
    g: 126,
    b: 189,
  },
  {
    pantoneId: 1228,
    name: '17-4041 TCX',
    majorColorName: 'Blue',
    colorName: 'Marina',
    r: 79,
    g: 132,
    b: 196,
  },
  {
    pantoneId: 1229,
    name: '17-4111 TCX',
    majorColorName: 'Blue',
    colorName: 'Citadel',
    r: 116,
    g: 137,
    b: 149,
  },
  {
    pantoneId: 1230,
    name: '17-4123 TCX',
    majorColorName: 'Blue',
    colorName: 'Niagara',
    r: 84,
    g: 135,
    b: 164,
  },
  {
    pantoneId: 1231,
    name: '17-4131 TCX',
    majorColorName: 'Blue',
    colorName: 'Cendre Blue',
    r: 62,
    g: 127,
    b: 165,
  },
  {
    pantoneId: 1232,
    name: '17-4139 TCX',
    majorColorName: 'Blue',
    colorName: 'Azure Blue',
    r: 77,
    g: 145,
    b: 198,
  },
  {
    pantoneId: 1233,
    name: '17-4247 TCX',
    majorColorName: 'Blue',
    colorName: 'Diva Blue',
    r: 0,
    g: 123,
    b: 178,
  },
  {
    pantoneId: 1234,
    name: '17-4320 TCX',
    majorColorName: 'Blue',
    colorName: 'Adriatic Blue',
    r: 92,
    g: 137,
    b: 155,
  },
  {
    pantoneId: 1235,
    name: '17-4328 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Moon',
    r: 54,
    g: 134,
    b: 160,
  },
  {
    pantoneId: 1236,
    name: '17-4336 TCX',
    majorColorName: 'Blue',
    colorName: 'Blithe',
    r: 0,
    g: 132,
    b: 189,
  },
  {
    pantoneId: 1237,
    name: '17-4402 TCX',
    majorColorName: 'Gray',
    colorName: 'Neutral Gray',
    r: 142,
    g: 145,
    b: 143,
  },
  {
    pantoneId: 1238,
    name: '17-4405 TCX',
    majorColorName: 'Gray',
    colorName: 'Monument',
    r: 132,
    g: 137,
    b: 140,
  },
  {
    pantoneId: 1239,
    name: '17-4408 TCX',
    majorColorName: 'Gray',
    colorName: 'Lead',
    r: 122,
    g: 137,
    b: 143,
  },
  {
    pantoneId: 1240,
    name: '17-4412 TCX',
    majorColorName: 'Blue',
    colorName: 'Smoke Blue',
    r: 109,
    g: 137,
    b: 148,
  },
  {
    pantoneId: 1241,
    name: '17-4421 TCX',
    majorColorName: 'Blue',
    colorName: 'Larkspur',
    r: 60,
    g: 125,
    b: 144,
  },
  {
    pantoneId: 1242,
    name: '17-4427 TCX',
    majorColorName: 'Blue',
    colorName: 'Bluejay',
    r: 21,
    g: 126,
    b: 160,
  },
  {
    pantoneId: 1243,
    name: '17-4432 TCX',
    majorColorName: 'Blue',
    colorName: 'Vivid Blue',
    r: 0,
    g: 136,
    b: 176,
  },
  {
    pantoneId: 1244,
    name: '17-4433 TCX',
    majorColorName: 'Blue',
    colorName: 'Dresden Blue',
    r: 0,
    g: 134,
    b: 187,
  },
  {
    pantoneId: 1245,
    name: '17-4435 TCX',
    majorColorName: 'Blue',
    colorName: 'Malibu Blue',
    r: 0,
    g: 140,
    b: 193,
  },
  {
    pantoneId: 1246,
    name: '17-4440 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Danube',
    r: 0,
    g: 135,
    b: 182,
  },
  {
    pantoneId: 1247,
    name: '17-4540 TCX',
    majorColorName: 'Blue',
    colorName: 'Hawaiian Ocean',
    r: 0,
    g: 141,
    b: 185,
  },
  {
    pantoneId: 1248,
    name: '17-4716 TCX',
    majorColorName: 'Blue',
    colorName: 'Storm Blue',
    r: 71,
    g: 120,
    b: 138,
  },
  {
    pantoneId: 1249,
    name: '17-4724 TCX',
    majorColorName: 'Blue',
    colorName: 'Pagoda Blue',
    r: 26,
    g: 127,
    b: 142,
  },
  {
    pantoneId: 1250,
    name: '17-4728 TCX',
    majorColorName: 'Blue',
    colorName: 'Algiers Blue',
    r: 0,
    g: 133,
    b: 156,
  },
  {
    pantoneId: 1251,
    name: '17-4730 TCX',
    majorColorName: 'Blue',
    colorName: 'Caneel Bay',
    r: 0,
    g: 132,
    b: 159,
  },
  {
    pantoneId: 1252,
    name: '17-4735 TCX',
    majorColorName: 'Blue',
    colorName: 'Capri Breeze',
    r: 0,
    g: 135,
    b: 153,
  },
  {
    pantoneId: 1253,
    name: '17-4818 TCX',
    majorColorName: 'Blue',
    colorName: 'Bristol Blue',
    r: 85,
    g: 143,
    b: 145,
  },
  {
    pantoneId: 1254,
    name: '17-4911 TCX',
    majorColorName: 'Blue',
    colorName: 'Arctic',
    r: 100,
    g: 133,
    b: 137,
  },
  {
    pantoneId: 1255,
    name: '17-4919 TCX',
    majorColorName: 'Blue',
    colorName: 'Teal',
    r: 71,
    g: 133,
    b: 137,
  },
  {
    pantoneId: 1256,
    name: '17-4928 TCX',
    majorColorName: 'Blue',
    colorName: 'Lake Blue',
    r: 0,
    g: 140,
    b: 150,
  },
  {
    pantoneId: 1257,
    name: '17-5024 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Teal Blue',
    r: 0,
    g: 127,
    b: 124,
  },
  {
    pantoneId: 1258,
    name: '17-5025 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Navigate',
    r: 0,
    g: 133,
    b: 131,
  },
  {
    pantoneId: 1259,
    name: '17-5029 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Deep Peacock Blue',
    r: 0,
    g: 131,
    b: 129,
  },
  {
    pantoneId: 1260,
    name: '17-5034 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Lapis',
    r: 0,
    g: 134,
    b: 132,
  },
  {
    pantoneId: 1261,
    name: '17-5102 TCX',
    majorColorName: 'Gray',
    colorName: 'Griffin',
    r: 141,
    g: 143,
    b: 143,
  },
  {
    pantoneId: 1262,
    name: '17-5107 TCX',
    majorColorName: 'Gray',
    colorName: 'Chinois Green',
    r: 124,
    g: 140,
    b: 135,
  },
  {
    pantoneId: 1263,
    name: '17-5110 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Trellis',
    r: 106,
    g: 137,
    b: 136,
  },
  {
    pantoneId: 1264,
    name: '17-5111 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Oil Blue',
    r: 101,
    g: 140,
    b: 136,
  },
  {
    pantoneId: 1265,
    name: '17-5117 TCX',
    majorColorName: 'Blue',
    colorName: 'Green-Blue Slate',
    r: 53,
    g: 128,
    b: 130,
  },
  {
    pantoneId: 1266,
    name: '17-5122 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Latigo Bay',
    r: 55,
    g: 145,
    b: 144,
  },
  {
    pantoneId: 1267,
    name: '17-5126 TCX',
    majorColorName: 'Blue',
    colorName: 'Viridian Green',
    r: 0,
    g: 148,
    b: 153,
  },
  {
    pantoneId: 1268,
    name: '17-5130 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Columbia',
    r: 0,
    g: 146,
    b: 136,
  },
  {
    pantoneId: 1269,
    name: '17-5330 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Dynasty Green',
    r: 0,
    g: 142,
    b: 128,
  },
  {
    pantoneId: 1270,
    name: '17-5335 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Spectra Green',
    r: 0,
    g: 155,
    b: 140,
  },
  {
    pantoneId: 1271,
    name: '17-5421 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Porcelain Green',
    r: 16,
    g: 135,
    b: 128,
  },
  {
    pantoneId: 1272,
    name: '17-5430 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Alhambra',
    r: 0,
    g: 135,
    b: 120,
  },
  {
    pantoneId: 1273,
    name: '17-5513 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Deep Sea',
    r: 79,
    g: 124,
    b: 116,
  },
  {
    pantoneId: 1274,
    name: '17-5528 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Greenlake',
    r: 0,
    g: 125,
    b: 105,
  },
  {
    pantoneId: 1275,
    name: '17-5633 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Deep Green',
    r: 0,
    g: 146,
    b: 118,
  },
  {
    pantoneId: 1276,
    name: '17-5638 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Vivid Green',
    r: 0,
    g: 158,
    b: 130,
  },
  {
    pantoneId: 1277,
    name: '17-5641 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Emerald',
    r: 0,
    g: 148,
    b: 115,
  },
  {
    pantoneId: 1278,
    name: '17-5722 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Bottle Green',
    r: 66,
    g: 125,
    b: 109,
  },
  {
    pantoneId: 1279,
    name: '17-5734 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Viridis',
    r: 0,
    g: 132,
    b: 107,
  },
  {
    pantoneId: 1280,
    name: '17-5735 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Parakeet',
    r: 0,
    g: 140,
    b: 105,
  },
  {
    pantoneId: 1281,
    name: '17-5912 TCX',
    majorColorName: 'Green',
    colorName: 'Dark Ivy',
    r: 91,
    g: 119,
    b: 99,
  },
  {
    pantoneId: 1282,
    name: '17-5923 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Pine Green',
    r: 58,
    g: 121,
    b: 94,
  },
  {
    pantoneId: 1283,
    name: '17-5936 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Simply Green',
    r: 0,
    g: 155,
    b: 117,
  },
  {
    pantoneId: 1284,
    name: '17-5937 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Deep Mint',
    r: 0,
    g: 158,
    b: 109,
  },
  {
    pantoneId: 1285,
    name: '17-6009 TCX',
    majorColorName: 'Gray',
    colorName: 'Laurel Wreath',
    r: 97,
    g: 111,
    b: 101,
  },
  {
    pantoneId: 1286,
    name: '17-6030 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Jelly Bean',
    r: 0,
    g: 134,
    b: 88,
  },
  {
    pantoneId: 1287,
    name: '17-6153 TCX',
    majorColorName: 'Green',
    colorName: 'Fern Green',
    r: 0,
    g: 140,
    b: 69,
  },
  {
    pantoneId: 1288,
    name: '17-6206 TCX',
    majorColorName: 'Gray',
    colorName: 'Shadow',
    r: 136,
    g: 141,
    b: 130,
  },
  {
    pantoneId: 1289,
    name: '17-6212 TCX',
    majorColorName: 'Gray',
    colorName: 'Sea Spray',
    r: 113,
    g: 126,
    b: 111,
  },
  {
    pantoneId: 1290,
    name: '17-6219 TCX',
    majorColorName: 'Green',
    colorName: 'Deep Grass Green',
    r: 85,
    g: 131,
    b: 103,
  },
  {
    pantoneId: 1291,
    name: '17-6229 TCX',
    majorColorName: 'Green',
    colorName: 'Medium Green',
    r: 60,
    g: 130,
    b: 78,
  },
  {
    pantoneId: 1292,
    name: '17-6319 TCX',
    majorColorName: 'Green',
    colorName: 'Kashmir',
    r: 111,
    g: 141,
    b: 106,
  },
  {
    pantoneId: 1293,
    name: '17-6323 TCX',
    majorColorName: 'Green',
    colorName: 'Hedge Green',
    r: 118,
    g: 138,
    b: 117,
  },
  {
    pantoneId: 1294,
    name: '17-6333 TCX',
    majorColorName: 'Green',
    colorName: 'Mint Green',
    r: 72,
    g: 125,
    b: 73,
  },
  {
    pantoneId: 1295,
    name: '18-0000 TCX',
    majorColorName: 'Gray',
    colorName: 'Smoked Pearl',
    r: 101,
    g: 100,
    b: 102,
  },
  {
    pantoneId: 1296,
    name: '18-0108 TCX',
    majorColorName: 'Green',
    colorName: 'Dill',
    r: 100,
    g: 119,
    b: 85,
  },
  {
    pantoneId: 1297,
    name: '18-0110 TCX',
    majorColorName: 'Green',
    colorName: 'English Ivy',
    r: 97,
    g: 132,
    b: 91,
  },
  {
    pantoneId: 1298,
    name: '18-0117 TCX',
    majorColorName: 'Green',
    colorName: 'Vineyard Green',
    r: 95,
    g: 115,
    b: 85,
  },
  {
    pantoneId: 1299,
    name: '18-0119 TCX',
    majorColorName: 'Green',
    colorName: 'Willow Bough',
    r: 89,
    g: 117,
    b: 77,
  },
  {
    pantoneId: 1300,
    name: '18-0121 TCX',
    majorColorName: 'Green',
    colorName: 'Elm Green',
    r: 84,
    g: 112,
    b: 83,
  },
  {
    pantoneId: 1301,
    name: '18-0125 TCX',
    majorColorName: 'Green',
    colorName: 'Artichoke Green',
    r: 75,
    g: 109,
    b: 65,
  },
  {
    pantoneId: 1302,
    name: '18-0130 TCX',
    majorColorName: 'Green',
    colorName: 'Cactus',
    r: 83,
    g: 113,
    b: 61,
  },
  {
    pantoneId: 1303,
    name: '18-0135 TCX',
    majorColorName: 'Green',
    colorName: 'Treetop',
    r: 71,
    g: 106,
    b: 48,
  },
  {
    pantoneId: 1304,
    name: '18-0201 TCX',
    majorColorName: 'Gray',
    colorName: 'Castlerock',
    r: 95,
    g: 94,
    b: 98,
  },
  {
    pantoneId: 1305,
    name: '18-0228 TCX',
    majorColorName: 'Green',
    colorName: 'Pesto',
    r: 89,
    g: 95,
    b: 52,
  },
  {
    pantoneId: 1306,
    name: '18-0306 TCX',
    majorColorName: 'Gray',
    colorName: 'Gunmetal',
    r: 92,
    g: 93,
    b: 91,
  },
  {
    pantoneId: 1307,
    name: '18-0312 TCX',
    majorColorName: 'Yellow',
    colorName: 'Deep Lichen Green',
    r: 110,
    g: 110,
    b: 92,
  },
  {
    pantoneId: 1308,
    name: '18-0316 TCX',
    majorColorName: 'Green',
    colorName: 'Olivine',
    r: 102,
    g: 107,
    b: 84,
  },
  {
    pantoneId: 1309,
    name: '18-0317 TCX',
    majorColorName: 'Green',
    colorName: 'Bronze Green',
    r: 82,
    g: 95,
    b: 72,
  },
  {
    pantoneId: 1310,
    name: '18-0322 TCX',
    majorColorName: 'Green',
    colorName: 'Cypress',
    r: 84,
    g: 90,
    b: 62,
  },
  {
    pantoneId: 1311,
    name: '18-0324 TCX',
    majorColorName: 'Green',
    colorName: 'Calliste Green',
    r: 117,
    g: 122,
    b: 78,
  },
  {
    pantoneId: 1312,
    name: '18-0328 TCX',
    majorColorName: 'Green',
    colorName: 'Cedar Green',
    r: 94,
    g: 103,
    b: 55,
  },
  {
    pantoneId: 1313,
    name: '18-0332 TCX',
    majorColorName: 'Green',
    colorName: 'Grasshopper',
    r: 119,
    g: 130,
    b: 74,
  },
  {
    pantoneId: 1314,
    name: '18-0403 TCX',
    majorColorName: 'Gray',
    colorName: 'Dark Gull Gray',
    r: 98,
    g: 93,
    b: 93,
  },
  {
    pantoneId: 1315,
    name: '18-0420 TCX',
    majorColorName: 'Green',
    colorName: 'Four Leaf Clover',
    r: 97,
    g: 102,
    b: 82,
  },
  {
    pantoneId: 1316,
    name: '18-0422 TCX',
    majorColorName: 'Green',
    colorName: 'Loden Green',
    r: 110,
    g: 113,
    b: 83,
  },
  {
    pantoneId: 1317,
    name: '18-0426 TCX',
    majorColorName: 'Yellow',
    colorName: 'Capulet Olive',
    r: 101,
    g: 99,
    b: 68,
  },
  {
    pantoneId: 1318,
    name: '18-0430 TCX',
    majorColorName: 'Yellow',
    colorName: 'Avocado',
    r: 103,
    g: 98,
    b: 50,
  },
  {
    pantoneId: 1319,
    name: '18-0435 TCX',
    majorColorName: 'Green',
    colorName: 'Calla Green',
    r: 106,
    g: 111,
    b: 52,
  },
  {
    pantoneId: 1320,
    name: '18-0503 TCX',
    majorColorName: 'Gray',
    colorName: 'Gargoyle',
    r: 104,
    g: 103,
    b: 103,
  },
  {
    pantoneId: 1321,
    name: '18-0510 TCX',
    majorColorName: 'Gray',
    colorName: 'Castor Gray',
    r: 100,
    g: 103,
    b: 98,
  },
  {
    pantoneId: 1322,
    name: '18-0513 TCX',
    majorColorName: 'Brown',
    colorName: 'Bungee Cord',
    r: 105,
    g: 97,
    b: 86,
  },
  {
    pantoneId: 1323,
    name: '18-0515 TCX',
    majorColorName: 'Gray',
    colorName: 'Dusty Olive',
    r: 100,
    g: 99,
    b: 86,
  },
  {
    pantoneId: 1324,
    name: '18-0521 TCX',
    majorColorName: 'Yellow',
    colorName: 'Burnt Olive',
    r: 100,
    g: 96,
    b: 73,
  },
  {
    pantoneId: 1325,
    name: '18-0525 TCX',
    majorColorName: 'Yellow',
    colorName: 'Iguana',
    r: 129,
    g: 132,
    b: 85,
  },
  {
    pantoneId: 1326,
    name: '18-0527 TCX',
    majorColorName: 'Yellow',
    colorName: 'Olive Branch',
    r: 106,
    g: 106,
    b: 69,
  },
  {
    pantoneId: 1327,
    name: '18-0538 TCX',
    majorColorName: 'Yellow',
    colorName: 'Woodbine',
    r: 123,
    g: 127,
    b: 50,
  },
  {
    pantoneId: 1328,
    name: '18-0601 TCX',
    majorColorName: 'Gray',
    colorName: 'Charcoal Gray',
    r: 108,
    g: 104,
    b: 104,
  },
  {
    pantoneId: 1329,
    name: '18-0615 TCX',
    majorColorName: 'Brown',
    colorName: 'Stone Gray',
    r: 104,
    g: 94,
    b: 79,
  },
  {
    pantoneId: 1330,
    name: '18-0617 TCX',
    majorColorName: 'Brown',
    colorName: 'Covert Green',
    r: 128,
    g: 118,
    b: 95,
  },
  {
    pantoneId: 1331,
    name: '18-0622 TCX',
    majorColorName: 'Yellow',
    colorName: 'Olive Drab',
    r: 117,
    g: 109,
    b: 71,
  },
  {
    pantoneId: 1332,
    name: '18-0627 TCX',
    majorColorName: 'Yellow',
    colorName: 'Fir Green',
    r: 103,
    g: 89,
    b: 42,
  },
  {
    pantoneId: 1333,
    name: '18-0629 TCX',
    majorColorName: 'Brown',
    colorName: 'Lizard',
    r: 113,
    g: 100,
    b: 62,
  },
  {
    pantoneId: 1334,
    name: '18-0724 TCX',
    majorColorName: 'Brown',
    colorName: 'Gothic Olive',
    r: 124,
    g: 110,
    b: 79,
  },
  {
    pantoneId: 1335,
    name: '18-0820 TCX',
    majorColorName: 'Brown',
    colorName: 'Capers',
    r: 105,
    g: 94,
    b: 75,
  },
  {
    pantoneId: 1336,
    name: '18-0825 TCX',
    majorColorName: 'Brown',
    colorName: 'Nutria',
    r: 117,
    g: 102,
    b: 62,
  },
  {
    pantoneId: 1337,
    name: '18-0830 TCX',
    majorColorName: 'Brown',
    colorName: 'Butternut',
    r: 122,
    g: 100,
    b: 63,
  },
  {
    pantoneId: 1338,
    name: '18-0832 TCX',
    majorColorName: 'Brown',
    colorName: 'Plantation',
    r: 122,
    g: 99,
    b: 50,
  },
  {
    pantoneId: 1339,
    name: '18-0835 TCX',
    majorColorName: 'Brown',
    colorName: 'Dried Tobacco',
    r: 153,
    g: 123,
    b: 56,
  },
  {
    pantoneId: 1340,
    name: '18-0840 TCX',
    majorColorName: 'Brown',
    colorName: 'Tapenade',
    r: 128,
    g: 93,
    b: 36,
  },
  {
    pantoneId: 1341,
    name: '18-0920 TCX',
    majorColorName: 'Brown',
    colorName: 'Kangaroo',
    r: 114,
    g: 94,
    b: 67,
  },
  {
    pantoneId: 1342,
    name: '18-0928 TCX',
    majorColorName: 'Brown',
    colorName: 'Sepia',
    r: 107,
    g: 84,
    b: 62,
  },
  {
    pantoneId: 1343,
    name: '18-0930 TCX',
    majorColorName: 'Brown',
    colorName: 'Coffee Liqueur',
    r: 106,
    g: 81,
    b: 59,
  },
  {
    pantoneId: 1344,
    name: '18-0933 TCX',
    majorColorName: 'Brown',
    colorName: 'Rubber',
    r: 129,
    g: 91,
    b: 55,
  },
  {
    pantoneId: 1345,
    name: '18-0935 TCX',
    majorColorName: 'Brown',
    colorName: 'Buckthorn Brown',
    r: 167,
    g: 111,
    b: 31,
  },
  {
    pantoneId: 1346,
    name: '18-0937 TCX',
    majorColorName: 'Brown',
    colorName: 'Bronze Brown',
    r: 130,
    g: 94,
    b: 47,
  },
  {
    pantoneId: 1347,
    name: '18-0939 TCX',
    majorColorName: 'Brown',
    colorName: 'Cumin',
    r: 146,
    g: 114,
    b: 64,
  },
  {
    pantoneId: 1348,
    name: '18-0940 TCX',
    majorColorName: 'Brown',
    colorName: 'Golden Brown',
    r: 145,
    g: 103,
    b: 47,
  },
  {
    pantoneId: 1349,
    name: '18-0950 TCX',
    majorColorName: 'Brown',
    colorName: 'Cathay Spice',
    r: 153,
    g: 100,
    b: 44,
  },
  {
    pantoneId: 1350,
    name: '18-1015 TCX',
    majorColorName: 'Brown',
    colorName: 'Shitake',
    r: 115,
    g: 98,
    b: 83,
  },
  {
    pantoneId: 1351,
    name: '18-1016 TCX',
    majorColorName: 'Brown',
    colorName: 'Cub',
    r: 110,
    g: 92,
    b: 75,
  },
  {
    pantoneId: 1352,
    name: '18-1018 TCX',
    majorColorName: 'Brown',
    colorName: 'Otter',
    r: 127,
    g: 103,
    b: 79,
  },
  {
    pantoneId: 1353,
    name: '18-1022 TCX',
    majorColorName: 'Brown',
    colorName: 'Ermine',
    r: 131,
    g: 107,
    b: 79,
  },
  {
    pantoneId: 1354,
    name: '18-1027 TCX',
    majorColorName: 'Brown',
    colorName: 'Bison',
    r: 110,
    g: 79,
    b: 58,
  },
  {
    pantoneId: 1355,
    name: '18-1030 TCX',
    majorColorName: 'Brown',
    colorName: 'Thrush',
    r: 147,
    g: 107,
    b: 79,
  },
  {
    pantoneId: 1356,
    name: '18-1031 TCX',
    majorColorName: 'Brown',
    colorName: 'Toffee',
    r: 117,
    g: 81,
    b: 57,
  },
  {
    pantoneId: 1357,
    name: '18-1033 TCX',
    majorColorName: 'Brown',
    colorName: 'Dachshund',
    r: 112,
    g: 79,
    b: 55,
  },
  {
    pantoneId: 1358,
    name: '18-1048 TCX',
    majorColorName: 'Brown',
    colorName: "Monk's Robe",
    r: 112,
    g: 72,
    b: 34,
  },
  {
    pantoneId: 1359,
    name: '18-1110 TCX',
    majorColorName: 'Brown',
    colorName: 'Brindle',
    r: 130,
    g: 119,
    b: 107,
  },
  {
    pantoneId: 1360,
    name: '18-1112 TCX',
    majorColorName: 'Brown',
    colorName: 'Walnut',
    r: 119,
    g: 106,
    b: 95,
  },
  {
    pantoneId: 1361,
    name: '18-1124 TCX',
    majorColorName: 'Brown',
    colorName: 'Partridge',
    r: 114,
    g: 84,
    b: 64,
  },
  {
    pantoneId: 1362,
    name: '18-1130 TCX',
    majorColorName: 'Brown',
    colorName: 'Aztec',
    r: 122,
    g: 87,
    b: 71,
  },
  {
    pantoneId: 1363,
    name: '18-1137 TCX',
    majorColorName: 'Brown',
    colorName: 'Rawhide',
    r: 134,
    g: 94,
    b: 73,
  },
  {
    pantoneId: 1364,
    name: '18-1140 TCX',
    majorColorName: 'Brown',
    colorName: 'Mocha Bisque',
    r: 140,
    g: 84,
    b: 58,
  },
  {
    pantoneId: 1365,
    name: '18-1142 TCX',
    majorColorName: 'Brown',
    colorName: 'Leather Brown',
    r: 151,
    g: 87,
    b: 43,
  },
  {
    pantoneId: 1366,
    name: '18-1154 TCX',
    majorColorName: 'Brown',
    colorName: 'Glazed Ginger',
    r: 145,
    g: 85,
    b: 43,
  },
  {
    pantoneId: 1367,
    name: '18-1160 TCX',
    majorColorName: 'Brown',
    colorName: 'Sudan Brown',
    r: 172,
    g: 107,
    b: 41,
  },
  {
    pantoneId: 1368,
    name: '18-1222 TCX',
    majorColorName: 'Brown',
    colorName: 'Cocoa Brown',
    r: 108,
    g: 80,
    b: 67,
  },
  {
    pantoneId: 1369,
    name: '18-1229 TCX',
    majorColorName: 'Brown',
    colorName: 'Carob Brown',
    r: 133,
    g: 92,
    b: 76,
  },
  {
    pantoneId: 1370,
    name: '18-1230 TCX',
    majorColorName: 'Brown',
    colorName: 'Coconut Shell',
    r: 135,
    g: 78,
    b: 60,
  },
  {
    pantoneId: 1371,
    name: '18-1235 TCX',
    majorColorName: 'Brown',
    colorName: 'Russet',
    r: 143,
    g: 95,
    b: 80,
  },
  {
    pantoneId: 1372,
    name: '18-1238 TCX',
    majorColorName: 'Brown',
    colorName: 'Rustic Brown',
    r: 133,
    g: 81,
    b: 65,
  },
  {
    pantoneId: 1373,
    name: '18-1239 TCX',
    majorColorName: 'Brown',
    colorName: 'Sierra',
    r: 152,
    g: 92,
    b: 65,
  },
  {
    pantoneId: 1374,
    name: '18-1242 TCX',
    majorColorName: 'Brown',
    colorName: 'Brown Patina',
    r: 131,
    g: 79,
    b: 61,
  },
  {
    pantoneId: 1375,
    name: '18-1244 TCX',
    majorColorName: 'Brown',
    colorName: 'Ginger Bread',
    r: 140,
    g: 74,
    b: 47,
  },
  {
    pantoneId: 1376,
    name: '18-1248 TCX',
    majorColorName: 'Brown',
    colorName: 'Rust',
    r: 181,
    g: 90,
    b: 48,
  },
  {
    pantoneId: 1377,
    name: '18-1250 TCX',
    majorColorName: 'Brown',
    colorName: 'Bombay Brown',
    r: 159,
    g: 81,
    b: 48,
  },
  {
    pantoneId: 1378,
    name: '18-1306 TCX',
    majorColorName: 'Brown',
    colorName: 'Iron',
    r: 115,
    g: 100,
    b: 96,
  },
  {
    pantoneId: 1379,
    name: '18-1312 TCX',
    majorColorName: 'Brown',
    colorName: 'Deep Taupe',
    r: 123,
    g: 102,
    b: 96,
  },
  {
    pantoneId: 1380,
    name: '18-1314 TCX',
    majorColorName: 'Brown',
    colorName: 'Acorn',
    r: 126,
    g: 94,
    b: 82,
  },
  {
    pantoneId: 1381,
    name: '18-1320 TCX',
    majorColorName: 'Brown',
    colorName: 'Clove',
    r: 135,
    g: 97,
    b: 85,
  },
  {
    pantoneId: 1382,
    name: '18-1321 TCX',
    majorColorName: 'Brown',
    colorName: 'Brownie',
    r: 143,
    g: 114,
    b: 101,
  },
  {
    pantoneId: 1383,
    name: '18-1326 TCX',
    majorColorName: 'Brown',
    colorName: 'Nutmeg',
    r: 126,
    g: 92,
    b: 84,
  },
  {
    pantoneId: 1384,
    name: '18-1336 TCX',
    majorColorName: 'Brown',
    colorName: 'Copper Brown',
    r: 154,
    g: 96,
    b: 81,
  },
  {
    pantoneId: 1385,
    name: '18-1343 TCX',
    majorColorName: 'Brown',
    colorName: 'Auburn',
    r: 161,
    g: 88,
    b: 67,
  },
  {
    pantoneId: 1386,
    name: '18-1346 TCX',
    majorColorName: 'Pink',
    colorName: 'Bruschetta',
    r: 167,
    g: 89,
    b: 73,
  },
  {
    pantoneId: 1387,
    name: '18-1350 TCX',
    majorColorName: 'Brown',
    colorName: 'Burnt Brick',
    r: 161,
    g: 77,
    b: 58,
  },
  {
    pantoneId: 1388,
    name: '18-1354 TCX',
    majorColorName: 'Brown',
    colorName: 'Burnt Ochre',
    r: 187,
    g: 79,
    b: 53,
  },
  {
    pantoneId: 1389,
    name: '18-1404 TCX',
    majorColorName: 'Pink',
    colorName: 'Sparrow',
    r: 105,
    g: 89,
    b: 92,
  },
  {
    pantoneId: 1390,
    name: '18-1405 TCX',
    majorColorName: 'Pink',
    colorName: 'Flint',
    r: 112,
    g: 88,
    b: 97,
  },
  {
    pantoneId: 1391,
    name: '18-1409 TCX',
    majorColorName: 'Pink',
    colorName: 'Peppercorn',
    r: 108,
    g: 86,
    b: 86,
  },
  {
    pantoneId: 1392,
    name: '18-1411 TCX',
    majorColorName: 'Pink',
    colorName: 'Plum Wine',
    r: 103,
    g: 69,
    b: 80,
  },
  {
    pantoneId: 1393,
    name: '18-1415 TCX',
    majorColorName: 'Pink',
    colorName: 'Marron',
    r: 110,
    g: 76,
    b: 75,
  },
  {
    pantoneId: 1394,
    name: '18-1418 TCX',
    majorColorName: 'Pink',
    colorName: 'Crushed Berry',
    r: 128,
    g: 79,
    b: 90,
  },
  {
    pantoneId: 1395,
    name: '18-1420 TCX',
    majorColorName: 'Pink',
    colorName: 'Wild Ginger',
    r: 124,
    g: 76,
    b: 83,
  },
  {
    pantoneId: 1396,
    name: '18-1421 TCX',
    majorColorName: 'Brown',
    colorName: 'Cognac',
    r: 139,
    g: 100,
    b: 90,
  },
  {
    pantoneId: 1397,
    name: '18-1425 TCX',
    majorColorName: 'Pink',
    colorName: 'Mahogany',
    r: 130,
    g: 77,
    b: 70,
  },
  {
    pantoneId: 1398,
    name: '18-1426 TCX',
    majorColorName: 'Pink',
    colorName: 'Apple Butter',
    r: 132,
    g: 75,
    b: 77,
  },
  {
    pantoneId: 1399,
    name: '18-1433 TCX',
    majorColorName: 'Pink',
    colorName: 'Chutney',
    r: 152,
    g: 89,
    b: 75,
  },
  {
    pantoneId: 1400,
    name: '18-1434 TCX',
    majorColorName: 'Pink',
    colorName: 'Etruscan Red',
    r: 162,
    g: 87,
    b: 75,
  },
  {
    pantoneId: 1401,
    name: '18-1435 TCX',
    majorColorName: 'Pink',
    colorName: 'Withered Rose',
    r: 162,
    g: 102,
    b: 102,
  },
  {
    pantoneId: 1402,
    name: '18-1436 TCX',
    majorColorName: 'Pink',
    colorName: 'Light Mahogany',
    r: 173,
    g: 109,
    b: 104,
  },
  {
    pantoneId: 1403,
    name: '18-1438 TCX',
    majorColorName: 'Pink',
    colorName: 'Marsala',
    r: 150,
    g: 79,
    b: 76,
  },
  {
    pantoneId: 1404,
    name: '18-1441 TCX',
    majorColorName: 'Brown',
    colorName: 'Baked Clay',
    r: 156,
    g: 86,
    b: 66,
  },
  {
    pantoneId: 1405,
    name: '18-1442 TCX',
    majorColorName: 'Pink',
    colorName: 'Red Ochre',
    r: 145,
    g: 56,
    b: 50,
  },
  {
    pantoneId: 1406,
    name: '18-1443 TCX',
    majorColorName: 'Pink',
    colorName: 'Redwood',
    r: 166,
    g: 89,
    b: 76,
  },
  {
    pantoneId: 1407,
    name: '18-1444 TCX',
    majorColorName: 'Pink',
    colorName: 'Tandoori Spice',
    r: 159,
    g: 68,
    b: 64,
  },
  {
    pantoneId: 1408,
    name: '18-1445 TCX',
    majorColorName: 'Red',
    colorName: 'Spicy Orange',
    r: 215,
    g: 60,
    b: 38,
  },
  {
    pantoneId: 1409,
    name: '18-1447 TCX',
    majorColorName: 'Orange',
    colorName: 'Orange Rust',
    r: 194,
    g: 90,
    b: 60,
  },
  {
    pantoneId: 1410,
    name: '18-1448 TCX',
    majorColorName: 'Pink',
    colorName: 'Chili',
    r: 190,
    g: 81,
    b: 65,
  },
  {
    pantoneId: 1411,
    name: '18-1449 TCX',
    majorColorName: 'Red',
    colorName: 'Ketchup',
    r: 154,
    g: 56,
    b: 45,
  },
  {
    pantoneId: 1412,
    name: '18-1450 TCX',
    majorColorName: 'Pink',
    colorName: 'Mecca Orange',
    r: 189,
    g: 87,
    b: 69,
  },
  {
    pantoneId: 1413,
    name: '18-1451 TCX',
    majorColorName: 'Brown',
    colorName: 'Autumn Glaze',
    r: 179,
    g: 87,
    b: 63,
  },
  {
    pantoneId: 1414,
    name: '18-1454 TCX',
    majorColorName: 'Red',
    colorName: 'Red Clay',
    r: 194,
    g: 69,
    b: 45,
  },
  {
    pantoneId: 1415,
    name: '18-1512 TCX',
    majorColorName: 'Pink',
    colorName: 'Rose Brown',
    r: 128,
    g: 86,
    b: 91,
  },
  {
    pantoneId: 1416,
    name: '18-1531 TCX',
    majorColorName: 'Pink',
    colorName: 'Barn Red',
    r: 143,
    g: 66,
    b: 59,
  },
  {
    pantoneId: 1417,
    name: '18-1535 TCX',
    majorColorName: 'Pink',
    colorName: 'Ginger Spice',
    r: 182,
    g: 93,
    b: 75,
  },
  {
    pantoneId: 1418,
    name: '18-1536 TCX',
    majorColorName: 'Pink',
    colorName: 'Hot Sauce',
    r: 171,
    g: 79,
    b: 65,
  },
  {
    pantoneId: 1419,
    name: '18-1537 TCX',
    majorColorName: 'Brown',
    colorName: 'Copper Coin',
    r: 186,
    g: 107,
    b: 87,
  },
  {
    pantoneId: 1420,
    name: '18-1540 TCX',
    majorColorName: 'Pink',
    colorName: 'Cinnabar',
    r: 156,
    g: 69,
    b: 59,
  },
  {
    pantoneId: 1421,
    name: '18-1547 TCX',
    majorColorName: 'Pink',
    colorName: 'Bossa Nova',
    r: 151,
    g: 58,
    b: 54,
  },
  {
    pantoneId: 1422,
    name: '18-1550 TCX',
    majorColorName: 'Red',
    colorName: 'Aurora Red',
    r: 185,
    g: 58,
    b: 50,
  },
  {
    pantoneId: 1423,
    name: '18-1555 TCX',
    majorColorName: 'Red',
    colorName: 'Molten Lava',
    r: 181,
    g: 51,
    b: 46,
  },
  {
    pantoneId: 1424,
    name: '18-1561 TCX',
    majorColorName: 'Red',
    colorName: 'Orange.com',
    r: 218,
    g: 50,
    b: 28,
  },
  {
    pantoneId: 1425,
    name: '18-1612 TCX',
    majorColorName: 'Pink',
    colorName: 'Rose Taupe',
    r: 128,
    g: 96,
    b: 98,
  },
  {
    pantoneId: 1426,
    name: '18-1613 TCX',
    majorColorName: 'Pink',
    colorName: 'Renaissance Rose',
    r: 134,
    g: 85,
    b: 96,
  },
  {
    pantoneId: 1427,
    name: '18-1614 TCX',
    majorColorName: 'Pink',
    colorName: 'Nocturne',
    r: 122,
    g: 75,
    b: 86,
  },
  {
    pantoneId: 1428,
    name: '18-1616 TCX',
    majorColorName: 'Pink',
    colorName: 'Roan Rouge',
    r: 136,
    g: 81,
    b: 87,
  },
  {
    pantoneId: 1429,
    name: '18-1619 TCX',
    majorColorName: 'Pink',
    colorName: 'Maroon',
    r: 131,
    g: 70,
    b: 85,
  },
  {
    pantoneId: 1430,
    name: '18-1629 TCX',
    majorColorName: 'Pink',
    colorName: 'Faded Rose',
    r: 191,
    g: 100,
    b: 100,
  },
  {
    pantoneId: 1431,
    name: '18-1630 TCX',
    majorColorName: 'Pink',
    colorName: 'Dusty Cedar',
    r: 173,
    g: 93,
    b: 93,
  },
  {
    pantoneId: 1432,
    name: '18-1631 TCX',
    majorColorName: 'Pink',
    colorName: 'Earth Red',
    r: 149,
    g: 66,
    b: 78,
  },
  {
    pantoneId: 1433,
    name: '18-1633 TCX',
    majorColorName: 'Pink',
    colorName: 'Garnet Rose',
    r: 172,
    g: 75,
    b: 85,
  },
  {
    pantoneId: 1434,
    name: '18-1634 TCX',
    majorColorName: 'Pink',
    colorName: 'Baroque Rose',
    r: 179,
    g: 90,
    b: 102,
  },
  {
    pantoneId: 1435,
    name: '18-1635 TCX',
    majorColorName: 'Pink',
    colorName: 'Slate Rose',
    r: 180,
    g: 88,
    b: 101,
  },
  {
    pantoneId: 1436,
    name: '18-1643 TCX',
    majorColorName: 'Pink',
    colorName: 'Cardinal',
    r: 173,
    g: 62,
    b: 72,
  },
  {
    pantoneId: 1437,
    name: '18-1648 TCX',
    majorColorName: 'Pink',
    colorName: 'Baked Apple',
    r: 179,
    g: 70,
    b: 70,
  },
  {
    pantoneId: 1438,
    name: '18-1649 TCX',
    majorColorName: 'Pink',
    colorName: 'Deep Sea Coral',
    r: 217,
    g: 97,
    b: 91,
  },
  {
    pantoneId: 1439,
    name: '18-1651 TCX',
    majorColorName: 'Pink',
    colorName: 'Cayenne',
    r: 224,
    g: 73,
    b: 81,
  },
  {
    pantoneId: 1440,
    name: '18-1652 TCX',
    majorColorName: 'Red',
    colorName: 'Rococco Red',
    r: 187,
    g: 54,
    b: 63,
  },
  {
    pantoneId: 1441,
    name: '18-1655 TCX',
    majorColorName: 'Red',
    colorName: 'Mars Red',
    r: 188,
    g: 39,
    b: 49,
  },
  {
    pantoneId: 1442,
    name: '18-1658 TCX',
    majorColorName: 'Red',
    colorName: 'Pompeian Red',
    r: 164,
    g: 41,
    b: 46,
  },
  {
    pantoneId: 1443,
    name: '18-1660 TCX',
    majorColorName: 'Red',
    colorName: 'Tomato',
    r: 206,
    g: 41,
    b: 57,
  },
  {
    pantoneId: 1444,
    name: '18-1661 TCX',
    majorColorName: 'Red',
    colorName: 'Tomato Puree',
    r: 197,
    g: 51,
    b: 70,
  },
  {
    pantoneId: 1445,
    name: '18-1662 TCX',
    majorColorName: 'Red',
    colorName: 'Flame Scarlet',
    r: 205,
    g: 33,
    b: 42,
  },
  {
    pantoneId: 1446,
    name: '18-1663 TCX',
    majorColorName: 'Pink',
    colorName: 'Chinese Red',
    r: 190,
    g: 19,
    b: 45,
  },
  {
    pantoneId: 1447,
    name: '18-1664 TCX',
    majorColorName: 'Red',
    colorName: 'Fiery Red',
    r: 208,
    g: 28,
    b: 31,
  },
  {
    pantoneId: 1448,
    name: '18-1703 TCX',
    majorColorName: 'Gray',
    colorName: 'Shark',
    r: 109,
    g: 99,
    b: 107,
  },
  {
    pantoneId: 1449,
    name: '18-1706 TCX',
    majorColorName: 'Pink',
    colorName: 'Black Plum',
    r: 108,
    g: 87,
    b: 101,
  },
  {
    pantoneId: 1450,
    name: '18-1709 TCX',
    majorColorName: 'Pink',
    colorName: 'Tulipwood',
    r: 128,
    g: 84,
    b: 102,
  },
  {
    pantoneId: 1451,
    name: '18-1710 TCX',
    majorColorName: 'Pink',
    colorName: 'Grape Nectar',
    r: 141,
    g: 92,
    b: 116,
  },
  {
    pantoneId: 1452,
    name: '18-1716 TCX',
    majorColorName: 'Pink',
    colorName: 'Damson',
    r: 133,
    g: 76,
    b: 101,
  },
  {
    pantoneId: 1453,
    name: '18-1718 TCX',
    majorColorName: 'Pink',
    colorName: 'Hawthorn Rose',
    r: 136,
    g: 76,
    b: 94,
  },
  {
    pantoneId: 1454,
    name: '18-1720 TCX',
    majorColorName: 'Pink',
    colorName: 'Violet Quartz',
    r: 139,
    g: 73,
    b: 99,
  },
  {
    pantoneId: 1455,
    name: '18-1725 TCX',
    majorColorName: 'Pink',
    colorName: 'Dry Rose',
    r: 140,
    g: 71,
    b: 89,
  },
  {
    pantoneId: 1456,
    name: '18-1741 TCX',
    majorColorName: 'Pink',
    colorName: 'Raspberry Wine',
    r: 182,
    g: 55,
    b: 83,
  },
  {
    pantoneId: 1457,
    name: '18-1754 TCX',
    majorColorName: 'Pink',
    colorName: 'Raspberry',
    r: 211,
    g: 46,
    b: 94,
  },
  {
    pantoneId: 1458,
    name: '18-1755 TCX',
    majorColorName: 'Pink',
    colorName: 'Rouge Red',
    r: 226,
    g: 70,
    b: 102,
  },
  {
    pantoneId: 1459,
    name: '18-1756 TCX',
    majorColorName: 'Pink',
    colorName: 'Teaberry',
    r: 220,
    g: 56,
    b: 85,
  },
  {
    pantoneId: 1460,
    name: '18-1760 TCX',
    majorColorName: 'Pink',
    colorName: 'Barberry',
    r: 191,
    g: 25,
    b: 69,
  },
  {
    pantoneId: 1461,
    name: '18-1761 TCX',
    majorColorName: 'Pink',
    colorName: 'Ski Patrol',
    r: 187,
    g: 18,
    b: 55,
  },
  {
    pantoneId: 1462,
    name: '18-1762 TCX',
    majorColorName: 'Red',
    colorName: 'Hibiscus',
    r: 221,
    g: 56,
    b: 72,
  },
  {
    pantoneId: 1463,
    name: '18-1763 TCX',
    majorColorName: 'Red',
    colorName: 'High Risk Red',
    r: 199,
    g: 31,
    b: 45,
  },
  {
    pantoneId: 1464,
    name: '18-1764 TCX',
    majorColorName: 'Pink',
    colorName: 'Lollipop',
    r: 204,
    g: 28,
    b: 59,
  },
  {
    pantoneId: 1465,
    name: '18-1807 TCX',
    majorColorName: 'Pink',
    colorName: 'Twilight Mauve',
    r: 139,
    g: 111,
    b: 112,
  },
  {
    pantoneId: 1466,
    name: '18-1852 TCX',
    majorColorName: 'Pink',
    colorName: 'Rose Red',
    r: 201,
    g: 35,
    b: 81,
  },
  {
    pantoneId: 1467,
    name: '18-1856 TCX',
    majorColorName: 'Pink',
    colorName: 'Virtual Pink',
    r: 198,
    g: 23,
    b: 78,
  },
  {
    pantoneId: 1468,
    name: '18-1945 TCX',
    majorColorName: 'Pink',
    colorName: 'Bright Rose',
    r: 197,
    g: 25,
    b: 89,
  },
  {
    pantoneId: 1469,
    name: '18-2027 TCX',
    majorColorName: 'Pink',
    colorName: 'Beaujolais',
    r: 128,
    g: 48,
    b: 76,
  },
  {
    pantoneId: 1470,
    name: '18-2043 TCX',
    majorColorName: 'Pink',
    colorName: 'Raspberry Sorbet',
    r: 210,
    g: 56,
    b: 108,
  },
  {
    pantoneId: 1471,
    name: '18-2109 TCX',
    majorColorName: 'Pink',
    colorName: 'Grape Shake',
    r: 136,
    g: 105,
    b: 113,
  },
  {
    pantoneId: 1472,
    name: '18-2120 TCX',
    majorColorName: 'Pink',
    colorName: 'Honeysuckle',
    r: 217,
    g: 79,
    b: 112,
  },
  {
    pantoneId: 1473,
    name: '18-2133 TCX',
    majorColorName: 'Pink',
    colorName: 'Pink Flambe',
    r: 211,
    g: 80,
    b: 122,
  },
  {
    pantoneId: 1474,
    name: '18-2143 TCX',
    majorColorName: 'Pink',
    colorName: 'Beetroot Purple',
    r: 207,
    g: 45,
    b: 113,
  },
  {
    pantoneId: 1475,
    name: '18-2320 TCX',
    majorColorName: 'Pink',
    colorName: 'Clover',
    r: 138,
    g: 51,
    b: 113,
  },
  {
    pantoneId: 1476,
    name: '18-2326 TCX',
    majorColorName: 'Pink',
    colorName: 'Cactus Flower',
    r: 168,
    g: 62,
    b: 108,
  },
  {
    pantoneId: 1477,
    name: '18-2328 TCX',
    majorColorName: 'Pink',
    colorName: 'Fuchsia Red',
    r: 171,
    g: 52,
    b: 117,
  },
  {
    pantoneId: 1478,
    name: '18-2333 TCX',
    majorColorName: 'Pink',
    colorName: 'Raspberry Rose',
    r: 204,
    g: 67,
    b: 133,
  },
  {
    pantoneId: 1479,
    name: '18-2336 TCX',
    majorColorName: 'Pink',
    colorName: 'Very Berry',
    r: 183,
    g: 50,
    b: 117,
  },
  {
    pantoneId: 1480,
    name: '18-2436 TCX',
    majorColorName: 'Pink',
    colorName: 'Fuchsia Purple',
    r: 211,
    g: 52,
    b: 121,
  },
  {
    pantoneId: 1481,
    name: '18-2525 TCX',
    majorColorName: 'Pink',
    colorName: 'Magenta Haze',
    r: 157,
    g: 68,
    b: 110,
  },
  {
    pantoneId: 1482,
    name: '18-2527 TCX',
    majorColorName: 'Pink',
    colorName: 'Baton Rouge',
    r: 151,
    g: 60,
    b: 108,
  },
  {
    pantoneId: 1483,
    name: '18-2929 TCX',
    majorColorName: 'Pink',
    colorName: 'Purple Wine',
    r: 140,
    g: 53,
    b: 115,
  },
  {
    pantoneId: 1484,
    name: '18-3011 TCX',
    majorColorName: 'Pink',
    colorName: 'Argyle Purple',
    r: 137,
    g: 92,
    b: 121,
  },
  {
    pantoneId: 1485,
    name: '18-3012 TCX',
    majorColorName: 'Pink',
    colorName: 'Purple Gumdrop',
    r: 122,
    g: 89,
    b: 111,
  },
  {
    pantoneId: 1486,
    name: '18-3013 TCX',
    majorColorName: 'Pink',
    colorName: 'Berry Conserve',
    r: 118,
    g: 82,
    b: 105,
  },
  {
    pantoneId: 1487,
    name: '18-3015 TCX',
    majorColorName: 'Pink',
    colorName: 'Amethyst',
    r: 134,
    g: 77,
    b: 117,
  },
  {
    pantoneId: 1488,
    name: '18-3022 TCX',
    majorColorName: 'Pink',
    colorName: 'Deep Orchid',
    r: 144,
    g: 63,
    b: 117,
  },
  {
    pantoneId: 1489,
    name: '18-3025 TCX',
    majorColorName: 'Pink',
    colorName: 'Striking Purple',
    r: 148,
    g: 78,
    b: 135,
  },
  {
    pantoneId: 1490,
    name: '18-3027 TCX',
    majorColorName: 'Pink',
    colorName: 'Purple Orchid',
    r: 173,
    g: 77,
    b: 140,
  },
  {
    pantoneId: 1491,
    name: '18-3211 TCX',
    majorColorName: 'Pink',
    colorName: 'Grapeade',
    r: 133,
    g: 103,
    b: 123,
  },
  {
    pantoneId: 1492,
    name: '18-3218 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Concord Grape',
    r: 124,
    g: 83,
    b: 121,
  },
  {
    pantoneId: 1493,
    name: '18-3220 TCX',
    majorColorName: 'Pink',
    colorName: 'Very Grape',
    r: 146,
    g: 114,
    b: 136,
  },
  {
    pantoneId: 1494,
    name: '18-3224 TCX',
    majorColorName: 'Pink',
    colorName: 'Radiant Orchid',
    r: 173,
    g: 94,
    b: 153,
  },
  {
    pantoneId: 1495,
    name: '18-3230 TCX',
    majorColorName: 'Pink',
    colorName: 'Meadow Mauve',
    r: 169,
    g: 86,
    b: 140,
  },
  {
    pantoneId: 1496,
    name: '18-3324 TCX',
    majorColorName: 'Purple',
    colorName: 'Dahlia',
    r: 132,
    g: 62,
    b: 131,
  },
  {
    pantoneId: 1497,
    name: '18-3331 TCX',
    majorColorName: 'Purple',
    colorName: 'Hyacinth Violet',
    r: 141,
    g: 70,
    b: 135,
  },
  {
    pantoneId: 1498,
    name: '18-3339 TCX',
    majorColorName: 'Pink',
    colorName: 'Vivid Viola',
    r: 153,
    g: 60,
    b: 124,
  },
  {
    pantoneId: 1499,
    name: '18-3410 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Vintage Violet',
    r: 99,
    g: 79,
    b: 98,
  },
  {
    pantoneId: 1500,
    name: '18-3415 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Grape Jam',
    r: 114,
    g: 86,
    b: 113,
  },
  {
    pantoneId: 1501,
    name: '18-3418 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Chinese Violet',
    r: 131,
    g: 94,
    b: 129,
  },
  {
    pantoneId: 1502,
    name: '18-3513 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Grape Compote',
    r: 107,
    g: 88,
    b: 118,
  },
  {
    pantoneId: 1503,
    name: '18-3518 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Patrician Purple',
    r: 108,
    g: 78,
    b: 121,
  },
  {
    pantoneId: 1504,
    name: '18-3520 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Purple Heart',
    r: 116,
    g: 85,
    b: 135,
  },
  {
    pantoneId: 1505,
    name: '18-3522 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Crushed Grape',
    r: 122,
    g: 84,
    b: 127,
  },
  {
    pantoneId: 1506,
    name: '18-3531 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Royal Lilac',
    r: 119,
    g: 77,
    b: 142,
  },
  {
    pantoneId: 1507,
    name: '18-3533 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Dewberry',
    r: 139,
    g: 88,
    b: 151,
  },
  {
    pantoneId: 1508,
    name: '18-3615 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Imperial Palace',
    r: 96,
    g: 78,
    b: 122,
  },
  {
    pantoneId: 1509,
    name: '18-3633 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Deep Lavender',
    r: 119,
    g: 84,
    b: 150,
  },
  {
    pantoneId: 1510,
    name: '18-3710 TCX',
    majorColorName: 'Gray',
    colorName: 'Gray Ridge',
    r: 132,
    g: 121,
    b: 134,
  },
  {
    pantoneId: 1511,
    name: '18-3712 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Purple Sage',
    r: 117,
    g: 105,
    b: 126,
  },
  {
    pantoneId: 1512,
    name: '18-3714 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Mulled Grape',
    r: 103,
    g: 90,
    b: 116,
  },
  {
    pantoneId: 1513,
    name: '18-3715 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Montana Grape',
    r: 108,
    g: 89,
    b: 113,
  },
  {
    pantoneId: 1514,
    name: '18-3718 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Purple Haze',
    r: 128,
    g: 115,
    b: 150,
  },
  {
    pantoneId: 1515,
    name: '18-3737 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Passion Flower',
    r: 109,
    g: 86,
    b: 152,
  },
  {
    pantoneId: 1516,
    name: '18-3812 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Cadet',
    r: 106,
    g: 99,
    b: 120,
  },
  {
    pantoneId: 1517,
    name: '18-3817 TCX',
    majorColorName: 'Blue',
    colorName: 'Heron',
    r: 98,
    g: 97,
    b: 126,
  },
  {
    pantoneId: 1518,
    name: '18-3820 TCX',
    majorColorName: 'Blue',
    colorName: 'Twilight Purple',
    r: 102,
    g: 100,
    b: 139,
  },
  {
    pantoneId: 1519,
    name: '18-3828 TCX',
    majorColorName: 'Blue',
    colorName: 'Corsican Blue',
    r: 100,
    g: 96,
    b: 147,
  },
  {
    pantoneId: 1520,
    name: '18-3833 TCX',
    majorColorName: 'Blue',
    colorName: 'Dusted Peri',
    r: 105,
    g: 107,
    b: 160,
  },
  {
    pantoneId: 1521,
    name: '18-3834 TCX',
    majorColorName: 'Blue',
    colorName: 'Veronica',
    r: 109,
    g: 102,
    b: 149,
  },
  {
    pantoneId: 1522,
    name: '18-3838 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Ultra Violet',
    r: 95,
    g: 75,
    b: 139,
  },
  {
    pantoneId: 1523,
    name: '18-3840 TCX',
    majorColorName: 'Blue',
    colorName: 'Purple Opulence',
    r: 96,
    g: 86,
    b: 154,
  },
  {
    pantoneId: 1524,
    name: '18-3905 TCX',
    majorColorName: 'Gray',
    colorName: 'Excalibur',
    r: 103,
    g: 97,
    b: 104,
  },
  {
    pantoneId: 1525,
    name: '18-3910 TCX',
    majorColorName: 'Blue',
    colorName: 'Folkstone Gray',
    r: 98,
    g: 104,
    b: 121,
  },
  {
    pantoneId: 1526,
    name: '18-3916 TCX',
    majorColorName: 'Blue',
    colorName: 'Flint Stone',
    r: 103,
    g: 114,
    b: 131,
  },
  {
    pantoneId: 1527,
    name: '18-3918 TCX',
    majorColorName: 'Blue',
    colorName: 'China Blue',
    r: 84,
    g: 100,
    b: 119,
  },
  {
    pantoneId: 1528,
    name: '18-3920 TCX',
    majorColorName: 'Blue',
    colorName: 'Coastal Fjord',
    r: 80,
    g: 93,
    b: 126,
  },
  {
    pantoneId: 1529,
    name: '18-3921 TCX',
    majorColorName: 'Blue',
    colorName: 'Bijou Blue',
    r: 78,
    g: 94,
    b: 127,
  },
  {
    pantoneId: 1530,
    name: '18-3922 TCX',
    majorColorName: 'Blue',
    colorName: 'Coronet Blue',
    r: 89,
    g: 114,
    b: 142,
  },
  {
    pantoneId: 1531,
    name: '18-3927 TCX',
    majorColorName: 'Blue',
    colorName: 'Velvet Morning',
    r: 96,
    g: 104,
    b: 141,
  },
  {
    pantoneId: 1532,
    name: '18-3928 TCX',
    majorColorName: 'Blue',
    colorName: 'Dutch Blue',
    r: 74,
    g: 99,
    b: 141,
  },
  {
    pantoneId: 1533,
    name: '18-3930 TCX',
    majorColorName: 'Blue',
    colorName: 'Bleached Denim',
    r: 100,
    g: 111,
    b: 155,
  },
  {
    pantoneId: 1534,
    name: '18-3932 TCX',
    majorColorName: 'Blue',
    colorName: 'Marlin',
    r: 81,
    g: 91,
    b: 135,
  },
  {
    pantoneId: 1535,
    name: '18-3935 TCX',
    majorColorName: 'Blue',
    colorName: 'Wedgewood',
    r: 100,
    g: 121,
    b: 179,
  },
  {
    pantoneId: 1536,
    name: '18-3937 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Yonder',
    r: 90,
    g: 119,
    b: 168,
  },
  {
    pantoneId: 1537,
    name: '18-3943 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Iris',
    r: 90,
    g: 91,
    b: 159,
  },
  {
    pantoneId: 1538,
    name: '18-3944 TCX',
    majorColorName: 'Blue',
    colorName: 'Violet Storm',
    r: 92,
    g: 97,
    b: 157,
  },
  {
    pantoneId: 1539,
    name: '18-3945 TCX',
    majorColorName: 'Blue',
    colorName: 'Amparo Blue',
    r: 73,
    g: 96,
    b: 168,
  },
  {
    pantoneId: 1540,
    name: '18-3946 TCX',
    majorColorName: 'Blue',
    colorName: 'Baja Blue',
    r: 95,
    g: 109,
    b: 176,
  },
  {
    pantoneId: 1541,
    name: '18-3949 TCX',
    majorColorName: 'Blue',
    colorName: 'Dazzling Blue',
    r: 56,
    g: 80,
    b: 160,
  },
  {
    pantoneId: 1542,
    name: '18-3963 TCX',
    majorColorName: 'Blue',
    colorName: 'Spectrum Blue',
    r: 61,
    g: 60,
    b: 124,
  },
  {
    pantoneId: 1543,
    name: '18-4005 TCX',
    majorColorName: 'Gray',
    colorName: 'Steel Gray',
    r: 114,
    g: 111,
    b: 112,
  },
  {
    pantoneId: 1544,
    name: '18-4011 TCX',
    majorColorName: 'Blue',
    colorName: 'Goblin Blue',
    r: 95,
    g: 114,
    b: 120,
  },
  {
    pantoneId: 1545,
    name: '18-4018 TCX',
    majorColorName: 'Blue',
    colorName: 'Real Teal',
    r: 64,
    g: 93,
    b: 115,
  },
  {
    pantoneId: 1546,
    name: '18-4020 TCX',
    majorColorName: 'Blue',
    colorName: "Captain's Blue",
    r: 85,
    g: 112,
    b: 136,
  },
  {
    pantoneId: 1547,
    name: '18-4023 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Ashes',
    r: 59,
    g: 95,
    b: 120,
  },
  {
    pantoneId: 1548,
    name: '18-4025 TCX',
    majorColorName: 'Blue',
    colorName: 'Copen Blue',
    r: 81,
    g: 107,
    b: 132,
  },
  {
    pantoneId: 1549,
    name: '18-4026 TCX',
    majorColorName: 'Blue',
    colorName: 'Stellar',
    r: 70,
    g: 100,
    b: 126,
  },
  {
    pantoneId: 1550,
    name: '18-4027 TCX',
    majorColorName: 'Blue',
    colorName: 'Moonlight Blue',
    r: 80,
    g: 104,
    b: 134,
  },
  {
    pantoneId: 1551,
    name: '18-4029 TCX',
    majorColorName: 'Blue',
    colorName: 'Federal Blue',
    r: 67,
    g: 98,
    b: 139,
  },
  {
    pantoneId: 1552,
    name: '18-4032 TCX',
    majorColorName: 'Blue',
    colorName: 'Deep Water',
    r: 38,
    g: 102,
    b: 145,
  },
  {
    pantoneId: 1553,
    name: '18-4036 TCX',
    majorColorName: 'Blue',
    colorName: 'Parisian Blue',
    r: 79,
    g: 124,
    b: 164,
  },
  {
    pantoneId: 1554,
    name: '18-4039 TCX',
    majorColorName: 'Blue',
    colorName: 'Regatta',
    r: 72,
    g: 122,
    b: 183,
  },
  {
    pantoneId: 1555,
    name: '18-4041 TCX',
    majorColorName: 'Blue',
    colorName: 'Star Sapphire',
    r: 56,
    g: 97,
    b: 146,
  },
  {
    pantoneId: 1556,
    name: '18-4043 TCX',
    majorColorName: 'Blue',
    colorName: 'Palace Blue',
    r: 52,
    g: 108,
    b: 176,
  },
  {
    pantoneId: 1557,
    name: '18-4045 TCX',
    majorColorName: 'Blue',
    colorName: 'Daphne',
    r: 15,
    g: 95,
    b: 154,
  },
  {
    pantoneId: 1558,
    name: '18-4051 TCX',
    majorColorName: 'Blue',
    colorName: 'Strong Blue',
    r: 31,
    g: 93,
    b: 160,
  },
  {
    pantoneId: 1559,
    name: '18-4105 TCX',
    majorColorName: 'Gray',
    colorName: 'Moon Mist',
    r: 128,
    g: 129,
    b: 125,
  },
  {
    pantoneId: 1560,
    name: '18-4140 TCX',
    majorColorName: 'Blue',
    colorName: 'French Blue',
    r: 0,
    g: 114,
    b: 181,
  },
  {
    pantoneId: 1561,
    name: '18-4141 TCX',
    majorColorName: 'Blue',
    colorName: 'Campanula',
    r: 50,
    g: 114,
    b: 175,
  },
  {
    pantoneId: 1562,
    name: '18-4148 TCX',
    majorColorName: 'Blue',
    colorName: 'Victoria Blue',
    r: 8,
    g: 88,
    b: 157,
  },
  {
    pantoneId: 1563,
    name: '18-4215 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Mirage',
    r: 92,
    g: 109,
    b: 124,
  },
  {
    pantoneId: 1564,
    name: '18-4217 TCX',
    majorColorName: 'Blue',
    colorName: 'Bluestone',
    r: 87,
    g: 114,
    b: 132,
  },
  {
    pantoneId: 1565,
    name: '18-4220 TCX',
    majorColorName: 'Blue',
    colorName: 'Provincial Blue',
    r: 92,
    g: 121,
    b: 142,
  },
  {
    pantoneId: 1566,
    name: '18-4222 TCX',
    majorColorName: 'Blue',
    colorName: 'Bluesteel',
    r: 53,
    g: 99,
    b: 124,
  },
  {
    pantoneId: 1567,
    name: '18-4225 TCX',
    majorColorName: 'Blue',
    colorName: 'Saxony Blue',
    r: 31,
    g: 102,
    b: 128,
  },
  {
    pantoneId: 1568,
    name: '18-4231 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Sapphire',
    r: 9,
    g: 87,
    b: 123,
  },
  {
    pantoneId: 1569,
    name: '18-4232 TCX',
    majorColorName: 'Blue',
    colorName: 'Faience',
    r: 42,
    g: 106,
    b: 139,
  },
  {
    pantoneId: 1570,
    name: '18-4244 TCX',
    majorColorName: 'Blue',
    colorName: 'Directoire Blue',
    r: 0,
    g: 97,
    b: 163,
  },
  {
    pantoneId: 1571,
    name: '18-4247 TCX',
    majorColorName: 'Blue',
    colorName: 'Brilliant Blue',
    r: 0,
    g: 117,
    b: 179,
  },
  {
    pantoneId: 1572,
    name: '18-4252 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Aster',
    r: 0,
    g: 119,
    b: 179,
  },
  {
    pantoneId: 1573,
    name: '18-4320 TCX',
    majorColorName: 'Blue',
    colorName: 'Aegean Blue',
    r: 78,
    g: 110,
    b: 129,
  },
  {
    pantoneId: 1574,
    name: '18-4330 TCX',
    majorColorName: 'Blue',
    colorName: 'Swedish Blue',
    r: 0,
    g: 126,
    b: 177,
  },
  {
    pantoneId: 1575,
    name: '18-4334 TCX',
    majorColorName: 'Blue',
    colorName: 'Mediterranian Blue',
    r: 20,
    g: 120,
    b: 167,
  },
  {
    pantoneId: 1576,
    name: '18-4417 TCX',
    majorColorName: 'Blue',
    colorName: 'Tapestry',
    r: 67,
    g: 101,
    b: 115,
  },
  {
    pantoneId: 1577,
    name: '18-4432 TCX',
    majorColorName: 'Blue',
    colorName: 'Turkish Tile',
    r: 0,
    g: 105,
    b: 139,
  },
  {
    pantoneId: 1578,
    name: '18-4440 TCX',
    majorColorName: 'Blue',
    colorName: 'Cloisonne',
    r: 0,
    g: 117,
    b: 175,
  },
  {
    pantoneId: 1579,
    name: '18-4510 TCX',
    majorColorName: 'Blue',
    colorName: 'Trooper',
    r: 105,
    g: 122,
    b: 126,
  },
  {
    pantoneId: 1580,
    name: '18-4522 TCX',
    majorColorName: 'Blue',
    colorName: 'Colonial Blue',
    r: 45,
    g: 100,
    b: 113,
  },
  {
    pantoneId: 1581,
    name: '18-4525 TCX',
    majorColorName: 'Blue',
    colorName: 'Caribbean Sea',
    r: 0,
    g: 129,
    b: 157,
  },
  {
    pantoneId: 1582,
    name: '18-4528 TCX',
    majorColorName: 'Blue',
    colorName: 'Mosaic Blue',
    r: 0,
    g: 117,
    b: 143,
  },
  {
    pantoneId: 1583,
    name: '18-4530 TCX',
    majorColorName: 'Blue',
    colorName: 'Celestial',
    r: 0,
    g: 99,
    b: 128,
  },
  {
    pantoneId: 1584,
    name: '18-4535 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Jewel',
    r: 0,
    g: 123,
    b: 170,
  },
  {
    pantoneId: 1585,
    name: '18-4537 TCX',
    majorColorName: 'Blue',
    colorName: 'Methyl Blue',
    r: 0,
    g: 116,
    b: 168,
  },
  {
    pantoneId: 1586,
    name: '18-4612 TCX',
    majorColorName: 'Blue',
    colorName: 'North Atlantic',
    r: 83,
    g: 109,
    b: 112,
  },
  {
    pantoneId: 1587,
    name: '18-4711 TCX',
    majorColorName: 'Blue',
    colorName: 'Stormy Sea',
    r: 110,
    g: 128,
    b: 130,
  },
  {
    pantoneId: 1588,
    name: '18-4718 TCX',
    majorColorName: 'Blue',
    colorName: 'Hydro',
    r: 66,
    g: 105,
    b: 114,
  },
  {
    pantoneId: 1589,
    name: '18-4726 TCX',
    majorColorName: 'Blue',
    colorName: 'Biscay Bay',
    r: 9,
    g: 121,
    b: 136,
  },
  {
    pantoneId: 1590,
    name: '18-4728 TCX',
    majorColorName: 'Blue',
    colorName: 'Harbor Blue',
    r: 0,
    g: 101,
    b: 110,
  },
  {
    pantoneId: 1591,
    name: '18-4733 TCX',
    majorColorName: 'Blue',
    colorName: 'Enamel Blue',
    r: 0,
    g: 122,
    b: 142,
  },
  {
    pantoneId: 1592,
    name: '18-4735 TCX',
    majorColorName: 'Blue',
    colorName: 'Tile Blue',
    r: 0,
    g: 132,
    b: 145,
  },
  {
    pantoneId: 1593,
    name: '18-4834 TCX',
    majorColorName: 'Blue',
    colorName: 'Deep Lake',
    r: 0,
    g: 101,
    b: 107,
  },
  {
    pantoneId: 1594,
    name: '18-4930 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Tropical Green',
    r: 0,
    g: 135,
    b: 134,
  },
  {
    pantoneId: 1595,
    name: '18-4936 TCX',
    majorColorName: 'Blue',
    colorName: 'Fanfare',
    r: 0,
    g: 109,
    b: 112,
  },
  {
    pantoneId: 1596,
    name: '18-5020 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Parasailing',
    r: 0,
    g: 115,
    b: 108,
  },
  {
    pantoneId: 1597,
    name: '18-5105 TCX',
    majorColorName: 'Gray',
    colorName: 'Sedona Sage',
    r: 104,
    g: 109,
    b: 108,
  },
  {
    pantoneId: 1598,
    name: '18-5112 TCX',
    majorColorName: 'Blue-green',
    colorName: 'Sea Pine',
    r: 76,
    g: 105,
    b: 105,
  },
  {
    pantoneId: 1599,
    name: '18-5115 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'North Sea',
    r: 49,
    g: 108,
    b: 107,
  },
  {
    pantoneId: 1600,
    name: '18-5121 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Bayou',
    r: 32,
    g: 112,
    b: 111,
  },
  {
    pantoneId: 1601,
    name: '18-5128 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Blue Grass',
    r: 0,
    g: 124,
    b: 122,
  },
  {
    pantoneId: 1602,
    name: '18-5203 TCX',
    majorColorName: 'Gray',
    colorName: 'Pewter',
    r: 102,
    g: 101,
    b: 100,
  },
  {
    pantoneId: 1603,
    name: '18-5308 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Blue Spruce',
    r: 72,
    g: 107,
    b: 103,
  },
  {
    pantoneId: 1604,
    name: '18-5315 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Bayberry',
    r: 37,
    g: 89,
    b: 88,
  },
  {
    pantoneId: 1605,
    name: '18-5322 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Alpine Green',
    r: 0,
    g: 95,
    b: 86,
  },
  {
    pantoneId: 1606,
    name: '18-5338 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Ultramarine Green',
    r: 0,
    g: 107,
    b: 84,
  },
  {
    pantoneId: 1607,
    name: '18-5410 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Silver Pine',
    r: 78,
    g: 104,
    b: 102,
  },
  {
    pantoneId: 1608,
    name: '18-5418 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Antique Green',
    r: 41,
    g: 103,
    b: 92,
  },
  {
    pantoneId: 1609,
    name: '18-5424 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Cadmium Green',
    r: 0,
    g: 103,
    b: 91,
  },
  {
    pantoneId: 1610,
    name: '18-5606 TCX',
    majorColorName: 'Gray',
    colorName: 'Balsam Green',
    r: 87,
    g: 102,
    b: 100,
  },
  {
    pantoneId: 1611,
    name: '18-5610 TCX',
    majorColorName: 'Blue',
    colorName: 'Brittany Blue',
    r: 76,
    g: 126,
    b: 134,
  },
  {
    pantoneId: 1612,
    name: '18-5611 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Dark Forest',
    r: 85,
    g: 105,
    b: 98,
  },
  {
    pantoneId: 1613,
    name: '18-5612 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Sagebrush Green',
    r: 86,
    g: 117,
    b: 114,
  },
  {
    pantoneId: 1614,
    name: '18-5616 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Posy Green',
    r: 50,
    g: 91,
    b: 81,
  },
  {
    pantoneId: 1615,
    name: '18-5618 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Deep Jungle',
    r: 54,
    g: 113,
    b: 111,
  },
  {
    pantoneId: 1616,
    name: '18-5619 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Tidepool',
    r: 10,
    g: 111,
    b: 105,
  },
  {
    pantoneId: 1617,
    name: '18-5620 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Ivy',
    r: 34,
    g: 108,
    b: 99,
  },
  {
    pantoneId: 1618,
    name: '18-5621 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Fir',
    r: 58,
    g: 114,
    b: 95,
  },
  {
    pantoneId: 1619,
    name: '18-5622 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Frosty Spruce',
    r: 87,
    g: 130,
    b: 112,
  },
  {
    pantoneId: 1620,
    name: '18-5624 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Shady Glade',
    r: 0,
    g: 110,
    b: 91,
  },
  {
    pantoneId: 1621,
    name: '18-5633 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Bosphorus',
    r: 0,
    g: 117,
    b: 88,
  },
  {
    pantoneId: 1622,
    name: '18-5642 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Golf Green',
    r: 0,
    g: 135,
    b: 99,
  },
  {
    pantoneId: 1623,
    name: '18-5718 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Smoke Pine',
    r: 62,
    g: 98,
    b: 87,
  },
  {
    pantoneId: 1624,
    name: '18-5725 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Galapagos Green',
    r: 41,
    g: 104,
    b: 95,
  },
  {
    pantoneId: 1625,
    name: '18-5841 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Pepper Green',
    r: 0,
    g: 125,
    b: 96,
  },
  {
    pantoneId: 1626,
    name: '18-5913 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Garden Topiary',
    r: 62,
    g: 82,
    b: 75,
  },
  {
    pantoneId: 1627,
    name: '18-6011 TCX',
    majorColorName: 'Green',
    colorName: 'Duck Green',
    r: 83,
    g: 102,
    b: 92,
  },
  {
    pantoneId: 1628,
    name: '18-6018 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Foliage Green',
    r: 62,
    g: 111,
    b: 88,
  },
  {
    pantoneId: 1629,
    name: '18-6022 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Leprechaun',
    r: 55,
    g: 134,
    b: 97,
  },
  {
    pantoneId: 1630,
    name: '18-6024 TCX',
    majorColorName: 'Green',
    colorName: 'Amazon',
    r: 31,
    g: 115,
    b: 73,
  },
  {
    pantoneId: 1631,
    name: '18-6114 TCX',
    majorColorName: 'Green',
    colorName: 'Myrtle',
    r: 79,
    g: 107,
    b: 88,
  },
  {
    pantoneId: 1632,
    name: '18-6216 TCX',
    majorColorName: 'Green',
    colorName: 'Comfrey',
    r: 91,
    g: 121,
    b: 97,
  },
  {
    pantoneId: 1633,
    name: '18-6320 TCX',
    majorColorName: 'Green',
    colorName: 'Fairway',
    r: 71,
    g: 112,
    b: 80,
  },
  {
    pantoneId: 1634,
    name: '18-6330 TCX',
    majorColorName: 'Green',
    colorName: 'Juniper',
    r: 61,
    g: 114,
    b: 69,
  },
  {
    pantoneId: 1635,
    name: '19-0000 TCX',
    majorColorName: 'Gray',
    colorName: 'Raven',
    r: 65,
    g: 62,
    b: 61,
  },
  {
    pantoneId: 1636,
    name: '19-0230 TCX',
    majorColorName: 'Green',
    colorName: 'Garden Green',
    r: 73,
    g: 94,
    b: 53,
  },
  {
    pantoneId: 1637,
    name: '19-0303 TCX',
    majorColorName: 'Gray',
    colorName: 'Jet Black',
    r: 45,
    g: 44,
    b: 47,
  },
  {
    pantoneId: 1638,
    name: '19-0309 TCX',
    majorColorName: 'Gray',
    colorName: 'Thyme',
    r: 80,
    g: 87,
    b: 76,
  },
  {
    pantoneId: 1639,
    name: '19-0312 TCX',
    majorColorName: 'Gray',
    colorName: 'Beetle',
    r: 85,
    g: 88,
    b: 76,
  },
  {
    pantoneId: 1640,
    name: '19-0315 TCX',
    majorColorName: 'Green',
    colorName: 'Black Forest',
    r: 65,
    g: 79,
    b: 60,
  },
  {
    pantoneId: 1641,
    name: '19-0323 TCX',
    majorColorName: 'Green',
    colorName: 'Chive',
    r: 74,
    g: 83,
    b: 53,
  },
  {
    pantoneId: 1642,
    name: '19-0405 TCX',
    majorColorName: 'Gray',
    colorName: 'Beluga',
    r: 74,
    g: 72,
    b: 67,
  },
  {
    pantoneId: 1643,
    name: '19-0414 TCX',
    majorColorName: 'Yellow',
    colorName: 'Forest Night',
    r: 67,
    g: 66,
    b: 55,
  },
  {
    pantoneId: 1644,
    name: '19-0415 TCX',
    majorColorName: 'Green',
    colorName: 'Duffel Bag',
    r: 57,
    g: 64,
    b: 52,
  },
  {
    pantoneId: 1645,
    name: '19-0417 TCX',
    majorColorName: 'Green',
    colorName: 'Kombu Green',
    r: 58,
    g: 64,
    b: 50,
  },
  {
    pantoneId: 1646,
    name: '19-0419 TCX',
    majorColorName: 'Green',
    colorName: 'Rifle Green',
    r: 65,
    g: 72,
    b: 50,
  },
  {
    pantoneId: 1647,
    name: '19-0506 TCX',
    majorColorName: 'Gray',
    colorName: 'Black Ink',
    r: 68,
    g: 65,
    b: 60,
  },
  {
    pantoneId: 1648,
    name: '19-0508 TCX',
    majorColorName: 'Gray',
    colorName: 'Peat',
    r: 59,
    g: 58,
    b: 54,
  },
  {
    pantoneId: 1649,
    name: '19-0509 TCX',
    majorColorName: 'Yellow',
    colorName: 'Rosin',
    r: 54,
    g: 54,
    b: 45,
  },
  {
    pantoneId: 1650,
    name: '19-0511 TCX',
    majorColorName: 'Yellow',
    colorName: 'Grape Leaf',
    r: 84,
    g: 81,
    b: 68,
  },
  {
    pantoneId: 1651,
    name: '19-0512 TCX',
    majorColorName: 'Yellow',
    colorName: 'Ivy Green',
    r: 88,
    g: 84,
    b: 66,
  },
  {
    pantoneId: 1652,
    name: '19-0515 TCX',
    majorColorName: 'Yellow',
    colorName: 'Olive Night',
    r: 83,
    g: 80,
    b: 64,
  },
  {
    pantoneId: 1653,
    name: '19-0516 TCX',
    majorColorName: 'Brown',
    colorName: 'Dark Olive',
    r: 87,
    g: 77,
    b: 53,
  },
  {
    pantoneId: 1654,
    name: '19-0608 TCX',
    majorColorName: 'Brown',
    colorName: 'Black Olive',
    r: 72,
    g: 65,
    b: 59,
  },
  {
    pantoneId: 1655,
    name: '19-0614 TCX',
    majorColorName: 'Brown',
    colorName: 'Wren',
    r: 74,
    g: 65,
    b: 57,
  },
  {
    pantoneId: 1656,
    name: '19-0617 TCX',
    majorColorName: 'Brown',
    colorName: 'Teak',
    r: 101,
    g: 83,
    b: 65,
  },
  {
    pantoneId: 1657,
    name: '19-0618 TCX',
    majorColorName: 'Brown',
    colorName: 'Beech',
    r: 91,
    g: 79,
    b: 59,
  },
  {
    pantoneId: 1658,
    name: '19-0622 TCX',
    majorColorName: 'Brown',
    colorName: 'Military Olive',
    r: 99,
    g: 86,
    b: 59,
  },
  {
    pantoneId: 1659,
    name: '19-0712 TCX',
    majorColorName: 'Brown',
    colorName: 'Demitasse',
    r: 64,
    g: 52,
    b: 43,
  },
  {
    pantoneId: 1660,
    name: '19-0809 TCX',
    majorColorName: 'Brown',
    colorName: 'Chocolate Chip',
    r: 104,
    g: 90,
    b: 78,
  },
  {
    pantoneId: 1661,
    name: '19-0810 TCX',
    majorColorName: 'Brown',
    colorName: 'Major Brown',
    r: 91,
    g: 81,
    b: 73,
  },
  {
    pantoneId: 1662,
    name: '19-0812 TCX',
    majorColorName: 'Brown',
    colorName: 'Turkish Coffee',
    r: 72,
    g: 63,
    b: 57,
  },
  {
    pantoneId: 1663,
    name: '19-0814 TCX',
    majorColorName: 'Brown',
    colorName: 'Slate Black',
    r: 75,
    g: 61,
    b: 51,
  },
  {
    pantoneId: 1664,
    name: '19-0815 TCX',
    majorColorName: 'Brown',
    colorName: 'Desert Palm',
    r: 90,
    g: 70,
    b: 50,
  },
  {
    pantoneId: 1665,
    name: '19-0820 TCX',
    majorColorName: 'Brown',
    colorName: 'Canteen',
    r: 94,
    g: 83,
    b: 71,
  },
  {
    pantoneId: 1666,
    name: '19-0822 TCX',
    majorColorName: 'Brown',
    colorName: 'Tarmac',
    r: 90,
    g: 83,
    b: 72,
  },
  {
    pantoneId: 1667,
    name: '19-0912 TCX',
    majorColorName: 'Brown',
    colorName: 'Chocolate Brown',
    r: 78,
    g: 64,
    b: 59,
  },
  {
    pantoneId: 1668,
    name: '19-0915 TCX',
    majorColorName: 'Pink',
    colorName: 'Coffee Bean',
    r: 64,
    g: 49,
    b: 47,
  },
  {
    pantoneId: 1669,
    name: '19-1012 TCX',
    majorColorName: 'Pink',
    colorName: 'French Roast',
    r: 88,
    g: 66,
    b: 63,
  },
  {
    pantoneId: 1670,
    name: '19-1015 TCX',
    majorColorName: 'Brown',
    colorName: 'Bracken',
    r: 79,
    g: 63,
    b: 59,
  },
  {
    pantoneId: 1671,
    name: '19-1016 TCX',
    majorColorName: 'Pink',
    colorName: 'Java',
    r: 67,
    g: 51,
    b: 49,
  },
  {
    pantoneId: 1672,
    name: '19-1020 TCX',
    majorColorName: 'Brown',
    colorName: 'Dark Earth',
    r: 92,
    g: 73,
    b: 57,
  },
  {
    pantoneId: 1673,
    name: '19-1034 TCX',
    majorColorName: 'Brown',
    colorName: 'Breen',
    r: 121,
    g: 93,
    b: 52,
  },
  {
    pantoneId: 1674,
    name: '19-1101 TCX',
    majorColorName: 'Gray',
    colorName: 'After Dark',
    r: 60,
    g: 53,
    b: 53,
  },
  {
    pantoneId: 1675,
    name: '19-1102 TCX',
    majorColorName: 'Gray',
    colorName: 'Licorice',
    r: 58,
    g: 53,
    b: 54,
  },
  {
    pantoneId: 1676,
    name: '19-1111 TCX',
    majorColorName: 'Pink',
    colorName: 'Black Coffee',
    r: 59,
    g: 48,
    b: 47,
  },
  {
    pantoneId: 1677,
    name: '19-1116 TCX',
    majorColorName: 'Brown',
    colorName: 'Carafe',
    r: 93,
    g: 71,
    b: 58,
  },
  {
    pantoneId: 1678,
    name: '19-1118 TCX',
    majorColorName: 'Brown',
    colorName: 'Chestnut',
    r: 88,
    g: 64,
    b: 57,
  },
  {
    pantoneId: 1679,
    name: '19-1121 TCX',
    majorColorName: 'Brown',
    colorName: 'Pinecone',
    r: 97,
    g: 71,
    b: 59,
  },
  {
    pantoneId: 1680,
    name: '19-1213 TCX',
    majorColorName: 'Pink',
    colorName: 'Shopping Bag',
    r: 90,
    g: 71,
    b: 67,
  },
  {
    pantoneId: 1681,
    name: '19-1217 TCX',
    majorColorName: 'Brown',
    colorName: 'Mustang',
    r: 104,
    g: 75,
    b: 64,
  },
  {
    pantoneId: 1682,
    name: '19-1218 TCX',
    majorColorName: 'Brown',
    colorName: 'Potting Soil',
    r: 84,
    g: 57,
    b: 45,
  },
  {
    pantoneId: 1683,
    name: '19-1220 TCX',
    majorColorName: 'Brown',
    colorName: 'Cappuccino',
    r: 99,
    g: 63,
    b: 51,
  },
  {
    pantoneId: 1684,
    name: '19-1228 TCX',
    majorColorName: 'Brown',
    colorName: 'Root Beer',
    r: 113,
    g: 74,
    b: 65,
  },
  {
    pantoneId: 1685,
    name: '19-1230 TCX',
    majorColorName: 'Brown',
    colorName: 'Friar Brown',
    r: 110,
    g: 73,
    b: 58,
  },
  {
    pantoneId: 1686,
    name: '19-1235 TCX',
    majorColorName: 'Brown',
    colorName: 'Brunette',
    r: 102,
    g: 66,
    b: 56,
  },
  {
    pantoneId: 1687,
    name: '19-1241 TCX',
    majorColorName: 'Brown',
    colorName: 'Tortoise Shell',
    r: 117,
    g: 71,
    b: 52,
  },
  {
    pantoneId: 1688,
    name: '19-1245 TCX',
    majorColorName: 'Brown',
    colorName: 'Arabian Spice',
    r: 136,
    g: 67,
    b: 50,
  },
  {
    pantoneId: 1689,
    name: '19-1250 TCX',
    majorColorName: 'Brown',
    colorName: 'Picante',
    r: 141,
    g: 63,
    b: 45,
  },
  {
    pantoneId: 1690,
    name: '19-1314 TCX',
    majorColorName: 'Pink',
    colorName: 'Seal Brown',
    r: 73,
    g: 59,
    b: 57,
  },
  {
    pantoneId: 1691,
    name: '19-1317 TCX',
    majorColorName: 'Pink',
    colorName: 'Bitter Chocolate',
    r: 80,
    g: 49,
    b: 48,
  },
  {
    pantoneId: 1692,
    name: '19-1320 TCX',
    majorColorName: 'Pink',
    colorName: 'Sable',
    r: 110,
    g: 64,
    b: 60,
  },
  {
    pantoneId: 1693,
    name: '19-1321 TCX',
    majorColorName: 'Pink',
    colorName: 'Rum Raisin',
    r: 88,
    g: 52,
    b: 50,
  },
  {
    pantoneId: 1694,
    name: '19-1322 TCX',
    majorColorName: 'Pink',
    colorName: 'Brown Stone',
    r: 89,
    g: 60,
    b: 57,
  },
  {
    pantoneId: 1695,
    name: '19-1325 TCX',
    majorColorName: 'Pink',
    colorName: 'Hot Chocolate',
    r: 104,
    g: 59,
    b: 57,
  },
  {
    pantoneId: 1696,
    name: '19-1327 TCX',
    majorColorName: 'Pink',
    colorName: 'Andorra',
    r: 96,
    g: 53,
    b: 53,
  },
  {
    pantoneId: 1697,
    name: '19-1331 TCX',
    majorColorName: 'Pink',
    colorName: 'Madder Brown',
    r: 106,
    g: 51,
    b: 49,
  },
  {
    pantoneId: 1698,
    name: '19-1333 TCX',
    majorColorName: 'Brown',
    colorName: 'Sequoia',
    r: 128,
    g: 72,
    b: 57,
  },
  {
    pantoneId: 1699,
    name: '19-1334 TCX',
    majorColorName: 'Pink',
    colorName: 'Henna',
    r: 124,
    g: 66,
    b: 60,
  },
  {
    pantoneId: 1700,
    name: '19-1338 TCX',
    majorColorName: 'Pink',
    colorName: 'Russet Brown',
    r: 116,
    g: 51,
    b: 50,
  },
  {
    pantoneId: 1701,
    name: '19-1420 TCX',
    majorColorName: 'Pink',
    colorName: 'Deep Mahogany',
    r: 85,
    g: 59,
    b: 57,
  },
  {
    pantoneId: 1702,
    name: '19-1430 TCX',
    majorColorName: 'Brown',
    colorName: 'Mink',
    r: 115,
    g: 75,
    b: 66,
  },
  {
    pantoneId: 1703,
    name: '19-1431 TCX',
    majorColorName: 'Pink',
    colorName: 'Fudgesickle',
    r: 99,
    g: 64,
    b: 58,
  },
  {
    pantoneId: 1704,
    name: '19-1436 TCX',
    majorColorName: 'Pink',
    colorName: 'Cinnamon',
    r: 107,
    g: 65,
    b: 57,
  },
  {
    pantoneId: 1705,
    name: '19-1518 TCX',
    majorColorName: 'Pink',
    colorName: 'Puce',
    r: 80,
    g: 57,
    b: 56,
  },
  {
    pantoneId: 1706,
    name: '19-1521 TCX',
    majorColorName: 'Pink',
    colorName: 'Red Mahogany',
    r: 96,
    g: 55,
    b: 61,
  },
  {
    pantoneId: 1707,
    name: '19-1522 TCX',
    majorColorName: 'Pink',
    colorName: 'Zinfandel',
    r: 92,
    g: 41,
    b: 53,
  },
  {
    pantoneId: 1708,
    name: '19-1524 TCX',
    majorColorName: 'Pink',
    colorName: 'Oxblood Red',
    r: 112,
    g: 57,
    b: 63,
  },
  {
    pantoneId: 1709,
    name: '19-1525 TCX',
    majorColorName: 'Pink',
    colorName: 'Port',
    r: 102,
    g: 51,
    b: 54,
  },
  {
    pantoneId: 1710,
    name: '19-1526 TCX',
    majorColorName: 'Pink',
    colorName: 'Chocolate Truffle',
    r: 97,
    g: 46,
    b: 53,
  },
  {
    pantoneId: 1711,
    name: '19-1528 TCX',
    majorColorName: 'Pink',
    colorName: 'Windsor Wine',
    r: 88,
    g: 43,
    b: 54,
  },
  {
    pantoneId: 1712,
    name: '19-1530 TCX',
    majorColorName: 'Pink',
    colorName: 'Burnt Russet',
    r: 126,
    g: 57,
    b: 64,
  },
  {
    pantoneId: 1713,
    name: '19-1532 TCX',
    majorColorName: 'Pink',
    colorName: 'Rosewood',
    r: 129,
    g: 54,
    b: 57,
  },
  {
    pantoneId: 1714,
    name: '19-1533 TCX',
    majorColorName: 'Pink',
    colorName: 'Cowhide',
    r: 136,
    g: 67,
    b: 68,
  },
  {
    pantoneId: 1715,
    name: '19-1540 TCX',
    majorColorName: 'Pink',
    colorName: 'Burnt Henna',
    r: 126,
    g: 57,
    b: 47,
  },
  {
    pantoneId: 1716,
    name: '19-1543 TCX',
    majorColorName: 'Pink',
    colorName: 'Brick Red',
    r: 140,
    g: 55,
    b: 62,
  },
  {
    pantoneId: 1717,
    name: '19-1557 TCX',
    majorColorName: 'Pink',
    colorName: 'Chili Pepper',
    r: 155,
    g: 27,
    b: 48,
  },
  {
    pantoneId: 1718,
    name: '19-1606 TCX',
    majorColorName: 'Pink',
    colorName: 'Raisin',
    r: 82,
    g: 65,
    b: 68,
  },
  {
    pantoneId: 1719,
    name: '19-1608 TCX',
    majorColorName: 'Pink',
    colorName: 'Prune Purple',
    r: 92,
    g: 58,
    b: 77,
  },
  {
    pantoneId: 1720,
    name: '19-1617 TCX',
    majorColorName: 'Pink',
    colorName: 'Burgundy',
    r: 100,
    g: 49,
    b: 62,
  },
  {
    pantoneId: 1721,
    name: '19-1619 TCX',
    majorColorName: 'Pink',
    colorName: 'Fudge',
    r: 73,
    g: 51,
    b: 56,
  },
  {
    pantoneId: 1722,
    name: '19-1620 TCX',
    majorColorName: 'Pink',
    colorName: 'Huckleberry',
    r: 91,
    g: 67,
    b: 73,
  },
  {
    pantoneId: 1723,
    name: '19-1621 TCX',
    majorColorName: 'Pink',
    colorName: 'Catawba Grape',
    r: 93,
    g: 60,
    b: 67,
  },
  {
    pantoneId: 1724,
    name: '19-1623 TCX',
    majorColorName: 'Pink',
    colorName: 'Vineyard Wine',
    r: 88,
    g: 54,
    b: 61,
  },
  {
    pantoneId: 1725,
    name: '19-1624 TCX',
    majorColorName: 'Pink',
    colorName: 'Sassafras',
    r: 84,
    g: 53,
    b: 59,
  },
  {
    pantoneId: 1726,
    name: '19-1625 TCX',
    majorColorName: 'Pink',
    colorName: 'Decadent Chocolate',
    r: 81,
    g: 50,
    b: 53,
  },
  {
    pantoneId: 1727,
    name: '19-1627 TCX',
    majorColorName: 'Pink',
    colorName: 'Port Royale',
    r: 80,
    g: 43,
    b: 51,
  },
  {
    pantoneId: 1728,
    name: '19-1629 TCX',
    majorColorName: 'Pink',
    colorName: 'Ruby Wine',
    r: 119,
    g: 51,
    b: 59,
  },
  {
    pantoneId: 1729,
    name: '19-1650 TCX',
    majorColorName: 'Pink',
    colorName: 'Biking Red',
    r: 119,
    g: 33,
    b: 46,
  },
  {
    pantoneId: 1730,
    name: '19-1655 TCX',
    majorColorName: 'Pink',
    colorName: 'Garnet',
    r: 149,
    g: 54,
    b: 64,
  },
  {
    pantoneId: 1731,
    name: '19-1656 TCX',
    majorColorName: 'Pink',
    colorName: 'Rio Red',
    r: 138,
    g: 34,
    b: 50,
  },
  {
    pantoneId: 1732,
    name: '19-1663 TCX',
    majorColorName: 'Red',
    colorName: 'Ribbon Red',
    r: 185,
    g: 38,
    b: 54,
  },
  {
    pantoneId: 1733,
    name: '19-1664 TCX',
    majorColorName: 'Pink',
    colorName: 'True Red',
    r: 191,
    g: 25,
    b: 50,
  },
  {
    pantoneId: 1734,
    name: '19-1716 TCX',
    majorColorName: 'Pink',
    colorName: 'Mauve Wine',
    r: 91,
    g: 54,
    b: 68,
  },
  {
    pantoneId: 1735,
    name: '19-1718 TCX',
    majorColorName: 'Pink',
    colorName: 'Fig',
    r: 83,
    g: 45,
    b: 59,
  },
  {
    pantoneId: 1736,
    name: '19-1725 TCX',
    majorColorName: 'Pink',
    colorName: 'Tawny Port',
    r: 92,
    g: 44,
    b: 53,
  },
  {
    pantoneId: 1737,
    name: '19-1726 TCX',
    majorColorName: 'Pink',
    colorName: 'Cordovan',
    r: 112,
    g: 47,
    b: 59,
  },
  {
    pantoneId: 1738,
    name: '19-1759 TCX',
    majorColorName: 'Pink',
    colorName: 'American Beauty',
    r: 167,
    g: 51,
    b: 64,
  },
  {
    pantoneId: 1739,
    name: '19-1760 TCX',
    majorColorName: 'Red',
    colorName: 'Scarlet',
    r: 188,
    g: 43,
    b: 61,
  },
  {
    pantoneId: 1740,
    name: '19-1761 TCX',
    majorColorName: 'Pink',
    colorName: 'Tango Red',
    r: 172,
    g: 14,
    b: 46,
  },
  {
    pantoneId: 1741,
    name: '19-1762 TCX',
    majorColorName: 'Pink',
    colorName: 'Crimson',
    r: 174,
    g: 14,
    b: 54,
  },
  {
    pantoneId: 1742,
    name: '19-1763 TCX',
    majorColorName: 'Red',
    colorName: 'Racing Red',
    r: 189,
    g: 22,
    b: 44,
  },
  {
    pantoneId: 1743,
    name: '19-1764 TCX',
    majorColorName: 'Pink',
    colorName: 'Lipstick Red',
    r: 179,
    g: 26,
    b: 56,
  },
  {
    pantoneId: 1744,
    name: '19-1840 TCX',
    majorColorName: 'Pink',
    colorName: 'Deep Claret',
    r: 151,
    g: 52,
    b: 67,
  },
  {
    pantoneId: 1745,
    name: '19-1850 TCX',
    majorColorName: 'Pink',
    colorName: 'Red Bud',
    r: 150,
    g: 45,
    b: 73,
  },
  {
    pantoneId: 1746,
    name: '19-1860 TCX',
    majorColorName: 'Pink',
    colorName: 'Persian Red',
    r: 162,
    g: 20,
    b: 65,
  },
  {
    pantoneId: 1747,
    name: '19-1862 TCX',
    majorColorName: 'Pink',
    colorName: 'Jester Red',
    r: 158,
    g: 16,
    b: 48,
  },
  {
    pantoneId: 1748,
    name: '19-1934 TCX',
    majorColorName: 'Pink',
    colorName: 'Tibetan Red',
    r: 120,
    g: 42,
    b: 57,
  },
  {
    pantoneId: 1749,
    name: '19-1940 TCX',
    majorColorName: 'Pink',
    colorName: 'Rumba Red',
    r: 124,
    g: 36,
    b: 57,
  },
  {
    pantoneId: 1750,
    name: '19-1955 TCX',
    majorColorName: 'Pink',
    colorName: 'Cerise',
    r: 164,
    g: 18,
    b: 71,
  },
  {
    pantoneId: 1751,
    name: '19-2009 TCX',
    majorColorName: 'Pink',
    colorName: 'Hortensia',
    r: 85,
    g: 59,
    b: 80,
  },
  {
    pantoneId: 1752,
    name: '19-2014 TCX',
    majorColorName: 'Pink',
    colorName: 'Prune',
    r: 96,
    g: 55,
    b: 73,
  },
  {
    pantoneId: 1753,
    name: '19-2024 TCX',
    majorColorName: 'Pink',
    colorName: 'Rhododendron',
    r: 114,
    g: 43,
    b: 63,
  },
  {
    pantoneId: 1754,
    name: '19-2025 TCX',
    majorColorName: 'Pink',
    colorName: 'Red Plum',
    r: 124,
    g: 41,
    b: 70,
  },
  {
    pantoneId: 1755,
    name: '19-2030 TCX',
    majorColorName: 'Pink',
    colorName: 'Beet Red',
    r: 122,
    g: 31,
    b: 61,
  },
  {
    pantoneId: 1756,
    name: '19-2033 TCX',
    majorColorName: 'Pink',
    colorName: 'Anemone',
    r: 132,
    g: 44,
    b: 72,
  },
  {
    pantoneId: 1757,
    name: '19-2047 TCX',
    majorColorName: 'Pink',
    colorName: 'Sangria',
    r: 152,
    g: 37,
    b: 81,
  },
  {
    pantoneId: 1758,
    name: '19-2118 TCX',
    majorColorName: 'Pink',
    colorName: 'Winetasting',
    r: 73,
    g: 42,
    b: 52,
  },
  {
    pantoneId: 1759,
    name: '19-2311 TCX',
    majorColorName: 'Pink',
    colorName: 'Eggplant',
    r: 97,
    g: 63,
    b: 76,
  },
  {
    pantoneId: 1760,
    name: '19-2312 TCX',
    majorColorName: 'Pink',
    colorName: 'Crushed Violets',
    r: 100,
    g: 58,
    b: 76,
  },
  {
    pantoneId: 1761,
    name: '19-2315 TCX',
    majorColorName: 'Pink',
    colorName: 'Grape Wine',
    r: 90,
    g: 47,
    b: 67,
  },
  {
    pantoneId: 1762,
    name: '19-2410 TCX',
    majorColorName: 'Pink',
    colorName: 'Amaranth',
    r: 111,
    g: 60,
    b: 86,
  },
  {
    pantoneId: 1763,
    name: '19-2428 TCX',
    majorColorName: 'Pink',
    colorName: 'Magenta Purple',
    r: 107,
    g: 38,
    b: 75,
  },
  {
    pantoneId: 1764,
    name: '19-2430 TCX',
    majorColorName: 'Pink',
    colorName: 'Purple Potion',
    r: 105,
    g: 39,
    b: 70,
  },
  {
    pantoneId: 1765,
    name: '19-2431 TCX',
    majorColorName: 'Pink',
    colorName: 'Boysenberry',
    r: 133,
    g: 50,
    b: 92,
  },
  {
    pantoneId: 1766,
    name: '19-2432 TCX',
    majorColorName: 'Pink',
    colorName: 'Raspberry Radiance',
    r: 128,
    g: 42,
    b: 80,
  },
  {
    pantoneId: 1767,
    name: '19-2434 TCX',
    majorColorName: 'Pink',
    colorName: 'Festival Fuchsia',
    r: 158,
    g: 44,
    b: 106,
  },
  {
    pantoneId: 1768,
    name: '19-2514 TCX',
    majorColorName: 'Pink',
    colorName: 'Italian Plum',
    r: 83,
    g: 49,
    b: 70,
  },
  {
    pantoneId: 1769,
    name: '19-2520 TCX',
    majorColorName: 'Pink',
    colorName: 'Potent Purple',
    r: 70,
    g: 38,
    b: 57,
  },
  {
    pantoneId: 1770,
    name: '19-2524 TCX',
    majorColorName: 'Pink',
    colorName: 'Dark Purple',
    r: 88,
    g: 33,
    b: 71,
  },
  {
    pantoneId: 1771,
    name: '19-2630 TCX',
    majorColorName: 'Pink',
    colorName: 'Wild Aster',
    r: 146,
    g: 49,
    b: 111,
  },
  {
    pantoneId: 1772,
    name: '19-2814 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Wineberry',
    r: 90,
    g: 57,
    b: 91,
  },
  {
    pantoneId: 1773,
    name: '19-2816 TCX',
    majorColorName: 'Pink',
    colorName: 'Blackberry Wine',
    r: 77,
    g: 50,
    b: 70,
  },
  {
    pantoneId: 1774,
    name: '19-2820 TCX',
    majorColorName: 'Pink',
    colorName: 'Phlox',
    r: 105,
    g: 45,
    b: 93,
  },
  {
    pantoneId: 1775,
    name: '19-2924 TCX',
    majorColorName: 'Pink',
    colorName: 'Hollyhock',
    r: 130,
    g: 50,
    b: 112,
  },
  {
    pantoneId: 1776,
    name: '19-3022 TCX',
    majorColorName: 'Purple',
    colorName: 'Gloxinia',
    r: 98,
    g: 46,
    b: 90,
  },
  {
    pantoneId: 1777,
    name: '19-3138 TCX',
    majorColorName: 'Purple',
    colorName: 'Byzantium',
    r: 133,
    g: 59,
    b: 123,
  },
  {
    pantoneId: 1778,
    name: '19-3215 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Indigo',
    r: 76,
    g: 57,
    b: 87,
  },
  {
    pantoneId: 1779,
    name: '19-3217 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Shadow Purple',
    r: 78,
    g: 51,
    b: 78,
  },
  {
    pantoneId: 1780,
    name: '19-3218 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Plum Purple',
    r: 81,
    g: 48,
    b: 78,
  },
  {
    pantoneId: 1781,
    name: '19-3220 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Plum',
    r: 90,
    g: 49,
    b: 93,
  },
  {
    pantoneId: 1782,
    name: '19-3223 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Purple Passion',
    r: 104,
    g: 61,
    b: 98,
  },
  {
    pantoneId: 1783,
    name: '19-3230 TCX',
    majorColorName: 'Purple',
    colorName: 'Grape Juice',
    r: 104,
    g: 41,
    b: 97,
  },
  {
    pantoneId: 1784,
    name: '19-3316 TCX',
    majorColorName: 'Pink',
    colorName: 'Plum Perfect',
    r: 71,
    g: 52,
    b: 66,
  },
  {
    pantoneId: 1785,
    name: '19-3323 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Deep Purple',
    r: 80,
    g: 49,
    b: 76,
  },
  {
    pantoneId: 1786,
    name: '19-3325 TCX',
    majorColorName: 'Pink',
    colorName: 'Wood Violet',
    r: 117,
    g: 64,
    b: 106,
  },
  {
    pantoneId: 1787,
    name: '19-3336 TCX',
    majorColorName: 'Purple',
    colorName: 'Sparkling Grape',
    r: 119,
    g: 51,
    b: 118,
  },
  {
    pantoneId: 1788,
    name: '19-3424 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Sunset Purple',
    r: 111,
    g: 69,
    b: 110,
  },
  {
    pantoneId: 1789,
    name: '19-3438 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Bright Violet',
    r: 120,
    g: 67,
    b: 132,
  },
  {
    pantoneId: 1790,
    name: '19-3514 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Majesty',
    r: 89,
    g: 55,
    b: 97,
  },
  {
    pantoneId: 1791,
    name: '19-3518 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Grape Royale',
    r: 79,
    g: 45,
    b: 84,
  },
  {
    pantoneId: 1792,
    name: '19-3519 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Purple Pennant',
    r: 67,
    g: 44,
    b: 71,
  },
  {
    pantoneId: 1793,
    name: '19-3520 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Blackberry Cordial',
    r: 63,
    g: 42,
    b: 71,
  },
  {
    pantoneId: 1794,
    name: '19-3526 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Meadow Violet',
    r: 118,
    g: 79,
    b: 130,
  },
  {
    pantoneId: 1795,
    name: '19-3528 TCX',
    majorColorName: 'Purple',
    colorName: 'Imperial Purple',
    r: 84,
    g: 44,
    b: 93,
  },
  {
    pantoneId: 1796,
    name: '19-3536 TCX',
    majorColorName: 'Purple',
    colorName: 'Amaranth Purple',
    r: 106,
    g: 57,
    b: 123,
  },
  {
    pantoneId: 1797,
    name: '19-3540 TCX',
    majorColorName: 'Purple',
    colorName: 'Purple Magic',
    r: 102,
    g: 50,
    b: 113,
  },
  {
    pantoneId: 1798,
    name: '19-3542 TCX',
    majorColorName: 'Purple',
    colorName: 'Pansy',
    r: 101,
    g: 61,
    b: 124,
  },
  {
    pantoneId: 1799,
    name: '19-3617 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Mysterioso',
    r: 70,
    g: 57,
    b: 75,
  },
  {
    pantoneId: 1800,
    name: '19-3619 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Sweet Grape',
    r: 75,
    g: 59,
    b: 79,
  },
  {
    pantoneId: 1801,
    name: '19-3620 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Purple Reign',
    r: 86,
    g: 69,
    b: 107,
  },
  {
    pantoneId: 1802,
    name: '19-3622 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Loganberry',
    r: 90,
    g: 71,
    b: 105,
  },
  {
    pantoneId: 1803,
    name: '19-3632 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Petunia',
    r: 79,
    g: 52,
    b: 102,
  },
  {
    pantoneId: 1804,
    name: '19-3640 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Crown Jewel',
    r: 72,
    g: 45,
    b: 84,
  },
  {
    pantoneId: 1805,
    name: '19-3642 TCX',
    majorColorName: 'Purple',
    colorName: 'Royal Purple',
    r: 96,
    g: 63,
    b: 131,
  },
  {
    pantoneId: 1806,
    name: '19-3712 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Nightshade',
    r: 67,
    g: 55,
    b: 72,
  },
  {
    pantoneId: 1807,
    name: '19-3714 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Navy Cosmos',
    r: 80,
    g: 59,
    b: 83,
  },
  {
    pantoneId: 1808,
    name: '19-3716 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Purple Plumeria',
    r: 71,
    g: 56,
    b: 84,
  },
  {
    pantoneId: 1809,
    name: '19-3720 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Gothic Grape',
    r: 71,
    g: 57,
    b: 81,
  },
  {
    pantoneId: 1810,
    name: '19-3722 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Mulberry Purple',
    r: 73,
    g: 60,
    b: 98,
  },
  {
    pantoneId: 1811,
    name: '19-3725 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Purple Velvet',
    r: 65,
    g: 53,
    b: 77,
  },
  {
    pantoneId: 1812,
    name: '19-3728 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Grape',
    r: 67,
    g: 52,
    b: 85,
  },
  {
    pantoneId: 1813,
    name: '19-3730 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Gentian Violet',
    r: 84,
    g: 66,
    b: 117,
  },
  {
    pantoneId: 1814,
    name: '19-3731 TCX',
    majorColorName: 'Purple',
    colorName: 'Parachute Purple',
    r: 57,
    g: 40,
    b: 82,
  },
  {
    pantoneId: 1815,
    name: '19-3737 TCX',
    majorColorName: 'Purple',
    colorName: 'Heliotrope',
    r: 79,
    g: 56,
    b: 114,
  },
  {
    pantoneId: 1816,
    name: '19-3748 TCX',
    majorColorName: 'Purple',
    colorName: 'Prism Violet',
    r: 83,
    g: 61,
    b: 125,
  },
  {
    pantoneId: 1817,
    name: '19-3803 TCX',
    majorColorName: 'Gray',
    colorName: 'Plum Kitten',
    r: 98,
    g: 91,
    b: 92,
  },
  {
    pantoneId: 1818,
    name: '19-3810 TCX',
    majorColorName: 'Blue',
    colorName: 'Eclipse',
    r: 52,
    g: 49,
    b: 72,
  },
  {
    pantoneId: 1819,
    name: '19-3830 TCX',
    majorColorName: 'Blue',
    colorName: 'Astral Aura',
    r: 54,
    g: 49,
    b: 81,
  },
  {
    pantoneId: 1820,
    name: '19-3832 TCX',
    majorColorName: 'Blue',
    colorName: 'Navy Blue',
    r: 64,
    g: 63,
    b: 111,
  },
  {
    pantoneId: 1821,
    name: '19-3839 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Ribbon',
    r: 58,
    g: 57,
    b: 95,
  },
  {
    pantoneId: 1822,
    name: '19-3842 TCX',
    majorColorName: 'Blue',
    colorName: 'Deep Wisteria',
    r: 68,
    g: 63,
    b: 111,
  },
  {
    pantoneId: 1823,
    name: '19-3847 TCX',
    majorColorName: 'Blue',
    colorName: 'Deep Blue',
    r: 68,
    g: 55,
    b: 125,
  },
  {
    pantoneId: 1824,
    name: '19-3850 TCX',
    majorColorName: 'Blue',
    colorName: 'Liberty',
    r: 77,
    g: 68,
    b: 138,
  },
  {
    pantoneId: 1825,
    name: '19-3864 TCX',
    majorColorName: 'Blue',
    colorName: 'Mazarine Blue',
    r: 39,
    g: 60,
    b: 118,
  },
  {
    pantoneId: 1826,
    name: '19-3903 TCX',
    majorColorName: 'Gray',
    colorName: 'Shale',
    r: 74,
    g: 63,
    b: 65,
  },
  {
    pantoneId: 1827,
    name: '19-3905 TCX',
    majorColorName: 'Gray',
    colorName: 'Rabbit',
    r: 95,
    g: 87,
    b: 92,
  },
  {
    pantoneId: 1828,
    name: '19-3906 TCX',
    majorColorName: 'Gray',
    colorName: 'Dark Shadow',
    r: 74,
    g: 75,
    b: 77,
  },
  {
    pantoneId: 1829,
    name: '19-3908 TCX',
    majorColorName: 'Gray',
    colorName: 'Nine Iron',
    r: 70,
    g: 67,
    b: 74,
  },
  {
    pantoneId: 1830,
    name: '19-3915 TCX',
    majorColorName: 'Blue',
    colorName: 'Graystone',
    r: 77,
    g: 73,
    b: 91,
  },
  {
    pantoneId: 1831,
    name: '19-3919 TCX',
    majorColorName: 'Blue',
    colorName: 'Nightshadow Blue',
    r: 78,
    g: 83,
    b: 104,
  },
  {
    pantoneId: 1832,
    name: '19-3920 TCX',
    majorColorName: 'Blue',
    colorName: 'Peacoat',
    r: 43,
    g: 46,
    b: 67,
  },
  {
    pantoneId: 1833,
    name: '19-3921 TCX',
    majorColorName: 'Blue',
    colorName: 'Black Iris',
    r: 43,
    g: 48,
    b: 66,
  },
  {
    pantoneId: 1834,
    name: '19-3925 TCX',
    majorColorName: 'Blue',
    colorName: 'Patriot Blue',
    r: 54,
    g: 55,
    b: 86,
  },
  {
    pantoneId: 1835,
    name: '19-3926 TCX',
    majorColorName: 'Blue',
    colorName: 'Crown Blue',
    r: 70,
    g: 75,
    b: 101,
  },
  {
    pantoneId: 1836,
    name: '19-3928 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Indigo',
    r: 73,
    g: 81,
    b: 109,
  },
  {
    pantoneId: 1837,
    name: '19-3933 TCX',
    majorColorName: 'Blue',
    colorName: 'Medieval Blue',
    r: 41,
    g: 48,
    b: 78,
  },
  {
    pantoneId: 1838,
    name: '19-3935 TCX',
    majorColorName: 'Blue',
    colorName: 'Deep Cobalt',
    r: 64,
    g: 68,
    b: 102,
  },
  {
    pantoneId: 1839,
    name: '19-3936 TCX',
    majorColorName: 'Blue',
    colorName: 'Skipper Blue',
    r: 72,
    g: 74,
    b: 114,
  },
  {
    pantoneId: 1840,
    name: '19-3938 TCX',
    majorColorName: 'Blue',
    colorName: 'Twilight Blue',
    r: 49,
    g: 61,
    b: 100,
  },
  {
    pantoneId: 1841,
    name: '19-3939 TCX',
    majorColorName: 'Blue',
    colorName: 'Blueprint',
    r: 45,
    g: 51,
    b: 89,
  },
  {
    pantoneId: 1842,
    name: '19-3940 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Depths',
    r: 38,
    g: 48,
    b: 86,
  },
  {
    pantoneId: 1843,
    name: '19-3947 TCX',
    majorColorName: 'Blue',
    colorName: 'Orient Blue',
    r: 71,
    g: 69,
    b: 122,
  },
  {
    pantoneId: 1844,
    name: '19-3950 TCX',
    majorColorName: 'Blue',
    colorName: 'Deep Ultramarine',
    r: 56,
    g: 72,
    b: 131,
  },
  {
    pantoneId: 1845,
    name: '19-3951 TCX',
    majorColorName: 'Blue',
    colorName: 'Clematis Blue',
    r: 54,
    g: 59,
    b: 124,
  },
  {
    pantoneId: 1846,
    name: '19-3952 TCX',
    majorColorName: 'Blue',
    colorName: 'Surf the Web',
    r: 32,
    g: 60,
    b: 127,
  },
  {
    pantoneId: 1847,
    name: '19-3955 TCX',
    majorColorName: 'Blue',
    colorName: 'Royal Blue',
    r: 61,
    g: 66,
    b: 139,
  },
  {
    pantoneId: 1848,
    name: '19-4005 TCX',
    majorColorName: 'Gray',
    colorName: 'Stretch Limo',
    r: 43,
    g: 44,
    b: 48,
  },
  {
    pantoneId: 1849,
    name: '19-4006 TCX',
    majorColorName: 'Gray',
    colorName: 'Caviar',
    r: 41,
    g: 42,
    b: 45,
  },
  {
    pantoneId: 1850,
    name: '19-4007 TCX',
    majorColorName: 'Gray',
    colorName: 'Anthracite',
    r: 40,
    g: 40,
    b: 45,
  },
  {
    pantoneId: 1851,
    name: '19-4010 TCX',
    majorColorName: 'Blue',
    colorName: 'Total Eclipse',
    r: 44,
    g: 49,
    b: 61,
  },
  {
    pantoneId: 1852,
    name: '19-4013 TCX',
    majorColorName: 'Gray',
    colorName: 'Dark Navy',
    r: 46,
    g: 47,
    b: 54,
  },
  {
    pantoneId: 1853,
    name: '19-4014 TCX',
    majorColorName: 'Blue',
    colorName: 'Ombre Blue',
    r: 67,
    g: 72,
    b: 84,
  },
  {
    pantoneId: 1854,
    name: '19-4015 TCX',
    majorColorName: 'Gray',
    colorName: 'Blue Graphite',
    r: 50,
    g: 49,
    b: 55,
  },
  {
    pantoneId: 1855,
    name: '19-4019 TCX',
    majorColorName: 'Blue',
    colorName: 'India Ink',
    r: 60,
    g: 63,
    b: 74,
  },
  {
    pantoneId: 1856,
    name: '19-4023 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Nights',
    r: 54,
    g: 59,
    b: 72,
  },
  {
    pantoneId: 1857,
    name: '19-4024 TCX',
    majorColorName: 'Blue',
    colorName: 'Dress Blues',
    r: 42,
    g: 50,
    b: 68,
  },
  {
    pantoneId: 1858,
    name: '19-4025 TCX',
    majorColorName: 'Blue',
    colorName: 'Mood Indigo',
    r: 53,
    g: 58,
    b: 76,
  },
  {
    pantoneId: 1859,
    name: '19-4026 TCX',
    majorColorName: 'Blue',
    colorName: 'Ensign Blue',
    r: 56,
    g: 76,
    b: 103,
  },
  {
    pantoneId: 1860,
    name: '19-4027 TCX',
    majorColorName: 'Blue',
    colorName: 'Estate Blue',
    r: 35,
    g: 54,
    b: 88,
  },
  {
    pantoneId: 1861,
    name: '19-4028 TCX',
    majorColorName: 'Blue',
    colorName: 'Insignia Blue',
    r: 47,
    g: 62,
    b: 85,
  },
  {
    pantoneId: 1862,
    name: '19-4030 TCX',
    majorColorName: 'Blue',
    colorName: 'True Navy',
    r: 63,
    g: 82,
    b: 119,
  },
  {
    pantoneId: 1863,
    name: '19-4035 TCX',
    majorColorName: 'Blue',
    colorName: 'Dark Blue',
    r: 48,
    g: 86,
    b: 121,
  },
  {
    pantoneId: 1864,
    name: '19-4037 TCX',
    majorColorName: 'Blue',
    colorName: 'Bright Cobalt',
    r: 56,
    g: 93,
    b: 141,
  },
  {
    pantoneId: 1865,
    name: '19-4039 TCX',
    majorColorName: 'Blue',
    colorName: 'Delft',
    r: 61,
    g: 94,
    b: 140,
  },
  {
    pantoneId: 1866,
    name: '19-4044 TCX',
    majorColorName: 'Blue',
    colorName: 'Limoges',
    r: 36,
    g: 63,
    b: 108,
  },
  {
    pantoneId: 1867,
    name: '19-4049 TCX',
    majorColorName: 'Blue',
    colorName: 'Snorkel Blue',
    r: 3,
    g: 79,
    b: 132,
  },
  {
    pantoneId: 1868,
    name: '19-4050 TCX',
    majorColorName: 'Blue',
    colorName: 'Nautical Blue',
    r: 26,
    g: 80,
    b: 145,
  },
  {
    pantoneId: 1869,
    name: '19-4052 TCX',
    majorColorName: 'Blue',
    colorName: 'Classic Blue',
    r: 15,
    g: 76,
    b: 129,
  },
  {
    pantoneId: 1870,
    name: '19-4053 TCX',
    majorColorName: 'Blue',
    colorName: 'Turkish Sea',
    r: 25,
    g: 81,
    b: 144,
  },
  {
    pantoneId: 1871,
    name: '19-4056 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Iolite',
    r: 26,
    g: 76,
    b: 139,
  },
  {
    pantoneId: 1872,
    name: '19-4057 TCX',
    majorColorName: 'Blue',
    colorName: 'True Blue',
    r: 30,
    g: 68,
    b: 119,
  },
  {
    pantoneId: 1873,
    name: '19-4104 TCX',
    majorColorName: 'Gray',
    colorName: 'Ebony',
    r: 65,
    g: 66,
    b: 74,
  },
  {
    pantoneId: 1874,
    name: '19-4110 TCX',
    majorColorName: 'Blue',
    colorName: 'Midnight Navy',
    r: 52,
    g: 65,
    b: 78,
  },
  {
    pantoneId: 1875,
    name: '19-4118 TCX',
    majorColorName: 'Blue',
    colorName: 'Dark Denim',
    r: 53,
    g: 70,
    b: 94,
  },
  {
    pantoneId: 1876,
    name: '19-4125 TCX',
    majorColorName: 'Blue',
    colorName: 'Majolica Blue',
    r: 39,
    g: 67,
    b: 87,
  },
  {
    pantoneId: 1877,
    name: '19-4127 TCX',
    majorColorName: 'Blue',
    colorName: 'Midnight',
    r: 50,
    g: 91,
    b: 116,
  },
  {
    pantoneId: 1878,
    name: '19-4150 TCX',
    majorColorName: 'Blue',
    colorName: 'Princess Blue',
    r: 0,
    g: 83,
    b: 156,
  },
  {
    pantoneId: 1879,
    name: '19-4151 TCX',
    majorColorName: 'Blue',
    colorName: 'Skydiver',
    r: 0,
    g: 88,
    b: 155,
  },
  {
    pantoneId: 1880,
    name: '19-4203 TCX',
    majorColorName: 'Gray',
    colorName: 'Moonless Night',
    r: 47,
    g: 45,
    b: 48,
  },
  {
    pantoneId: 1881,
    name: '19-4205 TCX',
    majorColorName: 'Gray',
    colorName: 'Phantom',
    r: 57,
    g: 55,
    b: 59,
  },
  {
    pantoneId: 1882,
    name: '19-4220 TCX',
    majorColorName: 'Blue',
    colorName: 'Dark Slate',
    r: 70,
    g: 81,
    b: 90,
  },
  {
    pantoneId: 1883,
    name: '19-4227 TCX',
    majorColorName: 'Blue',
    colorName: 'Indian Teal',
    r: 60,
    g: 88,
    b: 107,
  },
  {
    pantoneId: 1884,
    name: '19-4229 TCX',
    majorColorName: 'Blue',
    colorName: 'Orion Blue',
    r: 62,
    g: 79,
    b: 92,
  },
  {
    pantoneId: 1885,
    name: '19-4234 TCX',
    majorColorName: 'Blue',
    colorName: 'Ink Blue',
    r: 11,
    g: 83,
    b: 105,
  },
  {
    pantoneId: 1886,
    name: '19-4241 TCX',
    majorColorName: 'Blue',
    colorName: 'Moroccan Blue',
    r: 15,
    g: 78,
    b: 103,
  },
  {
    pantoneId: 1887,
    name: '19-4245 TCX',
    majorColorName: 'Blue',
    colorName: 'Imperial Blue',
    r: 0,
    g: 90,
    b: 146,
  },
  {
    pantoneId: 1888,
    name: '19-4305 TCX',
    majorColorName: 'Gray',
    colorName: 'Pirate Black',
    r: 54,
    g: 56,
    b: 56,
  },
  {
    pantoneId: 1889,
    name: '19-4316 TCX',
    majorColorName: 'Blue',
    colorName: 'Stargazer',
    r: 57,
    g: 80,
    b: 92,
  },
  {
    pantoneId: 1890,
    name: '19-4318 TCX',
    majorColorName: 'Blue',
    colorName: 'Mallard Blue',
    r: 58,
    g: 92,
    b: 110,
  },
  {
    pantoneId: 1891,
    name: '19-4324 TCX',
    majorColorName: 'Blue',
    colorName: 'Legion Blue',
    r: 31,
    g: 73,
    b: 91,
  },
  {
    pantoneId: 1892,
    name: '19-4329 TCX',
    majorColorName: 'Blue',
    colorName: 'Corsair',
    r: 24,
    g: 87,
    b: 108,
  },
  {
    pantoneId: 1893,
    name: '19-4340 TCX',
    majorColorName: 'Blue',
    colorName: 'Lyons Blue',
    r: 0,
    g: 88,
    b: 113,
  },
  {
    pantoneId: 1894,
    name: '19-4342 TCX',
    majorColorName: 'Blue',
    colorName: 'Seaport',
    r: 0,
    g: 94,
    b: 125,
  },
  {
    pantoneId: 1895,
    name: '19-4517 TCX',
    majorColorName: 'Blue',
    colorName: 'Mediterranea',
    r: 50,
    g: 87,
    b: 93,
  },
  {
    pantoneId: 1896,
    name: '19-4524 TCX',
    majorColorName: 'Blue',
    colorName: 'Shaded Spruce',
    r: 0,
    g: 88,
    b: 94,
  },
  {
    pantoneId: 1897,
    name: '19-4526 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Coral',
    r: 27,
    g: 83,
    b: 102,
  },
  {
    pantoneId: 1898,
    name: '19-4535 TCX',
    majorColorName: 'Blue',
    colorName: 'Ocean Depths',
    r: 0,
    g: 97,
    b: 117,
  },
  {
    pantoneId: 1899,
    name: '19-4726 TCX',
    majorColorName: 'Blue',
    colorName: 'Atlantic Deep',
    r: 39,
    g: 78,
    b: 85,
  },
  {
    pantoneId: 1900,
    name: '19-4818 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Mallard Green',
    r: 64,
    g: 94,
    b: 92,
  },
  {
    pantoneId: 1901,
    name: '19-4820 TCX',
    majorColorName: 'Blue',
    colorName: 'Balsam',
    r: 51,
    g: 86,
    b: 94,
  },
  {
    pantoneId: 1902,
    name: '19-4826 TCX',
    majorColorName: 'Blue',
    colorName: 'Dragonfly',
    r: 42,
    g: 92,
    b: 106,
  },
  {
    pantoneId: 1903,
    name: '19-4906 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Green Gables',
    r: 50,
    g: 66,
    b: 65,
  },
  {
    pantoneId: 1904,
    name: '19-4914 TCX',
    majorColorName: 'Blue',
    colorName: 'Deep Teal',
    r: 24,
    g: 69,
    b: 75,
  },
  {
    pantoneId: 1905,
    name: '19-4916 TCX',
    majorColorName: 'Blue',
    colorName: 'Pacific',
    r: 31,
    g: 89,
    b: 92,
  },
  {
    pantoneId: 1906,
    name: '19-4922 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Teal Green',
    r: 0,
    g: 99,
    b: 97,
  },
  {
    pantoneId: 1907,
    name: '19-5004 TCX',
    majorColorName: 'Gray',
    colorName: 'Urban Chic',
    r: 70,
    g: 78,
    b: 77,
  },
  {
    pantoneId: 1908,
    name: '19-5212 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Darkest Spruce',
    r: 48,
    g: 61,
    b: 60,
  },
  {
    pantoneId: 1909,
    name: '19-5217 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Storm',
    r: 3,
    g: 84,
    b: 83,
  },
  {
    pantoneId: 1910,
    name: '19-5226 TCX',
    majorColorName: 'Blue',
    colorName: 'Everglade',
    r: 0,
    g: 91,
    b: 93,
  },
  {
    pantoneId: 1911,
    name: '19-5320 TCX',
    majorColorName: 'Blue',
    colorName: 'Ponderosa Pine',
    r: 32,
    g: 59,
    b: 61,
  },
  {
    pantoneId: 1912,
    name: '19-5408 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Bistro Green',
    r: 57,
    g: 85,
    b: 81,
  },
  {
    pantoneId: 1913,
    name: '19-5411 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Trekking Green',
    r: 53,
    g: 80,
    b: 72,
  },
  {
    pantoneId: 1914,
    name: '19-5413 TCX',
    majorColorName: 'Blue-green',
    colorName: 'Jasper',
    r: 51,
    g: 89,
    b: 89,
  },
  {
    pantoneId: 1915,
    name: '19-5414 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'June Bug',
    r: 38,
    g: 74,
    b: 72,
  },
  {
    pantoneId: 1916,
    name: '19-5420 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Evergreen',
    r: 17,
    g: 87,
    b: 74,
  },
  {
    pantoneId: 1917,
    name: '19-5511 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Hunter Green',
    r: 51,
    g: 87,
    b: 73,
  },
  {
    pantoneId: 1918,
    name: '19-5513 TCX',
    majorColorName: 'Green',
    colorName: 'Dark Green',
    r: 49,
    g: 79,
    b: 64,
  },
  {
    pantoneId: 1919,
    name: '19-5708 TCX',
    majorColorName: 'Gray',
    colorName: 'Jet Set',
    r: 38,
    g: 44,
    b: 42,
  },
  {
    pantoneId: 1920,
    name: '19-5914 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Jungle Green',
    r: 60,
    g: 78,
    b: 71,
  },
  {
    pantoneId: 1921,
    name: '19-5917 TCX',
    majorColorName: 'Green',
    colorName: 'Sycamore',
    r: 53,
    g: 70,
    b: 61,
  },
  {
    pantoneId: 1922,
    name: '19-5920 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Pineneedle',
    r: 51,
    g: 77,
    b: 65,
  },
  {
    pantoneId: 1923,
    name: '19-6026 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Verdant Green',
    r: 18,
    g: 103,
    b: 74,
  },
  {
    pantoneId: 1924,
    name: '19-6110 TCX',
    majorColorName: 'Green',
    colorName: 'Deep Forest',
    r: 55,
    g: 65,
    b: 58,
  },
  {
    pantoneId: 1925,
    name: '19-6311 TCX',
    majorColorName: 'Green',
    colorName: 'Greener Pastures',
    r: 55,
    g: 80,
    b: 61,
  },
  {
    pantoneId: 1926,
    name: '19-3911 TCX',
    majorColorName: 'Gray',
    colorName: 'Black Beauty',
    r: 38,
    g: 38,
    b: 42,
  },
  {
    pantoneId: 1927,
    name: '19-4008 TCX',
    majorColorName: 'Gray',
    colorName: 'Meteorite',
    r: 43,
    g: 41,
    b: 41,
  },
  {
    pantoneId: 1928,
    name: '19-4003 TCX',
    majorColorName: 'Gray',
    colorName: 'Black Onyx',
    r: 43,
    g: 39,
    b: 43,
  },
  {
    pantoneId: 1929,
    name: '18-0523 TCX',
    majorColorName: 'Yellow',
    colorName: 'Winter Moss',
    r: 91,
    g: 90,
    b: 65,
  },
  {
    pantoneId: 1930,
    name: '18-3950 TCX',
    majorColorName: 'Gray',
    colorName: 'Iris Bloom',
    r: 91,
    g: 96,
    b: 158,
  },
  {
    pantoneId: 1931,
    name: '18-4214 TCX',
    majorColorName: 'Blue',
    colorName: 'Stormy Weather',
    r: 88,
    g: 100,
    b: 109,
  },
  {
    pantoneId: 1932,
    name: '18-0107 TCX',
    majorColorName: 'Green',
    colorName: 'Kale',
    r: 90,
    g: 114,
    b: 71,
  },
  {
    pantoneId: 1933,
    name: '17-4029 TCX',
    majorColorName: 'Blue',
    colorName: 'Quiet Harbor',
    r: 90,
    g: 120,
    b: 154,
  },
  {
    pantoneId: 1934,
    name: '17-4032 TCX',
    majorColorName: 'Blue',
    colorName: 'Lichen Blue',
    r: 93,
    g: 137,
    b: 179,
  },
  {
    pantoneId: 1935,
    name: '15-4714 TCX',
    majorColorName: 'Blue',
    colorName: 'Aquarelle',
    r: 97,
    g: 170,
    b: 177,
  },
  {
    pantoneId: 1936,
    name: '15-4428 TCX',
    majorColorName: 'Blue',
    colorName: 'Crystal Seas',
    r: 93,
    g: 175,
    b: 206,
  },
  {
    pantoneId: 1937,
    name: '19-1535 TCX',
    majorColorName: 'Pink',
    colorName: 'Syrah',
    r: 106,
    g: 40,
    b: 44,
  },
  {
    pantoneId: 1938,
    name: '19-1930 TCX',
    majorColorName: 'Pink',
    colorName: 'Pomegranate',
    r: 108,
    g: 40,
    b: 49,
  },
  {
    pantoneId: 1939,
    name: '19-1337 TCX',
    majorColorName: 'Pink',
    colorName: 'Fired Brick',
    r: 106,
    g: 46,
    b: 42,
  },
  {
    pantoneId: 1940,
    name: '18-3340 TCX',
    majorColorName: 'Purple',
    colorName: 'Charisma',
    r: 99,
    g: 42,
    b: 96,
  },
  {
    pantoneId: 1941,
    name: '19-1234 TCX',
    majorColorName: 'Brown',
    colorName: 'Rocky Road',
    r: 90,
    g: 62,
    b: 54,
  },
  {
    pantoneId: 1942,
    name: '19-1214 TCX',
    majorColorName: 'Pink',
    colorName: 'Chocolate Lab',
    r: 92,
    g: 62,
    b: 53,
  },
  {
    pantoneId: 1943,
    name: '18-3521 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Plum Jam',
    r: 98,
    g: 64,
    b: 118,
  },
  {
    pantoneId: 1944,
    name: '19-1224 TCX',
    majorColorName: 'Brown',
    colorName: 'Fondue Fudge',
    r: 93,
    g: 66,
    b: 54,
  },
  {
    pantoneId: 1945,
    name: '18-3635 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Picasso Lily',
    r: 99,
    g: 72,
    b: 120,
  },
  {
    pantoneId: 1946,
    name: '18-3620 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Mystical',
    r: 95,
    g: 78,
    b: 114,
  },
  {
    pantoneId: 1947,
    name: '19-0403 TCX',
    majorColorName: 'Gray',
    colorName: 'Sea Turtle',
    r: 94,
    g: 87,
    b: 73,
  },
  {
    pantoneId: 1948,
    name: '18-5210 TCX',
    majorColorName: 'Gray',
    colorName: 'Eiffel Tower',
    r: 92,
    g: 86,
    b: 88,
  },
  {
    pantoneId: 1949,
    name: '18-3907 TCX',
    majorColorName: 'Gray',
    colorName: 'Tornado',
    r: 94,
    g: 91,
    b: 96,
  },
  {
    pantoneId: 1950,
    name: '18-3912 TCX',
    majorColorName: 'Blue',
    colorName: 'Grisaille',
    r: 88,
    g: 94,
    b: 111,
  },
  {
    pantoneId: 1951,
    name: '18-0220 TCX',
    majorColorName: 'Green',
    colorName: 'Mayfly',
    r: 101,
    g: 102,
    b: 63,
  },
  {
    pantoneId: 1952,
    name: '19-1435 TCX',
    majorColorName: 'Brown',
    colorName: 'Cherry Mahogany',
    r: 102,
    g: 53,
    b: 43,
  },
  {
    pantoneId: 1953,
    name: '19-1531 TCX',
    majorColorName: 'Pink',
    colorName: 'Sun-Dried Tomato',
    r: 117,
    g: 35,
    b: 41,
  },
  {
    pantoneId: 1954,
    name: '19-1534 TCX',
    majorColorName: 'Pink',
    colorName: 'Merlot',
    r: 114,
    g: 38,
    b: 44,
  },
  {
    pantoneId: 1955,
    name: '19-1555 TCX',
    majorColorName: 'Pink',
    colorName: 'Red Dahlia',
    r: 125,
    g: 32,
    b: 39,
  },
  {
    pantoneId: 1956,
    name: '19-1652 TCX',
    majorColorName: 'Pink',
    colorName: 'Rhubarb',
    r: 119,
    g: 32,
    b: 47,
  },
  {
    pantoneId: 1957,
    name: '18-3540 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Purple Sapphire',
    r: 111,
    g: 70,
    b: 133,
  },
  {
    pantoneId: 1958,
    name: '19-1233 TCX',
    majorColorName: 'Brown',
    colorName: 'Tiramisu',
    r: 99,
    g: 66,
    b: 53,
  },
  {
    pantoneId: 1959,
    name: '18-1028 TCX',
    majorColorName: 'Brown',
    colorName: 'Emperador',
    r: 104,
    g: 72,
    b: 50,
  },
  {
    pantoneId: 1960,
    name: '19-0916 TCX',
    majorColorName: 'Brown',
    colorName: 'Rain Drum',
    r: 95,
    g: 76,
    b: 64,
  },
  {
    pantoneId: 1961,
    name: '19-0823 TCX',
    majorColorName: 'Brown',
    colorName: 'Crocodile',
    r: 93,
    g: 83,
    b: 72,
  },
  {
    pantoneId: 1962,
    name: '19-0510 TCX',
    majorColorName: 'Yellow',
    colorName: 'Kalamata',
    r: 95,
    g: 91,
    b: 76,
  },
  {
    pantoneId: 1963,
    name: '18-3908 TCX',
    majorColorName: 'Gray',
    colorName: 'Volcanic Glass',
    r: 97,
    g: 92,
    b: 96,
  },
  {
    pantoneId: 1964,
    name: '18-5204 TCX',
    majorColorName: 'Gray',
    colorName: 'Granite Gray',
    r: 97,
    g: 94,
    b: 95,
  },
  {
    pantoneId: 1965,
    name: '18-4006 TCX',
    majorColorName: 'Gray',
    colorName: 'Quiet Shade',
    r: 102,
    g: 103,
    b: 109,
  },
  {
    pantoneId: 1966,
    name: '17-3940 TCX',
    majorColorName: 'Gray',
    colorName: 'Iolite',
    r: 112,
    g: 123,
    b: 180,
  },
  {
    pantoneId: 1967,
    name: '14-4310 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Topaz',
    r: 120,
    g: 189,
    b: 212,
  },
  {
    pantoneId: 1968,
    name: '13-4720 TCX',
    majorColorName: 'White',
    colorName: 'Tanager Turquoise',
    r: 145,
    g: 220,
    b: 232,
  },
  {
    pantoneId: 1969,
    name: '14-4620 TCX',
    majorColorName: 'Blue',
    colorName: 'Island Paradise',
    r: 149,
    g: 222,
    b: 227,
  },
  {
    pantoneId: 1970,
    name: '19-1429 TCX',
    majorColorName: 'Pink',
    colorName: 'Smoked Paprika',
    r: 110,
    g: 54,
    b: 44,
  },
  {
    pantoneId: 1971,
    name: '18-1541 TCX',
    majorColorName: 'Pink',
    colorName: 'Brandy Brown',
    r: 115,
    g: 54,
    b: 42,
  },
  {
    pantoneId: 1972,
    name: '19-3425 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Spiced Plum',
    r: 109,
    g: 71,
    b: 115,
  },
  {
    pantoneId: 1973,
    name: '18-1506 TCX',
    majorColorName: 'Pink',
    colorName: 'Plum Truffle',
    r: 103,
    g: 86,
    b: 87,
  },
  {
    pantoneId: 1974,
    name: '18-1708 TCX',
    majorColorName: 'Gray',
    colorName: 'Ephemera',
    r: 111,
    g: 89,
    b: 101,
  },
  {
    pantoneId: 1975,
    name: '19-0808 TCX',
    majorColorName: 'Brown',
    colorName: 'Morel',
    r: 104,
    g: 92,
    b: 83,
  },
  {
    pantoneId: 1976,
    name: '18-1705 TCX',
    majorColorName: 'Gray',
    colorName: 'Arctic Dusk',
    r: 115,
    g: 91,
    b: 106,
  },
  {
    pantoneId: 1977,
    name: '18-1304 TCX',
    majorColorName: 'Brown',
    colorName: 'Falcon',
    r: 109,
    g: 98,
    b: 91,
  },
  {
    pantoneId: 1978,
    name: '18-0625 TCX',
    majorColorName: 'Yellow',
    colorName: 'Martini Olive',
    r: 113,
    g: 106,
    b: 77,
  },
  {
    pantoneId: 1979,
    name: '18-5806 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Agave Green',
    r: 107,
    g: 113,
    b: 105,
  },
  {
    pantoneId: 1980,
    name: '18-5102 TCX',
    majorColorName: 'Gray',
    colorName: 'Brushed Nickel',
    r: 115,
    g: 112,
    b: 111,
  },
  {
    pantoneId: 1981,
    name: '18-3933 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Granite',
    r: 113,
    g: 115,
    b: 136,
  },
  {
    pantoneId: 1982,
    name: '17-0530 TCX',
    majorColorName: 'Yellow',
    colorName: 'Guacamole',
    r: 121,
    g: 123,
    b: 58,
  },
  {
    pantoneId: 1983,
    name: '14-4320 TCX',
    majorColorName: 'Blue',
    colorName: 'Baltic Sea',
    r: 121,
    g: 181,
    b: 216,
  },
  {
    pantoneId: 1984,
    name: '14-4710 TCX',
    majorColorName: 'Blue',
    colorName: 'Tibetan Stone',
    r: 130,
    g: 194,
    b: 199,
  },
  {
    pantoneId: 1985,
    name: '14-4812 TCX',
    majorColorName: 'Blue',
    colorName: 'Aqua Splash',
    r: 133,
    g: 206,
    b: 209,
  },
  {
    pantoneId: 1986,
    name: '13-4810 TCX',
    majorColorName: 'White',
    colorName: 'Limpet Shell',
    r: 152,
    g: 221,
    b: 222,
  },
  {
    pantoneId: 1987,
    name: '18-1148 TCX',
    majorColorName: 'Brown',
    colorName: 'Caramel Cafe',
    r: 134,
    g: 76,
    b: 36,
  },
  {
    pantoneId: 1988,
    name: '18-1325 TCX',
    majorColorName: 'Brown',
    colorName: 'Spiced Apple',
    r: 120,
    g: 57,
    b: 55,
  },
  {
    pantoneId: 1989,
    name: '19-1536 TCX',
    majorColorName: 'Pink',
    colorName: 'Red Pear',
    r: 123,
    g: 53,
    b: 57,
  },
  {
    pantoneId: 1990,
    name: '18-3014 TCX',
    majorColorName: 'Pink',
    colorName: 'Grape Kiss',
    r: 123,
    g: 67,
    b: 104,
  },
  {
    pantoneId: 1991,
    name: '17-1708 TCX',
    majorColorName: 'Pink',
    colorName: 'Moonscape',
    r: 114,
    g: 95,
    b: 105,
  },
  {
    pantoneId: 1992,
    name: '18-3634 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Chive Blossom',
    r: 125,
    g: 93,
    b: 153,
  },
  {
    pantoneId: 1993,
    name: '17-1503 TCX',
    majorColorName: 'Gray',
    colorName: 'Storm Front',
    r: 120,
    g: 115,
    b: 118,
  },
  {
    pantoneId: 1994,
    name: '18-4016 TCX',
    majorColorName: 'Blue',
    colorName: 'December Sky',
    r: 118,
    g: 114,
    b: 117,
  },
  {
    pantoneId: 1995,
    name: '17-3911 TCX',
    majorColorName: 'Gray',
    colorName: 'Silver Filigree',
    r: 127,
    g: 124,
    b: 129,
  },
  {
    pantoneId: 1996,
    name: '17-4016 TCX',
    majorColorName: 'Blue',
    colorName: 'Gray Flannel',
    r: 132,
    g: 129,
    b: 130,
  },
  {
    pantoneId: 1997,
    name: '16-4030 TCX',
    majorColorName: 'Blue',
    colorName: 'Hydrangea',
    r: 132,
    g: 155,
    b: 204,
  },
  {
    pantoneId: 1998,
    name: '15-3932 TCX',
    majorColorName: 'Blue',
    colorName: 'Bel Air Blue',
    r: 129,
    g: 154,
    b: 193,
  },
  {
    pantoneId: 1999,
    name: '14-4615 TCX',
    majorColorName: 'Blue',
    colorName: 'Antigua Sand',
    r: 131,
    g: 194,
    b: 205,
  },
  {
    pantoneId: 2000,
    name: '14-4511 TCX',
    majorColorName: 'Blue',
    colorName: 'Gulf Stream',
    r: 136,
    g: 195,
    b: 208,
  },
  {
    pantoneId: 2001,
    name: '19-1757 TCX',
    majorColorName: 'Pink',
    colorName: 'Barbados Cherry',
    r: 170,
    g: 10,
    b: 39,
  },
  {
    pantoneId: 2002,
    name: '19-1758 TCX',
    majorColorName: 'Pink',
    colorName: 'Haute Red',
    r: 161,
    g: 23,
    b: 41,
  },
  {
    pantoneId: 2003,
    name: '19-1863 TCX',
    majorColorName: 'Pink',
    colorName: 'Scooter',
    r: 148,
    g: 30,
    b: 50,
  },
  {
    pantoneId: 2004,
    name: '18-3120 TCX',
    majorColorName: 'Gray',
    colorName: 'Willowherb',
    r: 142,
    g: 68,
    b: 131,
  },
  {
    pantoneId: 2005,
    name: '18-1246 TCX',
    majorColorName: 'Brown',
    colorName: 'Umber',
    r: 148,
    g: 74,
    b: 31,
  },
  {
    pantoneId: 2006,
    name: '17-1142 TCX',
    majorColorName: 'Brown',
    colorName: 'Argan Oil',
    r: 139,
    g: 89,
    b: 62,
  },
  {
    pantoneId: 2007,
    name: '18-1017 TCX',
    majorColorName: 'Brown',
    colorName: 'Caribou',
    r: 129,
    g: 109,
    b: 94,
  },
  {
    pantoneId: 2008,
    name: '18-1108 TCX',
    majorColorName: 'Brown',
    colorName: 'Fallen Rock',
    r: 128,
    g: 118,
    b: 105,
  },
  {
    pantoneId: 2009,
    name: '18-1210 TCX',
    majorColorName: 'Brown',
    colorName: 'Driftwood',
    r: 132,
    g: 122,
    b: 117,
  },
  {
    pantoneId: 2010,
    name: '17-3907 TCX',
    majorColorName: 'Gray',
    colorName: 'Quicksilver',
    r: 126,
    g: 125,
    b: 136,
  },
  {
    pantoneId: 2011,
    name: '17-4014 TCX',
    majorColorName: 'Blue',
    colorName: 'Titanium',
    r: 128,
    g: 125,
    b: 127,
  },
  {
    pantoneId: 2012,
    name: '17-3914 TCX',
    majorColorName: 'Gray',
    colorName: 'Sharkskin',
    r: 131,
    g: 132,
    b: 135,
  },
  {
    pantoneId: 2013,
    name: '15-4307 TCX',
    majorColorName: 'Gray',
    colorName: 'Tradewinds',
    r: 127,
    g: 135,
    b: 147,
  },
  {
    pantoneId: 2014,
    name: '17-3933 TCX',
    majorColorName: 'Blue',
    colorName: 'Silver Bullet',
    r: 129,
    g: 131,
    b: 154,
  },
  {
    pantoneId: 2015,
    name: '17-3929 TCX',
    majorColorName: 'Blue',
    colorName: 'Pale Iris',
    r: 136,
    g: 149,
    b: 197,
  },
  {
    pantoneId: 2016,
    name: '19-1559 TCX',
    majorColorName: 'Pink',
    colorName: 'Scarlet Sage',
    r: 157,
    g: 32,
    b: 47,
  },
  {
    pantoneId: 2017,
    name: '18-1340 TCX',
    majorColorName: 'Brown',
    colorName: "Potter's Clay",
    r: 158,
    g: 70,
    b: 36,
  },
  {
    pantoneId: 2018,
    name: '18-1440 TCX',
    majorColorName: 'Brown',
    colorName: 'Chili Oil',
    r: 142,
    g: 60,
    b: 54,
  },
  {
    pantoneId: 2019,
    name: '18-1163 TCX',
    majorColorName: 'Brown',
    colorName: 'Pumpkin Spice',
    r: 160,
    g: 92,
    b: 23,
  },
  {
    pantoneId: 2020,
    name: '18-1345 TCX',
    majorColorName: 'Brown',
    colorName: 'Cinnamon Stick',
    r: 155,
    g: 71,
    b: 34,
  },
  {
    pantoneId: 2021,
    name: '18-1155 TCX',
    majorColorName: 'Brown',
    colorName: 'Sugar Almond',
    r: 147,
    g: 85,
    b: 41,
  },
  {
    pantoneId: 2022,
    name: '17-1052 TCX',
    majorColorName: 'Brown',
    colorName: 'Roasted Pecan',
    r: 147,
    g: 89,
    b: 43,
  },
  {
    pantoneId: 2023,
    name: '18-1029 TCX',
    majorColorName: 'Brown',
    colorName: 'Toasted Coconut',
    r: 139,
    g: 106,
    b: 79,
  },
  {
    pantoneId: 2024,
    name: '18-3628 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Bellflower',
    r: 148,
    g: 105,
    b: 162,
  },
  {
    pantoneId: 2025,
    name: '17-1115 TCX',
    majorColorName: 'Brown',
    colorName: 'Petrified Oak',
    r: 141,
    g: 121,
    b: 96,
  },
  {
    pantoneId: 2026,
    name: '17-1314 TCX',
    majorColorName: 'Brown',
    colorName: 'Sepia Tint',
    r: 137,
    g: 117,
    b: 96,
  },
  {
    pantoneId: 2027,
    name: '17-3640 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Fairy Wren',
    r: 148,
    g: 121,
    b: 175,
  },
  {
    pantoneId: 2028,
    name: '16-1347 TCX',
    majorColorName: 'Orange',
    colorName: 'Peach Caramel',
    r: 197,
    g: 115,
    b: 61,
  },
  {
    pantoneId: 2029,
    name: '16-1348 TCX',
    majorColorName: 'Orange',
    colorName: 'Tomato Cream',
    r: 197,
    g: 118,
    b: 68,
  },
  {
    pantoneId: 2030,
    name: '15-1430 TCX',
    majorColorName: 'Orange',
    colorName: 'Pastry Shell',
    r: 189,
    g: 140,
    b: 102,
  },
  {
    pantoneId: 2031,
    name: '15-1425 TCX',
    majorColorName: 'Pink',
    colorName: 'Fenugreek',
    r: 192,
    g: 145,
    b: 108,
  },
  {
    pantoneId: 2032,
    name: '16-0920 TCX',
    majorColorName: 'Orange',
    colorName: 'Curds & Whey',
    r: 188,
    g: 164,
    b: 131,
  },
  {
    pantoneId: 2033,
    name: '15-1020 TCX',
    majorColorName: 'Orange',
    colorName: 'Ginger Root',
    r: 191,
    g: 165,
    b: 138,
  },
  {
    pantoneId: 2034,
    name: '15-1304 TCX',
    majorColorName: 'White',
    colorName: 'Humus',
    r: 183,
    g: 167,
    b: 147,
  },
  {
    pantoneId: 2035,
    name: '14-1208 TCX',
    majorColorName: 'Orange',
    colorName: 'Irish Cream',
    r: 192,
    g: 172,
    b: 146,
  },
  {
    pantoneId: 2036,
    name: '13-4201 TCX',
    majorColorName: 'White',
    colorName: 'Oyster Mushroom',
    r: 195,
    g: 198,
    b: 200,
  },
  {
    pantoneId: 2037,
    name: '13-4111 TCX',
    majorColorName: 'White',
    colorName: 'Plein Air',
    r: 191,
    g: 202,
    b: 214,
  },
  {
    pantoneId: 2038,
    name: '17-1452 TCX',
    majorColorName: 'Red',
    colorName: 'Koi',
    r: 209,
    g: 88,
    b: 55,
  },
  {
    pantoneId: 2039,
    name: '17-1349 TCX',
    majorColorName: 'Brown',
    colorName: 'Exuberance',
    r: 232,
    g: 104,
    b: 0,
  },
  {
    pantoneId: 2040,
    name: '15-1051 TCX',
    majorColorName: 'Orange',
    colorName: 'Golden Orange',
    r: 215,
    g: 148,
    b: 45,
  },
  {
    pantoneId: 2041,
    name: '15-0960 TCX',
    majorColorName: 'Brown',
    colorName: 'Mango Mojito',
    r: 214,
    g: 156,
    b: 47,
  },
  {
    pantoneId: 2042,
    name: '14-0952 TCX',
    majorColorName: 'Orange',
    colorName: 'Spicy Mustard',
    r: 216,
    g: 174,
    b: 71,
  },
  {
    pantoneId: 2043,
    name: '13-1105 TCX',
    majorColorName: 'White',
    colorName: 'Brown Rice',
    r: 199,
    g: 187,
    b: 164,
  },
  {
    pantoneId: 2044,
    name: '13-4104 TCX',
    majorColorName: 'White',
    colorName: 'Antarctica',
    r: 198,
    g: 197,
    b: 198,
  },
  {
    pantoneId: 2045,
    name: '17-1463 TCX',
    majorColorName: 'Orange',
    colorName: 'Tangerine Tango',
    r: 221,
    g: 65,
    b: 36,
  },
  {
    pantoneId: 2046,
    name: '17-1461 TCX',
    majorColorName: 'Orange',
    colorName: 'Orangeade',
    r: 226,
    g: 85,
    b: 44,
  },
  {
    pantoneId: 2047,
    name: '16-1164 TCX',
    majorColorName: 'Brown',
    colorName: 'Orange Pepper',
    r: 223,
    g: 117,
    b: 0,
  },
  {
    pantoneId: 2048,
    name: '15-1429 TCX',
    majorColorName: 'Pink',
    colorName: 'Dusted Clay',
    r: 204,
    g: 115,
    b: 87,
  },
  {
    pantoneId: 2049,
    name: '16-1345 TCX',
    majorColorName: 'Orange',
    colorName: 'Sun Baked',
    r: 210,
    g: 127,
    b: 99,
  },
  {
    pantoneId: 2050,
    name: '15-1045 TCX',
    majorColorName: 'Orange',
    colorName: 'Autumn Blaze',
    r: 217,
    g: 146,
    b: 46,
  },
  {
    pantoneId: 2051,
    name: '15-1125 TCX',
    majorColorName: 'Brown',
    colorName: 'Porcini',
    r: 204,
    g: 165,
    b: 128,
  },
  {
    pantoneId: 2052,
    name: '14-1315 TCX',
    majorColorName: 'Orange',
    colorName: 'Hazelnut',
    r: 207,
    g: 176,
    b: 149,
  },
  {
    pantoneId: 2053,
    name: '15-1218 TCX',
    majorColorName: 'Brown',
    colorName: 'Semolina',
    r: 206,
    g: 184,
    b: 153,
  },
  {
    pantoneId: 2054,
    name: '14-1803 TCX',
    majorColorName: 'Brown',
    colorName: 'Sepia Rose',
    r: 212,
    g: 186,
    b: 182,
  },
  {
    pantoneId: 2055,
    name: '13-0919 TCX',
    majorColorName: 'Orange',
    colorName: 'Soybean',
    r: 210,
    g: 194,
    b: 157,
  },
  {
    pantoneId: 2056,
    name: '12-4301 TCX',
    majorColorName: 'White',
    colorName: 'Almond Milk',
    r: 214,
    g: 206,
    b: 190,
  },
  {
    pantoneId: 2057,
    name: '13-0644 TCX',
    majorColorName: 'Yellow',
    colorName: 'Golden Kiwi',
    r: 243,
    g: 221,
    b: 62,
  },
  {
    pantoneId: 2058,
    name: '13-0646 TCX',
    majorColorName: 'Yellow',
    colorName: 'Meadowlark',
    r: 234,
    g: 217,
    b: 78,
  },
  {
    pantoneId: 2059,
    name: '16-1363 TCX',
    majorColorName: 'Orange',
    colorName: "Puffin's Bill",
    r: 233,
    g: 92,
    b: 32,
  },
  {
    pantoneId: 2060,
    name: '17-1360 TCX',
    majorColorName: 'Brown',
    colorName: 'Celosia Orange',
    r: 232,
    g: 112,
    b: 58,
  },
  {
    pantoneId: 2061,
    name: '13-0946 TCX',
    majorColorName: 'Orange',
    colorName: 'Jurassic Gold',
    r: 231,
    g: 170,
    b: 86,
  },
  {
    pantoneId: 2062,
    name: '13-1208 TCX',
    majorColorName: 'Orange',
    colorName: 'Peachy Keen',
    r: 226,
    g: 189,
    b: 179,
  },
  {
    pantoneId: 2063,
    name: '13-1308 TCX',
    majorColorName: 'Pink',
    colorName: 'Brazilian Sand',
    r: 218,
    g: 202,
    b: 183,
  },
  {
    pantoneId: 2064,
    name: '12-0110 TCX',
    majorColorName: 'White',
    colorName: 'Pistachio Shell',
    r: 215,
    g: 207,
    b: 187,
  },
  {
    pantoneId: 2065,
    name: '13-4108 TCX',
    majorColorName: 'White',
    colorName: 'Nimbus Cloud',
    r: 213,
    g: 213,
    b: 216,
  },
  {
    pantoneId: 2066,
    name: '12-0646 TCX',
    majorColorName: 'Yellow',
    colorName: 'Celandine',
    r: 235,
    g: 223,
    b: 103,
  },
  {
    pantoneId: 2067,
    name: '11-0622 TCX',
    majorColorName: 'Yellow',
    colorName: 'Yellow Iris',
    r: 238,
    g: 231,
    b: 142,
  },
  {
    pantoneId: 2068,
    name: '11-0623 TCX',
    majorColorName: 'Yellow',
    colorName: 'Yellow Pear',
    r: 236,
    g: 233,
    b: 155,
  },
  {
    pantoneId: 2069,
    name: '11-4001 TCX',
    majorColorName: 'White',
    colorName: 'Brilliant White',
    r: 237,
    g: 241,
    b: 254,
  },
  {
    pantoneId: 2070,
    name: '17-1361 TCX',
    majorColorName: 'Brown',
    colorName: 'Scarlet Ibis',
    r: 244,
    g: 85,
    b: 32,
  },
  {
    pantoneId: 2071,
    name: '16-1358 TCX',
    majorColorName: 'Orange',
    colorName: 'Orange Tiger',
    r: 249,
    g: 103,
    b: 20,
  },
  {
    pantoneId: 2072,
    name: '16-1453 TCX',
    majorColorName: 'Orange',
    colorName: 'Exotic Orange',
    r: 249,
    g: 101,
    b: 49,
  },
  {
    pantoneId: 2073,
    name: '15-1335 TCX',
    majorColorName: 'Orange',
    colorName: 'Tangelo',
    r: 254,
    g: 126,
    b: 3,
  },
  {
    pantoneId: 2074,
    name: '15-1151 TCX',
    majorColorName: 'Orange',
    colorName: 'Iceland Poppy',
    r: 244,
    g: 150,
    b: 58,
  },
  {
    pantoneId: 2075,
    name: '15-1520 TCX',
    majorColorName: 'Orange',
    colorName: 'Blooming Dahlia',
    r: 235,
    g: 150,
    b: 135,
  },
  {
    pantoneId: 2076,
    name: '13-0759 TCX',
    majorColorName: 'Yellow',
    colorName: 'Solar Power',
    r: 244,
    g: 191,
    b: 58,
  },
  {
    pantoneId: 2077,
    name: '12-2901 TCX',
    majorColorName: 'White',
    colorName: 'Morganite',
    r: 223,
    g: 205,
    b: 198,
  },
  {
    pantoneId: 2078,
    name: '12-0729 TCX',
    majorColorName: 'Yellow',
    colorName: 'Sundress',
    r: 235,
    g: 207,
    b: 137,
  },
  {
    pantoneId: 2079,
    name: '12-0718 TCX',
    majorColorName: 'Orange',
    colorName: 'Pineapple Slice',
    r: 231,
    g: 211,
    b: 145,
  },
  {
    pantoneId: 2080,
    name: '12-0709 TCX',
    majorColorName: 'White',
    colorName: 'Macadamia',
    r: 228,
    g: 207,
    b: 182,
  },
  {
    pantoneId: 2081,
    name: '13-0756 TCX',
    majorColorName: 'Yellow',
    colorName: 'Lemon Zest',
    r: 249,
    g: 216,
    b: 87,
  },
  {
    pantoneId: 2082,
    name: '12-0737 TCX',
    majorColorName: 'Orange',
    colorName: 'Goldfinch',
    r: 248,
    g: 220,
    b: 108,
  },
  {
    pantoneId: 2083,
    name: '12-0742 TCX',
    majorColorName: 'Yellow',
    colorName: 'Lemon Verbena',
    r: 243,
    g: 231,
    b: 121,
  },
  {
    pantoneId: 2084,
    name: '16-1460 TCX',
    majorColorName: 'Orange',
    colorName: 'Dragon Fire',
    r: 252,
    g: 100,
    b: 45,
  },
  {
    pantoneId: 2085,
    name: '15-1262 TCX',
    majorColorName: 'Orange',
    colorName: 'Carrot Curl',
    r: 254,
    g: 140,
    b: 24,
  },
  {
    pantoneId: 2086,
    name: '15-1264 TCX',
    majorColorName: 'Orange',
    colorName: 'Turmeric',
    r: 254,
    g: 132,
    b: 14,
  },
  {
    pantoneId: 2087,
    name: '16-1545 TCX',
    majorColorName: 'Pink',
    colorName: 'Coral Quartz',
    r: 247,
    g: 116,
    b: 100,
  },
  {
    pantoneId: 2088,
    name: '16-1548 TCX',
    majorColorName: 'Pink',
    colorName: 'Peach Echo',
    r: 247,
    g: 120,
    b: 107,
  },
  {
    pantoneId: 2089,
    name: '15-1243 TCX',
    majorColorName: 'Orange',
    colorName: 'Papaya',
    r: 254,
    g: 161,
    b: 102,
  },
  {
    pantoneId: 2090,
    name: '14-1052 TCX',
    majorColorName: 'Orange',
    colorName: 'Kumquat',
    r: 251,
    g: 170,
    b: 76,
  },
  {
    pantoneId: 2091,
    name: '14-1241 TCX',
    majorColorName: 'Orange',
    colorName: 'Orange Chiffon',
    r: 249,
    g: 170,
    b: 125,
  },
  {
    pantoneId: 2092,
    name: '13-1125 TCX',
    majorColorName: 'White',
    colorName: 'Peach Quartz',
    r: 245,
    g: 184,
    b: 149,
  },
  {
    pantoneId: 2093,
    name: '13-2802 TCX',
    majorColorName: 'White',
    colorName: 'Fairy Tale',
    r: 242,
    g: 193,
    b: 209,
  },
  {
    pantoneId: 2094,
    name: '14-0851 TCX',
    majorColorName: 'Orange',
    colorName: 'Samoan Sun',
    r: 251,
    g: 200,
    b: 95,
  },
  {
    pantoneId: 2095,
    name: '12-0758 TCX',
    majorColorName: 'Yellow',
    colorName: 'Yarrow',
    r: 250,
    g: 206,
    b: 109,
  },
  {
    pantoneId: 2096,
    name: '13-2808 TCX',
    majorColorName: 'White',
    colorName: 'Ballet Slipper',
    r: 235,
    g: 206,
    b: 213,
  },
  {
    pantoneId: 2097,
    name: '13-0849 TCX',
    majorColorName: 'Orange',
    colorName: 'Habanero Gold',
    r: 254,
    g: 212,
    b: 80,
  },
  {
    pantoneId: 2098,
    name: '13-0851 TCX',
    majorColorName: 'Orange',
    colorName: 'Minion Yellow',
    r: 254,
    g: 213,
    b: 93,
  },
  {
    pantoneId: 2099,
    name: '13-2807 TCX',
    majorColorName: 'White',
    colorName: 'Ballerina',
    r: 242,
    g: 207,
    b: 220,
  },
  {
    pantoneId: 2100,
    name: '11-4801 TCX',
    majorColorName: 'White',
    colorName: 'Tofu',
    r: 232,
    g: 227,
    b: 217,
  },
  {
    pantoneId: 2101,
    name: '11-0619 TCX',
    majorColorName: 'Yellow',
    colorName: 'Flan',
    r: 246,
    g: 227,
    b: 180,
  },
  {
    pantoneId: 2102,
    name: '12-0717 TCX',
    majorColorName: 'Orange',
    colorName: 'Anise Flower',
    r: 244,
    g: 227,
    b: 181,
  },
  {
    pantoneId: 2103,
    name: '11-0605 TCX',
    majorColorName: 'White',
    colorName: 'Jet Stream',
    r: 237,
    g: 230,
    b: 222,
  },
  {
    pantoneId: 2104,
    name: '11-1302 TCX',
    majorColorName: 'White',
    colorName: 'Sea Salt',
    r: 241,
    g: 230,
    b: 222,
  },
  {
    pantoneId: 2105,
    name: '11-1001 TCX',
    majorColorName: 'White',
    colorName: 'White Alyssum',
    r: 239,
    g: 235,
    b: 231,
  },
  {
    pantoneId: 2106,
    name: '11-0615 TCX',
    majorColorName: 'Yellow',
    colorName: 'Pear Sorbet',
    r: 243,
    g: 234,
    b: 195,
  },
  {
    pantoneId: 2107,
    name: '11-0515 TCX',
    majorColorName: 'Orange',
    colorName: 'Lemon Icing',
    r: 246,
    g: 235,
    b: 200,
  },
  {
    pantoneId: 2108,
    name: '11-0106 TCX',
    majorColorName: 'White',
    colorName: 'Sweet Corn',
    r: 240,
    g: 234,
    b: 214,
  },
  {
    pantoneId: 2109,
    name: '11-4302 TCX',
    majorColorName: 'White',
    colorName: 'Cannoli Cream',
    r: 240,
    g: 239,
    b: 226,
  },
  {
    pantoneId: 2110,
    name: '11-0607 TCX',
    majorColorName: 'White',
    colorName: 'Sugar Swizzle',
    r: 243,
    g: 238,
    b: 231,
  },
  {
    pantoneId: 2111,
    name: '11-0608 TCX',
    majorColorName: 'White',
    colorName: 'Coconut Milk',
    r: 240,
    g: 237,
    b: 229,
  },
  {
    pantoneId: 2112,
    name: '13-3207 TCX',
    majorColorName: 'White',
    colorName: 'Cherry Blossom',
    r: 247,
    g: 206,
    b: 224,
  },
  {
    pantoneId: 2113,
    name: '13-1511 TCX',
    majorColorName: 'Pink',
    colorName: 'Pink Salt',
    r: 247,
    g: 205,
    b: 199,
  },
  {
    pantoneId: 2114,
    name: '13-1520 TCX',
    majorColorName: 'White',
    colorName: 'Rose Quartz',
    r: 247,
    g: 202,
    b: 201,
  },
  {
    pantoneId: 2115,
    name: '12-1110 TCX',
    majorColorName: 'White',
    colorName: 'Creme de Peche',
    r: 245,
    g: 214,
    b: 198,
  },
  {
    pantoneId: 2116,
    name: '12-1813 TCX',
    majorColorName: 'Orange',
    colorName: "Mary's Rose",
    r: 247,
    g: 209,
    b: 212,
  },
  {
    pantoneId: 2117,
    name: '12-0711 TCX',
    majorColorName: 'Orange',
    colorName: 'Lemon Meringue',
    r: 246,
    g: 225,
    b: 153,
  },
  {
    pantoneId: 2118,
    name: '11-0110 TCX',
    majorColorName: 'White',
    colorName: 'Buttercream',
    r: 239,
    g: 224,
    b: 205,
  },
  {
    pantoneId: 2119,
    name: '12-2907 TCX',
    majorColorName: 'White',
    colorName: 'Rose Water',
    r: 248,
    g: 224,
    b: 231,
  },
  {
    pantoneId: 2120,
    name: '19-3909 TCX',
    majorColorName: 'Gray',
    colorName: 'Black Bean',
    r: 46,
    g: 39,
    b: 42,
  },
  {
    pantoneId: 2121,
    name: '19-3922 TCX',
    majorColorName: 'Blue',
    colorName: 'Sky Captain',
    r: 38,
    g: 41,
    b: 52,
  },
  {
    pantoneId: 2122,
    name: '19-4004 TCX',
    majorColorName: 'Gray',
    colorName: 'Tap Shoe',
    r: 42,
    g: 43,
    b: 45,
  },
  {
    pantoneId: 2123,
    name: '19-5350 TCX',
    majorColorName: 'Blue',
    colorName: 'Scarab',
    r: 35,
    g: 49,
    b: 45,
  },
  {
    pantoneId: 2124,
    name: '19-4038 TCX',
    majorColorName: 'Blue',
    colorName: 'Gibraltar Sea',
    r: 18,
    g: 56,
    b: 80,
  },
  {
    pantoneId: 2125,
    name: '19-4034 TCX',
    majorColorName: 'Blue',
    colorName: 'Sailor Blue',
    r: 14,
    g: 58,
    b: 83,
  },
  {
    pantoneId: 2126,
    name: '19-4120 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Opal',
    r: 15,
    g: 59,
    b: 87,
  },
  {
    pantoneId: 2127,
    name: '19-4045 TCX',
    majorColorName: 'Blue',
    colorName: 'Lapis Blue',
    r: 0,
    g: 75,
    b: 141,
  },
  {
    pantoneId: 2128,
    name: '18-4048 TCX',
    majorColorName: 'Blue',
    colorName: 'Nebulas Blue',
    r: 45,
    g: 98,
    b: 163,
  },
  {
    pantoneId: 2129,
    name: '19-4033 TCX',
    majorColorName: 'Blue',
    colorName: 'Poseidon',
    r: 18,
    g: 57,
    b: 85,
  },
  {
    pantoneId: 2130,
    name: '18-4250 TCX',
    majorColorName: 'Blue',
    colorName: 'Indigo Bunting',
    r: 0,
    g: 108,
    b: 169,
  },
  {
    pantoneId: 2131,
    name: '19-5220 TCX',
    majorColorName: 'Blue',
    colorName: 'Botanical Garden',
    r: 18,
    g: 64,
    b: 60,
  },
  {
    pantoneId: 2132,
    name: '19-5232 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Rain Forest',
    r: 21,
    g: 70,
    b: 62,
  },
  {
    pantoneId: 2133,
    name: '19-5406 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Pine Grove',
    r: 33,
    g: 54,
    b: 49,
  },
  {
    pantoneId: 2134,
    name: '19-5230 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Forest Biome',
    r: 24,
    g: 74,
    b: 69,
  },
  {
    pantoneId: 2135,
    name: '17-4245 TCX',
    majorColorName: 'Blue',
    colorName: 'Ibiza Blue',
    r: 0,
    g: 124,
    b: 183,
  },
  {
    pantoneId: 2136,
    name: '19-5918 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Mountain View',
    r: 46,
    g: 61,
    b: 48,
  },
  {
    pantoneId: 2137,
    name: '19-4540 TCX',
    majorColorName: 'Blue',
    colorName: 'Deep Lagoon',
    r: 0,
    g: 82,
    b: 101,
  },
  {
    pantoneId: 2138,
    name: '18-4434 TCX',
    majorColorName: 'Blue',
    colorName: 'Mykonos Blue',
    r: 0,
    g: 87,
    b: 128,
  },
  {
    pantoneId: 2139,
    name: '19-4048 TCX',
    majorColorName: 'Blue',
    colorName: 'Baleine Blue',
    r: 21,
    g: 81,
    b: 135,
  },
  {
    pantoneId: 2140,
    name: '19-6050 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Eden',
    r: 38,
    g: 78,
    b: 54,
  },
  {
    pantoneId: 2141,
    name: '18-5845 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Lush Meadow',
    r: 0,
    g: 110,
    b: 81,
  },
  {
    pantoneId: 2142,
    name: '18-5025 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Quetzal Green',
    r: 0,
    g: 104,
    b: 101,
  },
  {
    pantoneId: 2143,
    name: '19-4536 TCX',
    majorColorName: 'Blue',
    colorName: 'Crystal Teal',
    r: 0,
    g: 99,
    b: 124,
  },
  {
    pantoneId: 2144,
    name: '18-6030 TCX',
    majorColorName: 'Green',
    colorName: 'Jolly Green',
    r: 0,
    g: 120,
    b: 68,
  },
  {
    pantoneId: 2145,
    name: '18-4630 TCX',
    majorColorName: 'Blue',
    colorName: 'Tahitian Tide',
    r: 0,
    g: 107,
    b: 126,
  },
  {
    pantoneId: 2146,
    name: '18-4538 TCX',
    majorColorName: 'Blue',
    colorName: 'Hawaiian Surf',
    r: 0,
    g: 120,
    b: 167,
  },
  {
    pantoneId: 2147,
    name: '18-4430 TCX',
    majorColorName: 'Blue',
    colorName: 'Fjord Blue',
    r: 0,
    g: 114,
    b: 144,
  },
  {
    pantoneId: 2148,
    name: '17-4530 TCX',
    majorColorName: 'Blue',
    colorName: 'Barrier Reef',
    r: 0,
    g: 132,
    b: 161,
  },
  {
    pantoneId: 2149,
    name: '19-4011 TCX',
    majorColorName: 'Blue',
    colorName: 'Salute',
    r: 40,
    g: 43,
    b: 52,
  },
  {
    pantoneId: 2150,
    name: '19-4020 TCX',
    majorColorName: 'Blue',
    colorName: 'Dark Sapphire',
    r: 38,
    g: 43,
    b: 55,
  },
  {
    pantoneId: 2151,
    name: '19-3831 TCX',
    majorColorName: 'Blue',
    colorName: 'Maritime Blue',
    r: 39,
    g: 41,
    b: 61,
  },
  {
    pantoneId: 2152,
    name: '19-3923 TCX',
    majorColorName: 'Blue',
    colorName: 'Navy Blazer',
    r: 40,
    g: 45,
    b: 60,
  },
  {
    pantoneId: 2153,
    name: '19-4012 TCX',
    majorColorName: 'Blue',
    colorName: 'Carbon',
    r: 39,
    g: 47,
    b: 56,
  },
  {
    pantoneId: 2154,
    name: '19-3953 TCX',
    majorColorName: 'Blue',
    colorName: 'Sodalite Blue',
    r: 37,
    g: 54,
    b: 104,
  },
  {
    pantoneId: 2155,
    name: '19-4029 TCX',
    majorColorName: 'Blue',
    colorName: 'Navy Peony',
    r: 34,
    g: 58,
    b: 94,
  },
  {
    pantoneId: 2156,
    name: '19-3964 TCX',
    majorColorName: 'Gray',
    colorName: 'Blue Quartz',
    r: 39,
    g: 67,
    b: 116,
  },
  {
    pantoneId: 2157,
    name: '19-4326 TCX',
    majorColorName: 'Blue',
    colorName: 'Reflecting Pond',
    r: 32,
    g: 62,
    b: 74,
  },
  {
    pantoneId: 2158,
    name: '19-5030 TCX',
    majorColorName: 'Gray',
    colorName: 'Sea Moss',
    r: 37,
    g: 68,
    b: 69,
  },
  {
    pantoneId: 2159,
    name: '19-1018 TCX',
    majorColorName: 'Pink',
    colorName: 'Ganache',
    r: 52,
    g: 41,
    b: 42,
  },
  {
    pantoneId: 2160,
    name: '19-3713 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Deep Well',
    r: 44,
    g: 42,
    b: 51,
  },
  {
    pantoneId: 2161,
    name: '19-3924 TCX',
    majorColorName: 'Blue',
    colorName: 'Night Sky',
    r: 42,
    g: 42,
    b: 53,
  },
  {
    pantoneId: 2162,
    name: '19-3815 TCX',
    majorColorName: 'Blue',
    colorName: 'Evening Blue',
    r: 42,
    g: 41,
    b: 62,
  },
  {
    pantoneId: 2163,
    name: '19-4218 TCX',
    majorColorName: 'Gray',
    colorName: 'Vulcan',
    r: 45,
    g: 48,
    b: 54,
  },
  {
    pantoneId: 2164,
    name: '19-4021 TCX',
    majorColorName: 'Blue',
    colorName: 'Blueberry',
    r: 44,
    g: 51,
    b: 62,
  },
  {
    pantoneId: 2165,
    name: '19-4122 TCX',
    majorColorName: 'Blue',
    colorName: 'Moonlit Ocean',
    r: 41,
    g: 59,
    b: 77,
  },
  {
    pantoneId: 2166,
    name: '19-4121 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Wing Teal',
    r: 44,
    g: 64,
    b: 83,
  },
  {
    pantoneId: 2167,
    name: '19-4126 TCX',
    majorColorName: 'Blue',
    colorName: 'Deep Dive',
    r: 41,
    g: 73,
    b: 92,
  },
  {
    pantoneId: 2168,
    name: '19-4055 TCX',
    majorColorName: 'Blue',
    colorName: 'Galaxy Blue',
    r: 42,
    g: 75,
    b: 124,
  },
  {
    pantoneId: 2169,
    name: '18-4034 TCX',
    majorColorName: 'Blue',
    colorName: 'Vallarta Blue',
    r: 48,
    g: 101,
    b: 142,
  },
  {
    pantoneId: 2170,
    name: '17-4429 TCX',
    majorColorName: 'Blue',
    colorName: 'Navagio Bay',
    r: 49,
    g: 131,
    b: 160,
  },
  {
    pantoneId: 2171,
    name: '19-1103 TCX',
    majorColorName: 'Gray',
    colorName: 'Espresso',
    r: 54,
    g: 48,
    b: 49,
  },
  {
    pantoneId: 2172,
    name: '19-1106 TCX',
    majorColorName: 'Gray',
    colorName: "Mole'",
    r: 57,
    g: 45,
    b: 43,
  },
  {
    pantoneId: 2173,
    name: '19-1109 TCX',
    majorColorName: 'Gray',
    colorName: 'Chocolate Torte',
    r: 56,
    g: 46,
    b: 45,
  },
  {
    pantoneId: 2174,
    name: '19-1110 TCX',
    majorColorName: 'Pink',
    colorName: 'Chocolate Plum',
    r: 60,
    g: 45,
    b: 46,
  },
  {
    pantoneId: 2175,
    name: '19-4009 TCX',
    majorColorName: 'Gray',
    colorName: 'Outer Space',
    r: 47,
    g: 52,
    b: 65,
  },
  {
    pantoneId: 2176,
    name: '19-4022 TCX',
    majorColorName: 'Blue',
    colorName: 'Parisian Night',
    r: 50,
    g: 52,
    b: 65,
  },
  {
    pantoneId: 2177,
    name: '19-4031 TCX',
    majorColorName: 'Blue',
    colorName: 'Sargasso Sea',
    r: 53,
    g: 67,
    b: 90,
  },
  {
    pantoneId: 2178,
    name: '18-0330 TCX',
    majorColorName: 'Green',
    colorName: 'Twist of Lime',
    r: 78,
    g: 99,
    b: 44,
  },
  {
    pantoneId: 2179,
    name: '19-3750 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Violet Indigo',
    r: 62,
    g: 40,
    b: 92,
  },
  {
    pantoneId: 2180,
    name: '19-3628 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Acai',
    r: 70,
    g: 41,
    b: 90,
  },
  {
    pantoneId: 2181,
    name: '19-3902 TCX',
    majorColorName: 'Gray',
    colorName: 'Obsidian',
    r: 58,
    g: 54,
    b: 59,
  },
  {
    pantoneId: 2182,
    name: '19-3927 TCX',
    majorColorName: 'Blue',
    colorName: 'Graphite',
    r: 59,
    g: 59,
    b: 72,
  },
  {
    pantoneId: 2183,
    name: '19-0840 TCX',
    majorColorName: 'Brown',
    colorName: 'Delicioso',
    r: 63,
    g: 53,
    b: 47,
  },
  {
    pantoneId: 2184,
    name: '19-0413 TCX',
    majorColorName: 'Yellow',
    colorName: 'Deep Depths',
    r: 70,
    g: 72,
    b: 60,
  },
  {
    pantoneId: 2185,
    name: '19-2620 TCX',
    majorColorName: 'Pink',
    colorName: 'Winter Bloom',
    r: 71,
    g: 36,
    b: 59,
  },
  {
    pantoneId: 2186,
    name: '19-2420 TCX',
    majorColorName: 'Pink',
    colorName: 'Pickled Beet',
    r: 77,
    g: 35,
    b: 61,
  },
  {
    pantoneId: 2187,
    name: '19-0201 TCX',
    majorColorName: 'Green',
    colorName: 'Asphalt',
    r: 67,
    g: 68,
    b: 71,
  },
  {
    pantoneId: 2188,
    name: '19-3930 TCX',
    majorColorName: 'Blue',
    colorName: 'Odyssey Gray',
    r: 67,
    g: 68,
    b: 82,
  },
  {
    pantoneId: 2189,
    name: '19-1419 TCX',
    majorColorName: 'Brown',
    colorName: 'Chicory Coffee',
    r: 74,
    g: 52,
    b: 46,
  },
  {
    pantoneId: 2190,
    name: '19-0307 TCX',
    majorColorName: 'Gray',
    colorName: 'Climbing Ivy',
    r: 68,
    g: 73,
    b: 64,
  },
  {
    pantoneId: 2191,
    name: '19-5621 TCX',
    majorColorName: 'Gray',
    colorName: 'Cilantro',
    r: 67,
    g: 84,
    b: 75,
  },
  {
    pantoneId: 2192,
    name: '19-3638 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Tillandsia Purple',
    r: 86,
    g: 52,
    b: 116,
  },
  {
    pantoneId: 2193,
    name: '19-0910 TCX',
    majorColorName: 'Brown',
    colorName: 'Mulch',
    r: 67,
    g: 57,
    b: 55,
  },
  {
    pantoneId: 2194,
    name: '18-3839 TCX',
    majorColorName: 'Blue',
    colorName: 'Purple Corallites',
    r: 90,
    g: 78,
    b: 143,
  },
  {
    pantoneId: 2195,
    name: '19-3907 TCX',
    majorColorName: 'Gray',
    colorName: 'Forged Iron',
    r: 72,
    g: 70,
    b: 74,
  },
  {
    pantoneId: 2196,
    name: '19-3918 TCX',
    majorColorName: 'Blue',
    colorName: 'Periscope',
    r: 70,
    g: 68,
    b: 76,
  },
  {
    pantoneId: 2197,
    name: '19-0203 TCX',
    majorColorName: 'Green',
    colorName: 'Gray Pinstripe',
    r: 73,
    g: 73,
    b: 77,
  },
  {
    pantoneId: 2198,
    name: '19-3917 TCX',
    majorColorName: 'Blue',
    colorName: 'Blackened Pearl',
    r: 77,
    g: 75,
    b: 80,
  },
  {
    pantoneId: 2199,
    name: '19-3929 TCX',
    majorColorName: 'Blue',
    colorName: 'Vintage Indigo',
    r: 74,
    g: 85,
    b: 107,
  },
  {
    pantoneId: 2200,
    name: '18-4028 TCX',
    majorColorName: 'Blue',
    colorName: 'Bering Sea',
    r: 75,
    g: 91,
    b: 110,
  },
  {
    pantoneId: 2201,
    name: '18-3917 TCX',
    majorColorName: 'Blue',
    colorName: 'Gray Blue',
    r: 77,
    g: 88,
    b: 122,
  },
  {
    pantoneId: 2202,
    name: '18-3929 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Horizon',
    r: 78,
    g: 100,
    b: 130,
  },
  {
    pantoneId: 2203,
    name: '17-4028 TCX',
    majorColorName: 'Blue',
    colorName: 'Riverside',
    r: 76,
    g: 106,
    b: 146,
  },
  {
    pantoneId: 2204,
    name: '17-4033 TCX',
    majorColorName: 'Blue',
    colorName: 'Pacific Coast',
    r: 84,
    g: 128,
    b: 172,
  },
  {
    pantoneId: 2205,
    name: '19-2429 TCX',
    majorColorName: 'Pink',
    colorName: 'Plum Caspia',
    r: 97,
    g: 34,
    b: 74,
  },
  {
    pantoneId: 2206,
    name: '19-1724 TCX',
    majorColorName: 'Pink',
    colorName: 'Cabernet',
    r: 100,
    g: 36,
    b: 46,
  },
  {
    pantoneId: 2207,
    name: '19-1432 TCX',
    majorColorName: 'Brown',
    colorName: 'Chocolate Fondant',
    r: 86,
    g: 53,
    b: 45,
  },
  {
    pantoneId: 2208,
    name: '19-1215 TCX',
    majorColorName: 'Pink',
    colorName: 'Shaved Chocolate',
    r: 84,
    g: 59,
    b: 53,
  },
  {
    pantoneId: 2209,
    name: '19-3901 TCX',
    majorColorName: 'Gray',
    colorName: 'Magnet',
    r: 77,
    g: 75,
    b: 79,
  },
  {
    pantoneId: 2210,
    name: '16-4033 TCX',
    majorColorName: 'Blue',
    colorName: 'Granada Sky',
    r: 93,
    g: 129,
    b: 187,
  },
  {
    pantoneId: 2211,
    name: '19-3900 TCX',
    majorColorName: 'Gray',
    colorName: 'Pavement',
    r: 82,
    g: 77,
    b: 80,
  },
  {
    pantoneId: 2212,
    name: '19-3910 TCX',
    majorColorName: 'Blue',
    colorName: 'Iron Gate',
    r: 78,
    g: 80,
    b: 85,
  },
  {
    pantoneId: 2213,
    name: '19-4215 TCX',
    majorColorName: 'Gray',
    colorName: 'Turbulence',
    r: 78,
    g: 84,
    b: 91,
  },
  {
    pantoneId: 2214,
    name: '17-1311 TCX',
    majorColorName: 'Brown',
    colorName: 'Desert Taupe',
    r: 141,
    g: 126,
    b: 113,
  },
  {
    pantoneId: 2215,
    name: '16-0543 TCX',
    majorColorName: 'Yellow',
    colorName: 'Golden Lime',
    r: 154,
    g: 151,
    b: 56,
  },
  {
    pantoneId: 2216,
    name: '16-0545 TCX',
    majorColorName: 'Yellow',
    colorName: 'Split Pea',
    r: 156,
    g: 154,
    b: 64,
  },
  {
    pantoneId: 2217,
    name: '16-3916 TCX',
    majorColorName: 'White',
    colorName: 'Sleet',
    r: 146,
    g: 148,
    b: 155,
  },
  {
    pantoneId: 2218,
    name: '16-3921 TCX',
    majorColorName: 'Blue',
    colorName: 'Blue Heron',
    r: 150,
    g: 163,
    b: 199,
  },
  {
    pantoneId: 2219,
    name: '15-3919 TCX',
    majorColorName: 'White',
    colorName: 'Serenity',
    r: 145,
    g: 168,
    b: 208,
  },
  {
    pantoneId: 2220,
    name: '14-4122 TCX',
    majorColorName: 'Blue',
    colorName: 'Airy Blue',
    r: 146,
    g: 182,
    b: 213,
  },
  {
    pantoneId: 2221,
    name: '14-4315 TCX',
    majorColorName: 'Blue',
    colorName: 'Sea Angel',
    r: 152,
    g: 191,
    b: 202,
  },
  {
    pantoneId: 2222,
    name: '14-0340 TCX',
    majorColorName: 'White',
    colorName: 'Acid Lime',
    r: 186,
    g: 223,
    b: 48,
  },
  {
    pantoneId: 2223,
    name: '18-1657 TCX',
    majorColorName: 'Pink',
    colorName: 'Salsa',
    r: 170,
    g: 24,
    b: 43,
  },
  {
    pantoneId: 2224,
    name: '18-1659 TCX',
    majorColorName: 'Pink',
    colorName: 'Goji Berry',
    r: 185,
    g: 18,
    b: 40,
  },
  {
    pantoneId: 2225,
    name: '18-1654 TCX',
    majorColorName: 'Pink',
    colorName: 'Lychee',
    r: 186,
    g: 11,
    b: 50,
  },
  {
    pantoneId: 2226,
    name: '18-1759 TCX',
    majorColorName: 'Pink',
    colorName: 'Jalapeno Red',
    r: 178,
    g: 16,
    b: 60,
  },
  {
    pantoneId: 2227,
    name: '19-1662 TCX',
    majorColorName: 'Red',
    colorName: 'Samba',
    r: 162,
    g: 36,
    b: 47,
  },
  {
    pantoneId: 2228,
    name: '19-2039 TCX',
    majorColorName: 'Pink',
    colorName: 'Granita',
    r: 165,
    g: 35,
    b: 80,
  },
  {
    pantoneId: 2229,
    name: '19-2041 TCX',
    majorColorName: 'Pink',
    colorName: 'Cherries Jubilee',
    r: 162,
    g: 36,
    b: 82,
  },
  {
    pantoneId: 2230,
    name: '19-2045 TCX',
    majorColorName: 'Pink',
    colorName: 'Vivacious',
    r: 163,
    g: 40,
    b: 87,
  },
  {
    pantoneId: 2231,
    name: '18-1355 TCX',
    majorColorName: 'Brown',
    colorName: 'Rooibos Tea',
    r: 162,
    g: 60,
    b: 38,
  },
  {
    pantoneId: 2232,
    name: '18-1050 TCX',
    majorColorName: 'Brown',
    colorName: 'Honey Ginger',
    r: 168,
    g: 98,
    b: 23,
  },
  {
    pantoneId: 2233,
    name: '17-1342 TCX',
    majorColorName: 'Brown',
    colorName: 'Autumnal',
    r: 161,
    g: 83,
    b: 37,
  },
  {
    pantoneId: 2234,
    name: '18-3530 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Lavender Crystal',
    r: 147,
    g: 106,
    b: 152,
  },
  {
    pantoneId: 2235,
    name: '17-1038 TCX',
    majorColorName: 'Brown',
    colorName: "Tiger's Eye",
    r: 151,
    g: 124,
    b: 97,
  },
  {
    pantoneId: 2236,
    name: '17-1316 TCX',
    majorColorName: 'Brown',
    colorName: 'Portabella',
    r: 147,
    g: 123,
    b: 106,
  },
  {
    pantoneId: 2237,
    name: '17-1105 TCX',
    majorColorName: 'Brown',
    colorName: 'Roasted Cashew',
    r: 145,
    g: 133,
    b: 121,
  },
  {
    pantoneId: 2238,
    name: '17-3520 TCX',
    majorColorName: 'Gray',
    colorName: 'Diffused Orchid',
    r: 152,
    g: 121,
    b: 162,
  },
  {
    pantoneId: 2239,
    name: '17-3617 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'English Lavender',
    r: 157,
    g: 123,
    b: 176,
  },
  {
    pantoneId: 2240,
    name: '17-3735 TCX',
    majorColorName: 'Light-Purple',
    colorName: 'Sunlit Allium',
    r: 151,
    g: 135,
    b: 184,
  },
  {
    pantoneId: 2241,
    name: '16-1109 TCX',
    majorColorName: 'Brown',
    colorName: 'Greige',
    r: 146,
    g: 132,
    b: 117,
  },
  {
    pantoneId: 2242,
    name: '17-1108 TCX',
    majorColorName: 'Brown',
    colorName: 'Winter Twig',
    r: 148,
    g: 138,
    b: 122,
  },
  {
    pantoneId: 2243,
    name: '16-1606 TCX',
    majorColorName: 'Pink',
    colorName: 'Purple Dove',
    r: 152,
    g: 135,
    b: 140,
  },
  {
    pantoneId: 2244,
    name: '17-0630 TCX',
    majorColorName: 'Yellow',
    colorName: 'Tree House',
    r: 152,
    g: 140,
    b: 117,
  },
  {
    pantoneId: 2245,
    name: '16-3800 TCX',
    majorColorName: 'Gray',
    colorName: 'Satellite',
    r: 151,
    g: 141,
    b: 137,
  },
  {
    pantoneId: 2246,
    name: '16-4400 TCX',
    majorColorName: 'Gray',
    colorName: 'Mourning Dove',
    r: 148,
    g: 144,
    b: 139,
  },
  {
    pantoneId: 2247,
    name: '16-5101 TCX',
    majorColorName: 'White',
    colorName: 'Wet Weather',
    r: 146,
    g: 144,
    b: 144,
  },
  {
    pantoneId: 2248,
    name: '16-3915 TCX',
    majorColorName: 'White',
    colorName: 'Alloy',
    r: 152,
    g: 151,
    b: 154,
  },
  {
    pantoneId: 2249,
    name: '16-3905 TCX',
    majorColorName: 'White',
    colorName: 'Lilac Gray',
    r: 152,
    g: 150,
    b: 164,
  },
  {
    pantoneId: 2250,
    name: '15-3912 TCX',
    majorColorName: 'White',
    colorName: 'Aleutian',
    r: 154,
    g: 158,
    b: 179,
  },
  {
    pantoneId: 2251,
    name: '14-3912 TCX',
    majorColorName: 'White',
    colorName: 'Zen Blue',
    r: 159,
    g: 169,
    b: 190,
  },
  {
    pantoneId: 2252,
    name: '16-3922 TCX',
    majorColorName: 'Blue',
    colorName: 'Brunnera Blue',
    r: 155,
    g: 169,
    b: 202,
  },
  {
    pantoneId: 2253,
    name: '15-4030 TCX',
    majorColorName: 'White',
    colorName: 'Chambray Blue',
    r: 158,
    g: 180,
    b: 211,
  },
  {
    pantoneId: 2254,
    name: '13-0443 TCX',
    majorColorName: 'Green',
    colorName: 'Love Bird',
    r: 192,
    g: 219,
    b: 58,
  },
  {
    pantoneId: 2255,
    name: '18-1653 TCX',
    majorColorName: 'Pink',
    colorName: 'Toreador',
    r: 182,
    g: 16,
    b: 50,
  },
  {
    pantoneId: 2256,
    name: '18-1551 TCX',
    majorColorName: 'Red',
    colorName: 'Aura Orange',
    r: 180,
    g: 38,
    b: 42,
  },
  {
    pantoneId: 2257,
    name: '18-1049 TCX',
    majorColorName: 'Brown',
    colorName: 'Thai Curry',
    r: 171,
    g: 104,
    b: 25,
  },
  {
    pantoneId: 2258,
    name: '16-1438 TCX',
    majorColorName: 'Pink',
    colorName: 'Meerkat',
    r: 164,
    g: 111,
    b: 68,
  },
  {
    pantoneId: 2259,
    name: '17-1609 TCX',
    majorColorName: 'Pink',
    colorName: 'Mesa Rose',
    r: 166,
    g: 110,
    b: 122,
  },
  {
    pantoneId: 2260,
    name: '17-2411 TCX',
    majorColorName: 'Pink',
    colorName: 'Toadstool',
    r: 152,
    g: 128,
    b: 136,
  },
  {
    pantoneId: 2261,
    name: '16-3817 TCX',
    majorColorName: 'White',
    colorName: 'Rhapsody',
    r: 159,
    g: 134,
    b: 170,
  },
  {
    pantoneId: 2262,
    name: '16-3720 TCX',
    majorColorName: 'Pink',
    colorName: 'Sand Verbena',
    r: 159,
    g: 144,
    b: 193,
  },
  {
    pantoneId: 2263,
    name: '17-0949 TCX',
    majorColorName: 'Brown',
    colorName: 'Chai Tea',
    r: 177,
    g: 131,
    b: 47,
  },
  {
    pantoneId: 2264,
    name: '15-3716 TCX',
    majorColorName: 'Blue',
    colorName: 'Purple Rose',
    r: 176,
    g: 159,
    b: 202,
  },
  {
    pantoneId: 2265,
    name: '15-3720 TCX',
    majorColorName: 'Blue',
    colorName: 'Lilac Breeze',
    r: 179,
    g: 160,
    b: 201,
  },
  {
    pantoneId: 2266,
    name: '16-0205 TCX',
    majorColorName: 'Gray',
    colorName: 'Vintage Khaki',
    r: 154,
    g: 145,
    b: 134,
  },
  {
    pantoneId: 2267,
    name: '16-4703 TCX',
    majorColorName: 'Gray',
    colorName: 'Ghost Gray',
    r: 156,
    g: 155,
    b: 152,
  },
  {
    pantoneId: 2268,
    name: '16-3850 TCX',
    majorColorName: 'Gray',
    colorName: 'Silver Sconce',
    r: 161,
    g: 159,
    b: 165,
  },
  {
    pantoneId: 2269,
    name: '16-0550 TCX',
    majorColorName: 'Yellow',
    colorName: 'Lentil Sprout',
    r: 171,
    g: 164,
    b: 77,
  },
  {
    pantoneId: 2270,
    name: '15-4306 TCX',
    majorColorName: 'Gray',
    colorName: 'Belgian Block',
    r: 163,
    g: 169,
    b: 166,
  },
  {
    pantoneId: 2271,
    name: '14-4311 TCX',
    majorColorName: 'Blue',
    colorName: 'Corydalis Blue',
    r: 169,
    g: 202,
    b: 218,
  },
  {
    pantoneId: 2272,
    name: '14-4317 TCX',
    majorColorName: 'Blue',
    colorName: 'Cool Blue',
    r: 165,
    g: 197,
    b: 217,
  },
  {
    pantoneId: 2273,
    name: '13-0651 TCX',
    majorColorName: 'Yellow',
    colorName: 'Evening Primrose',
    r: 204,
    g: 219,
    b: 30,
  },
  {
    pantoneId: 2274,
    name: '13-5410 TCX',
    majorColorName: 'Blue-Green',
    colorName: 'Iced Aqua',
    r: 171,
    g: 211,
    b: 219,
  },
  {
    pantoneId: 2275,
    name: '18-1950 TCX',
    majorColorName: 'Pink',
    colorName: 'Jazzy',
    r: 182,
    g: 28,
    b: 80,
  },
  {
    pantoneId: 2276,
    name: '18-1549 TCX',
    majorColorName: 'Pink',
    colorName: 'Valiant Poppy',
    r: 188,
    g: 50,
    b: 44,
  },
  {
    pantoneId: 2277,
    name: '18-1249 TCX',
    majorColorName: 'Brown',
    colorName: 'Hawaiian Sunset',
    r: 187,
    g: 92,
    b: 20,
  },
  {
    pantoneId: 2278,
    name: '16-1449 TCX',
    majorColorName: 'Orange',
    colorName: 'Gold Flame',
    r: 180,
    g: 84,
    b: 34,
  },
  {
    pantoneId: 2279,
    name: '17-3240 TCX',
    majorColorName: 'Gray',
    colorName: 'Bodacious',
    r: 183,
    g: 107,
    b: 163,
  },
  {
    pantoneId: 2280,
    name: '15-1506 TCX',
    majorColorName: 'Orange',
    colorName: 'Etherea',
    r: 165,
    g: 149,
    b: 143,
  },
  {
    pantoneId: 2281,
    name: '16-1104 TCX',
    majorColorName: 'Brown',
    colorName: 'Crockery',
    r: 164,
    g: 152,
    b: 135,
  },
  {
    pantoneId: 2282,
    name: '16-1120 TCX',
    majorColorName: 'Brown',
    colorName: 'Starfish',
    r: 176,
    g: 154,
    b: 119,
  },
  {
    pantoneId: 2283,
    name: '15-3800 TCX',
    majorColorName: 'White',
    colorName: 'Porpoise',
    r: 167,
    g: 161,
    b: 158,
  },
  {
    pantoneId: 2284,
    name: '16-1103 TCX',
    majorColorName: 'Brown',
    colorName: 'Pure Cashmere',
    r: 173,
    g: 163,
    b: 150,
  },
  {
    pantoneId: 2285,
    name: '14-3906 TCX',
    majorColorName: 'White',
    colorName: 'Raindrops',
    r: 177,
    g: 170,
    b: 179,
  },
  {
    pantoneId: 2286,
    name: '15-3908 TCX',
    majorColorName: 'White',
    colorName: 'Icelandic Blue',
    r: 169,
    g: 173,
    b: 194,
  },
  {
    pantoneId: 2287,
    name: '13-3920 TCX',
    majorColorName: 'White',
    colorName: 'Halogen Blue',
    r: 189,
    g: 198,
    b: 220,
  },
  {
    pantoneId: 2288,
    name: '14-4202 TCX',
    majorColorName: 'White',
    colorName: 'Harbor Mist',
    r: 175,
    g: 177,
    b: 180,
  },
  {
    pantoneId: 2289,
    name: '18-1951 TCX',
    majorColorName: 'Pink',
    colorName: 'Love Potion',
    r: 192,
    g: 19,
    b: 82,
  },
  {
    pantoneId: 2290,
    name: '18-2045 TCX',
    majorColorName: 'Pink',
    colorName: 'Pink Peacock',
    r: 198,
    g: 33,
    b: 104,
  },
  {
    pantoneId: 2291,
    name: '17-1449 TCX',
    majorColorName: 'Pink',
    colorName: 'Pureed Pumpkin',
    r: 195,
    g: 65,
    b: 33,
  },
  {
    pantoneId: 2292,
    name: '17-1145 TCX',
    majorColorName: 'Brown',
    colorName: 'Autumn Maple',
    r: 196,
    g: 98,
    b: 21,
  },
  {
    pantoneId: 2293,
    name: '17-1450 TCX',
    majorColorName: 'Red',
    colorName: 'Summer Fig',
    r: 190,
    g: 75,
    b: 59,
  },
  {
    pantoneId: 2294,
    name: '17-1140 TCX',
    majorColorName: 'Brown',
    colorName: 'Marmalade',
    r: 193,
    g: 101,
    b: 18,
  },
  {
    pantoneId: 2295,
    name: '17-1345 TCX',
    majorColorName: 'Brown',
    colorName: 'Spice Route',
    r: 185,
    g: 91,
    b: 63,
  },
  {
    pantoneId: 2296,
    name: '16-1710 TCX',
    majorColorName: 'Pink',
    colorName: 'Foxglove',
    r: 185,
    g: 131,
    b: 145,
  },
  {
    pantoneId: 2297,
    name: '15-1040 TCX',
    majorColorName: 'Orange',
    colorName: 'Iced Coffee',
    r: 177,
    g: 143,
    b: 106,
  },
  {
    pantoneId: 2298,
    name: '15-1114 TCX',
    majorColorName: 'Brown',
    colorName: 'Travertine',
    r: 174,
    g: 153,
    b: 125,
  },
  {
    pantoneId: 2299,
    name: '15-3520 TCX',
    majorColorName: 'White',
    colorName: 'Crocus Petal',
    r: 185,
    g: 155,
    b: 197,
  },
  {
    pantoneId: 2300,
    name: '15-1307 TCX',
    majorColorName: 'White',
    colorName: 'White Pepper',
    r: 182,
    g: 168,
    b: 147,
  },
  {
    pantoneId: 2301,
    name: '14-0116 TCX',
    majorColorName: 'White',
    colorName: 'Margarita',
    r: 181,
    g: 195,
    b: 142,
  },
  {
    pantoneId: 2302,
    name: '14-4105 TCX',
    majorColorName: 'White',
    colorName: 'Micro Chip',
    r: 186,
    g: 188,
    b: 192,
  },
  {
    pantoneId: 2303,
    name: '14-4107 TCX',
    majorColorName: 'White',
    colorName: 'Quiet Gray',
    r: 185,
    g: 186,
    b: 189,
  },
  {
    pantoneId: 2304,
    name: '14-3949 TCX',
    majorColorName: 'White',
    colorName: 'Xenon Blue',
    r: 183,
    g: 192,
    b: 215,
  },
  {
    pantoneId: 2305,
    name: '13-4200 TCX',
    majorColorName: 'White',
    colorName: 'Omphalodes',
    r: 181,
    g: 206,
    b: 223,
  },
  {
    pantoneId: 2306,
    name: '17-2034 TCX',
    majorColorName: 'Pink',
    colorName: 'Pink Yarrow',
    r: 206,
    g: 49,
    b: 117,
  },
  {
    pantoneId: 2307,
    name: '18-1564 TCX',
    majorColorName: 'Red',
    colorName: 'Poinciana',
    r: 202,
    g: 52,
    b: 34,
  },
  {
    pantoneId: 2308,
    name: '18-2140 TCX',
    majorColorName: 'Pink',
    colorName: 'Cabaret',
    r: 203,
    g: 51,
    b: 115,
  },
  {
    pantoneId: 2309,
    name: '17-1926 TCX',
    majorColorName: 'Pink',
    colorName: 'Fruit Dove',
    r: 206,
    g: 91,
    b: 120,
  },
  {
    pantoneId: 2310,
    name: '16-1149 TCX',
    majorColorName: 'Orange',
    colorName: 'Desert Sun',
    r: 200,
    g: 118,
    b: 41,
  },
];
