import ChevronLeftIcon from 'assets/icons/ChevronLeftIcon';
import ChevronRightIcon from 'assets/icons/ChevronRightIcon';

const Pagination = ({ totalPages, currentPage, changePage, prevPage, nextPage }) => {
  const getPageNumbers = () => {
    const maxPagesToShow = 5;
    const pageNumbers = [];

    if (totalPages <= maxPagesToShow) {
      return Array.from({ length: totalPages }, (_, i) => i);
    }

    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages - 2, currentPage + 1);

    pageNumbers.push(0);

    if (startPage > 1) {
      pageNumbers.push('ellipsis-left');
    }

    pageNumbers.push(...Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i));

    if (endPage < totalPages - 2) {
      pageNumbers.push('ellipsis-right');
    }

    pageNumbers.push(totalPages - 1);

    return pageNumbers;
  };

  return (
    <div className="flex justify-center gap-3 mt-8 sm:mt-12">
      <button
        disabled={currentPage === 0}
        onClick={prevPage}
        className="w-8 sm:w-10 h-8 sm:h-10 flex items-center justify-center"
      >
        <ChevronLeftIcon
          className={`w-5 sm:w-6 h-5 sm:h-6 ${
            currentPage === 0 ? 'fill-app-icon-disabled' : 'fill-app-icon-primary'
          } transition-all`}
        />
      </button>

      <div className="flex rounded-lg">
        {getPageNumbers().map((page, index) => (
          <button
            key={index}
            onClick={() =>
              page !== 'ellipsis-left' && page !== 'ellipsis-right' && changePage(page)
            }
            disabled={page === 'ellipsis-left' || page === 'ellipsis-right'}
            className={`w-8 sm:w-10 h-8 sm:h-10 text-center flex items-center justify-center text-sm sm:text-base ${
              page === currentPage ? 'bg-app-brand text-white rounded-lg' : ''
            }`}
          >
            {page === 'ellipsis-left' || page === 'ellipsis-right' ? '...' : page + 1}
          </button>
        ))}
      </div>

      <button
        disabled={currentPage === totalPages - 1}
        onClick={nextPage}
        className="w-8 sm:w-10 h-8 sm:h-10 flex items-center justify-center"
      >
        <ChevronRightIcon
          className={`w-5 sm:w-6 h-5 sm:h-6 ${
            currentPage === totalPages - 1 ? 'fill-app-icon-disabled' : 'fill-app-icon-primary'
          } transition-all`}
        />
      </button>
    </div>
  );
};

export default Pagination;
