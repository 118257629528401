import CloseSquaredIcon from 'assets/icons/CloseSquaredIcon';
import React, { useEffect } from 'react';

function AppModal({ withClose, onClose, children }) {
  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => document.body.classList.remove('modal-open');
  }, []);

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center z-40">
      <div className="bg-app-black-primary z-50 app-modal p-4 sm:p-8 flex flex-col gap-3 sm:gap-6 shadow-md rounded-md">
        {withClose && (
          <div className="text-right">
            <button onClick={onClose}>
              <CloseSquaredIcon className="h-5 w-5 sm:h-6 sm:w-6 fill-app-white-primary" />
            </button>
          </div>
        )}
        {children}
      </div>
      <div className="fixed top-0 bottom-0 left-0 right-0 bg-app-black-primary/40 z-40" />
    </div>
  );
}

export default AppModal;
