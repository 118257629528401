// Custom usePathname hook
import { useState, useEffect } from 'react';

const usePathname = () => {
  const [pathname, setPathname] = useState(window.location.pathname);

  useEffect(() => {
    const updatePathname = () => setPathname(window.location.pathname);

    // Listen for popstate event (triggered on back/forward navigation)
    window.addEventListener('popstate', updatePathname);

    // Listen for pushstate and replacestate events (triggered by navigate actions)
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(this, args);
      updatePathname();
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(this, args);
      updatePathname();
    };

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('popstate', updatePathname);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  return pathname;
};

export default usePathname;
