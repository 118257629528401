import { useCallback, useState } from 'react';

function usePagination({ itemsPerPage, dataLength }) {
  const [currentPage, setCurrentPage] = useState(0);
  const startOffset = itemsPerPage * currentPage;
  const endOffset = startOffset + itemsPerPage;

  const prevPage = useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  const nextPage = useCallback(() => {
    if (currentPage < dataLength / itemsPerPage - 1) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, dataLength, itemsPerPage]);

  const changePage = useCallback(
    (page) => {
      if (page >= 0 && page < dataLength / itemsPerPage) {
        setCurrentPage(page);
      }
    },
    [dataLength, itemsPerPage]
  );

  return {
    currentPage,
    changePage,
    startOffset,
    endOffset,
    prevPage,
    nextPage,
    itemsPerPage,
  };
}

export default usePagination;
