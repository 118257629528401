import { createContext, useContext, useState } from 'react';

export const AuthContext = createContext();
export const AuthUpdateContext = createContext();

export const useAuthState = () => {
  return {
    authState: useContext(AuthContext),
    setAuthState: useContext(AuthUpdateContext),
  };
};

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    user: {
      cd: '',
      name: '',
    },
    credit: 0,
    exp: 0,
  });

  return (
    <AuthContext.Provider value={authState}>
      <AuthUpdateContext.Provider value={setAuthState}>
        {children}
      </AuthUpdateContext.Provider>
    </AuthContext.Provider>
  );
};
