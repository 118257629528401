import SpinnerIcon from 'assets/icons/SpinnerIcon';

function Button({
  type = 'primary',
  disabled,
  onClick,
  icon,
  text,
  className,
  key,
  isSubmitting,
}) {
  return (
    <button
      type="button"
      key={key}
      disabled={disabled || isSubmitting}
      onClick={onClick}
      className={`py-2 px-2 sm:px-3 min-w-28 font-bold rounded text-sm sm:text-base flex gap-1 justify-center items-center
        ${
          disabled || isSubmitting
            ? type === 'secondary'
              ? 'border border-app-disabled text-app-disabled'
              : 'border border-app-disabled bg-app-disabled text-app-black-primary'
            : type === 'secondary'
            ? 'border border-app-brand text-app-brand'
            : 'bg-app-brand text-app-black-primary border border-app-brand'
        } 
        ${className}`}
    >
      {isSubmitting ? (
        <SpinnerIcon
          className={`h-6 w-6 ${
            type === 'secondary' ? 'fill-app-brand' : 'fill-app-black-primary'
          } animate-spin mx-auto`}
        />
      ) : (
        <>
          <div className="opacity-60">{icon}</div>
          <p className="text-nowrap">{text}</p>
        </>
      )}
    </button>
  );
}

export default Button;
