import React from 'react';
import SectionLabel from './SectionLabel';
import ChevronLeftIcon from 'assets/icons/ChevronLeftIcon';

const Select = ({ className, options, placeholder, selectedOption, setSelectedOption }) => {
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className={`space-y-2 sm:space-y-4 ${className}`}>
      <SectionLabel title="Fabric Type" />
      <div className="relative">
        <select
          id="inputSelect"
          value={selectedOption}
          onChange={handleSelectChange}
          className="block w-full px-3 py-2 bg-app-black-primary border border-gray-300 rounded-md shadow-sm text-xs sm:text-sm appearance-none"
        >
          <option value="">{placeholder}</option>
          {options?.map((option, index) => (
            <option
              key={index}
              value={option?.value}
            >
              {option?.label}
            </option>
          ))}
        </select>
        <ChevronLeftIcon className="absolute right-0 top-0 mt-2 sm:mt-3 mr-2 w-4 h-4 fill-app-white-primary -rotate-90" />
      </div>
    </div>
  );
};

export default Select;
