import { useCallback, useState } from 'react';
import { useAuthState } from 'context/AuthContext';
import { decodeToken, setCookie } from 'utils/helpers';
import { useLoadingState } from 'context/LoadingContext';
import api from 'api';

function useFetch(request) {
  const { setAuthState } = useAuthState();
  const { setLoading: setLoadingProgress } = useLoadingState();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const fetch = useCallback(
    async ({ query, params, data, headers, onSuccess } = {}) => {
      try {
        setLoading(true);
        setLoadingProgress({ isLoading: true });
        setIsSubmitting(true);
        const paramKeys = params ? Object.keys(params) : [];
        const response = await api.request({
          method: request.method,
          url: params ? request.url.replace(`:${paramKeys[0]}`, params[paramKeys[0]]) : request.url,
          params: query || undefined,
          data: data || undefined,
          headers: {
            'Content-Type': 'application/json',
            ...headers,
          },
        });
        setData(response.data);

        if (response?.data?.access_token) {
          setCookie('ut', response?.data?.access_token);
          const decoded = decodeToken(response?.data?.access_token);
          if (decoded) {
            setAuthState(decoded);
          }
        }

        if (response.data?.refresh_token) {
          setCookie('rt', response.data?.refresh_token);
        }

        if (onSuccess) {
          onSuccess(response.data);
        }

        return response.data;
      } catch (error) {
        setError(error);
        return { error };
      } finally {
        setIsSubmitting(false);
        setLoading(false);
        setLoadingProgress({ isLoading: false });
      }
    },
    [request.method, request.url, setAuthState, setLoadingProgress]
  );

  return {
    data,
    loading,
    isSubmitting,
    error,
    fetch,
  };
}

export default useFetch;
