import React from 'react';

function AppCard({ children, className }) {
  return (
    <div
      className={`p-6 bg-app-black-primary rounded-xl shadow border border-app-black-secondary ${
        className ? className : ''
      }`}
    >
      {children}
    </div>
  );
}

export default AppCard;
