import React from 'react';

function EyeIcon({ className = 'h-6 w-6 fill-app-icon-primary' }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 16.08C13.1364 16.08 14.1023 15.6833 14.8977 14.89C15.6932 14.0967 16.0909 13.1333 16.0909 12C16.0909 10.8667 15.6932 9.90335 14.8977 9.11001C14.1023 8.31668 13.1364 7.92001 12 7.92001C10.8636 7.92001 9.89773 8.31668 9.10227 9.11001C8.30682 9.90335 7.90909 10.8667 7.90909 12C7.90909 13.1333 8.30682 14.0967 9.10227 14.89C9.89773 15.6833 10.8636 16.08 12 16.08ZM12 14.448C11.3182 14.448 10.7386 14.21 10.2614 13.734C9.78409 13.258 9.54545 12.68 9.54545 12C9.54545 11.32 9.78409 10.742 10.2614 10.266C10.7386 9.79001 11.3182 9.55201 12 9.55201C12.6818 9.55201 13.2614 9.79001 13.7386 10.266C14.2159 10.742 14.4545 11.32 14.4545 12C14.4545 12.68 14.2159 13.258 13.7386 13.734C13.2614 14.21 12.6818 14.448 12 14.448ZM12 18.8C9.78788 18.8 7.77273 18.1842 5.95455 16.9527C4.13636 15.7211 2.81818 14.0702 2 12C2.81818 9.92979 4.13636 8.2789 5.95455 7.04735C7.77273 5.81579 9.78788 5.20001 12 5.20001C14.2121 5.20001 16.2273 5.81579 18.0455 7.04735C19.8636 8.2789 21.1818 9.92979 22 12C21.1818 14.0702 19.8636 15.7211 18.0455 16.9527C16.2273 18.1842 14.2121 18.8 12 18.8ZM12 16.9867C13.7121 16.9867 15.2841 16.5371 16.7159 15.638C18.1477 14.7389 19.2424 13.5262 20 12C19.2424 10.4738 18.1477 9.26112 16.7159 8.36201C15.2841 7.4629 13.7121 7.01335 12 7.01335C10.2879 7.01335 8.71591 7.4629 7.28409 8.36201C5.85227 9.26112 4.75758 10.4738 4 12C4.75758 13.5262 5.85227 14.7389 7.28409 15.638C8.71591 16.5371 10.2879 16.9867 12 16.9867Z" />
    </svg>
  );
}

export default EyeIcon;
