import React, { useCallback, useEffect, useRef, useState } from 'react';

import Tab1 from './components/Tab1';
import Tab2 from './components/Tab2';
import Tab3 from './components/Tab3';

import useFetch from 'utils/hooks/useFetch';
import {
  API_BASE_URL,
  FIND_FABRICS_WITH_IMAGE,
  FIND_FABRICS_WITH_TEXT,
  GENERATE_FABRICS,
  GENERATE_MAPS,
  GET_HISTORY,
  SAVE,
} from 'utils/constants/api';
import { NUM_OF_OUTPUTS, STEP1_TABS } from 'utils/constants';
import VersionHistory from './components/version-history/VersionHistory';
import { useSearchParams } from 'react-router-dom';
import { useNavbarState } from 'context/NavbarContext';
import { urlToFile } from 'utils/helpers';

function Generator() {
  const endOfPageRef = useRef(null);
  const { navbarState } = useNavbarState();

  const { isSubmitting: submittingFindFabricWithText, fetch: findFabricsWithText } =
    useFetch(FIND_FABRICS_WITH_TEXT);
  const { isSubmitting: submittingFindFabricWithImages, fetch: findFabricsWithImages } =
    useFetch(FIND_FABRICS_WITH_IMAGE);
  const { isSubmitting: submittingGenerateFabrics, fetch: generateFabrics } =
    useFetch(GENERATE_FABRICS);
  const { isSubmitting: submittingGenerateMaps, fetch: generateMaps } = useFetch(GENERATE_MAPS);
  const { data: history, fetch: getHistory } = useFetch(GET_HISTORY);
  const { fetch: saveResults } = useFetch(SAVE);

  const [searchParams] = useSearchParams();
  const fabricCd = searchParams.get('f_cd');

  const [promptImages, setPromptImages] = useState([]);
  const [promptMessage, setPromptMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [fabricType, setFabricType] = useState('');

  const [specificPrompt, setSpecificPrompt] = useState('');
  const [selectedGenImage, setSelectedGenImage] = useState(null);
  const [numOfOutputs, setNumOfOutputs] = useState(NUM_OF_OUTPUTS[0]);
  const [selectedCustomization, setSelectedCustomization] = useState({
    fabricType: null,
    colors: [],
    patterns: [],
  });
  const [fabrics, setFabrics] = useState(null);
  const [generatedFabrics, setGeneratedFabrics] = useState([]);
  const [maps, setMaps] = useState(null);

  const [activeTab, setActiveTab] = useState(1);
  const [activeStep1Tab, setActiveStep1Tab] = useState(STEP1_TABS[0]);
  const [isSaved, setIsSaved] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    setPromptMessage(value);
  };

  const clearResults = () => {
    setFabrics(null);
    setSelectedImage(null);
  };

  const handleFindFabricsWithText = async () => {
    clearResults();
    const formData = new FormData();
    formData.append('message', `${fabricType}, ${promptMessage}`);
    formData.append('image_count', '3');
    const response = await findFabricsWithText({
      data: formData,
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });

    setFabrics(response);
  };

  const handleFindFabricsWithImages = async () => {
    clearResults();
    const formData = new FormData();
    Array.from(promptImages).forEach((image) => {
      formData.append('image', image?.file);
    });
    formData.append('image_count', '3');

    const response = await findFabricsWithImages({
      data: formData,
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
    setFabrics(response);
  };

  const handleGenerateFabrics = async () => {
    setGeneratedFabrics([]);
    const formData = new FormData();

    formData.append('message', specificPrompt);
    formData.append('input_type', selectedCustomization?.fabricType);
    formData.append('input_color', Array.from(selectedCustomization?.colors)?.join(', '));
    formData.append('input_pattern', Array.from(selectedCustomization?.patterns)?.join(', '));
    formData.append('image_count', numOfOutputs);
    formData.append('fabric_detail_cd', fabrics?.data?.fabric_detail_cd);
    formData.append('choice', selectedImage?.no);

    const response = await generateFabrics({
      data: formData,
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
    setGeneratedFabrics(response?.data);
  };

  const handleGenerateMaps = async (image) => {
    if (generatedFabrics?.fabric_detail_cd && image?.no) {
      const formData = new FormData();
      formData.append('fabric_detail_cd', generatedFabrics?.fabric_detail_cd);
      formData.append('choice', image?.no);
      const res = await generateMaps({
        data: formData,
        headers: {
          'Content-type': 'multipart/form-data',
        },
      });
      const data = res?.data;
      const _maps = data?.image_paths?.[data?.choice];
      setMaps(_maps);
    }
  };

  const handleSaveResults = async () => {
    const formData = new FormData();
    formData.append('previous_fabric_detail_cd', generatedFabrics?.fabric_detail_cd);
    formData.append('fabric_detail_cd', maps?.fabric_detail_cd);
    formData.append('choice', selectedGenImage?.no);

    await saveResults({
      data: formData,
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  };

  const scrollToTop = useCallback((behavior = 'instant') => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior });
    }, 100);
  }, []);

  const handleChangeTab = useCallback(
    (id) => {
      setActiveTab(id);
      scrollToTop('smooth');
    },
    [scrollToTop]
  );

  const handleGetHistory = useCallback(async () => {
    if (fabricCd) {
      const { data: history } = await getHistory({
        data: {
          fabric_cd: fabricCd,
        },
      });
      handleChangeTab(history?.step);
      setIsSaved(history?.done);

      const step1 = history?.[1]?.at(-1);
      const step2 = history?.[2]?.at(-1);
      const step3 = history?.[3]?.at(-1);

      if (step1) {
        if (step1.input_image) {
          const file = await urlToFile(step1.input_image, 'image.jpg', 'image/jpeg');

          setPromptImages([
            {
              file,
              preview: API_BASE_URL + step1.input_image,
            },
          ]);
          setActiveStep1Tab(STEP1_TABS[1]);
        } else {
          setPromptMessage(step1.prompt);
          setActiveStep1Tab(STEP1_TABS[0]);
        }

        setFabrics({
          data: step1,
        });
        const _selectedImage = step1.image_path?.find((img) => img?.no === step1.choice);
        setSelectedImage(_selectedImage);
      }

      if (step2) {
        setSelectedCustomization({
          fabricType: step2?.input_type,
          colors: step2?.input_color,
          patterns: step2?.input_pattern,
        });
        setSpecificPrompt(step2?.prompt);
        setNumOfOutputs(step2?.image_path?.length);
        setGeneratedFabrics(step2);
        const _selectedGenImage = history?.[2]
          ?.at(-1)
          .image_path?.find((img) => img?.no === step2.choice);
        setSelectedGenImage(_selectedGenImage);
      }

      if (step3) {
        setMaps(step3?.image_paths?.[step3?.choice]);
      }
    }
  }, [fabricCd, getHistory, handleChangeTab]);

  useEffect(() => {
    handleGetHistory();
  }, [handleGetHistory]);

  const tabs = [
    {
      id: 1,
      component: (
        <Tab1
          message={promptMessage}
          images={promptImages}
          isSubmitting={submittingFindFabricWithText || submittingFindFabricWithImages}
          fabrics={fabrics?.data}
          selectedImage={selectedImage}
          onChangeMessage={handleChange}
          setImages={setPromptImages}
          onSubmitText={handleFindFabricsWithText}
          onSubmitImages={handleFindFabricsWithImages}
          setSelectedImage={setSelectedImage}
          endOfPageRef={endOfPageRef}
          handleChangeTab={handleChangeTab}
          activeStep1Tab={activeStep1Tab}
          setActiveStep1Tab={setActiveStep1Tab}
          fabricType={fabricType}
          setFabricType={setFabricType}
        />
      ),
    },
    {
      id: 2,
      component: (
        <Tab2
          selectedImage={selectedImage}
          numOfOutputs={numOfOutputs}
          setNumOfOutputs={setNumOfOutputs}
          handleGenerateFabrics={handleGenerateFabrics}
          specificPrompt={specificPrompt}
          setSpecificPrompt={setSpecificPrompt}
          generatedFabrics={generatedFabrics}
          selectedGenImage={selectedGenImage}
          setSelectedGenImage={setSelectedGenImage}
          isSubmitting={submittingGenerateFabrics}
          handleChangeTab={handleChangeTab}
          selectedCustomization={selectedCustomization}
          setSelectedCustomization={setSelectedCustomization}
          handleGenerateMaps={handleGenerateMaps}
          fabrics={fabrics?.data}
        />
      ),
    },
    {
      id: 3,
      component: (
        <Tab3
          maps={maps}
          submittingGenerateMaps={submittingGenerateMaps}
          selectedGenImage={selectedGenImage}
          handleChangeTab={handleChangeTab}
          generatedFabrics={generatedFabrics}
          setSelectedGenImage={setSelectedGenImage}
          handleGenerateMaps={handleGenerateMaps}
          handleSaveResults={handleSaveResults}
          isSaved={isSaved}
        />
      ),
    },
  ];

  return (
    <div
      className="relative flex-1"
      style={{ minHeight: `calc(100vh - ${navbarState?.size}px)` }}
    >
      {tabs.find((tab) => tab?.id === activeTab)?.component}
      <VersionHistory
        history={history?.data}
        handleChangeTab={handleChangeTab}
      />
    </div>
  );
}

export default Generator;
