import React from 'react';
import AppModal from './base/AppModal';

function ConfirmationModal({
  title = 'Are you sure you want to proceed?',
  description = 'This action cannot be undone.',
  onClose,
  onYes,
}) {
  return (
    <AppModal
      onClose={onClose}
      withClose
    >
      <div className="space-y-3 sm:space-y-6 text-center">
        <p className="font-bold text-base sm:text-lg">{title}</p>
        <p className="text-sm sm:text-base">{description}</p>
        <div className="flex gap-2 text-sm sm:text-base">
          <button
            className="flex-1 py-2 border rounded-md border-app-white-primary text-app-white-primary"
            onClick={onClose}
          >
            No
          </button>
          <button
            className="flex-1 py-2 rounded-md bg-app-white-primary text-app-black-primary"
            onClick={onYes}
          >
            Yes
          </button>
        </div>
      </div>
    </AppModal>
  );
}

export default ConfirmationModal;
