import React, { useCallback, useEffect, useRef } from 'react';

const AppDropdown = ({
  className = '',
  children,
  buttonComponent,
  isOpen,
  setOpen,
  position = 'right-0',
}) => {
  const dropdownRef = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (isOpen && dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        setOpen(false);
      }
    },
    [isOpen, setOpen]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div
      ref={dropdownRef}
      className={`relative text-left ${className}`}
    >
      <div className="h-full flex items-center">{buttonComponent}</div>
      <div
        className={`origin-top z-10 absolute ${position} mt-2 rounded-md shadow-md transform transition-all duration-100 ${
          isOpen ? 'opacity-100 scale-100 fade-in' : 'opacity-0 scale-y-0 fade-out'
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default AppDropdown;
