import React from 'react';

function TabHeader({ subtitle, title, description }) {
  return (
    <div className="space-y-2">
      {subtitle && (
        <p className="text-xs sm:text-sm font-bold text-app-brand-text">
          {subtitle}
        </p>
      )}
      {title && <p className="text-xl sm:text-2xl font-bold">{title}</p>}
      {description && <p className="text-sm sm:text-base">{description}</p>}
    </div>
  );
}

export default TabHeader;
