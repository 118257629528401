import React from 'react';

function CopyIcon({ className = 'w-6 h-6 fill-app-icon-info' }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.47049 18C8.91402 18 8.43765 17.8042 8.04137 17.4125C7.6451 17.0208 7.44696 16.55 7.44696 16V4C7.44696 3.45 7.6451 2.97917 8.04137 2.5875C8.43765 2.19583 8.91402 2 9.47049 2H18.5764C19.1328 2 19.6092 2.19583 20.0055 2.5875C20.4018 2.97917 20.5999 3.45 20.5999 4V16C20.5999 16.55 20.4018 17.0208 20.0055 17.4125C19.6092 17.8042 19.1328 18 18.5764 18H9.47049ZM9.47049 16H18.5764V4H9.47049V16ZM5.42343 22C4.86696 22 4.39059 21.8042 3.99431 21.4125C3.59804 21.0208 3.3999 20.55 3.3999 20V6H5.42343V20H16.5528V22H5.42343Z" />
    </svg>
  );
}

export default CopyIcon;
