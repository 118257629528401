import React from 'react';

function SectionLabel({ title, description }) {
  return (
    <div className="space-y-1">
      <p className="font-semibold text-xs sm:text-sm">{title}</p>
      {description && <p className="text-[10px] sm:text-xs">{description}</p>}
    </div>
  );
}

export default SectionLabel;
