import React from 'react';

import Button from 'components/button/Button';

import { API_BASE_URL } from 'utils/constants/api';
import AppCard from 'components/card/base/AppCard';
import TabHeader from 'components/tab/TabHeader';
import BackButton from 'components/button/BackButton';
import ResultCard from 'components/card/ResultCard';
import ExportIcon from 'assets/icons/ExportIcon';
import { useNavigate } from 'react-router-dom';
import AppImage from 'components/image/base/AppImage';

function Tab3({
  maps,
  submittingGenerateMaps,
  selectedGenImage,
  handleChangeTab,
  generatedFabrics,
  setSelectedGenImage,
  handleGenerateMaps,
  handleSaveResults,
  isSaved,
}) {
  const navigate = useNavigate();
  const handleSelect = (image) => {
    handleGenerateMaps(image);
    setSelectedGenImage(image);
  };

  const handleDone = async () => {
    await handleSaveResults();
    navigate('/');
  };
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 py-8 app-main-container gap-6">
      <AppCard>
        <div className="w-full space-y-8">
          <TabHeader
            subtitle="Step 3"
            title="Texture Maps"
            description="Here, the AI-generated image you chose will be used to create detailed texture maps for the fabric"
          />
          <div className="space-y-8">
            <div className="grid grid-cols-2 xl:grid-cols-3 gap-2 sm:gap-4">
              {generatedFabrics?.image_path?.map((image, index) => {
                return (
                  <div
                    key={index}
                    className={`min-w-20 sm:min-w-40 w-full aspect-square hover:opacity-80 transition-all cursor-pointer rounded-xl p-3 sm:p-4 ${
                      selectedGenImage?.no === image?.no
                        ? 'bg-app-brand border border-app-brand'
                        : 'border border-app-white-primary'
                    }`}
                    onClick={() => handleSelect(image)}
                  >
                    <AppImage
                      src={API_BASE_URL + image?.path}
                      alt={image?.path}
                      className="w-full h-full rounded-lg object-cover"
                    />
                  </div>
                );
              })}
            </div>

            <div className="flex justify-between">
              <BackButton onClick={() => handleChangeTab(2)} />
              {!isSaved && (
                <Button
                  onClick={handleDone}
                  disabled={!maps}
                  text="Save"
                />
              )}
            </div>
          </div>
        </div>
      </AppCard>
      <ResultCard
        cardTitle="Results"
        images={maps?.image_path}
        isSubmitting={submittingGenerateMaps}
        actionButton={
          <Button
            disabled={!maps}
            icon={<ExportIcon />}
            type="secondary"
            text="Export"
            onClick={() => window.open(API_BASE_URL + maps?.zip_path)}
          />
        }
      />
    </div>
  );
}

export default Tab3;
