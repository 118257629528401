import React from 'react';
import ConfirmationModal from './ConfirmationModal';

function DeleteModal({ onClose, onYes, isOpen }) {
  if (!isOpen) return null;
  return (
    <ConfirmationModal
      title="Are you sure you want to delete this?"
      description="This action cannot be undone."
      onClose={onClose}
      onYes={onYes}
    />
  );
}

export default DeleteModal;
