function SidebarIcon({ className = 'fill-app-white-primary h-8 w-8' }) {
  return (
    <svg
      className={className}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="7" width="22" height="2" />
      <rect x="4" y="21" width="22" height="2" />
    </svg>
  );
}

export default SidebarIcon;
