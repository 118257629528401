import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import TabHeader from 'components/tab/TabHeader';
import AppCard from 'components/card/base/AppCard';
import Button from 'components/button/Button';
import TextInput from 'components/input/TextInput';

function ChangePassword() {
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Password must be filled'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password must be filled'),
    }),
    onSubmit: async () => {},
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  return (
    <div className="bg-app-black-primary flex-1 flex place-content-center">
      <div className="app-main-container flex items-center justify-center space-y-4 sm:space-x-8">
        <AppCard className="space-y-8 max-w-[450px] w-full">
          <TabHeader title="Change Password" />
          <div className="space-y-4">
            <TextInput
              id="password"
              name="password"
              className="sm:col-span-2"
              type="password"
              label="Password"
              placeholder="Write Your password"
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.password}
              error={formik.errors.password}
              onKeyDown={handleKeyDown}
            />
            <TextInput
              id="confirmPassword"
              name="confirmPassword"
              className="sm:col-span-2"
              type="password"
              label="Confirm Password"
              placeholder="Write Your password"
              value={formik.values.confirmPassword}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.confirmPassword}
              error={formik.errors.confirmPassword}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="flex flex-col space-y-3 items-center">
            <Button
              onClick={formik.handleSubmit}
              className="w-full"
              text="Change Password"
              isSubmitting={false}
            />
          </div>
        </AppCard>
      </div>
    </div>
  );
}

export default ChangePassword;
