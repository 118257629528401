import React from 'react';

function MoreIcon({ className = 'h-5 w-5 fill-[#F1F1F1]' }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 22C11.3125 22 10.724 21.7552 10.2344 21.2656C9.74479 20.776 9.5 20.1875 9.5 19.5C9.5 18.8125 9.74479 18.224 10.2344 17.7344C10.724 17.2448 11.3125 17 12 17C12.6875 17 13.276 17.2448 13.7656 17.7344C14.2552 18.224 14.5 18.8125 14.5 19.5C14.5 20.1875 14.2552 20.776 13.7656 21.2656C13.276 21.7552 12.6875 22 12 22ZM12 14.5C11.3125 14.5 10.724 14.2552 10.2344 13.7656C9.74479 13.276 9.5 12.6875 9.5 12C9.5 11.3125 9.74479 10.724 10.2344 10.2344C10.724 9.74479 11.3125 9.5 12 9.5C12.6875 9.5 13.276 9.74479 13.7656 10.2344C14.2552 10.724 14.5 11.3125 14.5 12C14.5 12.6875 14.2552 13.276 13.7656 13.7656C13.276 14.2552 12.6875 14.5 12 14.5ZM12 7C11.3125 7 10.724 6.75521 10.2344 6.26562C9.74479 5.77604 9.5 5.1875 9.5 4.5C9.5 3.8125 9.74479 3.22396 10.2344 2.73438C10.724 2.24479 11.3125 2 12 2C12.6875 2 13.276 2.24479 13.7656 2.73438C14.2552 3.22396 14.5 3.8125 14.5 4.5C14.5 5.1875 14.2552 5.77604 13.7656 6.26562C13.276 6.75521 12.6875 7 12 7Z" />
    </svg>
  );
}

export default MoreIcon;
