import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MainContainer from './components/layout/MainContainer';
import { routes, authRoutes } from './utils/constants/route';
import { NavbarProvider } from 'context/NavbarContext';
import { VersionHistoryProvider } from 'context/VersionHistoryContext';
import { AuthProvider } from 'context/AuthContext';
import { LoadingProvider } from 'context/LoadingContext';

let router = createBrowserRouter([...routes, ...authRoutes]);

function App() {
  return (
    <LoadingProvider>
      <AuthProvider>
        <NavbarProvider>
          <VersionHistoryProvider>
            <MainContainer>
              <RouterProvider
                router={router}
                fallbackElement={<p>Loading...</p>}
              />
            </MainContainer>
          </VersionHistoryProvider>
        </NavbarProvider>
      </AuthProvider>
    </LoadingProvider>
  );
}

export default App;
