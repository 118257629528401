import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';
import React from 'react';

function BackButton({ onClick }) {
  return (
    <button
      className="flex space-x-1 items-center text-app-icon-info text-sm sm:text-base"
      onClick={onClick}
    >
      <ArrowLeftIcon className="fill-app-icon-info w-4 sm:w-5 h-4 sm:h-5" />
      <p>Back</p>
    </button>
  );
}

export default BackButton;
