import React, { useState, useEffect } from 'react';
import ImagePlaceholder from '../ImagePlaceholder';

const AppImage = ({ src, alt, className, iconClassName, ...props }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => setIsValid(true);
    img.onerror = () => setIsValid(false);
  }, [src]);

  if (isValid) {
    return (
      <img
        className={className}
        src={src}
        alt={alt}
        loading="lazy"
        {...props}
      />
    );
  }
  return (
    <ImagePlaceholder
      className={className}
      iconClassName={iconClassName}
    />
  );
};

export default AppImage;
