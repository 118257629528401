function CloseIcon({ className = 'fill-app-white-primary h-8 w-8' }) {
  return (
    <svg
      className={className}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="7.92871"
        y="6.51465"
        width="22"
        height="2"
        transform="rotate(45 7.92871 6.51465)"
      />
      <rect
        x="6.51465"
        y="22.071"
        width="22"
        height="2"
        transform="rotate(-45 6.51465 22.071)"
      />
    </svg>
  );
}

export default CloseIcon;
