import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import TabHeader from 'components/tab/TabHeader';
import AppCard from 'components/card/base/AppCard';
import Button from 'components/button/Button';
import TextInput from 'components/input/TextInput';

function ForgotPassword() {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email must be filled'),
    }),
    onSubmit: async () => {},
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  return (
    <div className="bg-app-black-primary flex-1 flex place-content-center">
      <div className="app-main-container flex items-center justify-center space-y-4 sm:space-x-8">
        <AppCard className="space-y-8 max-w-[450px] w-full">
          <TabHeader title="Forgot Password" />
          <div className="space-y-4">
            <TextInput
              id="email"
              name="email"
              type="email"
              label="E-mail"
              placeholder="Write Your E-mail"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.email}
              error={formik.errors.email}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="flex flex-col space-y-3 items-center">
            <Button
              onClick={formik.handleSubmit}
              className="w-full"
              text="Send Reset Password Link"
              isSubmitting={false}
            />
          </div>
        </AppCard>
      </div>
    </div>
  );
}

export default ForgotPassword;
