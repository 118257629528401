import api from 'api';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { API_BASE_URL } from 'utils/constants/api';

export const setCookie = (name, value, onSuccess = () => {}) => {
  if (value && value !== undefined) {
    const decodedToken = jwtDecode(value);
    const expirationInSeconds = decodedToken.exp;
    const expirationDate = new Date(expirationInSeconds * 1000);
    Cookies.set(name, value, { expires: expirationDate });
    onSuccess();
  }
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const decodeToken = (token) => {
  if (token && token !== 'undefined') {
    return jwtDecode(token);
  } else {
    return null;
  }
};

export async function urlToFile(url, filename, mimeType) {
  const response = await api.get(url, {
    responseType: 'blob',
  });

  const data = response.data;
  const metadata = { type: mimeType };

  return new File([data], filename, metadata);
}

export const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

export const parseUrl = (url) => {
  const base = API_BASE_URL || window.location.origin;
  if (url) {
    return URL.parse(url, base)?.href;
  } else {
    return base;
  }
};
