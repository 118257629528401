import Button from 'components/button/Button';
import AppCard from 'components/card/base/AppCard';
import ResultCard from 'components/card/ResultCard';
import AppImage from 'components/image/base/AppImage';
import SectionLabel from 'components/input/SectionLabel';
import Select from 'components/input/Select';
import TextArea from 'components/input/TextArea';
import TabHeader from 'components/tab/TabHeader';
import React, { useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { FABRIC_TYPES, STEP1_TABS } from 'utils/constants';

function Tab1({
  message,
  images,
  isSubmitting,
  fabrics,
  selectedImage,
  onChangeMessage,
  setImages,
  onSubmitText,
  onSubmitImages,
  setSelectedImage,
  endOfPageRef,
  handleChangeTab,
  activeStep1Tab,
  setActiveStep1Tab,
  fabricType,
  setFabricType,
}) {
  const inputFileRef = useRef(null);

  const handleFileUpload = (e) => {
    const _images = Array.from(e.target.files).map((file, i) => {
      return {
        file,
        preview: URL.createObjectURL(file),
      };
    });

    setImages(_images);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const _images = acceptedFiles.map((file) => {
      return {
        file,
        preview: URL.createObjectURL(file),
      };
    });

    setImages(_images);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    },
    multiple: false,
    onDrop,
  });

  const onSubmit = () => {
    if (activeStep1Tab?.id === 1) {
      onSubmitText();
    }
    if (activeStep1Tab?.id === 2) {
      onSubmitImages();
    }
  };
  const disableSend = (!message && !images.length) || isSubmitting;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 py-8 app-main-container gap-6">
      <AppCard className="h-fit w-full space-y-8">
        <TabHeader
          subtitle="Step 1"
          title="Initial Input"
          description="Start by providing a text description or uploading an image of the fabric you're looking for. Our AI will use this information to create fabric options for you."
        />
        <div className="space-y-8">
          <div className="flex p-1 bg-app-black-secondary rounded-lg">
            {STEP1_TABS?.map((_tab, index) => (
              <button
                key={index}
                className={`flex-1 p-2 sm:p-3 text-sm sm:text-base rounded font-bold ${
                  _tab?.id === activeStep1Tab?.id
                    ? 'bg-app-brand text-app-black-primary'
                    : 'bg-app-black-secondary text-app-white-primary'
                }`}
                onClick={() => setActiveStep1Tab(_tab)}
              >
                {_tab?.name}
              </button>
            ))}
          </div>
          <div className="space-y-4">
            {activeStep1Tab?.id === 1 && (
              <div className="space-y-2 sm:space-y-4">
                <Select
                  options={FABRIC_TYPES}
                  placeholder="Choose fabric type"
                  selectedOption={fabricType}
                  setSelectedOption={(selected) => setFabricType(selected)}
                />
                <TextArea
                  title="Text Prompt"
                  description="Describe the fabric you have in mind. Our AI will create options based on your text prompt."
                  placeholder="Enter your text prompt here"
                  value={message}
                  onChange={onChangeMessage}
                  onSend={onSubmit}
                  disabled={isSubmitting}
                />
              </div>
            )}
            {activeStep1Tab?.id === 2 && (
              <div className="space-y-2 sm:space-y-4">
                <SectionLabel
                  title="Upload Image"
                  description="Input an image that reflects the fabric you're looking to customize."
                />
                {images?.length > 0 ? (
                  <div className="w-fit border rounded-xl p-3">
                    <AppImage
                      className="w-40 h-40 rounded-lg object-cover"
                      src={images?.[0]?.preview}
                      alt={`Uploaded`}
                    />
                  </div>
                ) : (
                  <>
                    <input
                      ref={inputFileRef}
                      accept=".jpeg, .png, .jpg"
                      className="hidden"
                      type="file"
                      multiple
                      onChange={handleFileUpload}
                    />
                    <button
                      {...getRootProps()}
                      className="border rounded-md w-full px-8 h-40 text-xs sm:text-sm text-gray-400"
                      onClick={() => inputFileRef?.current?.click()}
                    >
                      Drop your image here or click to upload
                      <input
                        type="file"
                        accept=".jpeg, .png, .gif"
                        {...getInputProps()}
                      />
                    </button>
                  </>
                )}
              </div>
            )}
            <div className="flex gap-4 justify-end">
              {images?.length > 0 && (
                <Button
                  disabled={isSubmitting}
                  className="border border-app-brand text-app-brand px-2 py-1 rounded text-xs sm:text-sm font-medium"
                  type="secondary"
                  onClick={() => setImages([])}
                  text="Clear"
                />
              )}
              <Button
                className="w-full sm:w-[180px]"
                isSubmitting={isSubmitting}
                disabled={disableSend}
                onClick={onSubmit}
                text="Find"
              />
            </div>
          </div>
        </div>
        <div ref={endOfPageRef} />
      </AppCard>
      <ResultCard
        cardTitle="Results and Select Results"
        cardDescription="Select one fabric from our collection. Our AI has picked these option based on your preferences"
        images={fabrics?.image_path}
        descriptions={[{ title: 'Fabric type', description: fabrics?.fabric_type_nm }]}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        isSubmitting={isSubmitting}
        handleContinue={() => handleChangeTab(2)}
      />
    </div>
  );
}

export default Tab1;
