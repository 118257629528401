import React from 'react'

function AvatarIcon({
   className = "w-5 h-5 fill-app-matte-black"
}) {
   return (
      <svg className={className} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M12.6401 20H3.36009C2.34927 19.9633 1.40766 19.477 0.792441 18.6742C0.17722 17.8713 -0.0473393 16.8356 0.180094 15.85L0.420094 14.71C0.69613 13.1668 2.02272 12.0327 3.59009 12H12.4101C13.9775 12.0327 15.3041 13.1668 15.5801 14.71L15.8201 15.85C16.0475 16.8356 15.823 17.8713 15.2077 18.6742C14.5925 19.477 13.6509 19.9633 12.6401 20Z" />
         <path d="M8.50009 10H7.50009C5.29096 10 3.50009 8.20915 3.50009 6.00001V3.36001C3.49743 2.46807 3.85057 1.61189 4.48127 0.981192C5.11197 0.350491 5.96815 -0.00265152 6.86009 1.49917e-05H9.14009C10.032 -0.00265152 10.8882 0.350491 11.5189 0.981192C12.1496 1.61189 12.5028 2.46807 12.5001 3.36001V6.00001C12.5001 7.06088 12.0787 8.0783 11.3285 8.82844C10.5784 9.57859 9.56096 10 8.50009 10Z" />
      </svg>
   )
}

export default AvatarIcon