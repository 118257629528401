import React, { useEffect, useState } from 'react';
import AppCard from './base/AppCard';
import ImagePlaceholder from 'components/image/ImagePlaceholder';
import Button from 'components/button/Button';
import TabHeader from 'components/tab/TabHeader';
import AppImage from 'components/image/base/AppImage';
import ImageModal from 'components/modal/ImageModal';
import { parseUrl, preloadImages } from 'utils/helpers';

function ResultCard({
  cardTitle,
  cardDescription,
  images,
  descriptions,
  selectedImage,
  setSelectedImage,
  isSubmitting,
  handleContinue,
  actionButton,
}) {
  const [imageModal, setImageModal] = useState({
    index: 0,
    isOpen: false,
    text: '',
  });

  const openModal = (index, text) => {
    setImageModal({
      index,
      isOpen: true,
      text,
    });
  };

  const closeModal = () => {
    setImageModal({
      isOpen: false,
      text: '',
    });
  };

  const prevImg = () => {
    setImageModal((prev) => ({
      ...prev,
      index: imageModal.index > 0 ? imageModal.index - 1 : 0,
    }));
  };
  const nextImg = () => {
    setImageModal((prev) => ({
      ...prev,
      index: imageModal.index < images.length - 1 ? imageModal.index + 1 : images.length - 1,
    }));
  };

  useEffect(() => {
    if (images?.length > 0) {
      preloadImages(images.map((image) => parseUrl(image?.original)));
    }
  }, [images]);

  return (
    <AppCard className="min-h-[500px]">
      {isSubmitting ? (
        <div className="space-y-3 sm:space-y-4">
          <TabHeader
            title={cardTitle}
            description={cardDescription}
          />
          <div className="grid grid-cols-2 xl:grid-cols-3 gap-2 sm:gap-4">
            {Array.from({ length: 6 })?.map((_, index) => (
              <div
                key={index}
                className="min-w-20 border border-app-white-primary rounded-2xl p-3 sm:p-2 md:p-4 animate-pulse"
              >
                <div className={`bg-app-light-grey rounded-xl w-full aspect-square`} />
              </div>
            ))}
          </div>
        </div>
      ) : images?.length > 0 ? (
        <div className="space-y-4">
          <TabHeader
            title={cardTitle}
            description={cardDescription}
          />
          <div className="space-y-3 sm:space-y-6">
            <div className="grid grid-cols-2 xl:grid-cols-3 gap-2 sm:gap-4">
              {images.map((image, index) => {
                return (
                  <div
                    key={index}
                    className={`relative flex flex-col min-w-20 w-full aspect-square hover:opacity-80 transition-all cursor-pointer rounded-xl p-3 sm:p-2 md:p-4 ${
                      selectedImage?.path === image?.path
                        ? 'bg-app-brand border border-app-brand'
                        : 'border border-app-white-primary'
                    }`}
                    onClick={() =>
                      setSelectedImage && selectedImage?.path !== image?.path
                        ? setSelectedImage(image)
                        : openModal(index, image?.name)
                    }
                  >
                    {image?.name && (
                      <p className="text-xs sm:text-sm text-center font-semibold mb-1 sm:mb-2">
                        {image?.name}
                      </p>
                    )}
                    <AppImage
                      src={parseUrl(image?.path)}
                      alt={image?.path}
                      className="w-full h-full rounded-lg object-cover"
                    />
                    {!setSelectedImage || selectedImage?.path === image?.path ? (
                      <div className="absolute inset-0 p-4 bg-black/20 rounded-xl opacity-0 hover:opacity-100 hover:flex justify-center items-center w-full h-full">
                        <p className="text-center select-none text-sm sm:text-base">See Image</p>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            {descriptions?.map((desc, index) => (
              <div
                key={index}
                className={`space-y-1 sm:space-y-2`}
              >
                <p className="font-semibold text-sm sm:text-base">{desc?.title}</p>
                <p className="text-xs sm:text-sm">{desc?.description}</p>
              </div>
            ))}
          </div>
          <div className="flex justify-end">
            {actionButton || (
              <Button
                disabled={!selectedImage}
                onClick={handleContinue}
                className="w-full sm:w-[180px]"
                text="Continue"
              />
            )}
          </div>
        </div>
      ) : (
        <div className="h-full flex flex-col justify-center items-center gap-6">
          <div className="h-[88px] w-[88px] flex items-center justify-center bg-app-black-secondary rounded">
            <ImagePlaceholder />
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-center text-app-white-title text-xl font-bold leading-loose">
              Prompt results will be displayed here
            </div>
            <div className="text-center text-icon-primary text-base font-normal leading-normal">
              Write the prompt that you want to create a texture!
            </div>
          </div>
        </div>
      )}

      <ImageModal
        isOpen={imageModal?.isOpen}
        imageSrc={parseUrl(images?.[imageModal?.index]?.original)}
        text={images?.[imageModal?.index]?.name}
        onClose={closeModal}
        onPrev={prevImg}
        onNext={nextImg}
        disablePrev={imageModal?.index === 0}
        disableNext={imageModal?.index === images?.length - 1}
      />
    </AppCard>
  );
}

export default ResultCard;
