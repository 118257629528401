import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import TabHeader from 'components/tab/TabHeader';
import LoginIllustration from 'assets/illustrations/login-illustration.png';
import AppCard from 'components/card/base/AppCard';
import Button from 'components/button/Button';
import TextInput from 'components/input/TextInput';
import { LOGIN } from 'utils/constants/api';
import useFetch from 'utils/hooks/useFetch';

function Login() {
  const { fetch: login, isSubmitting: submittingLogin } = useFetch(LOGIN);
  const formik = useFormik({
    initialValues: {
      email: 'johndoe@example.com',
      password: 'securepassword123',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email must be filled'),
      password: Yup.string().required('Password must be filled'),
    }),
    onSubmit: async () => {
      await login({
        data: {
          email: formik.values.email,
          password: formik.values.password,
        },
        onSuccess: () => {
          window.location.href = '/';
        },
      });
    },
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  return (
    <div className="bg-app-black-primary flex-1 flex place-content-center">
      <div className="app-main-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 sm:items-center gap-4 sm:gap-8">
        <div className="flex justify-center items-center col-span-1 lg:col-span-3">
          <img
            className="object-contain"
            src={LoginIllustration}
            alt="login-illustration"
          />
        </div>
        <AppCard className="col-span-1 lg:col-span-2 space-y-8">
          <TabHeader
            title="Sign In"
            description="Lorem ipsum dolor sit amet consectetur. Dignissim ullamcorper fusce viverra tincidunt."
          />
          <div className="space-y-4">
            <TextInput
              id="email"
              name="email"
              type="email"
              label="E-mail"
              placeholder="Write Your E-mail"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.email}
              error={formik.errors.email}
              onKeyDown={handleKeyDown}
            />
            <div className="flex flex-col space-y-3">
              <TextInput
                id="password"
                name="password"
                type="password"
                label="Password"
                placeholder="Write Your Password"
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                touched={formik.touched.password}
                error={formik.errors.password}
                onKeyDown={handleKeyDown}
              />
              <a
                href="/login"
                className="inline-block w-fit ml-auto text-app-icon-info text-sm font-semibold"
              >
                Forgot Password
              </a>
            </div>
          </div>
          <div className="flex flex-col space-y-3 items-center">
            <Button
              onClick={formik.handleSubmit}
              className="w-full"
              text="Sign In"
              isSubmitting={submittingLogin}
            />
            <div className="h-8">
              <p className="text-sm">
                Don't have an account?{' '}
                <a
                  href="/register"
                  className="text-app-icon-info underline font-semibold"
                >
                  Sign Up for an Account
                </a>
              </p>
            </div>
          </div>
        </AppCard>
      </div>
    </div>
  );
}

export default Login;
