import { useNavbarState } from 'context/NavbarContext';

function NotFound() {
  const { navbarState } = useNavbarState();

  return (
    <div
      className="app-main-container flex flex-col items-center justify-center text-center gap-2 sm:gap-4"
      style={{
        height: `calc(100vh - ${navbarState.size}px)`,
      }}
    >
      <div className="text-3xl sm:text-5xl font-bold">404</div>
      <div className="text-sm sm:text-base max-w-80">
        The page at <span className="font-semibold">'{window.location.pathname}'</span> is
        unavailable or does not exist.
      </div>
    </div>
  );
}

export default NotFound;
