import { createContext, useContext, useState } from 'react';

export const LoadingContext = createContext();
export const LoadingUpdateContext = createContext();

export const useLoadingState = () => {
  return {
    loading: useContext(LoadingContext),
    setLoading: useContext(LoadingUpdateContext),
  };
};

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState({
    isLoading: false,
  });

  return (
    <LoadingContext.Provider value={loading}>
      <LoadingUpdateContext.Provider value={setLoading}>{children}</LoadingUpdateContext.Provider>
    </LoadingContext.Provider>
  );
};
