import React from 'react';

function HistoryIcon({ className = 'h-5 w-5 fill-app-icon-info' }) {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 12.2222C9.38889 12.2222 8.86592 12.0048 8.43111 11.57C7.99629 11.1352 7.77852 10.6118 7.77778 10C7.77703 9.38815 7.99481 8.86518 8.43111 8.43111C8.8674 7.99704 9.39037 7.77926 10 7.77778C10.6096 7.77629 11.133 7.99407 11.57 8.43111C12.007 8.86815 12.2244 9.39111 12.2222 10C12.22 10.6089 12.0026 11.1322 11.57 11.57C11.1374 12.0078 10.6141 12.2252 10 12.2222ZM10 20C7.42592 20 5.19444 19.153 3.30555 17.4589C1.41667 15.7648 0.333333 13.6489 0.0555555 11.1111H2.33333C2.59259 13.037 3.44926 14.6296 4.90333 15.8889C6.35741 17.1481 8.05629 17.7778 10 17.7778C12.1667 17.7778 14.0048 17.0233 15.5144 15.5144C17.0241 14.0056 17.7785 12.1674 17.7778 10C17.777 7.83259 17.0226 5.99481 15.5144 4.48667C14.0063 2.97852 12.1681 2.2237 10 2.22222C8.72222 2.22222 7.52778 2.51852 6.41666 3.11111C5.30555 3.7037 4.37037 4.51852 3.61111 5.55555H6.66667V7.77778H0V1.11111H2.22222V3.72222C3.16667 2.53704 4.31963 1.62037 5.68111 0.972222C7.04259 0.324074 8.48222 0 10 0C11.3889 0 12.69 0.264074 13.9033 0.792222C15.1167 1.32037 16.1722 2.03296 17.07 2.93C17.9678 3.82704 18.6807 4.88259 19.2089 6.09667C19.737 7.31074 20.0007 8.61185 20 10C19.9993 11.3881 19.7355 12.6893 19.2089 13.9033C18.6822 15.1174 17.9693 16.173 17.07 17.07C16.1707 17.967 15.1152 18.68 13.9033 19.2089C12.6915 19.7378 11.3904 20.0015 10 20Z" />
    </svg>
  );
}

export default HistoryIcon;
