import React, { useEffect } from 'react';
import Navbar from './Navbar';
import { useAuthState } from 'context/AuthContext';
import { decodeToken, getCookie } from 'utils/helpers';
import { authRoutes } from 'utils/constants/route';
import LoadingTop from 'components/loading/LoadingTop';

function MainContainer({ children }) {
  const userToken = getCookie('ut');
  const refreshToken = getCookie('rt');
  const { setAuthState } = useAuthState();

  useEffect(() => {
    const decodedToken = decodeToken(userToken);
    if (decodedToken) {
      setAuthState(decodedToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  const isAuthPage = authRoutes.some((route) => window.location.pathname === route.path);

  const isNoToken = !isAuthPage && !userToken && !refreshToken;

  useEffect(() => {
    if (isNoToken) {
      window.location.href = '/login';
    }
  }, [isNoToken]);

  if (isNoToken) return null;

  return (
    <div className="flex flex-col w-full min-h-screen h-full">
      <Navbar />
      <LoadingTop />
      {children}
    </div>
  );
}

export default MainContainer;
