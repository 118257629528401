import ImageIcon from 'assets/icons/ImageIcon';
import React from 'react';

function ImagePlaceholder({ className, iconClassName }) {
  return (
    <div
      className={`p-3 bg-app-black-secondary flex items-center justify-center ${className}`}
    >
      <ImageIcon className={iconClassName} />
    </div>
  );
}

export default ImagePlaceholder;
