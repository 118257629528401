import React from 'react';

function ImageIcon({ className = 'h-[26px] w-[26px] fill-app-icon-primary' }) {
  return (
    <svg
      className={className}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.88889 26C2.09444 26 1.41435 25.7171 0.848611 25.1514C0.28287 24.5856 0 23.9056 0 23.1111V2.88889C0 2.09444 0.28287 1.41435 0.848611 0.848611C1.41435 0.28287 2.09444 0 2.88889 0H23.1111C23.9056 0 24.5856 0.28287 25.1514 0.848611C25.7171 1.41435 26 2.09444 26 2.88889V23.1111C26 23.9056 25.7171 24.5856 25.1514 25.1514C24.5856 25.7171 23.9056 26 23.1111 26H2.88889ZM2.88889 23.1111H23.1111V2.88889H2.88889V23.1111ZM4.33333 20.2222H21.6667L16.25 13L11.9167 18.7778L8.66667 14.4444L4.33333 20.2222ZM7.94444 10.1111C8.5463 10.1111 9.05787 9.90046 9.47917 9.47917C9.90046 9.05787 10.1111 8.5463 10.1111 7.94444C10.1111 7.34259 9.90046 6.83102 9.47917 6.40972C9.05787 5.98843 8.5463 5.77778 7.94444 5.77778C7.34259 5.77778 6.83102 5.98843 6.40972 6.40972C5.98843 6.83102 5.77778 7.34259 5.77778 7.94444C5.77778 8.5463 5.98843 9.05787 6.40972 9.47917C6.83102 9.90046 7.34259 10.1111 7.94444 10.1111Z" />
    </svg>
  );
}

export default ImageIcon;
