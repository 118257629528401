import React, { useEffect } from 'react';
import CloseSquaredIcon from 'assets/icons/CloseSquaredIcon';
import AppImage from 'components/image/base/AppImage';
import ChevronLeftIcon from 'assets/icons/ChevronLeftIcon';
import ChevronRightIcon from 'assets/icons/ChevronRightIcon';

const ImageModal = ({
  isOpen,
  imageSrc,
  text,
  onClose,
  onPrev,
  onNext,
  disablePrev,
  disableNext,
}) => {
  useEffect(() => {
    const onKeyDown = (event) => {
      switch (event.key) {
        case 'Escape':
          onClose();
          break;
        case 'ArrowLeft':
          onPrev();
          break;
        case 'ArrowRight':
          onNext();
          break;
        default:
          break;
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', onKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [isOpen, onClose, onNext, onPrev]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-40 flex justify-center items-center">
      <div className="z-50">
        <button
          className="absolute top-0 right-0 m-4 p-4"
          onClick={onClose}
        >
          <CloseSquaredIcon className="h-6 sm:h-8 w-6 sm:w-8 fill-app-white-primary" />
        </button>
        <div className="flex items-center space-x-2 sm:space-x-8 p-2">
          <button
            className="p-1 sm:p-2"
            onClick={onPrev}
          >
            <ChevronLeftIcon
              className={`h-6 sm:h-8 w-6 sm:w-8 transition-all ${
                disablePrev ? 'fill-app-icon-disabled' : 'fill-app-white-primary'
              }`}
            />
          </button>
          <div className="space-y-2 sm:space-y-4 min-w-56 sm:min-w-64 aspect-square">
            <AppImage
              src={imageSrc}
              alt={text}
              className="max-h-[80vh] h-full rounded-lg shadow-lg"
            />
            {text && <p className="text-sm sm:text-base font-bold text-center">{text}</p>}
          </div>
          <button
            className="p-1 sm:p-2"
            onClick={onNext}
          >
            <ChevronRightIcon
              className={`h-6 sm:h-8 w-6 sm:w-8 transition-all ${
                disableNext ? 'fill-app-icon-disabled' : 'fill-app-white-primary'
              }`}
            />
          </button>
        </div>
      </div>
      <div
        className="fixed inset-0 z-40 bg-app-black-primary/50"
        onClick={onClose}
      />
    </div>
  );
};

export default ImageModal;
