import React from 'react'

function EllipseIcon({
   className = "w-1 h-1 fill-white"
}) {
   return (
      <svg className={className} viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
         <circle cx="2" cy="2" r="2" />
      </svg>
   )
}

export default EllipseIcon