import React from 'react';

function ExportIcon({ className = 'w-6 h-6 fill-app-brand-text' }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.875 16.5V7.33125L7.95 10.2563L6.375 8.625L12 3L17.625 8.625L16.05 10.2563L13.125 7.33125V16.5H10.875ZM5.25 21C4.63125 21 4.10156 20.7797 3.66094 20.3391C3.22031 19.8984 3 19.3687 3 18.75V15.375H5.25V18.75H18.75V15.375H21V18.75C21 19.3687 20.7797 19.8984 20.3391 20.3391C19.8984 20.7797 19.3687 21 18.75 21H5.25Z" />
    </svg>
  );
}

export default ExportIcon;
