function ChevronLeftIcon({ className = 'w-6 h-6 fill-app-icon-disabled' }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.8541 22L5.80005 12L15.8541 2L18.2 4.33333L10.4919 12L18.2 19.6667L15.8541 22Z" />
    </svg>
  );
}

export default ChevronLeftIcon;
