import CloseSquaredIcon from 'assets/icons/CloseSquaredIcon';
import { useNavbarState } from 'context/NavbarContext';
import { useVersionHistoryState } from 'context/VersionHistoryContext';
import Content from './components/Content';
import { IS_MOBILE_SCREEN } from 'utils/constants';

function VersionHistory({ history = null, handleChangeTab }) {
  const { navbarState } = useNavbarState();
  const { versionHistoryState, setVersionHistoryState } = useVersionHistoryState();

  const step1 = history?.[1]?.at(-1);
  const step2 = history?.[2]?.at(-1);
  const step3 = history?.[3]?.at(-1);

  const handleOnView = (tab) => {
    handleChangeTab(tab);
    IS_MOBILE_SCREEN && setVersionHistoryState((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <div
      className={`flex flex-col fixed top-0 bottom-0 right-0 bg-app-black-primary border-l border-app-black-secondary shadow-lg py-4 sm:py-8 ${
        versionHistoryState?.isOpen
          ? 'w-[300px] sm:w-[min(360px,80vh)] px-4 opacity-100'
          : 'w-0 opacity-0'
      } transition-all duration-100`}
      style={{ marginTop: navbarState.size }}
    >
      <div className="flex justify-between border-b pb-4 mb-4">
        <p>Version History</p>
        <button onClick={() => setVersionHistoryState((prev) => ({ ...prev, isOpen: false }))}>
          <CloseSquaredIcon className="h-6 w-6 fill-app-white-primary" />
        </button>
      </div>
      <div className="flex-1 overflow-y-auto pr-2">
        {step3 && (
          <>
            <Content
              mainImage={step2.image_path?.find((img) => img?.no === step1.choice)}
              stepLabel="Texture Maps"
              createdDate={step3.created_dt}
              textureMaps={step3?.image_paths?.[step3?.choice]?.image_path}
              downloadLink={step3?.image_paths?.[step3?.choice]?.zip_path}
              onView={() => handleOnView(3)}
            />
            <div className="border-b h-4 mb-4" />
          </>
        )}
        {step2 && (
          <>
            <Content
              mainImage={step2.image_path?.find((img) => img?.no === step1.choice)}
              stepLabel="Customization Process"
              createdDate={step2.created_dt}
              generatedImages={step2.image_path}
              promptText={step2.prompt}
              colorText={step2.input_color?.join(', ')}
              patternText={step2.input_pattern?.join(', ')}
              onView={() => handleOnView(2)}
            />
            <div className="border-b h-4 mb-4" />
          </>
        )}
        {step1 && (
          <>
            <Content
              mainImage={step1?.image_path?.find((img) => img?.no === step1.choice)}
              stepLabel="Initial Input"
              createdDate={step1.created_dt}
              generatedImages={step1.image_path}
              promptText={step1.prompt}
              imageInput={step1.input_image}
              fabricText={step1?.fabric_type_nm}
              onView={() => handleOnView(1)}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default VersionHistory;
