import React from 'react';
import SectionLabel from './SectionLabel';

function TextArea({
  id,
  name,
  className,
  title,
  topDescription,
  description,
  placeholder,
  disabled,
  value,
  onChange,
  onBlur,
  touched,
  error,
  autoFocus,
  onSend,
}) {
  const handleOnKeyDown = (event, fn) => {
    if (event.key === 'Enter' && !event.shiftKey && !disabled) {
      event.preventDefault();
      fn();
    }
  };

  return (
    <div className={`space-y-2 sm:space-y-4 ${className}`}>
      <SectionLabel
        title={title}
        description={topDescription}
      />
      <div>
        <textarea
          id={id}
          name={name}
          className={`resize-none w-full text-xs sm:text-sm h-40 px-3 py-2 border ${
            touched && error ? 'border-app-red' : 'border-app-light-grey'
          } rounded-md`}
          placeholder={placeholder}
          value={value}
          autoFocus={autoFocus}
          onChange={onChange}
          onKeyDown={(e) => handleOnKeyDown(e, onSend)}
          disabled={disabled}
          onBlur={onBlur}
        />
        {touched && error ? (
          <div className="text-xs text-app-red-secondary mt-2">{error}</div>
        ) : null}
        <p className="text-[10px] sm:text-xs text-app-grey-tertiary">{description}</p>
      </div>
    </div>
  );
}

export default TextArea;
