import React from 'react';

function CoinsIcon({ className = 'w-5 h-5 fill-app-icon-warning' }) {
  return (
    <svg
      className={className}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.7273 15.28C10.697 15.28 8.97727 14.5748 7.56818 13.1643C6.15909 11.7538 5.45455 10.0323 5.45455 8C5.45455 5.96767 6.15909 4.24625 7.56818 2.83575C8.97727 1.42525 10.697 0.720001 12.7273 0.720001C14.7576 0.720001 16.4773 1.42525 17.8864 2.83575C19.2955 4.24625 20 5.96767 20 8C20 10.0323 19.2955 11.7538 17.8864 13.1643C16.4773 14.5748 14.7576 15.28 12.7273 15.28ZM5.45455 15.0525C3.84848 14.6278 2.53788 13.7633 1.52273 12.459C0.507576 11.1547 0 9.66833 0 8C0 6.33167 0.507576 4.84533 1.52273 3.541C2.53788 2.23667 3.84848 1.37217 5.45455 0.947501V2.8585C4.36364 3.23767 3.48485 3.89742 2.81818 4.83775C2.15152 5.77808 1.81818 6.83217 1.81818 8C1.81818 9.16784 2.15152 10.2219 2.81818 11.1623C3.48485 12.1026 4.36364 12.7623 5.45455 13.1415V15.0525ZM12.7273 13.46C14.2424 13.46 15.5303 12.9292 16.5909 11.8675C17.6515 10.8058 18.1818 9.51667 18.1818 8C18.1818 6.48333 17.6515 5.19417 16.5909 4.1325C15.5303 3.07083 14.2424 2.54 12.7273 2.54C11.2121 2.54 9.92424 3.07083 8.86364 4.1325C7.80303 5.19417 7.27273 6.48333 7.27273 8C7.27273 9.51667 7.80303 10.8058 8.86364 11.8675C9.92424 12.9292 11.2121 13.46 12.7273 13.46Z" />
    </svg>
  );
}

export default CoinsIcon;
