import { useState } from 'react';

const Tooltip = ({ text, children, trigger = 'hover', type = 'neutral' }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    if (trigger === 'hover' || trigger === 'both') {
      setIsVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (trigger === 'hover' || trigger === 'both') {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    if (trigger === 'click' || trigger === 'both') {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    }
  };

  return (
    <div
      className="inline-block relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <span className="hover:opacity-80 transition-all">{children}</span>
      <div
        className={`absolute left-1/2 -top-8 w-max -translate-x-1/2 transform ${
          isVisible ? 'opacity-100 mb-2 h-auto px-2 py-1' : 'opacity-0 mb-0 h-0 px-0 py-0'
        }  transition-all rounded ${
          type === 'success' ? 'bg-app-brand' : 'bg-app-black-secondary'
        } text-white text-[10px] sm:text-xs`}
      >
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
