import { useLoadingState } from 'context/LoadingContext';
import TopBarProgress from 'react-topbar-progress-indicator';

function LoadingTop() {
  const { loading } = useLoadingState();

  TopBarProgress.config({
    barColors: {
      0: '#4E9E87',
      '1.0': '#4E9E87',
    },
    shadowBlur: 5,
  });

  return <div>{loading.isLoading && <TopBarProgress />}</div>;
}

export default LoadingTop;
