import React from 'react';

function FrontierLogo({ className = 'h-5 sm:h-7' }) {
  return (
    <svg
      className={className}
      viewBox="0 0 151 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9989 0H0V28H27.9989V0Z"
        fill="url(#paint0_linear_95_2196)"
      />
      <path
        d="M3.17672 8.59189V3.18108H8.58478L3.17672 8.59315V8.59189Z"
        fill="#FDFEFE"
      />
      <path
        d="M3.17672 14.0039V8.59189L8.58478 8.59178L3.17672 14.0039Z"
        fill="#FDFEFE"
      />
      <path
        d="M8.58478 8.59178V3.18108H13.993L8.58495 8.59315L8.58478 8.59178Z"
        fill="#FDFEFE"
      />
      <path
        d="M16.697 3.18108H22.1051L16.697 8.59315V3.18108Z"
        fill="#FDFEFE"
      />
      <path
        d="M8.58495 14.0039L8.58478 8.59178H13.993L8.58495 14.0039Z"
        fill="#FDFEFE"
      />
      <path
        d="M13.9932 19.416V14.0039H19.4013L13.9932 19.416Z"
        fill="#FDFEFE"
      />
      <path
        d="M3.17672 19.416V14.0039H8.58495L3.17672 19.416Z"
        fill="#FDFEFE"
      />
      <path
        d="M3.17672 24.8281V19.416L8.58478 19.416L3.17672 24.8281Z"
        fill="#FDFEFE"
      />
      <path
        d="M35.6655 2.77579H52.4598V7.15615H40.5174V11.9199H51.041V16.3003H40.5174V24.844H35.6655V2.77579Z"
        fill="#FDFEFE"
      />
      <path
        d="M54.2535 7.94485H59.0424V11.349C59.2555 10.8117 59.5291 10.3097 59.8656 9.83925C60.2015 9.37011 60.5889 8.94758 61.0287 8.57298C61.8685 7.88052 63.0025 7.56522 64.4312 7.62955V12.6519H64.1833C62.5826 12.6519 61.3275 13.1349 60.418 14.1023C59.5091 15.0685 59.051 16.5706 59.0447 18.6088V24.8533H54.2548V7.94485H54.2535Z"
        fill="#FDFEFE"
      />
      <path
        d="M74.135 25.2215C72.8828 25.2328 71.6728 25.0083 70.5103 24.5441C69.4266 24.1141 68.4657 23.4935 67.6271 22.6838C66.8096 21.8841 66.1743 20.9596 65.7225 19.9103C65.2523 18.818 65.0215 17.6778 65.0312 16.4872V16.4241C65.0227 15.2373 65.2535 14.0997 65.7225 13.0099C66.1789 11.953 66.8199 11.0222 67.6448 10.2188C68.4897 9.40141 69.4557 8.77082 70.5429 8.32687C71.1221 8.09984 71.7185 7.92828 72.3304 7.81099C72.9416 7.69496 73.5586 7.63566 74.1813 7.6344C74.8045 7.63315 75.4215 7.68862 76.0328 7.80339C76.6446 7.91691 77.241 8.08595 77.8226 8.31173C78.9045 8.7418 79.866 9.36108 80.7046 10.1696C81.5215 10.9692 82.1574 11.8937 82.6104 12.9431C83.0817 14.0353 83.3125 15.1755 83.3039 16.3648V16.4254C83.3125 17.6148 83.0817 18.7562 82.6104 19.8485C82.1551 20.9054 81.5141 21.8337 80.6892 22.6371C79.8443 23.4557 78.8783 24.0863 77.79 24.5315C76.6183 25.0045 75.3998 25.234 74.1361 25.2189L74.135 25.2215ZM74.1978 21.092C74.8388 21.1047 75.4529 20.9836 76.0391 20.7288C76.568 20.4968 77.0245 20.165 77.4101 19.7349C77.7974 19.3036 78.0888 18.8155 78.283 18.2694C78.4887 17.693 78.5881 17.0989 78.5846 16.4872V16.4241C78.5881 15.8112 78.4835 15.2184 78.2704 14.6445C78.0625 14.0895 77.7586 13.5952 77.3558 13.1613C76.9514 12.7248 76.4784 12.383 75.9374 12.1358C75.3672 11.876 74.7691 11.7512 74.1436 11.7587C73.5032 11.7461 72.8891 11.8672 72.3001 12.122C71.7728 12.3553 71.3164 12.687 70.929 13.1133C70.5417 13.5447 70.2504 14.0341 70.0561 14.5814C69.8533 15.1578 69.7534 15.7519 69.7574 16.3636V16.4254C69.7522 17.0384 69.8579 17.6324 70.0738 18.2075C70.2795 18.7625 70.5823 19.2557 70.9856 19.6883C71.3855 20.1222 71.8533 20.464 72.3869 20.7149C72.9622 20.976 73.566 21.1022 74.1978 21.092Z"
        fill="#FDFEFE"
      />
      <path
        d="M89.981 7.94459V10.3599C90.2569 9.99542 90.5574 9.65236 90.8802 9.32946C91.2104 8.99651 91.5748 8.70515 91.9713 8.45538C92.3849 8.1943 92.8259 7.99252 93.2938 7.84999C93.8291 7.69739 94.3763 7.62421 94.9322 7.63181C96.7597 7.63181 98.1724 8.18928 99.1699 9.3017C100.168 10.4154 100.666 11.9504 100.664 13.9053V24.8442H95.8719V15.4377C95.8719 14.3064 95.6211 13.4525 95.1167 12.8736C94.6134 12.296 93.8982 12.0046 92.9739 11.9996C92.0478 11.9996 91.32 12.2909 90.7905 12.8736C90.2609 13.4563 89.9936 14.3114 89.9873 15.4377V24.8417H85.1909V7.94459H89.981Z"
        fill="#FDFEFE"
      />
      <path
        d="M108.95 25.1282C108.276 25.1345 107.612 25.0563 106.957 24.8923C106.372 24.7435 105.859 24.4648 105.415 24.0549C104.954 23.616 104.618 23.0963 104.407 22.496C104.141 21.7026 104.02 20.8891 104.045 20.0516V12.0426H102.028V7.94354H104.045V3.62119H108.833V7.9448H112.803V12.0439H108.823V19.2885C108.823 20.3871 109.338 20.937 110.369 20.9358C110.783 20.937 111.191 20.8841 111.591 20.7769C111.992 20.6696 112.372 20.512 112.73 20.3039V24.1494C112.157 24.485 111.549 24.7347 110.907 24.9012C110.265 25.0677 109.612 25.1433 108.949 25.1282H108.95Z"
        fill="#FDFEFE"
      />
      <path
        d="M114.966 12.7389L119.755 7.93476V24.8331H114.966V12.7389Z"
        fill="#FDFEFE"
      />
      <path
        d="M130.689 25.2217C129.478 25.233 128.307 25.0224 127.176 24.5923C126.116 24.1887 125.182 23.5896 124.372 22.795C123.571 22.0004 122.957 21.081 122.531 20.0367C122.075 18.8977 121.857 17.7147 121.876 16.4875V16.4244C121.866 15.2489 122.068 14.1112 122.483 13.0102C122.875 11.9671 123.447 11.035 124.199 10.2127C124.581 9.79649 125.004 9.42566 125.464 9.099C125.924 8.77233 126.415 8.49737 126.933 8.27542C127.452 8.05216 127.988 7.88694 128.543 7.7772C129.098 7.66745 129.657 7.61826 130.22 7.62838C130.849 7.61198 131.473 7.66871 132.091 7.79611C132.707 7.92351 133.301 8.12026 133.874 8.38385C134.883 8.86819 135.738 9.54546 136.44 10.417C137.137 11.2949 137.641 12.2724 137.95 13.3507C138.278 14.477 138.44 15.6273 138.437 16.8015C138.437 16.992 138.437 17.1925 138.42 17.4019C138.406 17.6112 138.394 17.8383 138.372 18.0565H126.624C126.855 19.1487 127.336 19.9761 128.057 20.5311C128.445 20.8199 128.869 21.0356 129.331 21.1806C129.791 21.3257 130.264 21.39 130.746 21.3749C131.489 21.3875 132.202 21.25 132.887 20.9637C133.609 20.6294 134.261 20.188 134.84 19.6406L137.583 22.0685C136.788 23.0561 135.821 23.8292 134.682 24.3867C134.058 24.6768 133.409 24.8925 132.734 25.0338C132.06 25.1738 131.378 25.2369 130.689 25.2205V25.2217ZM133.778 15.0711C133.727 14.5893 133.606 14.1264 133.412 13.6824C133.218 13.2385 132.962 12.8348 132.643 12.4704C132.488 12.3051 132.318 12.1588 132.131 12.0314C131.946 11.9028 131.747 11.7968 131.538 11.7111C131.329 11.6266 131.113 11.5635 130.89 11.5257C130.667 11.4865 130.443 11.4727 130.218 11.4828C129.991 11.474 129.766 11.4878 129.543 11.5257C129.32 11.5635 129.102 11.6253 128.892 11.7098C128.681 11.7943 128.481 11.899 128.293 12.0251C128.105 12.1512 127.932 12.2963 127.775 12.459C127.445 12.826 127.178 13.2321 126.97 13.6786C126.763 14.1251 126.627 14.5918 126.56 15.0799L133.778 15.0711Z"
        fill="#FDFEFE"
      />
      <path
        d="M140.423 7.94485H145.213V11.349C145.425 10.8117 145.699 10.3085 146.034 9.83925C146.37 9.37011 146.757 8.94758 147.197 8.57298C148.037 7.88052 149.171 7.56522 150.6 7.62955V12.6519H150.349C148.748 12.6519 147.494 13.1349 146.584 14.1023C145.675 15.0685 145.218 16.5706 145.213 18.6088V24.8533H140.423V7.94485Z"
        fill="#FDFEFE"
      />
      <path
        d="M114.966 7.93476L114.978 12.7389L119.755 7.93476H114.966Z"
        fill="url(#paint1_linear_95_2196)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_95_2196"
          x1="0"
          y1="0"
          x2="28"
          y2="27.9989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8BE574" />
          <stop
            offset="0.32"
            stopColor="#25A056"
          />
          <stop
            offset="1"
            stopColor="#BEE9ED"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_95_2196"
          x1="114.966"
          y1="10.3364"
          x2="119.755"
          y2="10.3364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8BE574" />
          <stop
            offset="0.07"
            stopColor="#72D46C"
          />
          <stop
            offset="0.26"
            stopColor="#3AAE5C"
          />
          <stop
            offset="0.35"
            stopColor="#25A056"
          />
          <stop
            offset="0.56"
            stopColor="#62BD92"
          />
          <stop
            offset="0.76"
            stopColor="#93D4C3"
          />
          <stop
            offset="0.91"
            stopColor="#B2E3E1"
          />
          <stop
            offset="1"
            stopColor="#BEE9ED"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default FrontierLogo;
