function ChevronRightIcon({ className = 'w-6 h-6 fill-app-icon-disabled' }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.5082 12L5.80005 4.33333L8.14599 2L18.2 12L8.14599 22L5.80005 19.6667L13.5082 12Z" />
    </svg>
  );
}

export default ChevronRightIcon;
