import { createContext, useContext, useState } from 'react';

export const VersionHistoryContext = createContext();
export const VersionHistoryUpdateContext = createContext();

export const useVersionHistoryState = () => {
  return {
    versionHistoryState: useContext(VersionHistoryContext),
    setVersionHistoryState: useContext(VersionHistoryUpdateContext),
  };
};

export const VersionHistoryProvider = ({ children }) => {
  const [versionHistoryState, setVersionHistoryState] = useState({
    isOpen: false,
  });

  return (
    <VersionHistoryContext.Provider value={versionHistoryState}>
      <VersionHistoryUpdateContext.Provider value={setVersionHistoryState}>
        {children}
      </VersionHistoryUpdateContext.Provider>
    </VersionHistoryContext.Provider>
  );
};
